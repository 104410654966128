export interface IPjListingProps {}

export interface IPjDetailNavProps {
  totalIDs: string[];
  //  currID: string;
  statusTab: string[];
}

export enum typeOfSiteText {
  AGRICULTURAL_LAND = "TXT_Option_AGRICULTURAL_LAND",
  CAR_PARK = "TXT_Option_CAR_PARK",
  COMMERCIAL = "TXT_Option_COMMERCIAL",
  FISH_POND = "TXT_Option_FISH_POND",
  GOVERNMENT_PROJECT = "TXT_Option_GOVERNMENT_PROJECT",
  INDUSTRIAL = "TXT_Option_INDUSTRIAL",
  RESIDENTIAL = "TXT_Option_RESIDENTIAL",
  SCHOOL_CAMPUS = "TXT_Option_SCHOOL_CAMPUS",
  VILLAGE_HOUSE = "TXT_Option_VILLAGE_HOUSE",
  WAREHOUSE = "TXT_Option_WAREHOUSE",
}

export enum typeOfSiteColor {
  AGRICULTURAL_LAND = "#09A34E",
  CAR_PARK = "#D70A8E",
  COMMERCIAL = "#459EE3",
  FISH_POND = "#6A3398",
  GOVERNMENT_PROJECT = "#E71b2b",
  INDUSTRIAL = "#6C330C",
  RESIDENTIAL = "LB.3",
  SCHOOL_CAMPUS = "#EACD29",
  VILLAGE_HOUSE = "#F4891E",
  WAREHOUSE = "GN.3",
}
