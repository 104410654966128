import { Box, chakra, Flex, Text, Divider } from "@chakra-ui/react";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PieChart,
  Cell,
  Pie,
  Sector,
  Label,
  ResponsiveContainer,
} from "recharts";
import { returnByColor } from "src/utils/util";

const AverageEfficiencyPieChart = ({ ratioData }: any) => {
  const pieContainerRef = useRef<any>();
  const [width, setWidth] = useState<any>(0);
  useLayoutEffect(() => {
    setWidth(pieContainerRef?.current?.offsetWidth);
  }, []);

  const { t } = useTranslation();
  const COLORS = [
    "#F4891E",
    "#6C330C",
    "#64A2BB",
    "#459EE3",
    "#09A34E",
    "#7DDF92",
    "#D70A8E",
    "#6A3398",
    "#EACD29",
    "#E71B2B",
  ];
  const [activeIndex, setActiveIndex] = useState(-1);
  const RADIAN = Math.PI / 180;

  const totalOfType = ratioData
    .filter((d: any) => d.value !== 0)
    ?.reduce((partialSum: any, data: any) => partialSum + data.value, 0);

  const totalProperties = ratioData.filter((d: any) => d.value !== 0).length;

  // const renderCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   index,
  // }: any) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <text
  //       // x={x}
  //       y={y}
  //       x={x > cx ? x - innerRadius / 2 : x + innerRadius / 2}
  //       // y={y > cy ? y - innerRadius / 2 : y - innerRadius / 2}
  //       fill="white"
  //       textAnchor={x > cx ? "start" : "end"}
  //       dominantBaseline="central"
  //       style={{ fontWeight: "bold" }}
  //     >
  //       {`${(percent * 100).toFixed(0)}%`}
  //     </text>
  //   );
  // };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);

    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;

    return (
      <g>
        {/* <Text x={cx} y={150} textAnchor="middle" fill={fill}>
            {payload.name}
          </text>
          <Text x={cx} y={230} textAnchor="middle" fill={fill}>
            {`${value}%`}
          </text> */}
        <foreignObject x={cx - 75} y={width / 2 - 50} width="150" height="100">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                textAlign: "center",
                fontWeight: "600",
                fontSize: "14px",
                padding: "0 10px",
                alignItems: "flex-end",
              }}
            >
              {payload.name}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                textAlign: "center",
                fontWeight: "bold",
                // marginTop: "5px",
                fontSize: "22px",
              }}
            >
              {`${((value / totalOfType) * 100).toFixed(1)} %`}
            </div>
          </div>
        </foreignObject>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius - 75}
          outerRadius={outerRadius - 70}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <>
      <Flex ref={pieContainerRef} fontWeight="bold">
        {t("TXT_Dashboard_pie_chart_title")}
      </Flex>
      <PieChart width={width} height={width}>
        <Pie
          data={ratioData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          startAngle={0}
          outerRadius={150}
          innerRadius={90}
          labelLine={false}
          paddingAngle={0}
          // label={renderCustomizedLabel}
          onMouseEnter={(_, index) => setActiveIndex(index)}
          onMouseOut={(_, index) => setActiveIndex(-1)}
        >
          {ratioData.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index].toString()} />
          ))}
        </Pie>
        {activeIndex == -1 && (
          <>
            <text
              x={width / 2}
              y={width / 2 - 30}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#000000"
              style={{ fontWeight: "bold" }}
              fontSize="44px"
            >
              {totalProperties}
            </text>
            <text
              x={width / 2}
              y={width / 2 + 20}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#000000"
              style={{ fontWeight: "bold" }}
              fontSize="22px"
            >
              {t("TXT_Properties")}
            </text>
          </>
        )}
      </PieChart>
      <Flex flexWrap="wrap" gap="10px">
        {COLORS.map((c, i) => {
          return (
            <Flex gap="5px" key={i} alignItems="center">
              <Flex bg={c} width="10px" height="10px"></Flex>
              <Flex fontSize="12px">{ratioData[i]?.name}</Flex>
            </Flex>
          );
        })}
      </Flex>
    </>
  );
};

export default AverageEfficiencyPieChart;
