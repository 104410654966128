import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Icon,
  useToast,
  useOutsideClick,
  Grid,
  GridItem,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { returnByTheme, returnByColor } from "../../../utils/util";

import { ReactComponent as GenerationIcon } from "src/icons/general/generationIcon.svg";
import { ReactComponent as ConsumptionIcon } from "src/icons/general/consumptionIcon.svg";
import { ReactComponent as ReductionIcon } from "src/icons/general/reductionIcon.svg";

import _ from "lodash";
import moment from "moment";

import { useTranslation } from "react-i18next";

import { typeOfSiteColor, typeOfSiteText } from "src/type/IProjectInterface";
import { IconContext } from "react-icons";
import { t } from "i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";

const ProjectPowerGenSection = ({ siteInfo, currPjStatusBarObj }: any) => {
  const isClient = localStorage.getItem("sf_Role") == "true";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  return (
    <Flex
      py="10px"
      borderRadius="8px"
      boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
      bg={"WT"}
    >
      <Flex flexDirection="column" flex={1} overflow="hidden">
        <Flex p="10px" color={returnByTheme()} fontWeight="bold">
          {t("TXT_Project_Electricity_analysis")}
        </Flex>
        <Flex bg="DISABLEDTEXT" height="1px" mb="10px"></Flex>

        <Flex justifyContent="space-between" alignItems="center" px="10px">
          <Flex>
            <Text color="GREY">{t("TXT_From")}: &nbsp;</Text>

            <Text>
              {moment(siteInfo.site?.sitePower.accumulateStartDate).format(
                "DD MMM ,YYYY"
              )}
            </Text>
          </Flex>

          <Flex>
            <Text color="GREY">{t("TXT_To")}: &nbsp;</Text>
            <Text>
              {" "}
              {moment(siteInfo.site?.sitePower.accumulateEndDate).format(
                "DD MMM , YYYY"
              )}
            </Text>
          </Flex>
        </Flex>

        <Box m="10px">
          <Swiper
            modules={[EffectFade, Navigation]}
            spaceBetween={0}
            navigation={true}
            effect="fade"
            // loop={true}
          >
            <SwiperSlide>
              <Flex justifyContent="space-between" bg="WT">
                <Flex
                  // boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                  p="10px"
                  flexDirection="column"
                  flex={1}
                  justifyContent="space-between"
                  alignItems="center"
                  borderRight="1px solid #CFCFCF"
                >
                  <GenerationIcon
                    fill={
                      currPjStatusBarObj?.index == 7 ? "#8e8e8e" : "#69B3E3"
                    }
                  />

                  <Box
                    textAlign="center"
                    color={
                      currPjStatusBarObj?.index == 7 ? "#8e8e8e" : "#69B3E3"
                    }
                    flexDirection="column"
                    flex={1}
                    width={"100%"}
                    mb="15px"
                  >
                    {t("TXT_Project_Generation")}
                  </Box>

                  <Box fontSize="25px" textAlign="center" fontWeight="bold">
                    {siteInfo?.site?.sitePower?.generatedKwh?.toLocaleString()}
                  </Box>
                  <Flex
                    fontSize="10px"
                    justifyContent="space-between"
                    width="80px"
                    color="GREY"
                  >
                    <Box>{t("TXT_Units")}</Box>
                    <Box>kW</Box>
                  </Flex>
                </Flex>

                <Flex
                  // boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                  p="10px"
                  flexDirection="column"
                  flex={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ConsumptionIcon
                    fill={
                      currPjStatusBarObj?.index == 7 ? "#8e8e8e" : "#e4513f"
                    }
                  />

                  <Box
                    textAlign="center"
                    // color="RD.3"
                    color={
                      currPjStatusBarObj?.index == 7 ? "#8e8e8e" : "#e4513f"
                    }
                    flexDirection="column"
                    flex={1}
                    width={"100%"}
                    mb="15px"
                  >
                    {t("TXT_Project_Consumption")}
                  </Box>

                  <Box fontSize="25px" textAlign="center" fontWeight="bold">
                    {siteInfo?.site?.sitePower?.consumedKwh?.toLocaleString()}
                  </Box>
                  <Flex
                    fontSize="10px"
                    justifyContent="space-between"
                    width="80px"
                    color="GREY"
                  >
                    <Box>{t("TXT_Units")}</Box>
                    <Box>kW</Box>
                  </Flex>
                </Flex>
              </Flex>
            </SwiperSlide>

            <SwiperSlide>
              <Flex justifyContent="space-between" bg="WT">
                <Flex
                  // boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                  p="10px"
                  flexDirection="column"
                  flex={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ReductionIcon
                    fill={
                      currPjStatusBarObj?.index == 7 ? "#8e8e8e" : "#7DDF92"
                    }
                  />
                  <Flex
                    textAlign="center"
                    color={
                      currPjStatusBarObj?.index == 7 ? "#8e8e8e" : "#7DDF92"
                    }
                    flexDirection="column"
                    flex={1}
                    width={"100%"}
                    mb="15px"
                  >
                    <Box>{t("TXT_Project_Carbon")}</Box>
                    <Box> {t("TXT_Project_Reduction")}</Box>
                  </Flex>
                  <Box fontSize="25px" textAlign="center" fontWeight="bold">
                    {Math.floor(
                      siteInfo?.site?.sitePower?.generatedKwh! * 0.997
                    ).toLocaleString()}
                  </Box>
                  <Flex
                    fontSize="10px"
                    justifyContent="space-between"
                    width="80px"
                    color="GREY"
                  >
                    <Box>kg</Box>
                    <Box>kW</Box>
                  </Flex>
                </Flex>
                <Flex flex={1}></Flex>
              </Flex>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ProjectPowerGenSection;
