import _, { replace } from "lodash";
import {
  Box,
  Flex,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import BaseInput from "src/components/BaseInput";
import CheckBox from "src/components/CheckBox";
import PasswordInput from "src/components/PasswordInput";
import StyledButton from "./StyledButton";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { useTranslation } from "react-i18next";
import { useMutation, useLazyQuery } from "@apollo/client";
import { returnByTheme } from "../../utils/util";
import {
  LoginDocument,
  GetmeDocument,
  CompanyPrivileges,
} from "src/graphql/generated";
import { useStore } from "../../app/App.store";
import { useNavigate } from "react-router-dom";

import { ReactComponent as AccountIcon } from "src/icons/auth/account.svg";
import { ReactComponent as SFLogo } from "src/icons/general/sfLogo.svg";
import LangSwitcher from "src/components/LangSwitcher";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);

  const [isShowWarning, setIsShowWarning] = useState(false);
  const [isShowForgotModal, setIsShowForgotModal] = useState(false);

  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToken } = useStore();

  const [login, { loading, error }] = useMutation(LoginDocument, {
    fetchPolicy: "no-cache",
    onCompleted: (x) => {
      if (x) {
        localStorage.setItem("token", x.login.token);
        //localStorage.setItem("userId", decoded.company.id);
        navigate("/project", { replace: true });
      }
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Incorrect account or password`,
        status: "error",
      });
    },
  });

  const isValid =
    !_.isEmpty(username) && !_.isEmpty(password) && password.length >= 8;

  const submit = () => {
    if (!isValid) setIsShowWarning(true);
    else {
      login({
        variables: {
          email: username,
          password: password,
        },
      });
    }
  };

  return (
    <Box
      width="calc(100% - 40px)"
      height="100%"
      overflowY={"scroll"}
      zIndex="20"
    >
      <Helmet>
        <title>{t("TXT_SignIn")}</title>
      </Helmet>
      <Box height="100%">
        <Flex align="center" mt="30px" justifyContent="space-between">
          <Flex align="center">
            <SFLogo width="80px" height="80px" />
            <Text
              zIndex={20}
              // position="absolute"
              // left="50px"
              // top="36px"
              color={returnByTheme()}
              fontSize="36px"
              fontWeight="extrabold"
              transition="0.3s ease-out"
              ml="10px"
            >
              SOLAR MONSTER
            </Text>
          </Flex>
          <LangSwitcher />
        </Flex>
        <Flex mt="15%" p="20px" flexDir="column" gap="25px" alignItems="center">
          <Text
            fontSize="65px"
            color="#052b12"
            fontWeight={600}
            lineHeight={0.9}
          >
            {t("TXT_Signin_Sign_in_to").toUpperCase()}
          </Text>
          <Text
            fontSize="65px"
            color={returnByTheme()}
            fontWeight={600}
            lineHeight={0.9}
          >
            {t("TXT_SolarMonster")}
          </Text>
          <Text fontSize="25px" color={returnByTheme()} lineHeight={2}>
            {t("TXT_Signin_Enter_details")}
          </Text>
          <BaseInput
            color={returnByTheme()}
            title={t("TXT_Account")}
            value={username}
            left={<AccountIcon stroke="currentcolor" />}
            onChange={(e) => setUsername(e.currentTarget.value)}
            isShowWarning={_.isEmpty(username) && isShowWarning}
            backgroundColor={"DISABLEDBG"}
          />
          <PasswordInput
            color={returnByTheme()}
            title={t("TXT_Password")}
            value={password}
            backgroundColor={"DISABLEDBG"}
            onChange={(e) => setPassword(e.currentTarget.value)}
            isShowWarning={
              (_.isEmpty(password) || password.length < 8) && isShowWarning
            }
          />

          <Flex
            flexDir="row"
            justifyContent="space-between"
            w="100%"
            mt="15px"
            mb="40px"
          >
            <CheckBox
              color={returnByTheme()}
              isChecked={isRememberMe}
              onChange={() => setIsRememberMe((v) => !v)}
            >
              {t("TXT_Remember_Me")}
            </CheckBox>
            <Text
              fontSize="25px"
              color={returnByTheme()}
              textDecoration="underline"
              cursor="pointer"
              onClick={() => setIsShowForgotModal(true)}
            >
              {t("TXT_Forget_Pw")}?
            </Text>
          </Flex>

          <StyledButton
            color={returnByTheme()}
            onClick={() => submit()}
            isLoading={loading}
          >
            {t("TXT_SignIn")}
          </StyledButton>
        </Flex>
      </Box>

      <ForgotPasswordModal
        isShowForgotModal={isShowForgotModal}
        setIsShowForgotModal={setIsShowForgotModal}
      />
    </Box>
  );
};

export default SignIn;
