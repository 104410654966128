import _, { isNil } from "lodash";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Progress,
  useToast,
  Text,
  Input,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Icon,
  Button,
} from "@chakra-ui/react";
import { FC, ReactElement, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { calculateIrr, returnByTheme } from "src/utils/util";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import PopUpModal from "src/components/PopUpModal";
import CurrencyInput from "react-currency-input-field";

import NumberOfSolarPanelSection from "./NumberOfSolarPanelSection";
import WattOfEachPanelSection from "./WattOfEachPanelSection";
import { FiCheckCircle } from "react-icons/fi";
import { BsCaretRight } from "react-icons/bs";
import { BiEnvelope } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import {
  CreateInvestmentDocument,
  ExportCalcResultDocument,
} from "src/graphql/generated";
import "./calculator.scss";
import GeneralTopHeader from "src/components/GeneralTopHeader";

const ProjectCalculator = () => {
  const [calcResult, setCalcResult] = useState<any>();
  const [pjStartDate, setPjStartDate] = useState(new Date());
  const [noOfPanel, setNoOfPanel] = useState(1);
  const [wattOfPanel, setWattOfPanel] = useState(1);
  const [currRatioType, setCurrRatioType] = useState<string>("share");
  const [sharingRatioData, setSharingRatioData] = useState<number>(0);
  const [fixedRatioData, setFixedRatioData] = useState<number>(0);

  const [estimateCostData, setEstimateCostData] = useState<{
    type: "price" | "irr";
    value: number;
  }>({
    type: "price",
    value: 1,
  });

  const [dailySolarIrr, setDailySolarIrr] = useState<number>(4.28);
  const [panelEffeciency, setPanelEffeciency] = useState<number>(75.0);
  const [firstYrDegradation, setFirstYrDegradation] = useState(0);
  const [secondYrDegradation, setSecondYrDegradation] = useState(0);
  const [otherYrDegradation, setOtherYrDegradation] = useState(0);
  const [profitTaxRate, setProfitTaxRate] = useState<number>(16.5);
  const [fitRate, setFitRate] = useState(0);
  const [rateToContractor, setRateToContractor] = useState(0);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const loginData = JSON.parse(localStorage.getItem("meData")!);
  const myCompanyID = loginData?.company?.id;

  const [exportCalcExcel, { loading: exporting }] = useLazyQuery(
    ExportCalcResultDocument,

    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const win: Window = window;
        if (data?.downloadInvestmentCalculator?.url) {
          // const url = data?.downloadInvestmentCalculator?.url;
          win.location = data?.downloadInvestmentCalculator?.url;
        }
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  const [goStartProject, { loading: starting }] = useMutation(
    CreateInvestmentDocument,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        navigate("/create/project", {
          state: {
            sharingRatio: sharingRatioData,
            investmentId: data.createInvestment.id,
          },
        });
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  const onChangeNoOfPanelHandler = (val: any) => {
    let v = val < 1 ? 1 : val > 1000 ? 1000 : val;

    setNoOfPanel(v);
  };

  const onChangeWattOfPanelHandler = (val: any) => {
    let v = val < 1 ? 1 : val > 1000 ? 1000 : val;

    setWattOfPanel(v);
  };

  const onChangeShareRatioHandler = (val: any) => {
    let v = val < 0 ? 0 : val > 100 ? 100 : val;

    setSharingRatioData(v);
  };

  const onChangeFixedRatioHandler = (val: any) => {
    // let v = val < 0 ? 0 : val > 100 ? 100 : val;

    setFixedRatioData(val);
  };

  const onChangeEstimateCostHandler = (val: any) => {
    let v = val < 1 ? 1 : val > 100 ? 100 : val;

    setEstimateCostData({
      type: estimateCostData.type,
      value: v,
    });
  };

  const onChangeDailySolarIrrHandler = (val: any) => {
    let v = val < 1 ? 1 : val > 100 ? 100 : val;

    setDailySolarIrr(v);
  };

  const onChangePanelEffeciencyHandler = (val: any) => {
    let v = val < 1 ? 1 : val > 100 ? 100 : val;

    setPanelEffeciency(v);
  };

  const onChangeFirstYearDegradationHandler = (val: any) => {
    let v = val < 0 ? 0 : val > 100 ? 100 : val;

    setFirstYrDegradation(v);
  };

  const onChangeSecondYearDegradationHandler = (val: any) => {
    let v = val < 0 ? 0 : val > 100 ? 100 : val;

    setSecondYrDegradation(v);
  };

  const onChangeOtherYearDegradationHandler = (val: any) => {
    let v = val < 0 ? 0 : val > 100 ? 100 : val;

    setOtherYrDegradation(v);
  };

  const onChangeProfitTaxRateHandler = (val: any) => {
    let v = val < 0 ? 0 : val > 100 ? 100 : val;

    setProfitTaxRate(v);
  };

  const onChangeFitRateHandler = (val: any) => {
    let v = val < 0 ? 0 : val > 100 ? 100 : val;

    setFitRate(v);
  };

  const onChangeOMRateHandler = (val: any) => {
    let v = val < 0 ? 0 : val > 100 ? 100 : val;

    setRateToContractor(v);
  };

  const notAbleToCalc =
    isNil(noOfPanel) ||
    isNil(wattOfPanel) ||
    isNil(sharingRatioData) ||
    isNil(estimateCostData.value) ||
    isNil(dailySolarIrr) ||
    isNil(panelEffeciency) ||
    isNil(profitTaxRate) ||
    isNil(fitRate);

  const calcHandler = () => {
    // console.log(noOfPanel);
    // console.log(wattOfPanel);
    // console.log(sharingRatioData);
    // console.log(fixedRatioData);
    // console.log(estimateCostData.value);
    // console.log(dailySolarIrr);
    // console.log(panelEffeciency);
    // console.log(firstYrDegradation);
    // console.log(secondYrDegradation);
    // console.log(otherYrDegradation);
    // console.log(profitTaxRate);
    // console.log(fitRate);
    // console.log(rateToContractor);

    const projectStartDate = moment(pjStartDate);
    const projectEndDate = moment("31-Dec-2033");
    const yearCount = Math.ceil(
      moment.duration(projectEndDate.diff(projectStartDate)).asYears()
    );

    const totalCapcity = noOfPanel * wattOfPanel;
    const pvCapcity = totalCapcity / 1000;
    const firstYearWattMultiplier =
      ((totalCapcity * ((dailySolarIrr * panelEffeciency) / 100) * 365) /
        1000 /
        totalCapcity) *
      (1 - Number(firstYrDegradation ?? 0) / 100) *
      (1 - Number(otherYrDegradation ?? 0) / 100);
    const firstYearFitProduction = pvCapcity * firstYearWattMultiplier * 1000;
    const panelDegraditionAfterward =
      Number(secondYrDegradation ?? 0) / 100 +
      Number(otherYrDegradation ?? 0) / 100 +
      ((Number(secondYrDegradation ?? 0) / 100) *
        Number(otherYrDegradation ?? 0)) /
        100;
    const totalCost =
      pvCapcity *
      1000 *
      (estimateCostData.type == "price" ? estimateCostData.value : 0); // support price only for now

    const forecastFitProduction = [];
    const forecastIncome = [];
    const forecastFixedRent = [];
    const forecastShareToClient = [];
    const forecastOmFee = [];
    const forecastAnnualIncomeBeforeTax = [];
    let investmentCost = 0;
    const forecastCashFlowBeforeTax: any = [];
    const forecastTaxableAmount = [];
    const forecastPayableTax = [];
    const forecastAnnualIncomeAfterTax = [];
    const forecastAnnualIncomeAfterTaxInvestmentCost = [];
    const forecastCashFlowAfterTaxInvestmentCost: any = [];

    //investmentCost
    investmentCost = -totalCost;

    for (let start = 0; start < yearCount; start++) {
      const tempStartDate = moment(projectStartDate);
      const yearStartString = tempStartDate
        .add(start, "years")
        .format("YYYY-MM");
      let yearEndString = moment(yearStartString, "YYYY-MM")
        .add(11, "months")
        .endOf("month")
        .format("YYYY-MM");
      if (start == yearCount - 1) {
        //last year
        yearEndString = tempStartDate.endOf("year").format("YYYY-MM");
      }

      const periodName = `${yearStartString} - ${yearEndString}`;

      //forecastFitProduction
      if (start == 0) {
        //first year
        forecastFitProduction.push({
          period: periodName,
          amount: firstYearFitProduction,
        });
      } else if (start == yearCount - 1) {
        //last year
        const startMonth = moment(projectStartDate).month();

        const fitAmount: any =
          (forecastFitProduction[start - 1].amount *
            (1 - panelDegraditionAfterward) *
            (12 - startMonth)) /
          12;
        forecastFitProduction.push({
          period: periodName,
          amount: fitAmount,
        });
      } else {
        const fitAmount: any =
          forecastFitProduction[start - 1].amount *
          (1 - panelDegraditionAfterward);
        forecastFitProduction.push({
          period: periodName,
          amount: fitAmount,
        });
      }

      //forecastIncome
      forecastIncome.push({
        period: periodName,
        amount: forecastFitProduction[start].amount * Number(fitRate),
      });

      //forecastFixedRent
      forecastFixedRent.push({
        period: periodName,
        amount: Number(fixedRatioData),
      });

      //forecastShareToClient
      forecastShareToClient.push({
        period: periodName,
        amount: forecastIncome[start].amount * (sharingRatioData / 100),
      });

      //forecastOmFee
      forecastOmFee.push({
        period: periodName,
        amount: Number(rateToContractor ?? 0) * pvCapcity * 1000,
      });

      //forecastAnnualIncomeBeforeTax
      forecastAnnualIncomeBeforeTax.push({
        period: periodName,
        amount:
          forecastIncome[start].amount -
          (forecastFixedRent[start].amount +
            forecastShareToClient[start].amount +
            forecastOmFee[start].amount),
      });

      //forecastCashFlowBeforeTax
      if (start == 0) {
        forecastCashFlowBeforeTax.push({
          period: periodName,
          amount: forecastAnnualIncomeBeforeTax[start].amount + investmentCost,
        });
      } else {
        forecastCashFlowBeforeTax.push({
          period: periodName,
          amount:
            forecastCashFlowBeforeTax[start - 1].amount +
            forecastAnnualIncomeBeforeTax[start].amount,
        });
      }

      //forecastTaxableAmount
      if (start == 0) {
        forecastTaxableAmount.push({
          period: periodName,
          amount:
            forecastIncome[start].amount -
            forecastFixedRent[start].amount -
            forecastShareToClient[start].amount -
            forecastOmFee[start].amount -
            totalCost,
        });
      } else {
        if (forecastAnnualIncomeBeforeTax[start].amount > 0) {
          const init: any =
            forecastTaxableAmount[start - 1].amount < 0
              ? forecastTaxableAmount[start - 1].amount
              : 0;
          forecastTaxableAmount.push({
            period: periodName,
            amount:
              init +
              forecastIncome[start].amount -
              (forecastFixedRent[start].amount +
                forecastShareToClient[start].amount +
                forecastOmFee[start].amount),
          });
        } else {
          forecastTaxableAmount.push({
            period: periodName,
            amount: 0,
          });
        }
      }

      //forecastPayableTax
      forecastPayableTax.push({
        period: periodName,
        amount:
          forecastTaxableAmount[start].amount > 0
            ? (forecastTaxableAmount[start].amount * Number(profitTaxRate)) /
              100
            : 0,
      });

      //forecastAnnualIncomeAfterTax
      forecastAnnualIncomeAfterTax.push({
        period: periodName,
        amount:
          forecastAnnualIncomeBeforeTax[start].amount -
          forecastPayableTax[start].amount,
      });

      //forecastAnnualIncomeAfterTaxInvestmentCost
      if (start == 0) {
        forecastAnnualIncomeAfterTaxInvestmentCost.push({
          period: periodName,
          amount: forecastAnnualIncomeAfterTax[start].amount + investmentCost,
        });
      } else {
        forecastAnnualIncomeAfterTaxInvestmentCost.push({
          period: periodName,
          amount: forecastAnnualIncomeAfterTax[start].amount,
        });
      }

      //forecastCashFlowAfterTaxInvestmentCost
      if (start == 0) {
        forecastCashFlowAfterTaxInvestmentCost.push({
          period: periodName,
          amount: forecastAnnualIncomeAfterTaxInvestmentCost[start].amount,
        });
      } else {
        forecastCashFlowAfterTaxInvestmentCost.push({
          period: periodName,
          amount:
            forecastCashFlowAfterTaxInvestmentCost[start - 1].amount +
            forecastAnnualIncomeAfterTax[start].amount,
        });
      }
    }

    const totalRevenue = forecastAnnualIncomeAfterTax.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0
    );
    const paybackPeriod = forecastCashFlowBeforeTax.filter(
      (value: any) => value.amount < 0
    ).length;
    const roi = ((totalRevenue - totalCost) / Math.abs(totalCost)) * 100;
    const fi = forecastAnnualIncomeAfterTaxInvestmentCost.map(
      (cost) => cost.amount
    );
    const irr = calculateIrr(fi, 0.2) * 100;
    // console.log("calc result");
    // console.log(`totalRevenue`);
    // console.log(totalRevenue);

    // console.log(`paybackPeriod`);
    // console.log(paybackPeriod);

    // console.log(`totalCost`);
    // console.log(totalCost);

    // console.log(`totalCapcity`);
    // console.log(totalCapcity);

    // console.log(`PvCapcity`);
    // console.log(pvCapcity);

    // console.log(`firstYearWattMultiplier`);
    // console.log(firstYearWattMultiplier);

    // console.log(`1st year ele gen`);
    // console.log(forecastFitProduction[0].amount);

    // console.log(`last year ele gen`);
    // console.log(forecastFitProduction[forecastFitProduction.length - 1].amount);

    // console.log(`roi`);
    // console.log(roi);

    // console.log(`fi`);
    // console.log(fi);

    // console.log(`irr`);
    // console.log(irr);
    setCalcResult({
      totalRevenue: Math.floor(totalRevenue).toLocaleString(),
      irr: irr.toFixed(2).toLocaleString(),
      roi: roi.toFixed(2).toLocaleString(),
      paybackPeriod: paybackPeriod,
      totalCost: totalCost.toFixed(2).toLocaleString(),
      totalCapcity: totalCapcity.toFixed(2).toLocaleString(),
      pvCapcity: pvCapcity.toFixed(2).toLocaleString(),
      firstYearWattMultiplier: firstYearWattMultiplier
        .toFixed(2)
        .toLocaleString(),
      firstYearElectricityGen: forecastFitProduction[0].amount
        .toFixed(2)
        .toLocaleString(),
      lastYearElectricityGen: forecastFitProduction[
        forecastFitProduction.length - 1
      ].amount
        .toFixed(2)
        .toLocaleString(),
    });
  };

  const resetAllCalc = () => {
    setNoOfPanel(1);
    setWattOfPanel(1);
    setCurrRatioType("share");
    setSharingRatioData(0);
    setFixedRatioData(0);
    setEstimateCostData({ type: "price", value: 1 });
    setDailySolarIrr(4.28);
    setPanelEffeciency(75.0);
    setFirstYrDegradation(0);
    setSecondYrDegradation(0);
    setOtherYrDegradation(0);
    setProfitTaxRate(16.5);
    setFitRate(0);
    setRateToContractor(0);
    setCalcResult({});
  };

  return (
    <>
      <Helmet>
        <title>Solar Monster - Calculator</title>
      </Helmet>

      {/* header section */}
      <Flex
        flexDirection="column"
        flex={1}
        h="100vh"
        backgroundColor="#F7FAFA"
        ml="65px"
        overflowX="auto"
      >
        <GeneralTopHeader
          headerTitle={t("TXT_Calculator")}
          hadRightCornerTools
        />
        {/* <Flex
          backgroundColor="WT"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          px="20px"
          minH="60px"
        >
          <Flex>
            <Text fontWeight="bold">{t("TXT_Calculator")}</Text>
          </Flex>
        </Flex> */}

        <Flex
          flexDirection="column"
          // marginTop={"25px"}
          maxW="1920px"
          mx="auto"
          width="100%"
          px="20px"
        >
          <Flex justifyContent="center" gap="20px" minHeight="100vh">
            <Flex flexDir="column" mt="20px" width="750px" mb="50px">
              {/* start date picker */}
              <Flex
                flexDir="column"
                borderRadius="12px"
                p="20px"
                bg="WT"
                mb="30px"
                boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.05)"
              >
                <Flex fontSize="25px" fontWeight="bold" mb="5px">
                  {t("TXT_Calculator_title")}
                </Flex>
                <Flex color="GREY" mb="20px">
                  {t("TXT_Calculator_desc")}
                </Flex>
                <Flex gap="10px" alignItems="center">
                  <Flex gap="10px" alignItems="center">
                    <Flex color="GREY">
                      {t("TXT_Calculator_select_start_date")}
                    </Flex>
                    <Flex>
                      <DatePicker
                        selected={pjStartDate}
                        maxDate={new Date("2033 12 31")}
                        customInput={
                          <Flex
                            alignItems="center"
                            gap="10px"
                            cursor="pointer"
                            color="#459ee3"
                            borderRadius="25px"
                            border="1px solid #459ee3"
                            py="5px"
                            px="15px"
                            onClick={(e) => e.preventDefault()}
                          >
                            {moment(pjStartDate).format("MMM, YYYY")}
                            <IoIosArrowDown />
                          </Flex>
                        }
                        onChange={(newDate: any) => {
                          setPjStartDate(newDate);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                      />
                    </Flex>
                  </Flex>
                  <Flex fontWeight="bold">
                    {t("TXT_Calculator_to_2033")}
                    {/* {moment(pjStartDate).add(1, "year").format("YYYY")} */}
                  </Flex>
                </Flex>
              </Flex>

              {/* no of panel */}
              <NumberOfSolarPanelSection
                noOfPanel={noOfPanel}
                setNoOfPanel={(val: any) => onChangeNoOfPanelHandler(val)}
              />

              {/* watt of each panel */}

              <WattOfEachPanelSection
                wattOfPanel={wattOfPanel}
                setWattOfPanel={(val: any) => onChangeWattOfPanelHandler(val)}
              />

              {/* first input row */}
              <Flex gap="20px" mb="20px">
                {/* share ratio section */}
                <Flex flex={1} flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_sharing_ratio")}
                    <Text color="RD.2">*</Text>
                  </Flex>
                  <Flex gap="5px" height="45px">
                    <Flex
                      flexShrink={0}
                      gap="5px"
                      p="5px"
                      bg="WT"
                      borderRadius="5px"
                      borderWidth="1px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Flex
                        borderRadius="5px"
                        py="3px"
                        px="5px"
                        bg={currRatioType === "share" ? "#E9EEF3" : "WT"}
                        borderWidth={currRatioType === "share" ? "1px" : "0"}
                        cursor="pointer"
                        onClick={() => setCurrRatioType("share")}
                        borderColor={
                          currRatioType === "share" ? "#69B3E3" : "none"
                        }
                        color={currRatioType === "share" ? "#69B3E3" : "GREY"}
                      >
                        {t("TXT_Calculator_share")}
                      </Flex>
                      <Flex
                        borderRadius="5px"
                        p="5px"
                        bg={currRatioType === "fixed" ? "#E9EEF3" : "WT"}
                        borderWidth={currRatioType === "fixed" ? "1px" : "0"}
                        color={currRatioType === "fixed" ? "#69B3E3" : "GREY"}
                        cursor="pointer"
                        onClick={() => setCurrRatioType("fixed")}
                        borderColor={
                          currRatioType === "fixed" ? "#69B3E3" : "none"
                        }
                      >
                        {t("TXT_Calculator_fixed")}
                      </Flex>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      // height="45px"
                      borderRadius="4px"
                      border="1px solid #f0f0f0"
                      overflow="hidden"
                    >
                      <CurrencyInput
                        className="calc-num-input"
                        // defaultValue={1000}
                        value={
                          currRatioType === "share"
                            ? sharingRatioData
                            : fixedRatioData
                        }
                        decimalsLimit={2}
                        onValueChange={(value, name) => {
                          if (currRatioType === "share") {
                            onChangeShareRatioHandler(value);
                          } else {
                            onChangeFixedRatioHandler(value);
                          }
                        }}
                      />
                      <Flex
                        bg="#69B3E3"
                        fontWeight="bold"
                        color="WT"
                        height="100%"
                        minWidth="55px"
                        px="15px"
                        justifyContent="center"
                        alignItems="center"
                        children={currRatioType === "share" ? "%" : "HKD"}
                      ></Flex>
                    </Flex>
                  </Flex>
                </Flex>

                {/* estimate cost section */}
                <Flex flex={1} flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_estimate_cost")}
                    <Text color="RD.2">*</Text>
                  </Flex>
                  <Flex gap="5px" height="45px">
                    <Flex
                      flexShrink={0}
                      gap="5px"
                      p="5px"
                      bg="WT"
                      borderRadius="5px"
                      borderWidth="1px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Flex
                        borderRadius="5px"
                        py="3px"
                        px="5px"
                        bg={estimateCostData.type == "price" ? "#E9EEF3" : "WT"}
                        borderWidth={
                          estimateCostData.type == "price" ? "1px" : "0"
                        }
                        cursor="pointer"
                        onClick={() =>
                          setEstimateCostData({
                            type: "price",
                            value: estimateCostData.value,
                          })
                        }
                        borderColor={
                          estimateCostData.type == "price" ? "#69B3E3" : "none"
                        }
                        color={
                          estimateCostData.type == "price" ? "#69B3E3" : "GREY"
                        }
                      >
                        {t("TXT_Calculator_price")}
                      </Flex>
                      <Flex
                        borderRadius="5px"
                        p="5px"
                        bg={estimateCostData.type == "irr" ? "#E9EEF3" : "WT"}
                        borderWidth={
                          estimateCostData.type == "irr" ? "1px" : "0"
                        }
                        color={
                          estimateCostData.type == "irr" ? "#69B3E3" : "GREY"
                        }
                        cursor="not-allowed"
                        // onClick={() =>
                        //   setEstimateCostData({
                        //     type: "irr",
                        //     value: estimateCostData.value,
                        //   })
                        // }
                        borderColor={
                          estimateCostData.type == "irr" ? "#69B3E3" : "none"
                        }
                      >
                        {t("TXT_Calculator_irr")}
                      </Flex>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      // height="45px"
                      borderRadius="4px"
                      border="1px solid #f0f0f0"
                      overflow="hidden"
                    >
                      <CurrencyInput
                        className="calc-num-input"
                        // defaultValue={1000}
                        value={estimateCostData.value}
                        decimalsLimit={2}
                        onValueChange={(value, name) =>
                          onChangeEstimateCostHandler(value)
                        }
                      />
                      <Flex
                        bg="#69B3E3"
                        fontWeight="bold"
                        color="WT"
                        height="100%"
                        minWidth="90px"
                        justifyContent="center"
                        alignItems="center"
                      >
                        HK$/W
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* second input row */}
              <Flex gap="20px" mb="20px">
                {/* daily irr  section*/}
                <Flex flex={1} gap="5px" flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_average_daily_solar_irradiation")}
                    <Text color="RD.2">*</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    height="45px"
                    borderRadius="4px"
                    border="1px solid #f0f0f0"
                    overflow="hidden"
                  >
                    <Flex
                      p="5px"
                      children={
                        <Flex
                          width="135px"
                          px="2px"
                          borderRadius="5px"
                          alignItems="center"
                          justifyContent="center"
                          gap="5px"
                          bg={dailySolarIrr == 4.28 ? "#E9EEF3" : "DISABLEDBG"}
                          borderWidth={dailySolarIrr == 4.28 ? "1px" : "0"}
                          borderColor={
                            dailySolarIrr == 4.28 ? "#69B3E3" : "GREY"
                          }
                          color={dailySolarIrr == 4.28 ? "#69B3E3" : "GREY"}
                        >
                          <Icon as={FiCheckCircle} />
                          {t("TXT_Default")} 4.28
                        </Flex>
                      }
                      cursor="pointer"
                      onClick={() => onChangeDailySolarIrrHandler(4.28)}
                      bg="WT"
                      height="100%"
                    ></Flex>
                    <CurrencyInput
                      className="calc-num-input"
                      // defaultValue={1000}
                      value={dailySolarIrr}
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        onChangeDailySolarIrrHandler(value)
                      }
                    />
                    <Flex
                      bg="#69B3E3"
                      fontWeight="bold"
                      color="WT"
                      height="100%"
                      minWidth="90px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      kWh/m2
                    </Flex>
                  </Flex>
                </Flex>
                {/* panel effeciency section */}
                <Flex flex={1} gap="5px" flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_panel_effeciency")}
                    <Text color="RD.2">*</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    height="45px"
                    borderRadius="4px"
                    border="1px solid #f0f0f0"
                    overflow="hidden"
                  >
                    <Flex
                      p="5px"
                      children={
                        <Flex
                          width="135px"
                          px="2px"
                          borderRadius="5px"
                          alignItems="center"
                          justifyContent="center"
                          gap="5px"
                          bg={
                            panelEffeciency == 75.0 ? "#E9EEF3" : "DISABLEDBG"
                          }
                          borderWidth={panelEffeciency == 75.0 ? "1px" : "0"}
                          borderColor={
                            panelEffeciency == 75.0 ? "#69B3E3" : "GREY"
                          }
                          color={panelEffeciency == 75.0 ? "#69B3E3" : "GREY"}
                        >
                          <Icon as={FiCheckCircle} />
                          {t("TXT_Default")} 75.0%
                        </Flex>
                      }
                      cursor="pointer"
                      onClick={() => onChangePanelEffeciencyHandler(75.0)}
                      bg="WT"
                      height="100%"
                    ></Flex>
                    <CurrencyInput
                      className="calc-num-input"
                      // defaultValue={1000}
                      value={panelEffeciency}
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        onChangePanelEffeciencyHandler(value)
                      }
                    />
                    <Flex
                      bg="#69B3E3"
                      fontWeight="bold"
                      color="WT"
                      height="100%"
                      minWidth="55px"
                      px="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      %
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* third input row */}
              <Flex gap="20px" mb="20px">
                {/* panel degradation section*/}
                <Flex flex={1} gap="5px" flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_panel_degradation")}
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    height="45px"
                    borderRadius="4px"
                    border="1px solid #f0f0f0"
                    overflow="hidden"
                  >
                    <Flex
                      children={t("TXT_Calculator_first_year")}
                      color="BK"
                      bg="WT"
                      minWidth="130px"
                      justifyContent="center"
                      alignItems="center"
                      borderRight="1px solid #f0f0f0"
                    ></Flex>
                    <CurrencyInput
                      className="calc-num-input"
                      // defaultValue={1000}
                      value={firstYrDegradation}
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        onChangeFirstYearDegradationHandler(value)
                      }
                    />
                    <Flex
                      bg="#69B3E3"
                      fontWeight="bold"
                      color="WT"
                      height="100%"
                      minWidth="55px"
                      px="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      %
                    </Flex>
                  </Flex>
                </Flex>
                {/* second yr section */}
                <Flex flex={1} gap="5px" flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    &nbsp;
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    height="45px"
                    borderRadius="4px"
                    border="1px solid #f0f0f0"
                    overflow="hidden"
                  >
                    <Flex
                      children={t("TXT_Calculator_second_year")}
                      color="BK"
                      bg="WT"
                      minWidth="130px"
                      justifyContent="center"
                      alignItems="center"
                      borderRight="1px solid #f0f0f0"
                    ></Flex>
                    <CurrencyInput
                      className="calc-num-input"
                      // defaultValue={1000}
                      value={secondYrDegradation}
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        onChangeSecondYearDegradationHandler(value)
                      }
                    />
                    <Flex
                      bg="#69B3E3"
                      fontWeight="bold"
                      color="WT"
                      height="100%"
                      minWidth="55px"
                      px="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      %
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* fourth input row */}
              <Flex gap="20px" mb="20px">
                {/* every year section*/}
                <Flex flex={1} gap="5px" flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_other_degradation")}
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    height="45px"
                    borderRadius="4px"
                    border="1px solid #f0f0f0"
                    overflow="hidden"
                  >
                    <Flex
                      children={t("TXT_Calculator_every_year")}
                      color="BK"
                      bg="WT"
                      minWidth="130px"
                      justifyContent="center"
                      alignItems="center"
                      borderRight="1px solid #f0f0f0"
                    ></Flex>
                    <CurrencyInput
                      className="calc-num-input"
                      // defaultValue={1000}
                      value={otherYrDegradation}
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        onChangeOtherYearDegradationHandler(value)
                      }
                    />
                    <Flex
                      bg="#69B3E3"
                      fontWeight="bold"
                      color="WT"
                      height="100%"
                      minWidth="55px"
                      px="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      %
                    </Flex>
                  </Flex>
                </Flex>

                {/* profit tax section */}
                <Flex flex={1} gap="5px" flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_profit_tax_rate")}
                    <Text color="RD.2">*</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    height="45px"
                    borderRadius="4px"
                    border="1px solid #f0f0f0"
                    overflow="hidden"
                  >
                    <Flex
                      p="5px"
                      children={
                        <Flex
                          width="135px"
                          px="2px"
                          borderRadius="5px"
                          alignItems="center"
                          justifyContent="center"
                          gap="5px"
                          bg={profitTaxRate == 16.5 ? "#E9EEF3" : "DISABLEDBG"}
                          borderWidth={profitTaxRate == 16.5 ? "1px" : "0"}
                          borderColor={
                            profitTaxRate == 16.5 ? "#69B3E3" : "GREY"
                          }
                          color={profitTaxRate == 16.5 ? "#69B3E3" : "GREY"}
                        >
                          <Icon as={FiCheckCircle} />
                          {t("TXT_Default")} 16.5%
                        </Flex>
                      }
                      cursor="pointer"
                      onClick={() => setProfitTaxRate(16.5)}
                      bg="WT"
                      height="100%"
                    ></Flex>
                    <CurrencyInput
                      className="calc-num-input"
                      // defaultValue={1000}
                      value={profitTaxRate}
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        onChangeProfitTaxRateHandler(value)
                      }
                    />
                    <Flex
                      bg="#69B3E3"
                      fontWeight="bold"
                      color="WT"
                      height="100%"
                      minWidth="55px"
                      px="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      %
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* fifth input row */}
              <Flex gap="20px" mb="20px">
                {/* fir rate section*/}
                <Flex flex={1} gap="5px" flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_fit_rate")}
                    <Text color="RD.2">*</Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    height="45px"
                    borderRadius="4px"
                    border="1px solid #f0f0f0"
                    overflow="hidden"
                  >
                    <CurrencyInput
                      className="calc-num-input"
                      // defaultValue={1000}
                      value={fitRate}
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        onChangeFitRateHandler(value)
                      }
                    />
                    <Flex
                      bg="#69B3E3"
                      fontWeight="bold"
                      color="WT"
                      height="100%"
                      minWidth="55px"
                      px="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      HK$/W
                    </Flex>
                  </Flex>
                </Flex>
                {/* O&M rate section */}
                <Flex flex={1} gap="5px" flexDir="column">
                  <Flex
                    color="GREY"
                    mb="2px"
                    fontWeight="semibold"
                    alignItems="center"
                  >
                    {t("TXT_Calculator_rate_to_contractor")}
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    height="45px"
                    borderRadius="4px"
                    border="1px solid #f0f0f0"
                    overflow="hidden"
                  >
                    <CurrencyInput
                      className="calc-num-input"
                      // defaultValue={1000}
                      value={rateToContractor}
                      decimalsLimit={2}
                      onValueChange={(value, name) =>
                        onChangeOMRateHandler(value)
                      }
                    />
                    <Flex
                      bg="#69B3E3"
                      fontWeight="bold"
                      color="WT"
                      height="100%"
                      minWidth="55px"
                      px="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      HK$/W
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* button section */}
              <Flex gap="20px" my="10px">
                <Button
                  py="25px"
                  backgroundColor={returnByTheme()}
                  borderWidth="1px"
                  borderRadius="40px"
                  borderColor="WT"
                  width="100%"
                  disabled={notAbleToCalc}
                  // isLoading={loading}
                  color="WT"
                  loadingText={t("TXT_Calculator_calculating")}
                  spinnerPlacement="end"
                  onClick={() => calcHandler()}
                >
                  <Icon as={BsCaretRight} width="25px" height="25px" />
                  {calcResult?.totalRevenue
                    ? t("TXT_Calculator_recalc")
                    : t("TXT_Calculator_calculate")}
                </Button>
                <Button
                  py="25px"
                  backgroundColor={"WT"}
                  borderWidth="1px"
                  borderRadius="40px"
                  borderColor="BK"
                  width="100%"
                  color="BK"
                  onClick={() => resetAllCalc()}
                >
                  {t("TXT_Calculator_reset")}
                </Button>
              </Flex>
            </Flex>

            {/* result section */}
            <Flex
              bg="#004B92"
              width="440px"
              py="40px"
              px="20px"
              flexDir="column"
              color="WT"
              // justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Flex fontSize="25px" fontWeight="bold">
                {t("TXT_Calculator_calculation_breakdown")}
              </Flex>
              <Flex fontSize="15px" px="60px">
                {t("TXT_Calculator_example_desc")}
              </Flex>
              {/* total box */}
              <Flex
                borderRadius="12px"
                border="2px solid #ffffff"
                p="10px"
                my="15px"
                flexDir="column"
                textAlign="center"
                width="100%"
                alignItems="stretch"
                fontWeight="bold"
              >
                <Flex fontWeight="bold" justifyContent="center">
                  {t("TXT_Calculator_total_revenue_after_share")}
                </Flex>
                <Flex
                  justifyContent="center"
                  fontSize="42px"
                  color="#69B3E3"
                  lineHeight="20px"
                  mt="20px"
                  mb="10px"
                >
                  {calcResult?.totalRevenue ?? "--"}
                </Flex>
                <Flex fontSize="18px" justifyContent="center">
                  HK$
                </Flex>
                <Flex width="100%" height="2px" my="5px" bg="WT"></Flex>
                <TotalBoxRow
                  label={"IRR"}
                  value={calcResult?.irr ?? "0"}
                  unit="%"
                />
                <TotalBoxRow
                  label={"ROI"}
                  value={calcResult?.roi ?? "0"}
                  unit="%"
                />
                <TotalBoxRow
                  label={t("TXT_Calculator_payback_period")}
                  value={calcResult?.paybackPeriod ?? "0"}
                  unit="/year(s)"
                />
                <TotalBoxRow
                  label={t("TXT_Calculator_total_cost")}
                  value={calcResult?.totalCost ?? "0"}
                  unit="HK$"
                />
              </Flex>

              <Flex flexDir="column" color="WT" width="100%">
                <Flex fontWeight="bold">
                  {t("TXT_Calculator_estimate_electricity_data")}
                </Flex>

                <TotalBoxRow
                  icon={
                    <Image
                      src={require("../../icons/general/batteryCharging.png")}
                    />
                  }
                  label={t("TXT_Calculator_total_capacity")}
                  value={calcResult?.totalCapcity ?? "0"}
                  unit="Watt(s)"
                />
                <TotalBoxRow
                  icon={
                    <Image
                      src={require("../../icons/general/solarPanelBig.png")}
                    />
                  }
                  label={t("TXT_Calculator_pv_capacity")}
                  value={calcResult?.pvCapcity ?? "0"}
                  unit="kWp"
                />
                <TotalBoxRow
                  icon={
                    <Image
                      src={require("../../icons/general/batteryPercentIcon.png")}
                    />
                  }
                  label={t("TXT_Calculator_first_year_watt_multiplier")}
                  value={calcResult?.firstYearWattMultiplier ?? "0"}
                  unit="kWh"
                />
              </Flex>
              <Flex width="100%" height="2px" my="15px" bg="WT"></Flex>

              <Flex flexDir="column" color="WT" width="100%">
                <Flex fontWeight="bold">
                  {t("TXT_Calculator_estimate_annual_electricity_generation")}
                </Flex>

                <TotalBoxRow
                  label={t("TXT_Calculator_first_year")}
                  value={calcResult?.firstYearElectricityGen ?? "0"}
                  unit="kWh"
                />
                <TotalBoxRow
                  label={t("TXT_Calculator_last_year")}
                  value={calcResult?.lastYearElectricityGen ?? "0"}
                  unit="kWh"
                />
              </Flex>
              <Flex
                width="100%"
                height="2px"
                mt="15px"
                mb="50px"
                bg="WT"
              ></Flex>
              {calcResult?.totalRevenue && (
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  gap="10px"
                  alignItems="center"
                >
                  <Flex
                    cursor="pointer"
                    flex={1}
                    justifyContent="center"
                    p="15px"
                    fontSize="1.5rem"
                    borderRadius="50px"
                    color="WT"
                    bg="linear-gradient(270deg ,#87CCDC 57.3%, #B9Ea9B )"
                    onClick={() => {
                      goStartProject({
                        variables: {
                          input: {
                            investorId: myCompanyID,
                            projectStartDate:
                              moment(pjStartDate).format("01-MMM-YYYY"),
                            projectEndDate: moment(
                              new Date(2033, 11, 31)
                            ).format("31-MMM-YYYY"),
                            numberOfPanel: Number(noOfPanel) ?? 0,
                            wattOfPanel: Number(wattOfPanel) ?? 0,
                            sharingRatio: Number(sharingRatioData) ?? 0,
                            fixedRent: Number(fixedRatioData) ?? 0,
                            estimatePrice:
                              estimateCostData.type == "price"
                                ? Number(estimateCostData.value)
                                : undefined,
                            estimateIrr:
                              estimateCostData.type == "irr"
                                ? Number(estimateCostData.value)
                                : undefined,
                            dailySolarIrradiation: Number(dailySolarIrr) ?? 0,
                            panelEfficiency: Number(panelEffeciency) ?? 0,
                            panelDegradationFirstYear:
                              Number(firstYrDegradation) ?? 0,
                            panelDegradationSecondYear:
                              Number(secondYrDegradation) ?? 0,
                            otherDegradation: Number(otherYrDegradation) ?? 0,
                            taxRate: Number(profitTaxRate) ?? 0,
                            fitRate: Number(fitRate) ?? 0,
                            omRate: Number(rateToContractor) ?? 0,
                          },
                        },
                      });
                    }}
                  >
                    {t("TXT_Calculator_start_project_now")}
                  </Flex>

                  <Image
                    cursor="pointer"
                    src={require("../../icons/general/exportCalcExcel.png")}
                    onClick={() => {
                      exportCalcExcel({
                        variables: {
                          input: {
                            projectStartDate:
                              moment(pjStartDate).format("01-MMM-YYYY"),
                            projectEndDate: moment(
                              new Date(2033, 11, 31)
                            ).format("31-MMM-YYYY"),
                            numberOfPanel: Number(noOfPanel) ?? 0,
                            wattOfPanel: Number(wattOfPanel) ?? 0,
                            sharingRatio: Number(sharingRatioData) ?? 0,
                            fixedRent: Number(fixedRatioData) ?? 0,
                            estimatePrice:
                              estimateCostData.type == "price"
                                ? Number(estimateCostData.value)
                                : undefined,
                            estimateIrr:
                              estimateCostData.type == "irr"
                                ? Number(estimateCostData.value)
                                : undefined,
                            dailySolarIrradiation: Number(dailySolarIrr) ?? 0,
                            panelEfficiency: Number(panelEffeciency) ?? 0,
                            panelDegradationFirstYear:
                              Number(firstYrDegradation) ?? 0,
                            panelDegradationSecondYear:
                              Number(secondYrDegradation) ?? 0,
                            otherDegradation: Number(otherYrDegradation) ?? 0,
                            taxRate: Number(profitTaxRate) ?? 0,
                            fitRate: Number(fitRate) ?? 0,
                            omRate: Number(rateToContractor) ?? 0,
                          },
                        },
                      });
                    }}
                  />
                </Flex>
              )}

              <Flex width="100%" mt="50px" mb="20px" justifyContent="center">
                {t("TXT_Calculator_need_further_help")}
              </Flex>

              <Flex
                onClick={(e) => {
                  window.open(`mailto:info@instelar.com`);
                }}
                borderRadius="50px"
                border="1px solid #ffffff"
                mb="20px"
                fontSize="25px"
                fontWeight="bold"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                py="5px"
                px="50px"
                gap="5px"
              >
                <BiEnvelope />
                {t("TXT_Calculator_contact_us")}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const TotalBoxRow = ({
  icon,
  label,
  value = "0",
  unit,
}: {
  icon?: ReactElement;
  label: string;
  value: string;
  unit: string;
}) => {
  return (
    <Flex justifyContent="space-between" mt="15px">
      <Flex alignItems="center">
        {icon} {label}
      </Flex>
      <Flex alignItems="center" gap="5px">
        <Text fontSize="25px" color="#69B3E3">
          {value == "0" ? "--" : value}
        </Text>
        <Text color="WT">{unit}</Text>
      </Flex>
    </Flex>
  );
};

export default ProjectCalculator;
