import _ from "lodash";
import { Box, Flex, Grid, GridItem, Image, Progress, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { GetDashboardMapByContractorIdDocument, GetDashboardMapByContractorIdQuery, GetDashboardMapByInvestorIdDocument, GetDashboardMapByInvestorIdQuery, SiteStatus } from "../../graphql/generated";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as NtMap } from "src/images/ntMapping.svg";
// import { ReactComponent as KlMap } from "src/images/klMapping.svg";
// import { ReactComponent as HkiMap } from "src/images/hkiMapping.svg";
import { useQuery } from "@apollo/client";
const isInvestor = localStorage.getItem("sf_Investor") == "true";

const ProjectMappingRow = () => {
	const { t } = useTranslation();

	const toast = useToast();
	const [ntHover, setNtHover] = useState(true);
	const [klHover, setKlHover] = useState(true);
	const [hkiHover, setHkiHover] = useState(true);

	let query = isInvestor ? GetDashboardMapByInvestorIdDocument : GetDashboardMapByContractorIdDocument;
	const { data: mapData } = useQuery(query, {
		onCompleted: (data: GetDashboardMapByInvestorIdQuery & GetDashboardMapByContractorIdQuery) => {
			const totalSiteOfHKI = isInvestor
				? data?.dashboardSitePowersByInvestorIdAndDistrict?.[0]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0)
				: data?.dashboardSitePowersByContractorIdAndDistrict?.[0]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0);
			const totalSiteOfKL = isInvestor
				? data?.dashboardSitePowersByInvestorIdAndDistrict?.[1]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0)
				: data?.dashboardSitePowersByContractorIdAndDistrict?.[1]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0);
			const totalSiteOfNT = isInvestor
				? data?.dashboardSitePowersByInvestorIdAndDistrict?.[2]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0)
				: data?.dashboardSitePowersByContractorIdAndDistrict?.[2]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0);
			if (totalSiteOfHKI == 0) {
				setHkiHover(false);
			}
			if (totalSiteOfKL == 0) {
				setKlHover(false);
			}
			if (totalSiteOfNT == 0) {
				setNtHover(false);
			}
		},
		onError: (error) => {
			return toast({
				position: "bottom-right",
				title: `Fail to get map data`,
				status: "error",
			});
		},
	});

	// const totalGen = mapData?.dashboardSitePowersByContractorIdAndDistrict?.[0].totalPowerGeneration +
	const totalGen = isInvestor
		? mapData?.dashboardSitePowersByInvestorIdAndDistrict?.reduce((partialSum, a) => partialSum + a.totalPowerGeneration, 0)
		: mapData?.dashboardSitePowersByContractorIdAndDistrict?.reduce((partialSum, a) => partialSum + a.totalPowerGeneration, 0);

	const totalSiteOfHKI = isInvestor
		? mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[0]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0)
		: mapData?.dashboardSitePowersByContractorIdAndDistrict?.[0]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0);
	const totalSiteOfKL = isInvestor
		? mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[1]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0)
		: mapData?.dashboardSitePowersByContractorIdAndDistrict?.[1]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0);
	const totalSiteOfNT = isInvestor
		? mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[2]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0)
		: mapData?.dashboardSitePowersByContractorIdAndDistrict?.[2]?.districtsSitePowers?.reduce((partialSum, kl) => partialSum + kl.siteNumber, 0);

	return (
		<Flex
			p="10px"
			bg="WT"
			borderRadius="8px"
			boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)"
			// minH="700px"
			flexDir="column"
		>
			<Flex mb="20px" fontWeight="bold">
				{t("TXT_Dashboard_generation_by_district")}
			</Flex>
			<Flex gap="30px">
				<Flex flex={0.7} overflow="auto" justifyContent="center">
					<Flex position="relative">
						{(ntHover && <Image src={require("../..//images/ntMapping.png")} />) || <Image src={require("../..//images/ntMappingGrey.png")} />}
						{/* <NtMap
              // onMouseEnter={() => setNtHover(true)}
              // onMouseLeave={() => setNtHover(false)}
              fill={ntHover ? "#F4891E" : "GREY"}
            /> */}
						<Flex position="absolute" bottom="198px" left="400px">
							{(klHover && <Image src={require("../..//images/klMapping.png")} />) || <Image src={require("../..//images/klMappingGrey.png")} />}
							{/* <KlMap
                // onMouseEnter={() => setKlHover(true)}
                // onMouseLeave={() => setKlHover(false)}
                fill={klHover ? "#09A34E" : "GREY"}
              /> */}
						</Flex>
						<Flex position="absolute" bottom="60px" left="390px">
							{(hkiHover && <Image src={require("../..//images/hkiMapping.png")} />) || <Image src={require("../..//images/hkiMappingGrey.png")} />}
							{/* <HkiMap
                // onMouseEnter={() => setHkiHover(true)}
                // onMouseLeave={() => setHkiHover(false)}
                fill={hkiHover ? "#459EE3" : "GREY"}
              /> */}
						</Flex>
						{/* nt radio */}
						<Flex
							display={ntHover ? "flex" : "none"}
							borderRadius="50%"
							width="135px"
							height="135px"
							flexDir="column"
							border="8px"
							bg="rgba(249, 115, 22 , 0.8)"
							borderColor="rgba(254, 215, 170, .5)"
							justifyContent="center"
							alignItems="center"
							position="absolute"
							top="120px"
							left="320px"
							// onMouseEnter={() => setNtHover(true)}
							// onMouseLeave={() => setNtHover(false)}
							color="WT"
						>
							<Text>{t("TXT_Total")}</Text>
							<Text>{t("TXT_Project")}</Text>
							<Text fontWeight="bold">{totalSiteOfNT}</Text>
						</Flex>

						{/* kl radio */}
						<Flex
							display={klHover ? "flex" : "none"}
							borderRadius="50%"
							width="135px"
							height="135px"
							flexDir="column"
							border="8px"
							bg="rgba(34, 197, 94 , 0.8)"
							borderColor="rgba(187, 247, 208, .5)"
							justifyContent="center"
							alignItems="center"
							position="absolute"
							top="260px"
							left="440px"
							// onMouseEnter={() => setKlHover(true)}
							// onMouseLeave={() => setKlHover(false)}
							color="WT"
						>
							<Text>{t("TXT_Total")}</Text>
							<Text>{t("TXT_Project")}</Text>
							<Text fontWeight="bold">{totalSiteOfKL}</Text>
						</Flex>

						{/* hki radio */}
						<Flex
							display={hkiHover ? "flex" : "none"}
							borderRadius="50%"
							width="135px"
							height="135px"
							flexDir="column"
							border="8px"
							bg="rgba(59, 130, 246 , 0.8)"
							borderColor="rgba(191, 219, 254, .5)"
							justifyContent="center"
							alignItems="center"
							position="absolute"
							top="410px"
							left="440px"
							// onMouseEnter={() => setHkiHover(true)}
							// onMouseLeave={() => setHkiHover(false)}
							color="WT"
						>
							<Text>{t("TXT_Total")}</Text>
							<Text>{t("TXT_Project")}</Text>
							<Text fontWeight="bold">{totalSiteOfHKI}</Text>
						</Flex>
					</Flex>
				</Flex>
				<Flex flex={0.3} gap="30px">
					<Flex flex={1} flexDir="column" gap="50px">
						{/* kowloon data */}
						<Flex flexDir="column" gap="10px">
							<Flex gap="10px" alignItems="center">
								<Image src={require("../../icons/general/klRadio.png")}></Image>
								<Text color="#09A34E" fontWeight="bold">
									{t("TXT_kowloon")}
								</Text>
							</Flex>
							<>
								{isInvestor &&
									mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[1]?.districtsSitePowers.map((data, i) => {
										return (
											<Flex justifyContent="space-between" key={`kl-${i}`}>
												<Text color="GREY">{t(`TXT_Option_${data.name.replaceAll("-", "_")}`)}</Text>
												<Text fontWeight={data.totalPowerGeneration !== 0 ? "bold" : "normal"} color={data.totalPowerGeneration !== 0 ? "BK" : "GREY"}>
													{data.totalPowerGeneration == 0 ? 0 : totalGen && ((data.totalPowerGeneration / totalGen) * 100).toFixed(1)}%
												</Text>
											</Flex>
										);
									})}
								{!isInvestor &&
									mapData?.dashboardSitePowersByContractorIdAndDistrict?.[1]?.districtsSitePowers.map((data, i) => {
										return (
											<Flex justifyContent="space-between" key={`kl-${i}`}>
												<Text color="GREY">{t(`TXT_Option_${data.name.replaceAll("-", "_")}`)}</Text>
												<Text fontWeight={data.totalPowerGeneration !== 0 ? "bold" : "normal"} color={data.totalPowerGeneration !== 0 ? "BK" : "GREY"}>
													{data.totalPowerGeneration == 0 ? 0 : totalGen && ((data.totalPowerGeneration / totalGen) * 100).toFixed(1)}%
												</Text>
											</Flex>
										);
									})}
							</>
							<Flex flexDir="column" borderTop="1px solid #F0F0F0" gap="5px" pt="5px">
								<Flex alignItems="center" justifyContent="space-between">
									<Image src={require("../../icons/general/solarPanelG.png")} width="25px" height="25px"></Image>
									<Flex fontWeight="bold">
										{isInvestor
											? mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[1].totalPowerGeneration.toLocaleString()
											: mapData?.dashboardSitePowersByContractorIdAndDistrict?.[1].totalPowerGeneration.toLocaleString()}
										KWh
									</Flex>
								</Flex>
								<Progress
									value={
										isInvestor
											? (mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[1].totalPowerGeneration! / totalGen!) * 100
											: (mapData?.dashboardSitePowersByContractorIdAndDistrict?.[1].totalPowerGeneration! / totalGen!) * 100
									}
									colorScheme="green"
									borderRadius="5px"
								/>
							</Flex>
						</Flex>

						{/* hong kong island data */}
						<Flex flexDir="column" gap="10px">
							<Flex gap="10px" alignItems="center">
								<Image src={require("../../icons/general/hkiRadio.png")}></Image>
								<Text color="#459EE3" fontWeight="bold">
									{t("TXT_hong_kong_island")}
								</Text>
							</Flex>
							<>
								{isInvestor &&
									mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[0]?.districtsSitePowers.map((data, i) => {
										return (
											<Flex justifyContent="space-between" key={`hki-${i}`}>
												<Text color="GREY">{t(`TXT_Option_${data.name.replaceAll("-", "_")}`)}</Text>
												<Text fontWeight={data.totalPowerGeneration !== 0 ? "bold" : "normal"} color={data.totalPowerGeneration !== 0 ? "BK" : "GREY"}>
													{data.totalPowerGeneration == 0 ? 0 : totalGen && ((data.totalPowerGeneration / totalGen) * 100).toFixed(1)}%
												</Text>
											</Flex>
										);
									})}
								{!isInvestor &&
									mapData?.dashboardSitePowersByContractorIdAndDistrict?.[0]?.districtsSitePowers.map((data, i) => {
										return (
											<Flex justifyContent="space-between" key={`hki-${i}`}>
												<Text color="GREY">{t(`TXT_Option_${data.name.replaceAll("-", "_")}`)}</Text>
												<Text fontWeight={data.totalPowerGeneration !== 0 ? "bold" : "normal"} color={data.totalPowerGeneration !== 0 ? "BK" : "GREY"}>
													{data.totalPowerGeneration == 0 ? 0 : totalGen && ((data.totalPowerGeneration / totalGen) * 100).toFixed(1)}%
												</Text>
											</Flex>
										);
									})}
							</>
							<Flex flexDir="column" borderTop="1px solid #F0F0F0" gap="5px" pt="5px">
								<Flex alignItems="center" justifyContent="space-between">
									<Image src={require("../../icons/general/solarPanelB.png")} width="25px" height="25px"></Image>
									<Flex fontWeight="bold">
										{isInvestor
											? mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[0].totalPowerGeneration.toLocaleString()
											: mapData?.dashboardSitePowersByContractorIdAndDistrict?.[0].totalPowerGeneration.toLocaleString()}
										KWh
									</Flex>
								</Flex>
								<Progress
									value={
										isInvestor
											? (mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[0].totalPowerGeneration! / totalGen!) * 100
											: (mapData?.dashboardSitePowersByContractorIdAndDistrict?.[0].totalPowerGeneration! / totalGen!) * 100
									}
									colorScheme="blue"
									borderRadius="5px"
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex flex={1}>
						{/* nt data */}
						<Flex flexDir="column" gap="10px" width="100%">
							<Flex gap="10px" alignItems="center">
								<Image src={require("../../icons/general/ntRadio.png")}></Image>
								<Text color="#F4891E" fontWeight="bold">
									{t("TXT_new_territories")}
								</Text>
							</Flex>
							<>
								{isInvestor &&
									mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[2]?.districtsSitePowers.map((data, i) => {
										return (
											<Flex justifyContent="space-between" key={`nt-${i}`}>
												<Text color="GREY">{t(`TXT_Option_${data.name.replaceAll("-", "_")}`)}</Text>
												<Text fontWeight={data.totalPowerGeneration !== 0 ? "bold" : "normal"} color={data.totalPowerGeneration !== 0 ? "BK" : "GREY"}>
													{data.totalPowerGeneration == 0 ? 0 : totalGen && ((data.totalPowerGeneration / totalGen) * 100).toFixed(1)}%
												</Text>
											</Flex>
										);
									})}
								{!isInvestor &&
									mapData?.dashboardSitePowersByContractorIdAndDistrict?.[2]?.districtsSitePowers.map((data, i) => {
										return (
											<Flex justifyContent="space-between" key={`nt-${i}`}>
												<Text color="GREY">{t(`TXT_Option_${data.name.replaceAll("-", "_")}`)}</Text>
												<Text fontWeight={data.totalPowerGeneration !== 0 ? "bold" : "normal"} color={data.totalPowerGeneration !== 0 ? "BK" : "GREY"}>
													{data.totalPowerGeneration == 0 ? 0 : totalGen && ((data.totalPowerGeneration / totalGen) * 100).toFixed(1)}%
												</Text>
											</Flex>
										);
									})}
							</>
							<Flex flexDir="column" borderTop="1px solid #F0F0F0" gap="5px" pt="5px">
								<Flex alignItems="center" justifyContent="space-between">
									<Image src={require("../../icons/general/solarPanelO.png")} width="25px" height="25px"></Image>
									<Flex fontWeight="bold">
										{isInvestor
											? mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[2].totalPowerGeneration.toLocaleString()
											: mapData?.dashboardSitePowersByContractorIdAndDistrict?.[2].totalPowerGeneration.toLocaleString()}
										KWh
									</Flex>
								</Flex>
								<Progress
									value={
										isInvestor
											? (mapData?.dashboardSitePowersByInvestorIdAndDistrict?.[2].totalPowerGeneration! / totalGen!) * 100
											: (mapData?.dashboardSitePowersByContractorIdAndDistrict?.[2].totalPowerGeneration! / totalGen!) * 100
									}
									colorScheme="orange"
									borderRadius="5px"
								/>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default ProjectMappingRow;
