import { Box, chakra, Flex, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowDown } from "react-icons/io";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Cell,
  Text,
} from "recharts";

const GenerationProfitBarLineChart = ({
  data,
  showHKE,
  selectedDate,
  updateDate,
  selectEleCompany,
  containerWidth,
}: {
  data: any;
  showHKE: boolean;
  selectedDate: string;
  updateDate: Function;
  selectEleCompany: Function;
  containerWidth: number;
}) => {
  const [focusBar, setFocusBar] = useState<number | null>(null);
  const [selectedEnergyGen, setSelectedEnergyGen] = useState(true);
  const [selectedRevenue, setSelectedRevenue] = useState(true);
  const [searchCLPDate, setSearchCLPDate] = useState(new Date(selectedDate));
  const [searchHKEDate, setSearchHKEDate] = useState(new Date(selectedDate));

  const { t } = useTranslation();

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    if (payload) {
      return (
        <Text
          x={x}
          y={y + 20}
          textAnchor="middle"
          fill={data[payload.index]?.profit == 0 ? "#E8E8E8" : "#000000"}
        >
          {payload.value}
        </Text>
      );
    }
    return null;
  };

  const CustomYAxisTick = ({ x, y, payload }: any) => {
    if (payload && payload.value) {
      return (
        <Text x={x - 20} y={y} textAnchor="middle" verticalAnchor="middle">
          {showHKE ? payload.value : payload.value}
        </Text>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      let profitData = selectedEnergyGen
        ? payload[1]?.value
        : payload[0]?.value;
      let generateData = payload[0]?.value;

      let displayProfitData =
        // profitData == 0 ? "0k" : (profitData / 1000).toFixed(2);
        profitData == 0 ? "0k" : profitData;
      let displayGenerateData =
        generateData == 0 ? "0k" : generateData.toFixed(2);
      if (profitData == 0 && generateData == 0) return <></>;
      return (
        <Flex
          bg="WT"
          borderRadius="8px"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          flexDir="column"
          p="10px"
          borderColor="GREY"
          gap="10px"
        >
          {selectedRevenue && (
            <Flex
              //  borderLeft="1px solid #ff7300" pl="10px"
              flexDir="column"
            >
              <Box fontSize="10px" color="#ff7300">
                Profit
              </Box>
              <Flex alignItems="baseline">
                <Box fontWeight="bold">{displayProfitData}</Box>
                <Box fontSize="8px" color="GREY">
                  &nbsp; HKD
                </Box>
              </Flex>
            </Flex>
          )}

          {selectedEnergyGen && (
            <Flex
              // borderLeft="1px solid #459EE3" pl="10px"
              flexDir="column"
            >
              <Box fontSize="10px" color="#459EE3">
                Generate
              </Box>
              <Flex alignItems="baseline">
                <Box fontWeight="bold">{displayGenerateData}</Box>
                <Box fontSize="8px" color="GREY">
                  &nbsp; KWh
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
      );
    }

    return null;
  };

  useEffect(() => {
    if (showHKE) {
      setSearchCLPDate(new Date(selectedDate));
    } else {
      setSearchHKEDate(new Date(selectedDate));
    }

    return () => {};
  }, [showHKE]);

  return (
    <>
      <Flex fontWeight="bold" mb="20px">
        {t("TXT_Dashboard_barline_chart_title")}
      </Flex>
      {/* switch button */}
      <Flex justifyContent="space-between" mb="10px" alignItems="center">
        <Flex gap="5px" borderRadius="25px" border="1px solid #004b92" p="3px">
          <Flex
            px="10px"
            py="5px"
            bg={showHKE ? "WT" : "themeBlue"}
            color={showHKE ? "GREY" : "WT"}
            cursor="pointer"
            borderRadius="25px"
            border={showHKE ? "none" : "1px solid #004b92"}
            onClick={() => selectEleCompany(false)}
          >
            CLP
          </Flex>

          <Flex
            px="10px"
            py="5px"
            bg={showHKE ? "themeBlue" : "WT"}
            color={showHKE ? "WT" : "GREY"}
            cursor="pointer"
            borderRadius="25px"
            border={showHKE ? "1px solid #004b92" : "none"}
            onClick={() => selectEleCompany(true)}
          >
            HKE
          </Flex>
        </Flex>
        <Flex gap="10px">
          {(!showHKE && (
            <DatePicker
              selected={searchCLPDate}
              customInput={
                <Flex
                  alignItems="center"
                  gap="10px"
                  cursor="pointer"
                  color="#004B92"
                  borderRadius="25px"
                  border="1px solid #004B92"
                  // py="5px"
                  p="10px"
                  onClick={(e) => e.preventDefault()}
                >
                  {moment(searchCLPDate).format("MMM,YYYY")}
                  <IoIosArrowDown />
                </Flex>
              }
              onChange={(newDate: any) => {
                setSearchCLPDate(newDate);
                updateDate(newDate);
                // getBrightSunData({
                //   variables: {
                //     filter: {
                //       startDate: moment(newDate).format("YYYY-M"),
                //       endDate: moment(newDate).add(1, "month").format("YYYY-M"),
                //     },
                //     today: true,
                //     limit: 40,
                //   },
                // });
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          )) || (
            <DatePicker
              selected={searchHKEDate}
              customInput={
                <Flex
                  alignItems="center"
                  gap="10px"
                  cursor="pointer"
                  color="#004B92"
                  borderRadius="25px"
                  border="1px solid #004B92"
                  // py="5px"
                  p="10px"
                  onClick={(e) => e.preventDefault()}
                >
                  {moment(searchHKEDate).format("YYYY")}
                  <IoIosArrowDown />
                </Flex>
              }
              onChange={(newDate: any) => {
                setSearchHKEDate(newDate);
                updateDate(newDate);
                // getBrightSunData({
                //   variables: {
                //     filter: {
                //       startDate: moment(newDate).format("YYYY-M"),
                //       endDate: moment(newDate).add(1, "month").format("YYYY-M"),
                //     },
                //     today: true,
                //     limit: 40,
                //   },
                // });
              }}
              dateFormat="MM/yyyy"
              showYearPicker
            />
          )}
        </Flex>
      </Flex>

      <Flex overflowX="scroll">
        {/* <ResponsiveContainer width={1000} height={360}> */}
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 0,
            bottom: 20,
            left: 0,
          }}
          width={1000}
          height={containerWidth - 80}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex!);
            } else {
              setFocusBar(null);
            }
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="name"
            tick={<CustomXAxisTick />}
            tickLine={{ stroke: "none" }}
          />
          <YAxis tick={<CustomYAxisTick />} />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          {selectedEnergyGen && (
            <Bar dataKey="generate" barSize={50} fill="#B6E1EA">
              {data.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={focusBar === index ? "#459EE3" : "#B6E1EA"}
                />
              ))}
            </Bar>
          )}

          {selectedRevenue && (
            <Line
              type="monotone"
              dataKey="profit"
              stroke="#ff7300"
              fill="#ff7300"
            />
          )}
        </ComposedChart>
        {/* </ResponsiveContainer> */}
      </Flex>

      {/* button section */}
      <Flex justifyContent="center" gap="20px" mb="20px">
        <Flex
          alignItems="center"
          gap="10px"
          borderWidth="1px"
          borderRadius="25px"
          py="7.5px"
          px="10px"
          borderColor={selectedEnergyGen ? "#459EE3" : "GREY"}
          onClick={() => {
            if (selectedRevenue == false) {
              setSelectedRevenue(true);
              setSelectedEnergyGen(!selectedEnergyGen);
            } else {
              setSelectedEnergyGen(!selectedEnergyGen);
            }
          }}
          cursor="pointer"
        >
          <Image
            src={
              selectedEnergyGen
                ? require("../../icons/general/blueRadio.png")
                : require("../../icons/general/greyRadio.png")
            }
            width="15px"
            height="15px"
          />
          <Box color={selectedEnergyGen ? "BK" : "GREY"}>
            {t("TXT_Dashboard_energy_generation")}
          </Box>
        </Flex>

        <Flex
          alignItems="center"
          gap="10px"
          borderWidth="1px"
          borderRadius="25px"
          py="7.5px"
          px="10px"
          borderColor={selectedRevenue ? "#F4891E" : "GREY"}
          onClick={() => {
            if (selectedEnergyGen == false) {
              setSelectedEnergyGen(true);
              setSelectedRevenue(!selectedRevenue);
            } else {
              setSelectedRevenue(!selectedRevenue);
            }
          }}
          cursor="pointer"
        >
          <Image
            src={
              selectedRevenue
                ? require("../../icons/general/orangeRadio.png")
                : require("../../icons/general/greyRadio.png")
            }
            width="15px"
            height="15px"
          />
          <Box color={selectedRevenue ? "BK" : "GREY"}>
            {t("TXT_Dashboard_revenue")}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default GenerationProfitBarLineChart;
