import { useState, Fragment, useEffect } from "react";
import {
  Flex,
  Text,
  Button,
  Input,
  Textarea,
  useToast,
  Icon,
} from "@chakra-ui/react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
//import CalendarModal from "../../components/CalendarModal";
import PopUpModal from "../../components/PopUpModal";
import {
  FiSend,
  FiArrowLeft,
  FiEdit,
  FiZoomIn,
  FiZoomOut,
} from "react-icons/fi";
import { IconContext } from "react-icons";
import jwt_decode from "jwt-decode";
import { ReactComponent as SuccessIcon } from "../../icons/general/success.svg";
import { returnByTheme, returnByColor } from "../../utils/util";
import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment";
import React from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CompanyDocument, SendInvoiceDocument } from "src/graphql/generated";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { RiFileDownloadLine } from "react-icons/ri";

type TagProps = {
  leftText: string;
  leftbg: string;
  rightText: string;
  rightbg: string;
  rightTextColor: string;
  inputKey: string;
};

const InformationTag = ({
  leftText,
  leftbg,
  rightText,
  rightbg,
  rightTextColor,
  inputKey,
}: TagProps) => {
  return (
    <Flex
      width="100%"
      borderRadius="40px"
      textColor="LB.3"
      py="5px"
      key={inputKey}
    >
      <Flex
        borderLeftRadius="40px"
        flex={1}
        px="20px"
        backgroundColor={leftbg}
        justifyContent="center"
        alignItems="center"
        py="5px"
      >
        <Text color="#000">{leftText}</Text>
      </Flex>
      <Flex
        flex={1}
        px="20px"
        borderRightRadius="40px"
        backgroundColor={rightbg}
        justifyContent="center"
        alignItems="center"
      >
        <Text color={rightTextColor}>{rightText}</Text>
      </Flex>
    </Flex>
  );
};

const InvoiceDetail = () => {
  const [trigger, setTrigger] = useState(false);
  const [isShowSendModal, setIsShowSendModal] = useState(false);
  //const [isShowAlertModal, setIsShowAlertModal] = useState(false);
  // const [senderEmail, setSenderEmail] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(``);

  // const [zoomSize, setZoomSize] = useState(1.2);
  const isClient = localStorage.getItem("sf_Role") == "true";
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [myCompanyData, setMyCompanyData] = useState<any>();
  const { invoiceId } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token!);
  const companyId = decodedToken.company?.id;

  const { infos, prevSelectedOpt, prevPayload, prevKeyword, prevDateRange } =
    (location?.state as any) ?? {};
  const pdfUrl = infos.file.url;
  const receivableTotal = infos?.bill?.receivableCharges.reduce(
    (a: any, b: { amount: any }) => a + b.amount,
    0
  );
  const payableTotal = infos?.bill?.payableCharges.reduce(
    (a: any, b: { amount: any }) => a + b.amount,
    0
  );

  const totalCost = Math.round(
    infos?.bill?.fitAmount + receivableTotal - payableTotal
  );

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    const invoiceTitle =
      infos?.total > 0
        ? t("TXT_Option_Credit") + " " + t("TXT_Note")
        : t("TXT_Option_Debit") + " " + t("TXT_Note");
    const defaultSubject = `${invoiceTitle} #${moment(infos.startTime).format(
      "DD-MMM-YYYY"
    )}`;
    setReceiverEmail(infos.site?.landOwner?.email);
    setSubject(defaultSubject);
    getMyCompany();

    return () => {};
  }, []);

  const [getMyCompany, { data: companyData }] = useLazyQuery(CompanyDocument, {
    variables: {
      id: companyId,
    },
    onCompleted: (data) => {
      const company = data.company;
      setMyCompanyData(company);
      setMessage(`Dear customer:  

Your invoice has been ready.If you have any question, 

please call our customer service hotline at (852) ${company?.phone?.phone} (Hong Kong).`);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [sendInvoice, { data: sendInvoiceStatus, loading }] = useMutation(
    SendInvoiceDocument,
    {
      onCompleted: (x) => {
        toast({
          position: "bottom-right",
          title: `invoice sent, wating for redirect...`,
          status: "success",
        });
        setTimeout(() => {
          navigate(`/invoice`);
        }, 3000);
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  return (
    <Flex backgroundColor="#E5E5E5" flexDirection="column" maxWidth="100%">
      <Flex
        flex={1}
        flexDirection="row"
        backgroundColor="#FFF"
        alignItems="center"
        justifyContent="center"
        py="10px"
        position="sticky"
        top="0px"
        zIndex={2}
      >
        <Flex
          position="absolute"
          left="20px"
          onClick={() =>
            navigate("/invoice", {
              state: {
                prevSelectedOpt: prevSelectedOpt,
                prevPayload: prevPayload,
                prevKeyword: prevKeyword,
                prevDateRange: prevDateRange,
              },
            })
          }
          cursor="pointer"
        >
          <Flex alignContent="center" justifyContent="center" p="10px">
            <FiArrowLeft width="35px" height="35px" />
          </Flex>
          <Text color="#8E8E8E" alignSelf="center">
            Invoice / &nbsp;
          </Text>
          <Text color="#000" fontWeight="700" alignSelf="center">
            {infos.code}
          </Text>
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          {!isClient && (
            <Button
              py="15px"
              px="30px"
              backgroundColor="WT"
              borderWidth="1px"
              borderRadius="40px"
              borderColor={returnByTheme()}
              width="100%"
              onClick={() => {
                navigate(`/invoice/detail/edit/${invoiceId}`, {
                  state: {
                    infos: infos,
                    prevSelectedOpt: prevSelectedOpt,
                    prevPayload: prevPayload,
                    prevKeyword: prevKeyword,
                    prevDateRange: prevDateRange,
                  },
                });
              }}
            >
              <IconContext.Provider
                value={{ color: returnByColor(), size: "25px" }}
              >
                <FiEdit />
                <Text
                  paddingLeft="10px"
                  color={returnByTheme()}
                  fontSize="18px"
                >
                  {t("TXT_Edit")}
                </Text>
              </IconContext.Provider>
            </Button>
          )}

          {!isClient && (
            <Button
              py="15px"
              px="30px"
              backgroundColor={returnByTheme()}
              borderWidth="1px"
              borderRadius="40px"
              borderColor="WT"
              width="100%"
              marginLeft="10px"
              onClick={() => setIsShowSendModal(true)}
            >
              <IconContext.Provider value={{ color: "#FFF", size: "25px" }}>
                <FiSend />
                <Text paddingLeft="10px" color="WT" fontSize="18px">
                  {t("TXT_Send")}
                </Text>
              </IconContext.Provider>
            </Button>
          )}

          {isClient && (
            <Button
              py="15px"
              px="30px"
              backgroundColor="WT"
              borderWidth="1px"
              borderRadius="40px"
              borderColor={returnByTheme()}
              width="100%"
              marginLeft="10px"
              // onClick={() => setIsShowSendModal(true)}
            >
              <IconContext.Provider
                value={{ color: returnByTheme(), size: "25px" }}
              >
                <Icon
                  as={RiFileDownloadLine}
                  w="20px"
                  h="20px"
                  mr="5px"
                  color="themeOrange"
                />
                <Text
                  paddingLeft="10px"
                  color={returnByTheme()}
                  fontSize="18px"
                >
                  {t("TXT_Download")}
                </Text>
              </IconContext.Provider>
            </Button>
          )}
        </Flex>
      </Flex>

      <Flex
        // flex={14}
        // minHeight="100vh"
        // height="100%"
        alignItems="center"
        // justifyContent="center"
        flexDirection="column"
        overflowX="auto"
      >
        {infos?.bill?.site?.electricCompany?.type === "CLP" && (
          <Flex position={"absolute"} top={100} right={0} zIndex={1}>
            <Button
              borderLeftRadius="40px"
              onClick={() => setTrigger(!trigger)}
              backgroundColor="LB.3"
              textColor="#FFF"
              //leftIcon={trigger ? <Text>close</Text> : <Text>open</Text>}
            >
              CLP
            </Button>

            {trigger && (
              <Flex
                w="490px"
                backgroundColor="LB.3"
                borderColor="LB.3"
                flexDirection={"column"}
                paddingLeft={45}
                paddingRight={45}
                paddingTop={5}
                paddingBottom={50}
                borderBottomLeftRadius={"40px"}
              >
                <Flex justifyContent="center" flexDirection="row">
                  <Flex
                    backgroundColor="#FFF"
                    borderRadius="40px"
                    px="10px"
                    textColor="LB.3"
                  >
                    <Text fontWeight="700"> {t("TXT_Account_number")}:</Text>
                    <Text>{infos?.bill?.site?.electricCompany?.username}</Text>
                  </Flex>
                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Fragment>
                    <Text color="#FFF" fontWeight="700">
                      {t("TXT_Project_Basic_info")}
                    </Text>
                    {infos?.bill?.site?.electricDetail?.electricMeters
                      .filter((x: { isFit: boolean }) => x.isFit)
                      .map((meter: { code: string }) => (
                        <InformationTag
                          leftText={t("TXT_Project_Meter_id_fit")}
                          leftbg="WT"
                          rightText={meter?.code}
                          rightbg="LB.5"
                          rightTextColor="LB.3"
                          inputKey={`${meter.code}`}
                        />
                      ))}
                    {infos?.bill?.site?.electricDetail?.electricMeters
                      .filter((x: { isFit: boolean }) => !x.isFit)
                      .map((meter: { code: string }) => (
                        <InformationTag
                          leftText={t("TXT_Project_Meter_id")}
                          leftbg="WT"
                          rightText={meter?.code}
                          rightbg="LB.5"
                          rightTextColor="LB.3"
                          inputKey={`${meter.code}`}
                        />
                      ))}
                    <InformationTag
                      leftText={t("TXT_Invoice_feed_in_tariff")}
                      leftbg="WT"
                      rightText={infos?.bill?.fitAmount}
                      rightbg="LB.5"
                      rightTextColor="LB.3"
                      inputKey="FitAmount"
                    />
                    <InformationTag
                      leftText={t("TXT_Invoice_total_amount")}
                      leftbg="WT"
                      rightText={totalCost.toString()}
                      // rightText={round(totalCost).toString()}
                      rightbg="LB.5"
                      rightTextColor="LB.3"
                      inputKey="Cost"
                    />
                  </Fragment>
                  <Fragment>
                    <Text color="#FFF" fontWeight="700">
                      {t("TXT_Invoice_receivable_charges")}
                    </Text>
                    {infos?.bill?.receivableCharges?.map(
                      (charge: { name: string; amount: number }) => (
                        <InformationTag
                          leftText={charge?.name}
                          leftbg="WT"
                          rightText={charge?.amount.toString()}
                          rightbg="LB.5"
                          rightTextColor="LB.3"
                          inputKey={`${charge?.name}_${charge?.amount}`}
                        />
                      )
                    )}
                  </Fragment>
                  <Fragment>
                    <Text color="#FFF" fontWeight="700">
                      {t("TXT_Invoice_payable_charges")}
                    </Text>
                    {infos?.bill?.payableCharges?.map(
                      (charge: { name: string; amount: number }) => (
                        <InformationTag
                          leftText={charge?.name}
                          leftbg="WT"
                          rightText={charge?.amount.toString()}
                          rightbg="LB.5"
                          rightTextColor="LB.3"
                          inputKey={`${charge?.name}_${charge?.amount}`}
                        />
                      )
                    )}
                  </Fragment>

                  <Button
                    height="34px"
                    backgroundColor="LB.3"
                    borderWidth="1px"
                    borderColor="WT"
                    p="15px"
                    py="25px"
                    borderRadius="40px"
                    mt={"50px"}
                    onClick={() => {
                      window.location = infos?.bill?.file?.url;
                    }}
                  >
                    <Text color="WT">{t("TXT_Invoice_download_bill")}</Text>
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
        {infos?.bill?.site?.electricCompany?.type === "HKE" && (
          <Flex position={"absolute"} top={100} right={0} zIndex={1}>
            <Button
              borderLeftRadius="40px"
              onClick={() => setTrigger(!trigger)}
              backgroundColor="RD.3"
              textColor="#FFF"
              //leftIcon={trigger ? <Text>close</Text> : <Text>open</Text>}
            >
              HKE
            </Button>

            {trigger && (
              <Flex
                w="490px"
                backgroundColor="RD.3"
                borderColor="RD.3"
                flexDirection={"column"}
                paddingLeft={45}
                paddingRight={45}
                paddingTop={5}
                paddingBottom={50}
                borderBottomLeftRadius={"40px"}
              >
                <Flex justifyContent="center" flexDirection="row">
                  <Flex
                    backgroundColor="#FFF"
                    borderRadius="40px"
                    px="10px"
                    textColor="RD.3"
                  >
                    <Text fontWeight="700">{t("TXT_Account_number")}:</Text>
                    <Text>{infos?.bill?.site?.electricCompany?.username}</Text>
                  </Flex>
                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Fragment>
                    <Text color="#FFF" fontWeight="700">
                      {t("TXT_Project_Basic_info")}
                    </Text>
                    {infos?.bill?.site?.electricDetail?.electricMeters
                      .filter((x: { isFit: boolean }) => x.isFit)
                      .map((meter: { code: string }) => (
                        <InformationTag
                          leftText={t("TXT_Project_Meter_id_fit")}
                          leftbg="WT"
                          rightText={meter?.code}
                          rightbg="RD.5"
                          rightTextColor="RD.3"
                          inputKey={`${meter.code}`}
                        />
                      ))}
                    {infos?.bill?.site?.electricDetail?.electricMeters
                      .filter((x: { isFit: boolean }) => !x.isFit)
                      .map((meter: { code: string }) => (
                        <InformationTag
                          leftText={t("TXT_Project_Meter_id")}
                          leftbg="WT"
                          rightText={meter?.code}
                          rightbg="RD.5"
                          rightTextColor="RD.3"
                          inputKey={`${meter.code}`}
                        />
                      ))}
                    <InformationTag
                      leftText={t("TXT_Invoice_feed_in_tariff")}
                      leftbg="WT"
                      rightText={infos?.bill?.fitAmount}
                      rightbg="RD.5"
                      rightTextColor="RD.3"
                      inputKey="FitAmount"
                    />
                    <InformationTag
                      leftText={t("TXT_Invoice_total_amount")}
                      leftbg="WT"
                      rightText={totalCost.toString()}
                      rightbg="RD.5"
                      rightTextColor="RD.3"
                      inputKey="Cost"
                    />
                  </Fragment>
                  <Fragment>
                    <Text color="#FFF" fontWeight="700">
                      {t("TXT_Invoice_receivable_charges")}
                    </Text>
                    {infos?.bill?.receivableCharges?.map(
                      (charge: { name: string; amount: number }) => (
                        <InformationTag
                          leftText={charge?.name}
                          leftbg="WT"
                          rightText={charge?.amount.toString()}
                          rightbg="RD.5"
                          rightTextColor="RD.3"
                          inputKey={`${charge?.name}_${charge?.amount}`}
                        />
                      )
                    )}
                  </Fragment>
                  <Fragment>
                    <Text color="#FFF" fontWeight="700">
                      {t("TXT_Invoice_payable_charges")}
                    </Text>
                    {infos?.bill?.payableCharges?.map(
                      (charge: { name: string; amount: number }) => (
                        <InformationTag
                          leftText={charge?.name}
                          leftbg="WT"
                          rightText={charge?.amount.toString()}
                          rightbg="RD.5"
                          rightTextColor="RD.3"
                          inputKey={`${charge?.name}_${charge?.amount}`}
                        />
                      )
                    )}
                  </Fragment>

                  <Button
                    height="34px"
                    backgroundColor="RD.3"
                    borderWidth="1px"
                    borderColor="WT"
                    p="15px"
                    py="25px"
                    borderRadius="40px"
                    mt={"50px"}
                    onClick={() => {
                      window.location = infos?.bill?.file?.url;
                    }}
                  >
                    <Text color="WT">{t("TXT_Invoice_download_bill")}</Text>
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>
        )}

        <Flex position="relative" my="20px" minH="calc(100vh - 90px)">
          <PdfViewer
            pdfUrl={pdfUrl}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            pageNumber={pageNumber}
            // zoomSize={zoomSize}
          />
        </Flex>

        {/* <Document
          file={{ url: pdfUrl }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log("Inside Error", error)}
        >
          <Page pageNumber={pageNumber} scale={zoomSize} />
        </Document> */}
        {/* <embed src={"http://www.africau.edu/images/default/sample.pdf"} width="800px" height="2100px"/>  */}
      </Flex>

      <PopUpModal
        isShow={isShowSendModal}
        onClose={() => setIsShowSendModal(false)}
        headerColor={returnByTheme()}
        headerText="Send invoice"
      >
        <Flex flexDirection="column">
          <Text fontSize="15px" color={returnByTheme()}>
            {t("TXT_From")}
          </Text>
          <Input
            disabled
            value={myCompanyData?.email}
            flex={3}
            color={returnByTheme()}
            bg={"#D9DDE0"}
            // onChange={(e) => setSenderEmail(e.target.value)}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            padding="5px"
          />
          <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
            {t("TXT_Send")} {t("TXT_To")}
          </Text>
          <Input
            flex={3}
            value={receiverEmail}
            onChange={(e) => setReceiverEmail(e.target.value)}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            padding="5px"
          />
          <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
            {t("TXT_Subject")}
          </Text>
          <Input
            flex={3}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            padding="5px"
          />
          <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
            {t("TXT_Message")}
          </Text>
          <Textarea
            flex={3}
            value={message}
            size="sm"
            onChange={(e) => setMessage(e.target.value)}
            minHeight="150px"
            borderRadius="10px"
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
          />
          <Button
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            disabled={loading}
            isLoading={loading}
            loadingText={t("Loading")}
            spinnerPlacement="end"
            onClick={() => {
              sendInvoice({
                variables: {
                  input: {
                    id: infos.id,
                    receiverEmail: receiverEmail,
                    subject: subject,
                    content: message,
                  },
                },
              });
            }}
            marginTop="20px"
            borderRadius="40px"
          >
            <IconContext.Provider value={{ color: "#FFF", size: "24px" }}>
              <FiSend />
              <Text paddingLeft="10px" color="WT">
                {t("TXT_Send")}
              </Text>
            </IconContext.Provider>
          </Button>
        </Flex>
      </PopUpModal>

      {/* <PopUpModal
        isShow={isShowSuccessModal}
        onClose={() => {
          setIsShowSuccessModal(false);
        }}
        headerColor={returnByTheme()}
        headerText="Message"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <SuccessIcon width="100px" height="100px" />
          <Text textAlign="center" fontWeight="700" color={returnByTheme()}>
            {t("TXT_Success")}
          </Text>
          <Text textAlign="center" fontWeight="700" paddingTop="15px">
            {t("TXT_Invoice_already_user_desc")}
          </Text>
          <Text textAlign="center" fontSize="15px" color="#8E8E8E">
            {t("TXT_Invoice_contact_you_desc")}
          </Text>

          <Flex
            flexDirection="column"
            backgroundColor="#F0F0F0"
            width="90%"
            marginTop="20px"
          >
            <Text> {t("TXT_Name")}</Text>
            <Text> {t("TXT_Email")}</Text>
            <Text> {t("TXT_Company_name")}</Text>
            <Text> {t("TXT_Phone")}</Text>
          </Flex>
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => {
              setIsShowSuccessModal(false);
              setIsShowSendModal(false);
            }}
            marginTop="20px"
            borderRadius="40px"
            flex={1}
            borderColor="#000"
            borderWidth="1px"
            width="50%"
            py="10px"
          >
            <Text color="#000">{t("TXT_Close")}</Text>
          </Button>
        </Flex>
      </PopUpModal> */}
    </Flex>
  );
};

const PdfViewer = React.memo(
  ({ pdfUrl, onDocumentLoadSuccess, pageNumber }: any) => {
    const [zoomSize, setZoomSize] = useState(1.2);
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
      <>
        <Document
          file={{ url: pdfUrl }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log("Inside Error", error)}
        >
          <Page pageNumber={pageNumber} scale={zoomSize} />
        </Document>
        <Flex
          flex={1}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          //backgroundColor="#FFA"
          zIndex={2}
          py="5px"
        >
          <Flex
            //p="5px"
            w="200px"
            h="60px"
            flexDirection="row"
            backgroundColor="WT"
            borderWidth="1px"
            borderRadius="15px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
            alignItems="center"
            justifyContent="center"
            gap="10px"
          >
            <IconContext.Provider value={{ color: "#8E8E8E", size: "35px" }}>
              <Flex cursor="pointer">
                <FiZoomIn
                  onClick={() => {
                    setZoomSize((cur) => (cur < 2 ? cur + 0.1 : cur));
                  }}
                />
              </Flex>

              <Flex cursor="pointer">
                <FiZoomOut
                  onClick={() => {
                    setZoomSize((cur) => (cur > 0.7 ? cur - 0.1 : cur));
                  }}
                />
              </Flex>
            </IconContext.Provider>
            <Flex
              alignItems="center"
              fontSize="18px"
              textColor="GREY"
              borderLeft="1px solid #F0F0F0"
              paddingLeft="10px"
              cursor="pointer"
              onClick={() => navigate(-1)}
              fontWeight="bold"
            >
              <Icon as={AiOutlineClose} width="20px" height="20px" mr="5px" />
              {t("TXT_Close")}
            </Flex>
          </Flex>
        </Flex>
      </>
    );
  },
  (prevProps: any, nextProps: any) => true
);

export default InvoiceDetail;
