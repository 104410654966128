import {
  Box,
  Input,
  Text,
  Flex,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import { returnByTheme } from "../../utils/util";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

interface ISelectProps {
  placeHolder: string;
  isRequired?: boolean;
  inputLabel?: string;
  hints?: string;
  onChange: (value: string) => void;
  inpputtedValue?: string;
  isDisable?: boolean;
  limitSymbol?: boolean;
  isTextArea?: boolean;
  leftIcon?: any;
  rightIcon?: any;
  maxLen?: number;
}

const FormTextInput = ({
  placeHolder,
  isRequired = false,
  inputLabel,
  hints,
  onChange,
  inpputtedValue,
  isDisable,
  limitSymbol,
  isTextArea = false,
  leftIcon,
  rightIcon,
  maxLen,
}: ISelectProps) => {
  return (
    <Flex>
      <Flex
        flex={1}
        flexDirection={"row"}
        py="10px"
        px="20px"
        justifyContent={"space-between"}
        gap="5px"
      >
        {/* label section */}
        <Flex flex={2} fontSize={15} color={"GREY"}>
          {isRequired ? (
            <Box>
              <Text display={"inline"}>{inputLabel}&nbsp;</Text>
              <Text color={"RD.1"} display={"inline"}>
                *
              </Text>
            </Box>
          ) : (
            <Flex>{inputLabel}</Flex>
          )}
        </Flex>
        {/* text input section */}
        <Flex flex={3} position="relative">
          {(isTextArea && (
            <Textarea
              disabled={isDisable}
              placeholder={placeHolder}
              _placeholder={{ color: "DISABLEDTEXT" }}
              resize={"none"}
              minHeight="150px"
              pt="10px"
              px="10px"
              pb="30px"
              borderColor={returnByTheme()}
              value={inpputtedValue}
              onChange={(e) => {
                onChange(
                  limitSymbol
                    ? // ? e.target?.value.replace(/[^0-9a-zA-Z\-_]+/g, "")
                      e.target?.value.replace(/[^0-9a-zA-Z]+/g, "")
                    : e.target?.value
                );
              }}
            />
          )) || (
            <InputGroup flexDirection={"column"}>
              {leftIcon && (
                <InputLeftElement
                  children={leftIcon}
                  width={"fit-content"}
                  height={"fit-content"}
                  top={"5px"}
                  left={"10px"}
                  background={"WT"}
                />
              )}

              <Input
                disabled={isDisable}
                placeholder={placeHolder}
                _placeholder={{ color: "DISABLEDTEXT" }}
                flex={1}
                px="10px"
                py="5px"
                maxLength={maxLen}
                borderColor={returnByTheme()}
                borderRadius="5px"
                borderWidth={1}
                justifyContent={"space-between"}
                value={inpputtedValue}
                onChange={(e) => {
                  onChange(
                    limitSymbol
                      ? // ? e.target?.value.replace(/[^0-9a-zA-Z\-_]+/g, "")
                        e.target?.value.replace(/[^0-9a-zA-Z]+/g, "")
                      : e.target?.value
                  );
                }}
              />

              {rightIcon && (
                <InputRightElement
                  children={rightIcon}
                  width={"fit-content"}
                  height={"fit-content"}
                  top={"5px"}
                  right={"10px"}
                  background={"WT"}
                />
              )}
              {hints && (
                <Flex fontSize="10px" color={"GREY"}>
                  {hints}
                </Flex>
              )}
            </InputGroup>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FormTextInput;
