import React, { useState } from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";

import { returnByTheme, returnByColor } from "../utils/util";
import { useStore } from "../app/App.store";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { BiGlobe, BiChevronDown } from "react-icons/bi";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const LangSwitcher = ({ color = "#004B92" }: { color?: string }) => {
  const [selectedLang, setSelectedLang] = useState("English");
  const [toggle, setToggle] = useState(false);

  const getLanguage = () => {
    return (
      i18next.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "en"
    );
  };

  return (
    <Flex
      alignItems="center"
      gap="5px"
      borderRadius="18px"
      borderColor={color}
      borderWidth="1px"
      px="5px"
      bg="WT"
      justifyContent="space-between"
      position="relative"
      cursor="pointer"
      onClick={() => setToggle(true)}
      width="140px"
      height="45px"
    >
      <Flex alignItems="center" gap="5px">
        <Icon as={BiGlobe} color={color} />
        <Flex color={color}>
          {getLanguage() == "en"
            ? "English"
            : getLanguage() == "tc"
            ? "繁體中文"
            : "简体中文"}
        </Flex>
      </Flex>

      <Icon as={BiChevronDown} color={color}></Icon>

      {/* lang options  */}
      {toggle && (
        <Flex
          flexDir="column"
          gap="5px"
          position="absolute"
          top="50px"
          left="0px"
          bg="WT"
          border={`1px solid ${color}`}
          width="100%"
          borderTopRadius="12px"
          overflow="hidden"
          onMouseLeave={() => setToggle(false)}
        >
          {[
            { text: "English", lagnCode: "en" },
            { text: "繁體中文", lagnCode: "tc" },
            { text: "简体中文", lagnCode: "sc" },
          ].map((lang, i) => {
            return (
              <Flex
                key={i}
                px="5px"
                _hover={{
                  cursor: "pointer",
                  bg: "DISABLEDBG",
                  color: color,
                }}
                onClick={() => {
                  i18next.changeLanguage(lang.lagnCode);
                  localStorage.setItem("langCode", lang.lagnCode);
                  setToggle(false);
                }}
              >
                {lang.text}
              </Flex>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default LangSwitcher;
