import React, { useEffect, useRef, useState } from "react";
import {
  Flex,
  Text,
  IconButton,
  Image,
  Avatar,
  Heading,
  Icon,
  useToast,
  Box,
  Link,
  keyframes,
  useOutsideClick,
} from "@chakra-ui/react";
import { FiBell, FiLogOut, FiSettings } from "react-icons/fi";
// import SideBarItem from "./SideBarItem";
import { returnByTheme, returnByColor } from "../utils/util";
import { useStore } from "../app/App.store";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  InvoicesDocument,
  GetmeDocument,
  CompanyPrivileges,
} from "../graphql/generated";
import { ReactComponent as DashboardSidebarIcon } from "src/icons/general/dashboardSidebarIcon.svg";
import { ReactComponent as ProjectSidebarIcon } from "src/icons/general/projectSidebarIcon.svg";
import { ReactComponent as InvoiceSidebarIcon } from "src/icons/general/invoiceSidebarIcon.svg";
import { ReactComponent as CalculatorIcon } from "src/icons/general/Calculator.svg";
import { ReactComponent as SFLogo } from "src/icons/general/sfLogo.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LangSwitcher from "./LangSwitcher";
import { useNavigate } from "react-router-dom";
import { isNil } from "lodash";

type SideBarProps = {
  location: String;
};

const SideBar = ({ location }: SideBarProps) => {
  const [toggleSizeMenu, setToggleSizeMenu] = useState(false);
  const [toggleBottomMenuH, setToggleBottomMenuH] = useState(0);
  const titleArr = [
    "S",
    "O",
    "L",
    "A",
    "R",
    "\u00A0",
    "M",
    "O",
    "N",
    "S",
    "T",
    "E",
    "R",
  ];
  const sideBarRef = useRef(null);
  const toggleBottomRef = useRef<any>(null);
  const leftBottomRef = useRef<any>(null);
  const { logout } = useStore();
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useOutsideClick({
    ref: sideBarRef,
    handler: (e: any) => {
      setToggleSizeMenu(false);
    },
  });

  useEffect(() => {
    if (toggleSizeMenu) {
      setToggleBottomMenuH(toggleBottomRef.current?.clientHeight);
    }
  }, [toggleSizeMenu]);

  const [getMe, { data: meData }] = useLazyQuery(GetmeDocument, {
    fetchPolicy: "no-cache",
    onCompleted: (x) => {
      localStorage.setItem("meData", JSON.stringify(x.me));
      let isClient = "false";

      if (x.me.type && x.me.type == "Company") {
        isClient = x.me.company!.privileges!.includes(
          CompanyPrivileges.LandOwner
        )
          ? "true"
          : "false";
        localStorage.setItem("sf_Role", isClient);

        //directly confirm user is investory only
        if (x.me.company!.privileges!.includes(CompanyPrivileges.Investor)) {
          localStorage.setItem("sf_Investor", "true");
        }
        
      } else {
        isClient = x.me.user!.roles!.includes("viewer") ? "true" : "false";
        localStorage.setItem("sf_Role", isClient);
      }
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  useEffect(() => {
    getMe();

    return () => {};
  }, []);

  const animationKeyframes = keyframes`
  // 20% {opacity:0.6;}
  // 40% {opacity:0.7;}
  // 70% {opacity:0.9;}
  // 100% {opacity:1;}
  from {opacity:0;}
  to {opacity:1;}
`;

  const animationBlur = keyframes`
  
    0%		{text-shadow:  0 0 100px #fff; opacity:0;}
    5%		{text-shadow:  0 0 90px #fff;}
    15%		{opacity: 1;}
    20%		{text-shadow:  0 0 0px #fff;}
    80%		{text-shadow:  0 0 0px #fff;}
    85%		{opacity: 1;}
    95%		{text-shadow:  0 0 90px #fff;}
    100%	{text-shadow:  0 0 100px #fff; opacity:0;}
  
 `;

  const animation = `${animationKeyframes} 0.5s ease-in-out `;
  const blurAnimation = `${animationBlur} 4s ease-out infinite alternate`;

  // const meData?.me = JSON.parse(localStorage.getItem("meData?.me")!);
  const userName =
    meData?.me?.type == "Company"
      ? meData?.me?.company?.name
      : meData?.me?.user?.name;
  const userLevel = meData?.me?.type == "Company" ? "Company" : "Staff";

  const topMenu =
    meData?.me?.type == "Company" &&
    meData?.me.company!.privileges!.includes(CompanyPrivileges.LandOwner)
      ? [
          {
            title: t("TXT_Project"),
            link: "project",
            action: () => {
              setToggleSizeMenu(false);
              navigate("/project");
            },
            icon: (
              <ProjectSidebarIcon
                width="25px"
                height="25px"
                stroke={
                  location.split("/")[1] === "project"
                    ? returnByColor()
                    : "#ffffff"
                }
              />
            ),
          },
          {
            title: t("TXT_Invoice"),
            link: "invoice",
            action: () => {
              setToggleSizeMenu(false);
              navigate("/invoice");
            },
            icon: (
              <InvoiceSidebarIcon
                width="25px"
                height="25px"
                stroke={
                  location.split("/")[1] === "invoice"
                    ? returnByColor()
                    : "#ffffff"
                }
              />
            ),
          },
        ]
      : [
          {
            title: t("TXT_Dashboard"),
            link: "dashboard",
            action: () => {
              setToggleSizeMenu(false);
              navigate("/dashboard");
            },
            icon: (
              <DashboardSidebarIcon
                width="25px"
                height="25px"
                stroke={
                  location.split("/")[1] === "dashboard"
                    ? returnByColor()
                    : "#ffffff"
                }
              />
            ),
          },
          {
            title: t("TXT_Project"),
            link: "project",
            action: () => {
              setToggleSizeMenu(false);
              navigate("/project");
            },
            icon: (
              <ProjectSidebarIcon
                width="25px"
                height="25px"
                stroke={
                  location.split("/")[1] === "project"
                    ? returnByColor()
                    : "#ffffff"
                }
              />
            ),
          },
          {
            title: t("TXT_Invoice"),
            link: "invoice",
            action: () => {
              setToggleSizeMenu(false);
              navigate("/invoice");
            },
            icon: (
              <InvoiceSidebarIcon
                width="25px"
                height="25px"
                stroke={
                  location.split("/")[1] === "invoice"
                    ? returnByColor()
                    : "#ffffff"
                }
              />
            ),
          },
          {
            title: t("TXT_Calculator"),
            link: "calculator",
            action: () => {
              setToggleSizeMenu(false);
              navigate("/calculator");
            },
            icon: (
              <CalculatorIcon
                width="25px"
                height="25px"
                fill={
                  location.split("/")[1] === "calculator"
                    ? returnByColor()
                    : "#ffffff"
                }
              />
            ),
          },
        ];

  const bottomMenu = [
    {
      title: t("TXT_Notification"),
      link: "notification",
      action: () => {
        setToggleSizeMenu(false);
        navigate("/notification");
      },
      icon: (
        <Icon
          as={FiBell}
          w="25px"
          h="25px"
          color={
            location.split("/")[1] === "notification"
              ? returnByColor()
              : "#ffffff"
          }
        />
      ),
    },
    // {
    //   title: t("TXT_Setting"),
    //   link: "setting",
    //   action: () => {
    //     setToggleSizeMenu(false);
    //     navigate("/setting");
    //   },
    //   icon: (
    //     <Icon
    //       as={FiSettings}
    //       w="25px"
    //       h="25px"
    //       color={
    //         location.split("/")[1] === "setting" ? returnByColor() : "#ffffff"
    //       }
    //     />
    //   ),
    // },
    {
      title: t("TXT_Logout"),
      action: () => logout(),
      icon: <Icon as={FiLogOut} w="25px" h="25px" color="WT" />,
    },
  ];

  return (
    <Flex
      w={toggleSizeMenu ? "100%" : "auto"}
      h="100%"
      flexDir="column"
      justifyContent="space-between"
      position="absolute"
      top="0"
      left="0"
      zIndex={20}
      ref={sideBarRef}
      boxShadow={"0px 0px 1px 1px rgba(215,215,215,0.3)"}
      bg="rgba(0,0,0,0.5)"
      overflow="auto"
    >
      <Flex
        // position="relative"
        bgColor={returnByTheme()}
        width="65px"
        height="100%"
        flexDir="column"
        justifyContent="space-between"
      >
        {/* top section */}
        <Flex flexDir="column">
          <Flex mb="40px">
            <Flex
              bg={returnByTheme()}
              p="20px"
              cursor="pointer"
              onClick={() => setToggleSizeMenu(!toggleSizeMenu)}
            >
              <Flex
                _hover={{
                  transform: "rotate(360deg)",
                  transition: "all 0.25s ease-in-out",
                }}
              >
                <SFLogo width="25px" height="25px" />
              </Flex>
            </Flex>
          </Flex>

          <Flex flexDir="column">
            {topMenu.map((t, i) => {
              const selected = location.split("/")[1] === `${t.link}`;
              // console.log(location.split("/")[1]);
              // console.log(t.link);
              // console.log(selected);
              return (
                <Flex
                  key={`topMenu${i}`}
                  alignItems="center"
                  onClick={() => t.action()}
                  cursor="pointer"
                >
                  <Flex
                    backgroundColor={selected ? "WT" : returnByTheme()}
                    px="20px"
                    py={selected ? "35px" : "20px"}
                    position="relative"
                    // height={i == topMenu.length - 1 ? "100%" : "fit-content"}
                  >
                    {selected && (
                      <Flex
                        backgroundColor={returnByTheme()}
                        position="absolute"
                        width="100%"
                        height="15px"
                        borderBottomRightRadius={"25"}
                        top="-1px"
                        left="0"
                        zIndex="20"
                      ></Flex>
                    )}

                    {t.icon}

                    {selected && (
                      <Flex
                        backgroundColor={returnByTheme()}
                        position="absolute"
                        width="100%"
                        height="15px"
                        borderTopRightRadius={"25"}
                        bottom="-1px"
                        left="0"
                        zIndex="20"
                      ></Flex>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>

        {/* bottom button */}
        <Flex
          flexDir="column"
          ref={leftBottomRef}
          height={toggleSizeMenu ? toggleBottomMenuH : "auto"}
        >
          <Flex flexDir="column">
            {bottomMenu.map((m, i) => {
              const selected = location.split("/")[1] === `${m.link}`;
              return (
                <Flex
                  key={`bottomMenu${i}`}
                  alignItems="center"
                  onClick={() => m.action()}
                  cursor="pointer"
                >
                  <Flex
                    flex={1}
                    backgroundColor={selected ? "WT" : returnByTheme()}
                    px="20px"
                    py={selected ? "35px" : "20px"}
                    position="relative"
                    // height={i == topMenu.length - 1 ? "100%" : "fit-content"}
                  >
                    {selected && (
                      <Flex
                        backgroundColor={returnByTheme()}
                        position="absolute"
                        width="100%"
                        height="15px"
                        borderBottomRightRadius={"25"}
                        top="-1px"
                        left="0"
                        zIndex="20"
                      ></Flex>
                    )}
                    {m.icon}

                    {selected && (
                      <Flex
                        backgroundColor={returnByTheme()}
                        position="absolute"
                        width="100%"
                        height="15px"
                        borderTopRightRadius={"25"}
                        bottom="-1px"
                        left="0"
                        zIndex="20"
                      ></Flex>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
          <Flex
            flex={1}
            flexDir="column"
            bg={returnByTheme()}
            p="10px"
            alignItems="center"
          >
            <Flex
              height="3px"
              bg="WT"
              width="100%"
              mt="10px"
              borderRadius="16px"
            ></Flex>
            <Flex
              py="25px"
              cursor="pointer"
              onClick={() => setToggleSizeMenu(!toggleSizeMenu)}
            >
              {(meData?.me?.type == "Company" && (
                <>
                  {meData?.me?.company?.profileImage?.url ? (
                    <Image
                      src={meData?.me?.company?.profileImage?.url}
                      w="25px"
                      h="25px"
                      objectFit={"contain"}
                      borderRadius="20px"
                    />
                  ) : (
                    <Avatar w="25px" h="25px" src="avatar-1.jpg" />
                  )}
                </>
              )) || (
                <>
                  {meData?.me?.user?.profileImage?.url ? (
                    <Image
                      src={meData?.me?.user?.profileImage?.url}
                      w="25px"
                      h="25px"
                      objectFit={"contain"}
                      borderRadius="20px"
                    />
                  ) : (
                    <Avatar w="25px" h="25px" src="avatar-1.jpg" />
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>

        {/* rifht toggle section */}
        {toggleSizeMenu && (
          <Flex
            flexDir="column"
            position="absolute"
            left="65px"
            bg="WT"
            width="400px"
            height="100%"
            justifyContent="space-between"
            borderTopRightRadius={toggleSizeMenu ? "28px" : "0"}
            animation={animation}
          >
            {/* top section */}
            <Flex flexDir="column">
              <Flex
                p="20px"
                color={returnByTheme()}
                fontWeight="bold"
                height="65px"
                // borderTopRightRadius="25px"
                // boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
              >
                {titleArr.map((t, i) => {
                  return (
                    <Text
                      animation={blurAnimation}
                      style={{ animationDelay: `${i * 0.2}s` }}
                      key={i}
                    >
                      {t}
                    </Text>
                  );
                })}
              </Flex>
              <Flex
                pb="10px"
                px="15px"
                fontWeight="bold"
                alignItems="center"
                height="41px"
              >
                <LangSwitcher />
              </Flex>

              <Flex flexDir="column">
                {topMenu.map((t, i) => {
                  const selected = location.split("/")[1] === `${t.link}`;

                  return (
                    <Flex
                      key={`toggleTop${i}`}
                      minHeight="65px"
                      alignItems="center"
                      px="20px"
                      py={selected ? "35px" : "20px"}
                      _hover={{
                        textDecoration: "underline",
                        color: returnByTheme(),
                      }}
                      onClick={() => t.action()}
                      cursor="pointer"
                      color={selected ? returnByTheme() : "GREY"}
                      // height={i == topMenu.length - 1 ? "100%" : "fit-content"}
                    >
                      {t.title}
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>

            {/* bottom section */}
            <Flex flexDir="column" ref={toggleBottomRef}>
              <Flex flexDir="column">
                {bottomMenu.map((m, i) => {
                  const selected = location.split("/")[1] === `${m.link}`;

                  return (
                    <Flex
                      key={`toggleBottom${i}`}
                      minHeight="65px"
                      alignItems="center"
                      px="20px"
                      py={selected ? "35px" : "20px"}
                      _hover={{
                        textDecoration: "underline",
                        color: returnByTheme(),
                      }}
                      onClick={() => m.action()}
                      cursor="pointer"
                      color={selected ? returnByTheme() : "GREY"}
                      // height={i == topMenu.length - 1 ? "100%" : "fit-content"}
                    >
                      {m.title}
                    </Flex>
                  );
                })}
              </Flex>
              <Flex bg="WT" px="20px" py="10px">
                <Flex
                  flex={1}
                  flexDir="column"
                  boxShadow={"0px 0px 1px 1px rgba(0,0,0,0.3)"}
                  borderRadius="10px"
                  overflow="hidden"
                >
                  <Flex px="10px" pt="5px" flexDir="column">
                    <Flex color={returnByTheme()} fontWeight="medium" bg="WT">
                      {userName}
                    </Flex>
                    <Flex fontSize="10px" mb="10px" bg="WT">
                      {userLevel}
                    </Flex>
                  </Flex>
                  <Link
                    // display={'flex'}
                    justifyContent="center"
                    p="10px"
                    color="WT"
                    fontSize="10px"
                    flex={1}
                    bg={returnByTheme()}
                    px="auto"
                    href={"/account"}
                    textAlign="center"
                    // onClick={() => }
                  >
                    {t("TXT_Manage_account")}
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        {toggleSizeMenu && (
          <Flex
            position="absolute"
            left="465px"
            width="calc(100vw - 465px)"
            height="100vh"
            // overflow="hidden"
            animation={animation}
            // bg="rgba(0,0,0,0.5)"
            onClick={() => setToggleSizeMenu(false)}
          ></Flex>
        )}

        {/* overlap wt line */}
        <Flex
          position="absolute"
          right="-1px"
          height="100%"
          width="1px"
          bg="WT"
        ></Flex>
      </Flex>
    </Flex>
  );
};
export default SideBar;
