import {
  Box,
  Input,
  Text,
  Flex,
  Button,
  Icon,
  Checkbox,
  Image,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import { IInvoiceConfig } from "../../../type/IAccountManageInterface";
import { returnByTheme } from "src/utils/util";

interface IInvoiceConfigProps {
  invoiceConfig: IInvoiceConfig;
  updateInvoiceConfigState: (value: any) => void;
  isOwner?: boolean;
}

const InvoiceConfigsSection = ({
  invoiceConfig,
  updateInvoiceConfigState,
  isOwner = false,
}: IInvoiceConfigProps) => {
  const { t } = useTranslation();

  const displayOptionArr = [
    // {
    //   label: t("TXT_Name"),
    //   key: "name",
    //   text: "Solar farm (admin...)",
    // },
    { label: t("TXT_Phone"), key: "phone", text: invoiceConfig.phone.text },
    {
      label: t("TXT_Email"),
      key: "email",
      text: invoiceConfig.email.text,
    },
    {
      label: t("TXT_Company"),
      key: "company",
      text: invoiceConfig.name.text,
    },
    {
      label: t("TXT_Website"),
      key: "website",
      text: invoiceConfig.website.text,
    },
    {
      label: t("TXT_Address"),
      key: "address",
      text: invoiceConfig.address.text,
    },
    // {
    //   label: t("TXT_Account_Additional_scripts"),
    //   key: "additionalText",
    //   text: invoiceConfig.additionalText.text,
    // },
  ];

  const countSelectedOption = (): number => {
    return displayOptionArr.filter((o) => {
      return invoiceConfig[o.key as keyof IInvoiceConfig].isShow;
    }).length;
  };

  const getNewState = (key: string, value: any) => {
    updateInvoiceConfigState({
      ...invoiceConfig,
      [key]:
        key == "additionalText" && typeof value !== "boolean"
          ? value
          : { ...invoiceConfig[key as keyof IInvoiceConfig], isShow: value },
    });
  };

  const currSelected = countSelectedOption();

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        // flex={1}
        flexDirection={"column"}
      >
        <SectionTitle title={t("TXT_Information")} />
        <Flex px="20px" flexDirection="column">
          <Flex justifyContent="space-between" py="10px">
            <Text fontWeight="bold">
              {t("TXT_Desc_select_contents_display")}
            </Text>
            {/* <Text fontWeight="medium">{currSelected} / 5</Text> */}
          </Flex>
          <Flex flexDirection="column">
            {displayOptionArr.map((o, i) => {
              return (
                <Flex
                  key={i}
                  py="15px"
                  borderBottom="1px solid #F8F8F8"
                  alignItems="center"
                >
                  {/* <Checkbox
                    size="lg"
                    isDisabled={
                      currSelected > 4 &&
                      !invoiceConfig[o?.key as keyof IInvoiceConfig]?.isShow
                    }
                    colorScheme="blue"
                    borderColor={returnByTheme()}
                    isChecked={
                      invoiceConfig[o?.key as keyof IInvoiceConfig]?.isShow
                    }
                    onChange={() => {
                      getNewState(
                        o.key,
                        !invoiceConfig[o?.key as keyof IInvoiceConfig]?.isShow
                      );
                    }}
                    zIndex={1}
                    mr="10px"
                  /> */}

                  <Text flex={2}> {o.label}</Text>
                  {(o.key !== "additionalText" && o.key !== "email" && (
                    <Text flex={3} color="DISABLEDTEXT">
                      {o.text}
                    </Text>
                  )) || (
                    <Input
                      disabled={isOwner ? false : true}
                      _placeholder={{ color: "DISABLEDTEXT" }}
                      flex={3}
                      px="5px"
                      borderColor={returnByTheme()}
                      borderRadius="5px"
                      borderWidth={1}
                      justifyContent={"space-between"}
                      value={
                        o.key == "email"
                          ? invoiceConfig.email.text
                          : invoiceConfig.additionalText.text
                      }
                      onChange={(e) => {
                        if (o.key == "additionalText") {
                          getNewState("additionalText", {
                            ...invoiceConfig.additionalText,
                            text: e.target.value,
                          });
                        } else {
                          getNewState("email", {
                            ...invoiceConfig.email,
                            text: e.target.value,
                          });
                        }
                      }}
                    />
                  )}
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>

      {/* image checkbox section */}
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        mt="20px"
        flexDirection={"column"}
      >
        <SectionTitle title={"Image"} />
        <Flex px="20px" flexDirection="column">
          <Flex justifyContent="space-between" py="10px">
            <Text fontWeight="bold">{t("TXT_Desc_select_images_display")}</Text>
          </Flex>
          <Flex flexDir="column">
            <Flex
              py="15px"
              borderBottom="1px solid #F8F8F8"
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* <Checkbox
                size="lg"
                colorScheme="blue"
                borderColor={returnByTheme()}
                isChecked={invoiceConfig.logo?.isShow}
                onChange={() => {
                  getNewState("logo", !invoiceConfig.logo?.isShow);
                }}
                zIndex={1}
                mr="10px"
              /> */}

              <Text flex={2}>{t("TXT_Company_logo")}</Text>
              <Box flex={3}>
                <Image
                  src={invoiceConfig.logo.text}
                  width="150px"
                  height="100px"
                />
              </Box>
            </Flex>

            <Flex
              py="15px"
              borderBottom="1px solid #F8F8F8"
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* <Checkbox
                size="lg"
                colorScheme="blue"
                borderColor={returnByTheme()}
                isChecked={invoiceConfig.chop?.isShow}
                onChange={() => {
                  getNewState("chop", !invoiceConfig.chop?.isShow);
                }}
                zIndex={1}
                mr="10px"
              /> */}

              <Text flex={2}>{t("TXT_Company_chop")}</Text>
              <Box flex={3}>
                <Image
                  src={invoiceConfig.chop.text}
                  width="150px"
                  height="100px"
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default InvoiceConfigsSection;
