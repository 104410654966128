import _ from "lodash";
import { ChangeEventHandler, useState } from "react";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Flex,
  Text,
} from "@chakra-ui/react";
import { returnByTheme } from "src/utils/util";

type Props = {
  id?: string;
  value?: string;
  title?: string;
  type?: React.HTMLInputTypeAttribute;
  left?: React.ReactNode;
  right?: React.ReactNode;
  isShowWarning?: boolean;
  warning?: string;
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  autoComplete?: boolean;
};

const BaseInput = ({
  id,
  value,
  title,
  type,
  left,
  right,
  isShowWarning,
  warning,
  color = returnByTheme(),
  borderColor = "#0000",
  backgroundColor,
  onChange,
  placeholder,
  autoComplete = true,
}: Props) => {
  const [isFocus, setIsFocus] = useState(false);

  const isTitleCollapsed = isFocus || !_.isEmpty(value);

  const newBorderColor = isShowWarning ? "RD.1" : color;
  const newBackgroundColor =
    backgroundColor || (isShowWarning ? "RD.1A15" : color + "A15");
  const textColor = isShowWarning ? "RD.1" : color;

  const isShowWarningText = isShowWarning && !_.isEmpty(warning);

  return (
    <>
      <InputGroup color={textColor}>
        <InputLeftElement
          userSelect="none"
          color="currentcolor"
          w="65px"
          height="100%"
          p={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          children={left}
        />

        <Flex
          position="absolute"
          height="100%"
          left="68px"
          top={isTitleCollapsed ? "5px" : "0px"}
          justifyContent="center"
          alignItems={isTitleCollapsed ? "flex-start" : "center"}
          fontSize={isTitleCollapsed ? "18px" : "25px"}
          zIndex={1}
        >
          {title}
        </Flex>
        <Input
          autoComplete={autoComplete ? "on" : "new-password"}
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          height="80px"
          fontSize="25px"
          pl="65px"
          pt="20px"
          borderRadius={0}
          borderColor={borderColor}
          borderWidth="3px"
          focusBorderColor={newBorderColor}
          bgColor={newBackgroundColor}
          _focus={{ backgroundColor: "#0000" }}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          placeholder={placeholder}
        />

        <InputRightElement
          userSelect="none"
          color="currentcolor"
          w={isShowWarningText ? "unset" : "65px"}
          // w="65px"
          height="100%"
          p={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="12px"
          children={
            isShowWarningText && !_.isEmpty(warning) ? (
              <Text pr="15px">{warning}</Text>
            ) : (
              right
            )
          }
        />
      </InputGroup>
    </>
  );
};

export default BaseInput;
