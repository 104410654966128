import { Key, useRef, useState, useEffect } from "react";
import {
  Flex,
  Text,
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Textarea,
  Input,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  CircularProgress,
  Image,
} from "@chakra-ui/react";
import { FiArrowLeft, FiAlertTriangle } from "react-icons/fi";
import PopUpModal from "../../components/PopUpModal";
import { ChevronDownIcon, DeleteIcon } from "@chakra-ui/icons";
import { IconContext } from "react-icons";
import { FiCalendar } from "react-icons/fi";
import DatePicker from "../../components/DatePicker";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  UpdateInvoiceDocument,
  UpdateInvoiceInput,
  UploadReceiptDocument,
} from "../../graphql/generated";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { returnByTheme, uploadDoc } from "../../utils/util";

const InvoiceEdit = () => {
  const { invoiceId } = useParams();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { infos, prevSelectedOpt, prevPayload, prevKeyword, prevDateRange } =
    (location?.state as any) ?? {};

  const [isChecked, setIsChecked] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dueDate, setDueDate] = useState(
    infos.dueAt ? new Date(infos.dueAt) : new Date()
  );
  const [invoiceStatus, setInvoiceStatus] = useState(infos.status);
  const [selectedFile, setSelectedFile] = useState();
  const [isShowAlertModal, setIsShowAlertModal] = useState(false);
  const [companyLogo, setCompanyLogo] = useState<File | null>();
  const [companyChop, setCompanyChop] = useState<File | null>();
  const isClient = localStorage.getItem("sf_Role") == "true";
  const logoRef = useRef<any>(null);
  const chopRef = useRef<any>(null);
  const dateRangePickerRef = useRef(null);

  //const clientInfos = infos.clientInformation.split(/\r?\n/);

  const [clientInfos, setClientInfos] = useState(infos.clientInformation);
  const [receivableInvoiceItems, setReceivableInvoiceItems] = useState(() =>
    infos.receivableCharges.map((x: { name: string; amount: number }) => ({
      name: x.name,
      amount: x.amount,
    }))
  );
  const [payableInvoiceItems, setPayableInvoiceItems] = useState(() =>
    infos.payableCharges.map((x: { name: string; amount: number }) => ({
      name: x.name,
      amount: x.amount,
    }))
  );
  const meData = JSON.parse(localStorage.getItem("meData")!);
  const invoiceType =
    infos?.total > 0 ? t("TXT_Option_Credit") : t("TXT_Option_Debit");
  const toast = useToast();
  type ChangeProps = {
    type: string;
    item: {
      name: string;
      amount: number | null;
    };
    value: any;
    index: number;
  };

  // check user role
  useEffect(() => {
    if (isClient) {
      navigate("/project");
    }

    return () => {};
  }, []);

  const receivableOnChange = ({ type, item, value, index }: ChangeProps) => {
    let temp = receivableInvoiceItems.slice();

    if (type === "name") {
      temp[index].name = value;
    } else if (type === "value") {
      temp[index].amount = value == "" ? 0 : parseFloat(value);
    }

    setReceivableInvoiceItems(temp);
  };

  const payableOnChange = ({ type, item, value, index }: ChangeProps) => {
    let temp = payableInvoiceItems.slice();
    // const index = temp.findIndex((x: { name: string }) => x.name === item.name);
    if (type === "name") {
      temp[index].name = value;
    } else if (type === "value") {
      temp[index].amount = value == "" ? 0 : parseFloat(value);
    }

    setPayableInvoiceItems(temp);
  };

  const [updateInvoice, { loading, error }] = useMutation(
    UpdateInvoiceDocument,
    {
      onCompleted: (x) => {
        navigate(`/invoice`);
      },
      onError: (error) => {
        //navigate(`/invoice`);
        return toast({
          position: "bottom-right",
          title: `Update Error: ${error}`,
          status: "error",
        });
      },
    }
  );

  const [uploadReceipt] = useMutation(UploadReceiptDocument, {
    onCompleted: (x) => {
      navigate(`/invoice`);
      return toast({
        position: "bottom-right",
        title: `Receipt uploaded`,
        status: "success",
      });
    },
    onError: (error) => {
      //navigate(`/invoice`);
      return toast({
        position: "bottom-right",
        title: `Update Error: ${error}`,
        status: "error",
      });
    },
  });

  const uploadReceiptHandler = async (event: any) => {
    const uploadedDoc = await uploadDoc(event?.target?.files);

    const arrOfFiles = Array.from(event?.target?.files).map((f: any, i) => {
      return {
        filePath: uploadedDoc.result[i].filePath,
        originalName: uploadedDoc.result[i].originalName,
      };
    });

    uploadReceipt({
      variables: {
        input: {
          id: infos.id,
          receipt: arrOfFiles[0],
        },
      },
    });
  };

  const uploadHandler = () => {
    let UpdateInvoicePayload: UpdateInvoiceInput = {
      id: infos.id,
      receivableCharges: receivableInvoiceItems,
      payableCharges: payableInvoiceItems,
      clientInformation: clientInfos,
      dueAt: dueDate.toString(),
    };

    const currentDate = new Date().toString();

    // for update status
    if (invoiceStatus == "Paid") {
      UpdateInvoicePayload = {
        ...UpdateInvoicePayload,
        paidAt: currentDate,
      };
    } else if (invoiceStatus == "Completed") {
      UpdateInvoicePayload = {
        ...UpdateInvoicePayload,
        completedAt: currentDate,
      };
    }

    //for update logo and chop
    if (companyLogo) {
      const urlArr = URL.createObjectURL(companyLogo).split("/");
      const urlPath = urlArr[urlArr.length - 1];

      UpdateInvoicePayload = {
        ...UpdateInvoicePayload,
        companyLogo: {
          filePath: urlPath,
          originalName: companyLogo.name,
          // url: urlPath,
        },
      };
    }
    if (companyChop) {
      const urlArr = URL.createObjectURL(companyChop).split("/");
      const urlPath = urlArr[urlArr.length - 1];
      UpdateInvoicePayload = {
        ...UpdateInvoicePayload,
        companyChop: {
          filePath: urlPath,
          originalName: companyChop.name,
          // url: urlPath,
        },
      };
    }

    const finalPayload = {
      variables: {
        input: UpdateInvoicePayload,
      },
    };

    updateInvoice(finalPayload);
  };

  return (
    <Flex backgroundColor="#E5E5E5" flexDirection="column">
      <>
        <Flex
          flex={1}
          flexDirection="row"
          backgroundColor="#FFF"
          alignItems="center"
          justifyContent="center"
          py="10px"
          position="sticky"
          top="0px"
          zIndex={1}
        >
          <Flex
            position="absolute"
            left="20px"
            onClick={() => setIsShowAlertModal(true)}
            _hover={{ cursor: "pointer", textDecoration: "underline" }}
          >
            <Flex alignContent="center" justifyContent="center" p="10px">
              <FiArrowLeft width="35px" height="35px" />
            </Flex>
            <Text color="#8E8E8E" alignSelf="center">
              {infos.code} /{" "}
            </Text>
            <Text color="#000" fontWeight="700" alignSelf="center">
              {t("TXT_Project_Editing")}
            </Text>
          </Flex>

          <Flex flexDirection="row" alignItems="center">
            <Button
              py="15px"
              px="30px"
              backgroundColor="WT"
              borderWidth="1px"
              borderRadius="40px"
              borderColor={returnByTheme()}
              width="100%"
              onClick={() => setIsShowAlertModal(true)}
              disabled={loading}
            >
              <Text color={returnByTheme()}>{t("TXT_Cancel")}</Text>
            </Button>

            <Button
              py="15px"
              px="30px"
              w="200px"
              backgroundColor={returnByTheme()}
              borderWidth="1px"
              borderRadius="40px"
              borderColor="WT"
              width="100%"
              marginLeft="10px"
              onClick={() => uploadHandler()}
              disabled={loading}
            >
              <Text color="WT">{t("TXT_Save")}</Text>
            </Button>
          </Flex>
        </Flex>
        {loading ? (
          <Flex
            height="100vh"
            opacity="1"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress isIndeterminate color="green.300" />
          </Flex>
        ) : (
          <Flex
            flex={14}
            height="100%"
            justifyContent="center"
            flexDirection="row"
            py="10px"
            maxW="1920px"
            mx="auto"
            px="20px"
            width="100%"
          >
            <Flex flex={2} minHeight="100vh" flexDirection="column">
              <Flex width="100%" flexDirection="column">
                <Accordion
                  allowToggle
                  width="100%"
                  defaultIndex={[0, 1]}
                  allowMultiple
                >
                  <AccordionItem backgroundColor="#FFF">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="700">
                          {t("TXT_Project_Basic_info")}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        borderTop="1px solid #F0F0F0"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} color="#8E8E8E" fontSize="15px">
                          {t("TXT_Invoice_invoice_type")}
                        </Text>
                        <Text flex={3}>
                          {invoiceType} {t("TXT_Note")}
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} color="#8E8E8E" fontSize="15px">
                          {t("TXT_Invoice_Invoice_ID")}
                        </Text>
                        <Text flex={3}>{infos.code}</Text>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} color="#8E8E8E" fontSize="15px">
                          {t("TXT_Invoice_Issue_date")}
                        </Text>
                        <Text flex={3}>
                          {moment(infos?.createdAt).format("D MMM,YYYY")}
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} color="#8E8E8E" fontSize="15px">
                          {t("TXT_Invoice_your_information")}
                        </Text>
                        <Text flex={3}>{meData?.address}</Text>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} color="#8E8E8E" fontSize="15px">
                          {t("TXT_Invoice_send_to_client")}
                        </Text>
                        <Flex flex={3}>
                          <Textarea
                            value={clientInfos}
                            size="sm"
                            onChange={(e) => setClientInfos(e.target.value)}
                            height="150px"
                          />
                        </Flex>
                      </Flex>
                      <Flex flexDirection="row" alignItems="center" py="10px">
                        <Text flex={2} color="#8E8E8E" fontSize="15px">
                          {t("TXT_Invoice_Due_date")}
                        </Text>
                        <Flex
                          p="5px"
                          flex={3}
                          onClick={() => setShowDatePicker(true)}
                          borderRadius="5px"
                          borderWidth="1px"
                          borderColor={returnByTheme()}
                          alignItems="center"
                          justifyContent="space-between"
                          cursor="pointer"
                        >
                          {moment(dueDate).format("DD-MMM-YYYY")}
                          <FiCalendar color={returnByTheme()} />
                        </Flex>
                      </Flex>
                      {showDatePicker && (
                        <DatePicker
                          dateRangePickerRef={dateRangePickerRef}
                          // isShowDatePicker={showDatePicker}
                          onClose={() => setShowDatePicker(false)}
                          selectedDate={dueDate}
                          setDate={(item: any) => setDueDate(item)}
                        />
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem marginTop="10px" backgroundColor="#FFF">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="700">
                          {t("TXT_Project_Electricity_details")}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        borderTop="1px solid #F0F0F0"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} color="#8E8E8E" fontSize="15px">
                          {t("TXT_Account_number")}
                        </Text>
                        <Text flex={3}>
                          {infos?.site?.electricCompany?.username}
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} color="#8E8E8E" fontSize="15px">
                          {t("TXT_Date_range")}
                        </Text>
                        <Text flex={3}>{`${moment(
                          infos?.bill?.startTime
                        ).format("D MMM ,YYYY")} - ${moment(
                          infos?.bill?.endTime
                        ).format("D MMM ,YYYY")}`}</Text>
                      </Flex>
                      {infos?.site?.electricDetail?.electricMeters
                        .filter((x: { isFit: boolean }) => x.isFit)
                        .map((meter: { code: string | number }) => (
                          <Flex
                            flexDirection="row"
                            alignItems="center"
                            borderBottom="1px solid #F0F0F0"
                            py="10px"
                          >
                            <Text flex={2} color="#8E8E8E" fontSize="15px">
                              {t("TXT_Project_Meter_id_fit")}
                            </Text>
                            <Text flex={3}>{meter?.code}</Text>
                          </Flex>
                        ))}
                      {infos?.site?.electricDetail?.electricMeters
                        .filter((x: { isFit: boolean }) => !x.isFit)
                        .map((meter: { code: string | number }) => (
                          <Flex
                            flexDirection="row"
                            alignItems="center"
                            borderBottom="1px solid #F0F0F0"
                            py="10px"
                          >
                            <Text flex={2} color="#8E8E8E" fontSize="15px">
                              {t("TXT_Project_Meter_id")}
                            </Text>
                            <Text flex={3}>{meter?.code}</Text>
                          </Flex>
                        ))}
                      <Flex
                        flexDirection="row"
                        backgroundColor="#F0F0F0"
                        py="10px"
                        mx="-16px"
                        px="16px"
                      >
                        <Text fontWeight="700">
                          {t("TXT_Receivable_count")}
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} fontWeight="700">
                          {t("TXT_Invoice_items")}
                        </Text>
                        <Text flex={3} fontWeight="700">
                          {t("TXT_Value")} ($HKD)
                        </Text>
                      </Flex>
                      {receivableInvoiceItems.map(
                        (
                          item: { name: string; amount: number | null },
                          index: number
                        ) => {
                          return (
                            <Flex
                              flexDirection="row"
                              alignItems="center"
                              py="10px"
                              key={index}
                            >
                              <Flex flex={2} paddingRight="10px">
                                <Input
                                  borderColor={returnByTheme()}
                                  fontSize="15px"
                                  value={item.name}
                                  onChange={(e) =>
                                    receivableOnChange({
                                      type: "name",
                                      item: item,
                                      value: e.target.value,
                                      index: index,
                                    })
                                  }
                                />
                              </Flex>
                              <Flex flex={3}>
                                <Input
                                  flex={3}
                                  borderColor={returnByTheme()}
                                  placeholder="HK$"
                                  type={"number"}
                                  value={item?.amount?.toString()}
                                  onChange={(e) =>
                                    receivableOnChange({
                                      type: "value",
                                      item: item,
                                      value: e.target.value,
                                      index: index,
                                    })
                                  }
                                />
                              </Flex>
                              <Flex paddingLeft="10px">
                                <Button
                                  border="1px solid #004B92"
                                  backgroundColor="#FFF"
                                  onClick={() => {
                                    let temp = receivableInvoiceItems.slice();
                                    temp.splice(index, 1);
                                    setReceivableInvoiceItems(temp);
                                  }}
                                >
                                  <DeleteIcon color={returnByTheme()} />
                                </Button>
                              </Flex>
                            </Flex>
                          );
                        }
                      )}
                      <Flex
                        flexDirection="row"
                        borderTop="1px solid #F0F0F0"
                        my="10px"
                        py="15px"
                      >
                        <Flex flex={2}>
                          <Button
                            height="34px"
                            backgroundColor="WT"
                            borderWidth="1px"
                            borderColor={returnByTheme()}
                            width="100%"
                            onClick={() => {
                              setReceivableInvoiceItems((cur: any) => [
                                ...cur,
                                {
                                  name: "",
                                  amount: null,
                                },
                              ]);
                            }}
                          >
                            <Text color={returnByTheme()} fontWeight="400">
                              + {t("TXT_Add_item")}
                            </Text>
                          </Button>
                        </Flex>
                        <Text flex={3} fontWeight="700"></Text>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        backgroundColor="#F0F0F0"
                        py="10px"
                        mx="-16px"
                        px="16px"
                      >
                        <Text fontWeight="700">{t("TXT_Payable_count")}</Text>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Text flex={2} fontWeight="700">
                          {t("TXT_Invoice_items")}
                        </Text>
                        <Text flex={3} fontWeight="700">
                          {t("TXT_Value")} ($HKD)
                        </Text>
                      </Flex>
                      {payableInvoiceItems.map(
                        (
                          item: { name: string; amount: number | null },
                          index2: number
                        ) => (
                          <Flex
                            flexDirection="row"
                            alignItems="center"
                            py="10px"
                            key={index2}
                          >
                            <Flex flex={2} paddingRight="10px">
                              <Input
                                borderColor={returnByTheme()}
                                value={item?.name}
                                onChange={(e) =>
                                  payableOnChange({
                                    type: "name",
                                    item: item,
                                    value: e.target.value,
                                    index: index2,
                                  })
                                }
                              />
                            </Flex>
                            <Flex flex={3}>
                              <Input
                                flex={3}
                                borderColor={returnByTheme()}
                                placeholder="HK$"
                                type={"number"}
                                value={item?.amount?.toString()}
                                onChange={(e) =>
                                  payableOnChange({
                                    type: "value",
                                    item: item,
                                    value: e.target.value,
                                    index: index2,
                                  })
                                }
                              />
                            </Flex>
                            <Flex paddingLeft="10px">
                              <Button
                                border="1px solid #004B92"
                                backgroundColor="#FFF"
                                onClick={() => {
                                  let temp = payableInvoiceItems.slice();
                                  temp.splice(index2, 1);
                                  setPayableInvoiceItems(temp);
                                }}
                              >
                                <DeleteIcon color={returnByTheme()} />
                              </Button>
                            </Flex>
                          </Flex>
                        )
                      )}
                      <Flex
                        flexDirection="row"
                        borderTop="1px solid #F0F0F0"
                        my="10px"
                        py="15px"
                      >
                        <Flex flex={2}>
                          <Button
                            height="34px"
                            backgroundColor="WT"
                            borderWidth="1px"
                            borderColor={returnByTheme()}
                            width="100%"
                            onClick={() => {
                              setPayableInvoiceItems((cur: any) => [
                                ...cur,
                                {
                                  name: "",
                                  amount: null,
                                },
                              ]);
                            }}
                          >
                            <Text color={returnByTheme()} fontWeight="400">
                              + {t("TXT_Add_item")}
                            </Text>
                          </Button>
                        </Flex>
                        <Text flex={3} fontWeight="700"></Text>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
            </Flex>
            <Flex flex={1} minHeight="100vh" flexDirection="column" px="10px">
              <Flex width="100%" flexDirection="column">
                <Accordion
                  allowToggle
                  width="100%"
                  defaultIndex={[0]}
                  allowMultiple
                >
                  <AccordionItem backgroundColor="#FFF">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="700">{t("TXT_Upload_image")}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        borderTop="1px solid #F0F0F0"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Flex flex={3} flexDirection="column">
                          <Text color="#8E8E8E" fontSize="15px">
                            {t("TXT_Company_logo")}
                          </Text>
                          <Text
                            color="#8E8E8E"
                            fontSize="10px"
                          >{`Resolution: 150 x100px (PNG/JPG)`}</Text>
                          {/* <Flex
                            flexDirection="row"
                            mt="10px"
                            justifyContent={"space-between"}
                          >
                            <Flex
                              height="34px"
                              flex={1}
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="5px"
                              borderColor={returnByTheme()}
                              borderWidth="1px"
                              mr="10px"
                              cursor="pointer"
                            >
                              <label>
                                <input
                                  ref={logoRef}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(e) =>
                                    setCompanyLogo(e?.target?.files?.[0])
                                  }
                                />
                                <Text
                                  cursor="pointer"
                                  color={returnByTheme()}
                                  fontWeight="400"
                                  textAlign="center"
                                >
                                  Change
                                </Text>
                              </label>
                            </Flex>

                            <Flex
                              height="34px"
                              flex={1}
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="5px"
                              borderColor={returnByTheme()}
                              borderWidth="1px"
                              onClick={() => {
                                logoRef.current.value = "";
                                setCompanyLogo(null);
                              }}
                              cursor="pointer"
                            >
                              <Text color={returnByTheme()} fontWeight="400">
                                Delete
                              </Text>
                            </Flex>
                          </Flex> */}
                        </Flex>
                        <Flex
                          border="1px dashed #000"
                          width="144px"
                          height="95px"
                          borderRadius="5px"
                          marginLeft="10px"
                          justifyContent="center"
                        >
                          <img
                            src={
                              companyLogo
                                ? URL.createObjectURL(companyLogo)
                                : infos.companyLogo?.url
                            }
                            style={{ objectFit: "contain" }}
                          />
                        </Flex>
                      </Flex>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        borderBottom="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Flex flex={3} flexDirection="column">
                          <Text color="#8E8E8E" fontSize="15px">
                            {t("TXT_Company_chop")}
                          </Text>
                          <Text color="#8E8E8E" fontSize="10px">
                            {t("TXT_Desc_resolution")}
                          </Text>
                          {/* <Flex
                            flexDirection="row"
                            mt="10px"
                            justifyContent={"space-between"}
                          >
                            <Flex
                              height="34px"
                              flex={1}
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="5px"
                              borderColor={returnByTheme()}
                              borderWidth="1px"
                              mr="10px"
                              cursor="pointer"
                            >
                              <label>
                                <input
                                  ref={chopRef}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(e) =>
                                    setCompanyChop(e?.target?.files?.[0])
                                  }
                                />
                                <Text
                                  color={returnByTheme()}
                                  fontWeight="400"
                                  textAlign="center"
                                  cursor="pointer"
                                >
                                  Change
                                </Text>
                              </label>
                            </Flex>

                            <Flex
                              height="34px"
                              flex={1}
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="5px"
                              borderColor={returnByTheme()}
                              borderWidth="1px"
                              onClick={() => {
                                chopRef.current.value = "";
                                setCompanyChop(null);
                              }}
                              cursor="pointer"
                            >
                              <Text color={returnByTheme()} fontWeight="400">
                                Delete
                              </Text>
                            </Flex>
                          </Flex> */}
                        </Flex>
                        <Flex
                          border="1px dashed #000"
                          width="144px"
                          height="95px"
                          borderRadius="5px"
                          marginLeft="10px"
                          justifyContent="center"
                        >
                          <img
                            src={
                              companyChop
                                ? URL.createObjectURL(companyChop)
                                : infos.companyChop?.url
                            }
                            style={{ objectFit: "contain" }}
                          />
                        </Flex>
                      </Flex>
                      {/*  <Flex flexDirection='row' alignItems="center" borderBottom='1px solid #F0F0F0' py='10px' >
                          <Flex flexDirection='row' >
                            <Checkbox
                              colorScheme="green"
                              borderColor={returnByTheme()}
                              isChecked={isChecked}
                              onChange={() => setIsChecked(!isChecked)}
                              zIndex={1}
                              
                            />
                            <Text fontSize='12px' paddingLeft='5px'>Synchronize changes to account information</Text>
                          </Flex>
                        </Flex> */}
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem marginTop="10px" backgroundColor="#FFF">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="700">
                          {t("TXT_Invoice_invoice_status")}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <Flex
                        flexDirection="column"
                        alignItems="center"
                        borderTop="1px solid #F0F0F0"
                        py="10px"
                      >
                        <Menu matchWidth={true}>
                          <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            width="100%"
                          >
                            <Flex w="100%">{invoiceStatus}</Flex>
                          </MenuButton>
                          <MenuList>
                            {/* <MenuItem
                              onClick={(e) => setInvoiceStatus("Draft")}
                            >
                              Draft
                            </MenuItem> */}
                            <MenuItem onClick={(e) => setInvoiceStatus("Paid")}>
                              {t("TXT_Option_Paid")}
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => setInvoiceStatus("Completed")}
                            >
                              {t("TXT_Option_Completed")}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                        <Text fontSize="13px" color="#8E8E8E">
                          {t("TXT_Invoice_invoice_wont_send_desc")}
                        </Text>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem marginTop="10px" backgroundColor="#FFF">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="700">{t("TXT_Receipt")}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <Flex justifyContent="center">
                      {infos.receipt?.url && <Image src={infos.receipt?.url} />}
                    </Flex>
                    <AccordionPanel>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        borderTop="1px solid #F0F0F0"
                        py="10px"
                      >
                        <label
                          style={{
                            //height:"34px",
                            backgroundColor: "#FFF",
                            borderWidth: "1px",
                            borderColor: "#004B92",
                            width: "100%",
                            alignItems: "center",
                            borderRadius: "10px",
                            justifyContent: "center",
                            padding: "5px",
                          }}
                        >
                          <input
                            type="file"
                            accept=".jpg,.png"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              uploadReceiptHandler(e);
                            }}
                          />
                          <Text
                            height="100%"
                            color={returnByTheme()}
                            fontWeight="400"
                            textAlign="center"
                            cursor="pointer"
                          >
                            {t("TXT_Upload")}
                          </Text>
                        </label>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
            </Flex>
          </Flex>
        )}

        <PopUpModal
          isShow={isShowAlertModal}
          onClose={() => {
            setIsShowAlertModal(false);
          }}
          headerColor="RD.3"
          headerText={t("TXT_Alert")}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <IconContext.Provider value={{ color: "#E4513F", size: "100px" }}>
              <FiAlertTriangle />
            </IconContext.Provider>
            <Text color="#E4513F" fontWeight="700">
              {t("TXT_Hey_wait")}
            </Text>
            <Text color="#000" textAlign="center" fontWeight="600">
              {t("TXT_Leave_popup_desc")}
            </Text>
            <Flex flexDirection="row" width="100%">
              <Button
                backgroundColor="WT"
                _hover={{ backgroundColor: "WT" }}
                onClick={() => setIsShowAlertModal(false)}
                marginTop="20px"
                borderRadius="40px"
                flex={1}
                borderColor="#000"
                borderWidth="1px"
              >
                <Text color="#000"> {t("TXT_Stay")}</Text>
              </Button>
              <Button
                backgroundColor="RD.3"
                _hover={{ backgroundColor: "RD.3" }}
                onClick={() => navigate(-1)}
                marginTop="20px"
                borderRadius="40px"
                flex={1}
                marginLeft="20px"
              >
                <Text color="WT"> {t("TXT_Leave")}</Text>
              </Button>
            </Flex>
          </Flex>
        </PopUpModal>
      </>
    </Flex>
  );
};

export default InvoiceEdit;
