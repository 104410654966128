import {
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Image,
} from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import React from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const NumberOfSolarPanelSection = ({
  noOfPanel,
  setNoOfPanel,
}: {
  noOfPanel: number;
  setNoOfPanel: any;
}) => {
  const noOfPanelRange = [
    5, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
  ];

  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap="10px" mb="40px">
      <Flex color="GREY" fontWeight="semibold">
        {t("TXT_Calculator_number_of_panel")}
      </Flex>
      <Flex gap="5px" mb="5px">
        {noOfPanelRange.map((range, i) => {
          return (
            <Flex
              bg="#E9EFF3"
              color={noOfPanel == range ? "#459EE3" : "DISABLEDTEXT"}
              borderRadius="25px"
              border={
                noOfPanel == range ? "1px solid #459EE3" : "1px solid #CFCFCF"
              }
              px="10px"
              py="2px"
              key={i}
              cursor="pointer"
              onClick={() => setNoOfPanel(range)}
            >
              {range}
            </Flex>
          );
        })}
      </Flex>

      <Flex gap="30px" alignItems="center" pl="15px">
        <Flex flex={2}>
          <Slider
            gap={3}
            defaultValue={1}
            value={noOfPanel ? noOfPanel : 0}
            onChange={(val) => setNoOfPanel(val)}
            colorScheme="twitter"
            min={0}
            max={1000}
            focusThumbOnChange={false}
          >
            <SliderTrack bg="DISABLEDTEXT">
              <SliderFilledTrack />
            </SliderTrack>

            <SliderThumb bg="#69B3E3" boxSize={7} borderRadius="4px">
              <Image
                src={require("../../icons/general/solarPanelW.png")}
                color="#459EE3"
              />
            </SliderThumb>

            <SliderMark
              value={noOfPanel ? noOfPanel : 0}
              textAlign="center"
              bg="#E9EFF3"
              color={"#459EE3"}
              borderRadius="4px"
              border={"1px solid #459EE3"}
              width="50px"
              // height="28px"
              mt="20px"
              ml="-25px"
            >
              {noOfPanel ? noOfPanel : 0}
            </SliderMark>
          </Slider>
        </Flex>
        <Flex flex={1}>
          <Flex
            justifyContent="space-between"
            // height="45px"
            borderRadius="4px"
            border="1px solid #f0f0f0"
            overflow="hidden"
          >
            <CurrencyInput
              className="calc-num-input"
              // defaultValue={1000}
              value={noOfPanel}
              decimalsLimit={2}
              onValueChange={(value, name) => setNoOfPanel(value)}
            />
            <Flex
              bg="#69B3E3"
              fontWeight="bold"
              color="WT"
              height="100%"
              minWidth="55px"
              justifyContent="center"
              alignItems="center"
            >
              PCS
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NumberOfSolarPanelSection;
