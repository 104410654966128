import _ from "lodash";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "@apollo/client";
import { WeathersDocument } from "../../graphql/generated";
import { useTranslation } from "react-i18next";
import { returnByTheme } from "src/utils/util";
import { useNavigate } from "react-router-dom";
// import weatherMapObj from "../../json/weatherMappingObj.json";

const WeatherForecastRow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const weatherMapObj = {
    weather50: {
      imagePath: require("../../icons/weather/pic50.png"),
      displayName: "TXT_Dashboard_weather_50",
    },
    weather51: {
      imagePath: require("../../icons/weather/pic51.png"),
      displayName: "TXT_Dashboard_weather_51",
    },
    weather52: {
      imagePath: require("../../icons/weather/pic52.png"),
      displayName: "TXT_Dashboard_weather_52",
    },
    weather53: {
      imagePath: require("../../icons/weather/pic53.png"),
      displayName: "TXT_Dashboard_weather_54",
    },
    weather54: {
      imagePath: require("../../icons/weather/pic54.png"),
      displayName: "TXT_Dashboard_weather_54",
    },
    weather60: {
      imagePath: require("../../icons/weather/pic60.png"),
      displayName: "TXT_Dashboard_weather_60",
    },
    weather61: {
      imagePath: require("../../icons/weather/pic61.png"),
      displayName: "TXT_Dashboard_weather_61",
    },
    weather62: {
      imagePath: require("../../icons/weather/pic62.png"),
      displayName: "TXT_Dashboard_weather_62",
    },
    weather63: {
      imagePath: require("../../icons/weather/pic63.png"),
      displayName: "TXT_Dashboard_weather_63",
    },
    weather64: {
      imagePath: require("../../icons/weather/pic64.png"),
      displayName: "TXT_Dashboard_weather_64",
    },
    weather65: {
      imagePath: require("../../icons/weather/pic65.png"),
      displayName: "TXT_Dashboard_weather_65",
    },
    weather70: {
      imagePath: require("../../icons/weather/pic70.png"),
      displayName: "TXT_Dashboard_weather_70",
    },
    weather71: {
      imagePath: require("../../icons/weather/pic71.png"),
      displayName: "TXT_Dashboard_weather_71",
    },
    weather72: {
      imagePath: require("../../icons/weather/pic72.png"),
      displayName: "TXT_Dashboard_weather_72",
    },
    weather73: {
      imagePath: require("../../icons/weather/pic73.png"),
      displayName: "TXT_Dashboard_weather_73",
    },
    weather74: {
      imagePath: require("../../icons/weather/pic74.png"),
      displayName: "TXT_Dashboard_weather_74",
    },
    weather75: {
      imagePath: require("../../icons/weather/pic75.png"),
      displayName: "TXT_Dashboard_weather_75",
    },
    weather76: {
      imagePath: require("../../icons/weather/pic76.png"),
      displayName: "TXT_Dashboard_weather_76",
    },
    weather77: {
      imagePath: require("../../icons/weather/pic77.png"),
      displayName: "TXT_Dashboard_weather_77",
    },
    weather80: {
      imagePath: require("../../icons/weather/pic80.png"),
      displayName: "TXT_Dashboard_weather_80",
    },
    weather81: {
      imagePath: require("../../icons/weather/pic81.png"),
      displayName: "TXT_Dashboard_weather_81",
    },
    weather82: {
      imagePath: require("../../icons/weather/pic82.png"),
      displayName: "TXT_Dashboard_weather_82",
    },
    weather83: {
      imagePath: require("../../icons/weather/pic83.png"),
      displayName: "TXT_Dashboard_weather_83",
    },
    weather84: {
      imagePath: require("../../icons/weather/pic84.png"),
      displayName: "TXT_Dashboard_weather_84",
    },
    weather85: {
      imagePath: require("../../icons/weather/pic85.png"),
      displayName: "TXT_Dashboard_weather_85",
    },
    weather90: {
      imagePath: require("../../icons/weather/pic90.png"),
      displayName: "TXT_Dashboard_weather_90",
    },
    weather91: {
      imagePath: require("../../icons/weather/pic91.png"),
      displayName: "TXT_Dashboard_weather_91",
    },
    weather92: {
      imagePath: require("../../icons/weather/pic92.png"),
      displayName: "TXT_Dashboard_weather_92",
    },
    weather93: {
      imagePath: require("../../icons/weather/pic93.png"),
      displayName: "TXT_Dashboard_weather_93",
    },
  };

  const { data: weather } = useQuery(WeathersDocument, {
    onCompleted: (data) => {},
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: "Fail to get weather data",
        status: "error",
      });
    },
  });

  return (
    <Flex justifyContent="space-between" gap="20px">
      <Flex
        flex={3}
        py="10px"
        px="20px"
        borderRadius="8px"
        boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
        bg="#FFF9F0"
        flexDir="column"
      >
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Flex gap="10px">
            <Image
              // src={require("../../icons/weather/pic81.png")}
              src={
                weatherMapObj[
                  ("weather" +
                    weather?.weathers[0]
                      .forecastIcon) as keyof typeof weatherMapObj
                ]?.imagePath
              }
              width="50px"
              height="50px"
              bg="#0049B2"
              borderRadius="5px"
            />

            <Flex flexDir="column" fontWeight="bold">
              <Box fontSize="18px">
                {t(
                  weatherMapObj[
                    ("weather" +
                      weather?.weathers[0]
                        .forecastIcon) as keyof typeof weatherMapObj
                  ]?.displayName
                ) ?? "-"}
              </Box>
              <Box fontSize="16px">
                {weather?.weathers[0]?.minTemperature}°C -{" "}
                {weather?.weathers[0]?.maxTemperature}°C
              </Box>
            </Flex>
          </Flex>
          <Flex bg="#F4891E" p="5px" borderRadius="8px" color="WT" gap="5px">
            <Image
              src={require("../../icons/weather/waterDrop.png")}
              width="25px"
              height="25px"
            />
            <Box fontWeight="bold">
              {weather?.weathers[0].minRelativeHumidity}%
            </Box>
          </Flex>
        </Flex>
        <Flex gap="10px" alignItems="center">
          <Box fontWeight="bold">{t("TXT_Project_Today")}</Box>
          <Flex fontSize="10px" color="GREY">
            {t("TXT_Dashboard_weather_station")}
          </Flex>
        </Flex>
      </Flex>

      <Flex overflowX="scroll" flex={8} gap="20px">
        {weather?.weathers.slice(1, -1).map((weatherObj, i) => {
          const mapObjKey = "weather" + weatherObj.forecastIcon;
          const mappedObj =
            weatherMapObj[mapObjKey as keyof typeof weatherMapObj];

          return (
            <Flex
              key={i}
              minW="fit-content"
              py="10px"
              px="20px"
              borderRadius="8px"
              // boxShadow="0px 0px 1px 3px rgba(0, 0, 0, 0.05)"
              border="1px solid rgba(0, 0, 0, 0.05)"
              bg="WT"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex
                gap="10px"
                fontWeight="bold"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src={mappedObj.imagePath}
                    // src={require("../../icons/weather/pic53.png")}
                    width="50px"
                    height="50px"
                    bg="#0049B2"
                    borderRadius="5px"
                  />
                  <Flex>{weatherObj.weekDay.substring(0, 3)}</Flex>
                </Flex>

                <Flex flexDir="column" fontWeight="bold">
                  <Flex>{t(mappedObj.displayName)}</Flex>
                  <Flex>
                    {weatherObj?.minTemperature}°C -{" "}
                    {weatherObj?.maxTemperature}°C
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default WeatherForecastRow;
