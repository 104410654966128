import { Box, Button, Text, Flex } from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import { IClientDetailData } from "../../../type/IBaseInfotInterface";
import { useTranslation } from "react-i18next";
import { isEmptyString, returnByTheme } from "src/utils/util";

interface IClientProps {
  clientData: IClientDetailData;
  updateClientDetailState: (value: any) => void;
  isPreviewMode?: boolean;
  onClose?: () => void;
}

const ClientDetailSection = ({
  clientData,
  updateClientDetailState,
  isPreviewMode = false,
  onClose = () => {},
}: IClientProps) => {
  const { t } = useTranslation();
  // console.log("client section state");
  // console.log(clientData);
  const [previewState, setPreviewState] = useState<IClientDetailData>({
    // name: "",
    // phone: "",
    email: "",
  });
  const displayData = isPreviewMode ? previewState : clientData;

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(clientData);
    }
    return () => {};
  }, []);

  const getNewState = (key: string, value: string) => {
    if (isPreviewMode) {
      setPreviewState({
        ...displayData,
        [key]: value,
      });
    } else {
      updateClientDetailState({
        ...displayData,
        [key]: value,
      });
    }
  };

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        width={"100%"}
        flexDirection={"column"}
        mt="20px"
        pb="10px"
      >
        {!isPreviewMode && (
          <SectionTitle title={t("TXT_Project_client_detail")} />
        )}

        {/* <FormTextInput
          placeHolder={"Name"}
          inputLabel={"Name"}
          isRequired={true}
          onChange={(value: string) => {
            getNewState("name", value);
          }}
          inpputtedValue={displayData?.name}
        />

        <FormTextInput
          placeHolder={"Phone"}
          inputLabel={"Phone"}
          isRequired={true}
          onChange={(value: string) => {
            getNewState("phone", value);
          }}
          inpputtedValue={displayData?.phone}
        /> */}

        <FormTextInput
          placeHolder={t("TXT_Email")}
          inputLabel={t("TXT_Email")}
          isRequired={true}
          onChange={(value: string) => {
            getNewState("email", value);
          }}
          inpputtedValue={displayData?.email}
        />
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            disabled={isEmptyString(displayData.email)}
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updateClientDetailState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default ClientDetailSection;
