import _ from "lodash";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  PinInput,
  PinInputField,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";

import { ReactComponent as WhiteCloseBtn } from "src/icons/general/whiteCloseBtn.svg";

type PickerProps = {
  isShow: boolean;
  onClose: Function;
  children?: React.ReactNode;
  headerColor?: string;
  headerText?: string;
  size?: string;
  bodyPx?: string;
};

const EditModal = ({
  isShow,
  onClose,
  children,
  headerColor,
  headerText,
  size,
  bodyPx = "10px",
}: PickerProps) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isShow}
      onClose={() => onClose()}
      isCentered
      scrollBehavior="inside"
      size={size}
    >
      <ModalOverlay />
      <ModalContent borderTopRadius="18px">
        <ModalHeader borderTopRadius="15px" backgroundColor={headerColor}>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text color="WT">{headerText}</Text>
            <Flex onClick={() => onClose()} cursor="pointer">
              <WhiteCloseBtn width="25px" height="25px" />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody pb="20px" px={bodyPx}>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {children}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
