import { Box, Select, Text, Flex } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { ReactComponent as ArrowDownBlack } from "src/icons/general/chevron-down-black.svg";
import { ReactComponent as ArrowDownGrey } from "src/icons/general/chevron-down-grey.svg";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";
import { useOutsideClick } from "@chakra-ui/react";
import { returnByTheme, convertDisplayText } from "../../utils/util";
import { useTranslation } from "react-i18next";
interface ISelectProps {
  options: Array<any>;
  isRequired?: boolean;
  inputLabel?: string;
  onChange: (value: string) => void;
  selectedValue: string;
  bgColor?: string;
}

const FormSelectInput = ({
  options,
  isRequired = false,
  inputLabel,
  onChange,
  selectedValue,
  bgColor,
}: ISelectProps) => {
  const [toggle, setToggle] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation();

  useOutsideClick({
    ref: ref,
    handler: () => setToggle(false),
  });

  const findDisplayName = (key: string) => {
    const arrOfKey = options.filter((o) => {
      return o.value == key;
    });
    // console.log(arrOfKey?.[0]?.name);
    return t(`${arrOfKey?.[0]?.name}`);
  };

  return (
    // <Flex flex={1}>
    <Flex
      flex={1}
      flexDirection={"row"}
      px={inputLabel ? "20px" : "0px"}
      py={inputLabel ? "10px" : "0px"}
      // px="20px"
      // py="10px"
      bg={bgColor ? bgColor : "transparent"}
      justifyContent={"space-between"}
      // alignItems="center"
      ref={ref}
      gap="5px"
    >
      {/* label section */}
      {inputLabel && (
        <Flex flex={2} fontSize={15} color={"GREY"}>
          {isRequired ? (
            <Box>
              <Text display={"inline"} color={bgColor ? "WT" : "GREY"}>
                {t(`${inputLabel}`)}&nbsp;
              </Text>
              <Text color={"RD.1"} display={"inline"}>
                *
              </Text>
            </Box>
          ) : (
            <Flex>{t(`${inputLabel}`)}</Flex>
          )}
        </Flex>
      )}

      {/* select list section */}
      <Flex position={"relative"} flex={3}>
        <Flex
          flex={1}
          onClick={() => setToggle(!toggle)}
          px="10px"
          py="5px"
          borderColor={returnByTheme()}
          borderRadius="5px"
          borderWidth={1}
          justifyContent={"space-between"}
          bg="WT"
          // bg={bgColor ? WT : "transparent"}
        >
          {/* {selectedValue ? selectedValue : options[0]?.name}{" "}
            <ArrowDownBlack /> */}
          {selectedValue !== ""
            ? findDisplayName(selectedValue)
            : t("TXT_Please_select_one_option")}
          <ArrowDownBlack />
        </Flex>
        <Flex
          position={"absolute"}
          top={"40px"}
          right={0}
          flex={1}
          width={"100%"}
          display={toggle ? "flex" : "none"}
          flexDirection={"column"}
          zIndex={3}
          backgroundColor={"WT"}
          borderBottomRadius="5px"
          borderWidth={1}
          borderColor={returnByTheme()}
        >
          {options &&
            options?.map((o, i) => {
              let isSelected = selectedValue === o?.value;
              // (inputLabel == "TXT_Project_project_progress"
              //   ? o.value
              //   : o?.name);
              return (
                <Flex
                  key={i}
                  justifyContent={"space-between"}
                  onClick={() => {
                    onChange(
                      o?.value
                      // inputLabel == "TXT_Project_project_progress"
                      //   ? o.value
                      //   : o?.name
                    );
                    setToggle(false);
                  }}
                  my="5px"
                  mx="10px"
                  _hover={{
                    cursor: "pointer",
                  }}
                >
                  <Text
                    color={"GREY"}
                    _hover={{
                      color: returnByTheme(),
                    }}
                  >
                    {t(`${o?.name}`)}
                    {/* {inputLabel == "TXT_Project_project_progress"
                      ? t(`${o?.name}`)
                      : t(`${o?.name}`)} */}
                  </Text>
                  {isSelected && <CheckIcon color={"GREY"} w={3} h={4} />}
                </Flex>
              );
            })}
        </Flex>
      </Flex>
    </Flex>
    // </Flex>
  );
};

export default FormSelectInput;
