import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Tabs,
  TabList,
  Tab,
  InputGroup,
  Input,
  useToast,
  useOutsideClick,
  Icon,
  Image,
  Spinner,
} from "@chakra-ui/react";

import { FiBell, FiCalendar, FiSearch } from "react-icons/fi";
import { SearchIcon, ChevronDownIcon, AddIcon } from "@chakra-ui/icons";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import FilterTag from "../../components/FilterTag";
import DateRangePicker from "../../components/DateRangePicker";
import CopyRightBar from "src/components/CopyRightBar";
import InvoiceTable from "./InvoiceTable";
import {
  returnByTheme,
  returnByColor,
  convertFirstUpperText,
} from "../../utils/util";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ContractorInvoiceUserDocument,
  LandOwnerInvoiceUserDocument,
  InvoicesDocument,
  MarkAsPaidDocument,
  Invoice_Status,
  InvoiceType,
  MarkAsCompletedDocument,
  PaginateInvoiceArgsSort,
  SendInvoicesDocument,
} from "../../graphql/generated";
import { useStore } from "src/app/App.store";
import {
  ISelectFilterOption,
  IAppliedFilterOption,
} from "../../type/IFilterOptInterface";
import { Helmet } from "react-helmet-async";
import "../../components/css/dateRangePicker.scss";

import { ReactComponent as BulkSendBtn } from "src/icons/general/bulkSendBtn.svg";
import { ReactComponent as BulkActionBtn } from "src/icons/general/bulkActionBtn.svg";
import { ReactComponent as DarkCloseBtn } from "src/icons/general/darkCloseBtn.svg";
import { ReactComponent as MarkAsIcon } from "src/icons/general/markAsIcon.svg";
import { ReactComponent as PrintIcon } from "src/icons/general/printIcon.svg";
import { ReactComponent as WhiteCloseBtn } from "src/icons/general/whiteCloseBtn.svg";
import FilterSelectOptions from "src/components/FilterSelectOptions";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PopUpModal from "src/components/PopUpModal";
import { RiFileDownloadLine } from "react-icons/ri";
import RightCornerTool from "src/components/RightCornerTool";

interface IInvoiceListQueryPayload {
  variables?: {
    filter?: {
      status?: Array<Invoice_Status>;
      startTime?: string | null;
      endTime?: string | null;
      type?: InvoiceType | null;
      keyword?: string;
      // names?: Array<string> | string | null;
      names?: Array<string> | undefined;
    };
    sort?: PaginateInvoiceArgsSort;
    offset?: number;
    limit?: number;
  };
}

const Invoice = () => {
  const location = useLocation();
  const {
    autoFilterStatus,
    autoFilterType,
    prevSelectedOpt,
    prevPayload,
    prevKeyword,
    prevDateRange,
  } = (location?.state as any) ?? {};
  const isClient = localStorage.getItem("sf_Role") == "true";
  const [invoiceList, setInvoiceList] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currDisplayTotal, setCurrDisplayTotal] = useState(20);
  const [currentTab, setCurrentTab] = useState("All");
  const [search, setSearch] = useState("");
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [appliedFilterOpt, setAppliedFilterOpt] =
    useState<IAppliedFilterOption>({
      invoiceStatus: [],
      invoiceType: [],
      invoiceClient: [],
    });

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const [filterOption, setFilterOption] = useState<ISelectFilterOption>();

  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState({ isShow: false, type: "" });
  const [showBothType, setShowBothType] = useState(false);
  const [showBulkAction, setShowBulkAction] = useState(false);
  const [showClientBulkAction, setShowClientBulkAction] = useState(false);
  const [showBulkSendConfirmPopup, setShowBulkSendConfirmPopup] =
    useState(false);

  // for call invoices api to use
  const [queryPayload, setQueryPayload] = useState<IInvoiceListQueryPayload>();

  const statusTabs = ["All", "Draft", "Overdue", "Sent", "Paid", "Completed"];

  const showDateRangeTag =
    dateRange?.[0]?.startDate !== null && dateRange?.[0]?.endDate !== null;

  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const statusFilterRef = useRef(null);
  const clientFilterRef = useRef(null);
  const typeFilterRef = useRef(null);
  const dateRangePickerRef = useRef(null);

  //const { userId } = useStore();

  // get the selected option when apply clicked
  const convertFilterParams = (props: IAppliedFilterOption) => {
    const siteId = searchParams.get('site_id')
    let payload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
          ...siteId ? { siteId } : {}
          // names:
          //   props?.invoiceClient && props?.invoiceClient?.length > 0
          //     ? [props?.invoiceClient[0]]
          //     : undefined,
        },
        limit: currDisplayTotal,
      },
    };
    if (props?.invoiceClient?.length > 0) {
      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...payload?.variables?.filter,
            names: props?.invoiceClient,
          },
        },
      };
    }

    if (props?.invoiceStatus?.length > 0) {
      const statusArrInEnum = props.invoiceStatus.map((e) => {
        return Invoice_Status[e as keyof typeof Invoice_Status];
      });
      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...payload?.variables?.filter,
            status: statusArrInEnum,
          },
        },
      };
    }

    if (props?.invoiceType?.length == 1) {
      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...payload?.variables?.filter,
            // type: props?.invoiceType?.[0]
            type: InvoiceType[
              props?.invoiceType?.[0] as keyof typeof InvoiceType
            ],
          },
        },
      };
      setShowBothType(false);
    } else if (props?.invoiceType?.length == 2) {
      const newTypePayload = _.omit(payload?.variables?.filter, "type");
      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...newTypePayload,
          },
        },
      };
      setShowBothType(true);
    } else {
      const newTypePayload = _.omit(payload?.variables?.filter, "type");
      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...newTypePayload,
          },
        },
      };
      setShowBothType(false);
    }

    setAppliedFilterOpt({ ...props });
    setQueryPayload(payload);
    console.log(13, payload)
    getAllInvoicesByQuery(payload);
  };

  const [getAllInvoicesByQuery, { data: invoicesInfos, loading, error }] =
    useLazyQuery(InvoicesDocument, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onCompleted: (c) => {
        setInvoiceList(c);
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: "Fail to get invoices",
          status: "error",
        });
      },
    });

  const { data: allClientsForAdmin } = useQuery(ContractorInvoiceUserDocument, {
    onError: (error) => {
      // return toast({
      //   position: "bottom-right",
      //   title: `Fail to fetch client list`,
      //   status: "error",
      // });
    },
  });

  const { data: allClientsForClient } = useQuery(LandOwnerInvoiceUserDocument, {
    // onError: (error) => {
    //   return toast({
    //     position: "bottom-right",
    //     title: t('TXT_Error_general_err_message'),
    //     status: "error",
    //   });
    // },
  });

  const [getAllInvoicesForCount, { data: allInvoices }] = useLazyQuery(
    InvoicesDocument,
    {
      // onError: (error) => {
      //   return toast({
      //     position: "bottom-right",
      //     title: t('TXT_Error_general_err_message'),
      //     status: "error",
      //   });
      // },
    }
  );
  const [getDraftInvoicesForCount, { data: allDraftInvoices }] = useLazyQuery(
    InvoicesDocument,
    {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );
  const [getOverdueInvoicesForCount, { data: allOverdueInvoices }] =
    useLazyQuery(InvoicesDocument, {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });
  const [getSentInvoicesForCount, { data: allSentInvoices }] = useLazyQuery(
    InvoicesDocument,
    {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );
  const [getPaidInvoicesForCount, { data: allPaidInvoices }] = useLazyQuery(
    InvoicesDocument,
    {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );
  const [getCompletedInvoicesForCount, { data: allCompletedInvoices }] =
    useLazyQuery(InvoicesDocument, {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });

  // const totalCountForEachTab = statusTabs.map((tab, i) => {
  //   return allInvoices?.invoices?.nodes?.filter((n, i) => {
  //     if (tab === "All") {
  //       return allInvoices?.invoices?.totalCount;
  //     } else {
  //       return tab.toUpperCase() == n?.status;
  //     }
  //   }).length;
  // });

  // action when tab change
  useEffect(() => {
    const siteId = searchParams.get('site_id')
    console.log('hi', siteId)
    let payload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
          ...siteId ? { siteId } : {}
        },
        limit: currDisplayTotal,
      },
    };

    if (currentTab !== "All") {
      payload = {
        ...queryPayload,
        variables: {
          ...queryPayload?.variables,
          filter: {
            ...queryPayload?.variables?.filter,
            ...siteId ? { siteId } : {},
            status: [
              Invoice_Status?.[currentTab as keyof typeof Invoice_Status],
            ],
          },
          limit: currDisplayTotal,
        },
      };
    }

    // setSearch("");
    // setFilterByType("");
    // setDateRange([
    //   {
    //     startDate: null,
    //     endDate: null,
    //     key: "selection",
    //   },
    // ]);

    setQueryPayload(payload);
    console.log(1, payload)
    getAllInvoicesByQuery(payload);
  }, [currentTab]);

  // update count total
  useEffect(() => {
    const siteId = searchParams.get('site_id')
    getAllInvoicesForCount({
      variables: {
        filter: {
          ...siteId ? { siteId } : {}
        },
      },
    });

    getDraftInvoicesForCount({
      variables: {
        filter: {
          status: [Invoice_Status.Draft],
          ...siteId ? { siteId } : {}
        },
      },
    });
    getOverdueInvoicesForCount({
      variables: {
        filter: {
          status: [Invoice_Status.Overdue],
          ...siteId ? { siteId } : {}
        },
      },
    });
    getSentInvoicesForCount({
      variables: {
        filter: {
          status: [Invoice_Status.Sent],
          ...siteId ? { siteId } : {}
        },
      },
    });
    getPaidInvoicesForCount({
      variables: {
        filter: {
          status: [Invoice_Status.Paid],
          ...siteId ? { siteId } : {}
        },
      },
    });
    getCompletedInvoicesForCount({
      variables: {
        filter: {
          status: [Invoice_Status.Completed],
          ...siteId ? { siteId } : {}
        },
      },
    });

    return () => { };
  }, []);

  // auto query from dashboard
  useEffect(() => {
    const siteId = searchParams.get('site_id')

    let payload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
          type: InvoiceType[autoFilterType as keyof typeof InvoiceType],
          ...siteId ? { siteId } : {},
        },
        limit: currDisplayTotal,
      },
    };

    setQueryPayload(payload);
    console.log(2, payload)
    getAllInvoicesByQuery(payload);
    if (autoFilterStatus) {
      setCurrentTab(
        autoFilterStatus &&
        autoFilterStatus[0].toUpperCase() + autoFilterStatus.slice(1)
      );
    }

    return () => { };
  }, [autoFilterStatus, autoFilterType]);

  const queryMore = () => {
    if (invoiceList?.invoices.pageInfo.hasNextPage) {
      let payload = {
        ...queryPayload,
        variables: {
          ...queryPayload?.variables,
          filter: {
            ...queryPayload?.variables?.filter,
          },
          limit: currDisplayTotal + 20,
        },
      };
      setCurrDisplayTotal(currDisplayTotal + 20);
      setQueryPayload(payload);

      console.log(3, payload)
      getAllInvoicesByQuery(payload);
    }
  };

  // date filter apply action
  const triggerDateFilter = (newRange: any) => {
    const startParams = moment(newRange?.[0]?.startDate).format("YYYY-MM-D");
    const endParams = moment(newRange?.[0]?.endDate).format("YYYY-MM-D");

    const newQueryPayload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
          startTime: startParams,
          endTime: startParams === endParams ? null : endParams,
        },
      },
    };
    setDateRange(newRange);
    setQueryPayload(newQueryPayload);

    console.log(4, newQueryPayload)
    getAllInvoicesByQuery(newQueryPayload);
    setIsShowDatePicker(false);
    // console.log(newQueryPayload);
  };

  const onKeywordSearch = () => {
    let payload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
          keyword: search,
        },
      },
    };

    setQueryPayload(payload);
    console.log(5, payload)
    getAllInvoicesByQuery(payload);
  };

  // open filter options list
  const toggleFilter = (type: string) => {
    setIsShowFilter({ isShow: !isShowFilter.isShow, type: type });
    // set filter option
    let option: { value: string; name: string }[] = [];
    switch (type) {
      case "invoiceStatus":
        option = [
          { value: "Paid", name: t("TXT_Option_Paid") },
          { value: "Overdue", name: t("TXT_Option_Overdue") },
          { value: "Draft", name: t("TXT_Option_Draft") },
          { value: "Sent", name: t("TXT_Option_Sent") },
          { value: "Completed", name: t("TXT_Option_Completed") },
        ];
        setFilterOption({ type: type, list: option });
        break;
      case "invoiceClient":
        if (isClient) {
          if (allClientsForClient && allClientsForClient.landOwnerInvoiceUser) {
            option = allClientsForClient.landOwnerInvoiceUser.map((c, i) => {
              return { value: c, name: c };
            });
          }
        } else {
          if (allClientsForAdmin && allClientsForAdmin.contractorInvoiceUser) {
            option = allClientsForAdmin.contractorInvoiceUser.map((c, i) => {
              return { value: c, name: c };
            });
          }
        }

        // option = [];

        setFilterOption({ type: type, list: option });
        break;
      case "invoiceType":
        option = [
          { value: "Debit", name: t("TXT_Option_Debit") },
          { value: "Credit", name: t("TXT_Option_Credit") },
        ];
        setFilterOption({ type: type, list: option });
        break;

      default:
        break;
    }
  };

  // const selectedClientText =
  //   allClients &&
  //   allClients.invoiceUser &&
  //   allClients.invoiceUser
  //     .filter((sc, i) => {
  //       return queryPayload?.variables?.filter?.names?.includes(sc.id);
  //     })
  //     .map((c) => {
  //       return c.name;
  //     })
  //     .join(" ,");

  // tag component for selected options
  const SelectedTag = ({ tagText, onClose }: any) => {
    return (
      <Flex
        backgroundColor={"DISABLEDTEXT"}
        mr="5px"
        px="10px"
        py="5px"
        borderRadius={8}
        alignItems="center"
      >
        <Text> {tagText}</Text>
        <Flex onClick={() => onClose()} _hover={{ opacity: 0.7 }}>
          <DarkCloseBtn />
        </Flex>
      </Flex>
    );
  };

  // clear filter
  const resetClientFilter = () => {
    const newClientPayload = _.omit(queryPayload?.variables?.filter, "names");
    const resetClientPayload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...newClientPayload,
        },
      },
    };

    setAppliedFilterOpt({ ...appliedFilterOpt, invoiceClient: [] });
    setQueryPayload(resetClientPayload);
    console.log(6, resetClientPayload)
    getAllInvoicesByQuery(resetClientPayload);
  };

  const resetTypeFilter = () => {
    const newTypePayload = _.omit(queryPayload?.variables?.filter, "type");

    const resetTypePayload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...newTypePayload,
        },
      },
    };

    setAppliedFilterOpt({ ...appliedFilterOpt, invoiceType: [] });
    setQueryPayload(resetTypePayload);
    console.log(7, resetTypePayload)
    getAllInvoicesByQuery(resetTypePayload);
    setShowBothType(false);
  };

  const resetStatusFilter = () => {
    const newStatusPayload = _.omit(queryPayload?.variables?.filter, "status");

    const resetStatusPayload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...newStatusPayload,
        },
      },
    };
    setAppliedFilterOpt({ ...appliedFilterOpt, invoiceStatus: [] });
    setQueryPayload(resetStatusPayload);
    console.log(8, resetStatusPayload)
    getAllInvoicesByQuery(resetStatusPayload);
  };

  const resetDateRangeFilter = () => {
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
    const resetDatePayload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
          startTime: null,
          endTime: null,
        },
      },
    };
    setQueryPayload(resetDatePayload);
    console.log(9, resetDatePayload)
    getAllInvoicesByQuery(resetDatePayload);
  };

  const handleSorting = (sortBy: PaginateInvoiceArgsSort) => {
    const sortingPayload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        sort: sortBy,
      },
    };

    setQueryPayload(sortingPayload);
    console.log(10, sortingPayload)
    getAllInvoicesByQuery(sortingPayload);
  };

  const [sendInvoices, { data: sendInvoicesStatus }] = useMutation(
    SendInvoicesDocument,
    {
      onCompleted: (x) => {
        if (x) {
          setCheckedItems([]);
          return toast({
            position: "bottom-right",
            title: `Bulk sent successfully`,
            status: "success",
          });
        }
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  useEffect(() => {
    if (prevSelectedOpt) setAppliedFilterOpt(prevSelectedOpt);
    if (prevPayload) {
      setQueryPayload(prevPayload);
      console.log(11, prevPayload)
      getAllInvoicesByQuery(prevPayload);
    }

    if (prevKeyword) setSearch(prevKeyword);
    if (prevDateRange) setDateRange(prevDateRange);

    // if (prevSelectedOpt || prevPayload || prevKeyword || prevDateRange) {
    //   setAppliedFilterOpt(prevSelectedOpt);

    //   setSearch(prevKeyword);
    //   setDateRange(prevDateRange);

    //   setQueryPayload(prevPayload);
    //   getAllInvoicesByQuery(prevPayload);
    // }

    return () => { };
  }, []);

  const isAllowBulkSend = checkedItems.filter((x: any) => {
    return x.customTemplateCode;
  });

  // console.log({
  //   autoFilterStatus,
  //   autoFilterType,
  //   prevSelectedOpt,
  //   prevPayload,
  //   prevKeyword,
  //   prevDateRange,
  // });
  return (
    <>
      <Helmet>
        <title>Solar Monster - Invoice</title>
      </Helmet>

      {/* header section */}
      <Flex
        flexDirection="column"
        flex={1}
        h="100vh"
        backgroundColor="#F7FAFA"
        ml="65px"
        overflowX="auto"
      >
        <Flex
          backgroundColor="WT"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          px="20px"
          minH="60px"
        >
          <Flex>
            <Text fontWeight="bold">{t("TXT_Invoice")}</Text>
          </Flex>
          <Tabs variant="unstyled" mx="20px">
            <TabList>
              {statusTabs.map((x, index) => {
                let displayCount = 0;
                switch (x) {
                  case "All":
                    displayCount = allInvoices?.invoices?.totalCount ?? 0;
                    break;
                  case "Draft":
                    displayCount = allDraftInvoices?.invoices?.totalCount ?? 0;
                    break;
                  case "Overdue":
                    displayCount = allOverdueInvoices?.invoices.totalCount ?? 0;
                    break;
                  case "Sent":
                    displayCount = allSentInvoices?.invoices.totalCount ?? 0;
                    break;
                  case "Paid":
                    displayCount = allPaidInvoices?.invoices.totalCount ?? 0;
                    break;
                  case "Completed":
                    displayCount =
                      allCompletedInvoices?.invoices.totalCount ?? 0;
                    break;

                  default:
                    break;
                }

                return (
                  <Tab
                    padding={4}
                    onClick={() => setCurrentTab(x)}
                    borderBottomWidth={currentTab === x ? "3px" : "0"}
                    color="GREY"
                    borderColor={returnByTheme()}
                    key={index}
                  // justifyContent={"space-between"}
                  >
                    <Text mr={3}>
                      {t(`TXT_Invoice_${x.replaceAll(" ", "_")}`)}
                    </Text>

                    {(loading && (
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color={isClient ? "themeOrange" : "blue.500"}
                        size="sm"
                      />
                    )) || (
                        <Text
                          color={"WT"}
                          px={3}
                          // marginLeft={3}
                          backgroundColor={
                            currentTab === x ? returnByTheme() : "GREY"
                          }
                          borderRadius={25}
                        >
                          {displayCount}
                          {/* {totalCountForEachTab[index]} */}
                        </Text>
                      )}
                  </Tab>
                );
              })}
            </TabList>
          </Tabs>

          <RightCornerTool />
        </Flex>

        <Flex
          // flex={4}
          flexDirection="column"
          marginTop={"25px"}
          maxW="1920px"
          mx="auto"
          px="20px"
          width="100%"
        >
          {/* search bar row */}
          <Flex
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            mx="19px"
          //pt="25px"
          >
            <InputGroup alignItems="center" flex={1} maxW="640px" mr="20px">
              <Input
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
                height="40px"
                fontSize="14px"
                borderRadius={"40px"}
                borderColor={returnByTheme()}
                borderWidth="1px"
                bgColor={"WT"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onKeywordSearch();
                  }
                }}
                //_focus={{ backgroundColor: "#0000" }}
                // pl="7"
                placeholder={t("TXT_Invoice_Search_keyword")}
              />
              <Flex
                zIndex={1}
                alignItems="center"
                justifyContent="center"
                w="34px"
                h="34px"
                borderRadius="18px"
                backgroundColor={returnByTheme()}
                position="absolute"
                right="3px"
                top="3px"
                onClick={() => onKeywordSearch()}
                _hover={{ opacity: 0.7 }}
              >
                <Icon as={FiSearch} w="22px" h="22px" color="WT" />
              </Flex>
            </InputGroup>
            {
              !isClient && (
                <Flex gap="10px">
                  <Flex
                    maxWidth="200px"
                    h="40px"
                    p="10px"
                    cursor="pointer"
                    onClick={() => {
                      navigate(`/invoice/create`);
                    }}
                    color="WT"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="18px"
                    backgroundColor={returnByTheme()}
                  // _hover={{ opacity: 0.7, cursor: "pointer" }}
                  >
                    <AddIcon />
                    <Text marginLeft={"5px"}>
                      {t("TXT_Invoice_new_invoice")}
                    </Text>
                  </Flex>
                  <Flex
                    maxWidth="200px"
                    h="40px"
                    p="10px"
                    cursor={
                      isAllowBulkSend.length == 0 ? "pointer" : "not-allowed"
                    }
                    pointerEvents={
                      isAllowBulkSend.length == 0 ? "auto" : "none"
                    }
                    onClick={() => setShowBulkSendConfirmPopup(true)}
                    border={"1px solid"}
                    color={isAllowBulkSend.length == 0 ? returnByTheme() : "WT"}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="18px"
                    backgroundColor={
                      isAllowBulkSend.length == 0 ? "WT" : "DISABLEDBG"
                    }
                  // _hover={{ opacity: 0.7, cursor: "pointer" }}
                  >
                    <BulkSendBtn
                      width={"25"}
                      height={"25"}
                      stroke={
                        isAllowBulkSend.length == 0 ? "#004b92" : "#FFFFFF"
                      }
                    />
                    <Text marginLeft={"5px"}>{t("TXT_Invoice_Bulk_send")}</Text>
                  </Flex>
                </Flex>
              )
              // || (
              //   <Flex
              //     maxWidth="200px"
              //     h="40px"
              //     p="10px"
              //     cursor={checkedItems.length > 0 ? "pointer" : "not-allowed"}
              //     pointerEvents={checkedItems.length > 0 ? "auto" : "none"}
              //     onClick={() => setShowClientBulkAction(true)}
              //     color="WT"
              //     alignItems="center"
              //     justifyContent="center"
              //     borderRadius="18px"
              //     backgroundColor={
              //       checkedItems.length > 0 ? returnByTheme() : "DISABLEDBG"
              //     }
              //     position="relative"
              //     // _hover={{ opacity: 0.7, cursor: "pointer" }}
              //   >
              //     <BulkActionBtn width={"40"} height={"40"} />
              //     <Text marginLeft={"5px"}>{t("TXT_Invoice_Bulk_action")}</Text>
              //     {showClientBulkAction && (
              //       <ClientBulkActionModal
              //         clientBulkActionClose={() => setShowClientBulkAction(false)}
              //         checkedItems={checkedItems}
              //       />
              //     )}
              //   </Flex>
              // )
            }
          </Flex>
          {/* filter bar row */}
          <Flex
            marginTop={"25px"}
            mx="19px"
            // mx={"15px"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Flex
              flexDirection="row"
            // alignItems="center"
            >
              <Flex position="relative">
                <FilterTag onClick={() => toggleFilter("invoiceClient")}>
                  <Flex flexDirection="row" alignItems="center">
                    <Text> {t("TXT_Invoice_Client")}</Text>
                    <ChevronDownIcon w="30px" h="30px" />
                  </Flex>
                </FilterTag>
                {isShowFilter.isShow &&
                  isShowFilter.type == "invoiceClient" && (
                    <FilterSelectOptions
                      filterListRef={clientFilterRef}
                      applyAction={(props: IAppliedFilterOption) => {
                        convertFilterParams(props);
                        setIsShowFilter({
                          isShow: false,
                          type: "invoiceClient",
                        });
                      }}
                      onClose={() =>
                        setIsShowFilter({
                          isShow: false,
                          type: "invoiceClient",
                        })
                      }
                      options={filterOption}
                      appliedFilterOpt={appliedFilterOpt}
                    />
                  )}
              </Flex>

              <Flex position="relative">
                <FilterTag onClick={() => toggleFilter("invoiceType")}>
                  <Flex flexDirection="row" alignItems="center">
                    <Text> {t("TXT_Invoice_Type")}</Text>
                    <ChevronDownIcon w="30px" h="30px" />
                  </Flex>
                </FilterTag>
                {isShowFilter.isShow && isShowFilter.type == "invoiceType" && (
                  <FilterSelectOptions
                    filterListRef={typeFilterRef}
                    applyAction={(props: IAppliedFilterOption) => {
                      convertFilterParams(props);
                      setIsShowFilter({ isShow: false, type: "invoiceType" });
                    }}
                    onClose={() =>
                      setIsShowFilter({ isShow: false, type: "invoiceType" })
                    }
                    options={filterOption}
                    appliedFilterOpt={appliedFilterOpt}
                  />
                )}
              </Flex>

              {/* <FilterTag onClick={() => toggleFilter("invoiceType")}>
                <Flex flexDirection="row" alignItems="center">
                  <Text>{t("TXT_Invoice_Type")}</Text>
                  <ChevronDownIcon w="30px" h="30px" />
                </Flex>
              </FilterTag> */}

              {currentTab === "All" && (
                <Flex position="relative">
                  <FilterTag onClick={() => toggleFilter("invoiceStatus")}>
                    <Flex flexDirection="row" alignItems="center">
                      <Text> {t("TXT_Invoice_Status")}</Text>
                      <ChevronDownIcon w="30px" h="30px" />
                    </Flex>
                  </FilterTag>
                  {isShowFilter.isShow &&
                    isShowFilter.type == "invoiceStatus" && (
                      <FilterSelectOptions
                        filterListRef={statusFilterRef}
                        applyAction={(props: IAppliedFilterOption) => {
                          convertFilterParams(props);
                          setIsShowFilter({
                            isShow: false,
                            type: "invoiceStatus",
                          });
                        }}
                        onClose={() =>
                          setIsShowFilter({
                            isShow: false,
                            type: "invoiceStatus",
                          })
                        }
                        options={filterOption}
                        appliedFilterOpt={appliedFilterOpt}
                      />
                    )}
                </Flex>
                // <FilterTag onClick={() => toggleFilter("invoiceStatus")}>
                //   <Flex flexDirection="row" alignItems="center">
                //     <Text>{t("TXT_Invoice_Status")}</Text>
                //     <ChevronDownIcon w="30px" h="30px" />
                //   </Flex>
                // </FilterTag>
              )}

              <Flex position="relative">
                <FilterTag onClick={() => setIsShowDatePicker(true)}>
                  <Flex flexDirection="row" alignItems="center">
                    <Text paddingRight="5px">
                      {t("TXT_Invoice_Select_date")}
                    </Text>
                    <FiCalendar color={returnByTheme()} />
                  </Flex>
                </FilterTag>
                {isShowDatePicker && (
                  <DateRangePicker
                    dateRangePickerRef={dateRangePickerRef}
                    onClose={() => setIsShowDatePicker(false)}
                    applyDateFilter={(newRange: any) => {
                      triggerDateFilter(newRange);
                    }}
                    dateRange={dateRange}
                  // setDateRange={setDateRange}
                  />
                )}
              </Flex>
            </Flex>

            {!isClient && (
              <Flex position={"relative"}>
                <Flex
                  w="40px"
                  h="40px"
                  cursor={checkedItems.length > 0 ? "pointer" : "not-allowed"}
                  pointerEvents={checkedItems.length > 0 ? "auto" : "none"}
                  onClick={() => setShowBulkAction(!showBulkAction)}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="18px"
                  backgroundColor={
                    checkedItems.length > 0 ? returnByTheme() : "DISABLEDBG"
                  }
                  _hover={{ opacity: 0.7 }}
                >
                  <BulkActionBtn
                    width={"40px"}
                    height={"40px"}
                    fill={checkedItems.length > 0 ? returnByColor() : "#F0F0F0"}
                  />
                </Flex>
                {showBulkAction && (
                  <BulkActionModal
                    bulkActionClose={() => setShowBulkAction(false)}
                    checkedItems={checkedItems}
                  />
                )}
              </Flex>
            )}
          </Flex>
          {/* selected filter tags */}
          <Flex
            marginLeft={"19px"}
            my="10px"
            alignItems="center"
            flexWrap="wrap"
            rowGap="10px"
          >
            <Flex mr="10px">{t("TXT_Filter_By")}:</Flex>
            {queryPayload?.variables?.filter?.names && (
              <SelectedTag
                tagText={queryPayload?.variables?.filter?.names
                  .map((n) => {
                    return n;
                  })
                  .join(", ")}
                onClose={() => resetClientFilter()}
              />
            )}

            {(queryPayload?.variables?.filter?.type || showBothType) && (
              <SelectedTag
                tagText={
                  showBothType
                    ? t("TXT_Option_Credit") +
                    " " +
                    t("TXT_Note") +
                    " , " +
                    t("TXT_Option_Debit") +
                    " " +
                    t("TXT_Note")
                    : convertFirstUpperText(
                      queryPayload?.variables?.filter?.type ?? ""
                    ) +
                    " " +
                    t("TXT_Note")
                }
                onClose={() => resetTypeFilter()}
              />
            )}

            {currentTab === "All" &&
              queryPayload?.variables?.filter?.status &&
              queryPayload?.variables?.filter?.status?.length > 0 && (
                <SelectedTag
                  tagText={
                    // queryPayload?.variables?.filter?.status?.length === 0 ||
                    queryPayload?.variables?.filter?.status?.length === 5
                      ? "All statuses"
                      : queryPayload?.variables?.filter?.status
                        .map((s) => {
                          return s;
                        })
                        .join(", ")
                  }
                  onClose={() => resetStatusFilter()}
                />
              )}

            {showDateRangeTag && (
              <SelectedTag
                tagText={
                  dateRange?.[0]?.endDate !== null
                    ? `Issue date: ${moment(dateRange?.[0]?.startDate).format(
                      "YYYY-MM-D"
                    )} - ${moment(dateRange?.[0]?.endDate).format(
                      "YYYY-MM-D"
                    )}`
                    : `Issue date: ${moment(dateRange?.[0]?.startDate).format(
                      "YYYY-MM-D"
                    )}`
                }
                onClose={() => resetDateRangeFilter()}
              />
            )}
          </Flex>
        </Flex>
        {/* invoice list setcion */}
        <Flex
          mt={"10px"}
          maxW="1920px"
          mx="auto"
          px="20px"
          width="100%"
          height="100%"
          mb="70px"
        >
          <InvoiceTable
            invoicesInfos={invoiceList?.invoices?.nodes}
            isLoading={loading}
            reFetch={() => getAllInvoicesByQuery({})}
            queryMore={() => queryMore()}
            updateCheckedState={(checkedItem: any) => {
              setCheckedItems(checkedItem);
            }}
            handleSorting={(sortBy: PaginateInvoiceArgsSort) =>
              handleSorting(sortBy)
            }
            prevSelectedOpt={appliedFilterOpt}
            prevPayload={queryPayload}
            prevKeyword={search}
            prevDateRange={dateRange}
          />
        </Flex>
        <PopUpModal
          isShow={showBulkSendConfirmPopup}
          onClose={() => setShowBulkSendConfirmPopup(false)}
          headerColor={returnByTheme()}
          headerText="Send invoice"
        >
          <Flex flexDirection="column">
            <Flex flexDirection="column" gap="10px" alignItems="center">
              <Image
                src={require("../../icons/general/bulkSendLogo.png")}
                width="140px"
                height="140px"
                mt="20px"
              />
              <Flex color={returnByTheme()} fontWeight="bold">
                {t("TXT_Invoice_Bulk_send_total", {
                  total: checkedItems.length,
                })}
              </Flex>
              <Flex fontWeight="bold">{t("TXT_Invoice_Bulk_send_desc")}</Flex>
            </Flex>
            <Flex justifyContent="space-between" gap="10px">
              <Button
                flex={1}
                backgroundColor="WT"
                border="1px solid #000000"
                // _hover={{ backgroundColor: {returnByTheme()} }}
                // disabled={loading}
                onClick={() => setShowBulkSendConfirmPopup(false)}
                marginTop="20px"
                borderRadius="40px"
              >
                {t("TXT_Cancel")}
              </Button>
              <Button
                flex={1}
                color="WT"
                backgroundColor={returnByTheme()}
                _hover={{ backgroundColor: returnByTheme() }}
                // disabled={loading}
                onClick={() => {
                  const sendPayload = {
                    variables: {
                      input: {
                        ids: checkedItems.map((ci: any) => ci.id),
                      },
                    },
                  };
                  sendInvoices(sendPayload);
                  setShowBulkSendConfirmPopup(false);
                }}
                marginTop="20px"
                borderRadius="40px"
              >
                {t("TXT_Send")}
              </Button>
            </Flex>
          </Flex>
        </PopUpModal>

        <CopyRightBar />
      </Flex>
    </>
  );
};

// client bulk action component
const ClientBulkActionModal = React.forwardRef((props: any, ref) => {
  const clientBulkctionRef = useRef<any>(null);
  useOutsideClick({
    ref: clientBulkctionRef,
    handler: (e) => {
      // e?.stopPropagation();
      props?.clientBulkActionClose();
    },
  });

  const { t } = useTranslation();

  return (
    <Flex
      ref={clientBulkctionRef}
      backgroundColor="transparent"
      position="absolute"
      right="0"
      top="50px"
      flexDirection={"column"}
      zIndex={11}
      minW="200px"
    >
      <Flex
        px="20px"
        height="45"
        borderTopRightRadius="25px"
        borderTopLeftRadius="25px"
        borderColor={returnByTheme()}
        backgroundColor={returnByTheme()}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text color={"WT"}> {t("TXT_Invoice_Bulk_action")} </Text>
        <WhiteCloseBtn
          cursor={"pointer"}
          width={20}
          height={20}
          onClick={(e) => {
            e?.stopPropagation();
            props?.clientBulkActionClose();
          }}
        />
      </Flex>

      <Flex
        borderWidth={1}
        borderColor={returnByTheme()}
        backgroundColor={"WT"}
        flexDirection={"column"}
      >
        <Flex alignItems={"center"} py="5px">
          <Button
            backgroundColor="#0000"
            flex={1}
            justifyContent={"flex-start"}
            onClick={() => {
              // console.log("bulk download~");
            }}
          >
            <Icon
              as={RiFileDownloadLine}
              w="20px"
              h="20px"
              mr="5px"
              color="GREY"
            />
            <Text color={"GREY"} marginLeft="5px">
              {t("TXT_Invoice_Download_invoice")}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
});

// bulk action component
const BulkActionModal = React.forwardRef((props: any, ref) => {
  const bulkctionRef = useRef<any>(null);
  useOutsideClick({
    ref: bulkctionRef,
    handler: (e) => {
      // e?.stopPropagation();
      props?.bulkActionClose();
    },
  });

  const toast = useToast();
  const { t } = useTranslation();
  const [setInvoicesAsPaid, { data: markAsPaidStatus }] = useMutation(
    MarkAsPaidDocument,
    {
      onCompleted: (x) => {
        return toast({
          position: "bottom-right",
          title: `Status chagned as Paid`,
          status: "success",
        });
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  const [setInvoicesAsComplete, { data: markAsCompleteStatus }] = useMutation(
    MarkAsCompletedDocument,
    {
      onCompleted: (x) => {
        return toast({
          position: "bottom-right",
          title: `Status chagned as Complete`,
          status: "success",
        });
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  return (
    <Flex
      ref={bulkctionRef}
      backgroundColor="transparent"
      position="absolute"
      right="40px"
      top={0}
      flexDirection={"column"}
      zIndex={11}
    >
      <Flex
        px="20px"
        height="45"
        borderTopRightRadius="25px"
        borderTopLeftRadius="25px"
        borderColor={returnByTheme()}
        backgroundColor={returnByTheme()}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text color={"WT"}> {t("TXT_Invoice_More_actions")} </Text>
        <WhiteCloseBtn
          cursor={"pointer"}
          width={20}
          height={20}
          onClick={() => props?.bulkActionClose()}
        />
      </Flex>

      <Flex
        borderWidth={1}
        borderColor={returnByTheme()}
        backgroundColor={"WT"}
        flexDirection={"column"}
      >
        <Flex alignItems={"center"} py="5px">
          <Button
            backgroundColor="#0000"
            flex={1}
            justifyContent={"flex-start"}
            onClick={(e) => {
              e.stopPropagation();
              setInvoicesAsPaid({
                variables: {
                  input: {
                    ids: props?.checkedItems.map((ci: any) => ci.id),
                  },
                },
              });
              props?.bulkActionClose();
            }}
          >
            <MarkAsIcon width={25} height={25} />
            <Text color={"GREY"} marginLeft="5px">
              {t("TXT_Invoice_Mark_as_paid")}
            </Text>
          </Button>
        </Flex>
        <Flex alignItems={"center"} py="5px">
          <Button
            backgroundColor="#0000"
            flex={1}
            justifyContent={"flex-start"}
            onClick={(e) => {
              e.stopPropagation();
              setInvoicesAsComplete({
                variables: {
                  input: {
                    ids: props?.checkedItems.map((ci: any) => ci.id),
                  },
                },
              });
              props?.bulkActionClose();
            }}
          >
            <MarkAsIcon width={25} height={25} />
            <Text color={"GREY"} marginLeft="5px">
              {t("TXT_Invoice_Mark_as_completed")}
            </Text>
          </Button>
        </Flex>
        {/* <Flex alignItems={"center"} py="5px">
          <Button
            backgroundColor="#0000"
            flex={1}
            justifyContent={"flex-start"}
            onClick={() => {
              console.log("print~");
            }}
          >
            <PrintIcon />
            <Text color={"GREY"} marginLeft="5px">
              {t("TXT_Print")}
            </Text>
          </Button>
        </Flex> */}
      </Flex>
    </Flex>
  );
});

export default Invoice;
