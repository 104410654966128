import _ from "lodash";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BaseInput from "src/components/BaseInput";
import CheckBox from "src/components/CheckBox";
import PasswordInput from "src/components/PasswordInput";
import StyledButton from "./StyledButton";

import { ReactComponent as AccountIcon } from "src/icons/auth/account.svg";
import { ReactComponent as EmailIcon } from "src/icons/auth/email.svg";
import { ReactComponent as CompanyIcon } from "src/icons/auth/company.svg";
import { ReactComponent as PhoneIcon } from "src/icons/auth/phone.svg";

import { useTranslation } from "react-i18next";
import LangSwitcher from "src/components/LangSwitcher";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAccept, setIsAccept] = useState(false);

  const { t } = useTranslation();

  const [isShowWarning, setIsShowWarning] = useState(false);

  const isValid =
    !_.isEmpty(name) &&
    !_.isEmpty(email) &&
    email.includes("@") &&
    !_.isEmpty(company) &&
    !_.isEmpty(contactNumber) &&
    contactNumber.length === 8 &&
    !_.isEmpty(signupPassword) &&
    signupPassword.length >= 8 &&
    !_.isEmpty(confirmPassword) &&
    signupPassword === confirmPassword &&
    isAccept;

  const submit = () => {
    if (!isValid) setIsShowWarning(true);
  };
  // useEffect(() => {
  //   setSignupPassword("");

  //   return () => {};
  // }, []);
  // console.log(signupPassword);

  return (
    <Box width="calc(100% - 40px)" height="100%" overflowY={"scroll"}>
      <Helmet>
        <title>{t("TXT_Signup")}</title>
      </Helmet>
      <Box overflowY="auto">
        <Flex mt="30px" justifyContent="flex-end">
          <LangSwitcher color="#FCAF20" />
        </Flex>
        <Flex
          // w="900px"
          p="20px"
          flexDir="column"
          gap="25px"
          alignItems="center"
          textAlign="center"
        >
          <Text
            fontSize="65px"
            color="#052b12"
            fontWeight={600}
            lineHeight={0.9}
          >
            {t("TXT_Signup_Create_account")}
          </Text>
          <Text fontSize="35px" color="OG.2" lineHeight={2}>
            {t("TXT_Signup_Desc")}
            {/* <br /> */}
          </Text>
          <BaseInput
            color="OG.2"
            title={t("TXT_Name")}
            value={name}
            left={<AccountIcon stroke="currentcolor" />}
            onChange={(e) => setName(e.currentTarget.value)}
            isShowWarning={_.isEmpty(name) && isShowWarning}
          />
          <BaseInput
            color="OG.2"
            title={t("TXT_Email")}
            value={email}
            left={<EmailIcon stroke="currentcolor" />}
            onChange={(e) => setEmail(e.currentTarget.value)}
            isShowWarning={
              (_.isEmpty(email) || !email.includes("@")) && isShowWarning
            }
          />
          <BaseInput
            color="OG.2"
            title={t("TXT_Company")}
            value={company}
            left={<CompanyIcon stroke="currentcolor" />}
            onChange={(e) => setCompany(e.currentTarget.value)}
            isShowWarning={_.isEmpty(company) && isShowWarning}
          />
          <BaseInput
            color="OG.2"
            title={t("TXT_Contact_number")}
            value={contactNumber}
            left={<PhoneIcon stroke="currentcolor" />}
            type="number"
            onChange={(e) => setContactNumber(e.currentTarget.value)}
            isShowWarning={
              (_.isEmpty(contactNumber) || contactNumber.length !== 8) &&
              isShowWarning
            }
          />

          <PasswordInput
            color="OG.2"
            title={t("TXT_Password")}
            value={signupPassword}
            onChange={(e) => setSignupPassword(e.currentTarget.value)}
            isShowWarning={
              (_.isEmpty(signupPassword) ||
                signupPassword.length < 8 ||
                signupPassword !== confirmPassword) &&
              isShowWarning
            }
            autoComplete={false}
          />

          <PasswordInput
            color="OG.2"
            title={t("TXT_Confirm_password")}
            confirm
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            isShowWarning={
              (_.isEmpty(confirmPassword) ||
                signupPassword.length < 8 ||
                signupPassword !== confirmPassword) &&
              isShowWarning
            }
          />

          <Flex
            flexDir="row"
            justifyContent="space-between"
            w="100%"
            mt="15px"
            mb="40px"
          >
            <CheckBox
              color={isShowWarning && !isAccept ? "RD.1" : "OG.2"}
              isChecked={isAccept}
              onChange={() => setIsAccept((v) => !v)}
            >
              {t("TXT_Signup_Accept_desc")} <u>{t("TXT_Signup_Accept_TNC")}</u>
            </CheckBox>
          </Flex>

          <StyledButton color="OG.2" onClick={submit}>
            {t("TXT_Signup").toUpperCase()}
          </StyledButton>
        </Flex>
      </Box>
    </Box>
  );
};

export default SignUp;
