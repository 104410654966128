import _ from "lodash";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  DashboardActiveSitesDocument,
  SiteStatus,
} from "../../graphql/generated";
import { useTranslation } from "react-i18next";
import { returnByTheme } from "src/utils/util";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

const ProjectKindsRow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const { data: projectKinds } = useQuery(DashboardActiveSitesDocument, {
    onCompleted: (data) => {},
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Fail to get projects data`,
        status: "error",
      });
    },
  });

  return (
    // <Flex
    //   p="10px"
    //   borderRadius="8px"
    //   boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)"
    //   bg="WT"
    //   justifyContent="space-between"
    //   overflow="auto"
    // >
    <Grid
      templateColumns="repeat(4, 1fr)"
      p="10px"
      borderRadius="8px"
      boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)"
      bg="WT"
      justifyContent="space-between"
      overflow="auto"
    >
      <GridItem colSpan={1} mr="3%">
        <Flex alignItems="center">
          <Image src={require("../../icons/general/briefcase.png")}></Image>
          <Flex flexDir="column" ml="10px">
            <Flex color="DISABLEDTEXT">
              {t("TXT_Dashboard_active_projects")}
            </Flex>
            <Flex fontWeight="bold" fontSize="28px">
              {projectKinds?.dashboardActiveSites.active}
            </Flex>
          </Flex>
          <Flex
            ml="auto"
            color={returnByTheme()}
            bg="WT"
            py="10px"
            px="15px"
            borderRadius="25px"
            boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)"
            cursor="pointer"
            onClick={() => navigate(`/project`)}
          >
            {t("TXT_Dashboard_all_projects")}
          </Flex>
        </Flex>
      </GridItem>

      <GridItem
        // alignItems="center"
        // justifyContent="space-between"
        margin="auto 0"
        gap="10px"
        colSpan={1}
        // flexShrink={0}
        borderLeft="1px solid #E8E8E8"
        px="3%"
      >
        <Flex justifyContent="space-between" alignItems="center" gap="2%">
          <Flex flexDir="column" width="100%">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap="2%"
              width="100%"
            >
              <Flex color="GREY" wordBreak="keep-all">
                {t("TXT_Project_Application")}
              </Flex>
              <Flex fontSize="28px" fontWeight="bold">
                {projectKinds?.dashboardActiveSites.application == 0
                  ? 0
                  : (
                      projectKinds?.dashboardActiveSites.application! * 100
                    ).toFixed(1)}
                %
              </Flex>
            </Flex>
            <Progress
              value={projectKinds?.dashboardActiveSites.application! * 100}
              colorScheme="yellow"
              borderRadius="5px"
            />
          </Flex>
          <Image
            src={require("../../icons/general/magnifier.png")}
            onClick={() => {
              navigate(`/project`, {
                state: {
                  filterType: "Application",
                },
              });
            }}
            cursor="pointer"
          ></Image>
        </Flex>
      </GridItem>

      <GridItem
        // alignItems="center"
        // justifyContent="space-between"
        margin="auto 0"
        gap="10px"
        colSpan={1}
        // flexShrink={0}
        borderLeft="1px solid #E8E8E8"
        px="3%"
      >
        <Flex justifyContent="space-between" alignItems="center" gap="2%">
          <Flex flexDir="column" width="100%">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap="2%"
              width="100%"
            >
              <Flex color="GREY" wordBreak="keep-all">
                {t("TXT_Project_Installation")}
              </Flex>
              <Flex fontSize="28px" fontWeight="bold">
                {projectKinds?.dashboardActiveSites.installation == 0
                  ? 0
                  : (
                      projectKinds?.dashboardActiveSites.installation! * 100
                    ).toFixed(1)}
                %
              </Flex>
            </Flex>
            <Progress
              value={projectKinds?.dashboardActiveSites?.installation! * 100}
              colorScheme="green"
              borderRadius="5px"
            />
          </Flex>
          <Image
            src={require("../../icons/general/magnifier.png")}
            onClick={() => {
              navigate(`/project`, {
                state: {
                  filterType: "Installation",
                },
              });
            }}
            cursor="pointer"
          ></Image>
        </Flex>
      </GridItem>

      <GridItem
        // alignItems="center"
        // justifyContent="space-between"
        margin="auto 0"
        gap="10px"
        colSpan={1}
        // flexShrink={0}
        borderLeft="1px solid #E8E8E8"
        px="3%"
      >
        <Flex justifyContent="space-between" alignItems="center" gap="2%">
          <Flex flexDir="column" width="100%">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap="2%"
              width="100%"
            >
              <Flex color="GREY" wordBreak="keep-all">
                {t("TXT_Project_Listed")}
              </Flex>
              <Flex fontSize="28px" fontWeight="bold">
                {projectKinds?.dashboardActiveSites.listed == 0
                  ? 0
                  : (projectKinds?.dashboardActiveSites.listed! * 100).toFixed(
                      1
                    )}
                %
              </Flex>
            </Flex>
            <Progress
              value={projectKinds?.dashboardActiveSites?.listed! * 100}
              colorScheme="blue"
              borderRadius="5px"
            />
          </Flex>
          <Image
            src={require("../../icons/general/magnifier.png")}
            onClick={() => {
              navigate(`/project`, {
                state: {
                  filterType: "Listed",
                },
              });
            }}
            cursor="pointer"
          ></Image>
        </Flex>
      </GridItem>
      {/* </Flex> */}
    </Grid>
  );
};

export default ProjectKindsRow;
