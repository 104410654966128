import { Button } from "@chakra-ui/react";
import { ReactNode, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { returnByTheme } from "../../utils/util";

type ButtonProps = {
  color?: string;
  children?: ReactNode;
  ghost?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disable?: boolean;
  isLoading?: boolean;
};
const StyledButton = ({
  color = returnByTheme(),
  children,
  ghost = false,
  onClick,
  disable = false,
  isLoading,
}: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      w="320px"
      h="100px"
      bgColor={ghost ? "#0000" : color}
      borderColor={ghost ? "WT" : "#0000"}
      borderWidth={ghost ? "3px" : "0px"}
      color="WT"
      borderRadius="50px"
      fontSize="25px"
      isLoading={isLoading}
      loadingText={t("TXT_Loading")}
      spinnerPlacement="end"
      fontWeight="normal"
      shadow={ghost ? "none" : "lg"}
      onClick={(evt) => (onClick ? onClick(evt) : {})}
      _hover={ghost ? { backgroundColor: "#0000" } : {}}
      _active={ghost ? { backgroundColor: "#0000" } : {}}
      disabled={disable || isLoading}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
