import { Box, Input, Text, Flex } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import TextPreviewRow from "../../../components/createProjectForm/TextPreviewRow";

import { ReactComponent as EditIcon } from "src/icons/general/editIcon.svg";
import { returnByTheme, returnByColor } from "../../../utils/util";
import { IClientDetailData } from "../../../type/IBaseInfotInterface";
import { useTranslation } from "react-i18next";

interface IClientProps {
  clientData: IClientDetailData;
  editClientDetailAction: () => void;
}

const ClientDetailPreview = ({
  clientData,
  editClientDetailAction,
}: IClientProps) => {
  // console.log("client detail section preview");
  // console.log(clientData);
  const { t } = useTranslation();
  const {
    // name, phone,
    email,
  } = clientData;

  return (
    <Box mb="30px">
      <SectionTitle
        title={t("TXT_Project_BItitle_client_detail")}
        editAction={() => editClientDetailAction()}
      />
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        px="20px"
        flexDirection={"column"}
      >
        {/* <TextPreviewRow label="Name" value={name} hadBorder={false} />
        <TextPreviewRow label="Phone" value={phone} /> */}
        <TextPreviewRow
          label={t("TXT_Email")}
          value={email}
          hadBorder={false}
        />
      </Flex>
    </Box>
  );
};

const SectionTitle = ({
  title,
  editAction,
}: {
  title: string;
  editAction: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb="5px">
      <Flex fontSize={18} fontWeight={"bold"}>
        {title}
      </Flex>
      <Flex
        cursor={"pointer"}
        borderRadius="4px"
        borderWidth="1px"
        borderColor={returnByTheme()}
        bg="WT"
        color={returnByTheme()}
        alignItems={"center"}
        px="20px"
        py="2px"
        onClick={() => editAction()}
      >
        <EditIcon stroke={returnByColor()} />
        <Box ml="5px">{t("TXT_Edit")}</Box>
      </Flex>
    </Flex>
  );
};

export default ClientDetailPreview;
