import React, { createRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  Checkbox,
  Flex,
  Tag,
  Text,
  useOutsideClick,
  useToast,
  Icon,
  Image,
  Spinner,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { FiMoreHorizontal, FiDownload, FiTrash, FiSend } from "react-icons/fi";
import { useRef, useState, useEffect } from "react";
import moment from "moment";
import {
  CompanyDocument,
  PaginateInvoiceArgsSort,
  RemoveInvoiceDocument,
  SendInvoiceDocument,
} from "src/graphql/generated";
import { ReactComponent as PreviewReciptIcon } from "src/icons/general/previewRecipt.svg";
import { ReactComponent as DownloadBillIcon } from "src/icons/general/downloadBill.svg";
import { ReactComponent as BackTopBtn } from "src/icons/general/arrow-up-circle.svg";
import { ReactComponent as WhiteCloseBtn } from "src/icons/general/whiteCloseBtn.svg";
import { ReactComponent as SendInvoiceIcon } from "src/icons/general/sendInvoiceIcon.svg";
import { ReactComponent as EditInvoiceIcon } from "src/icons/general/editInvoiceIcon.svg";
import { ReactComponent as NoResultIcon } from "src/icons/general/noResultIcon.svg";
import { ChevronUpIcon, ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { ReactComponent as SuccessIcon } from "../../icons/general/success.svg";

import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { returnByTheme } from "../../utils/util";
import { IconContext } from "react-icons";
import jwt_decode from "jwt-decode";
import PopUpModal from "src/components/PopUpModal";

const Status = {
  Paid: "#09A34E",
  SENT: "#459EE3",
  OVERDUE: "#F4891E",
  DRAFT: "#EACD29",
  COMPLETED: "#0F255E",
  UNPAID: "#E71B2B",
};
enum Invoice_Status {
  DRAFT,
  SENT,
  PAID,
  COMPLETED,
  OVERDUE,
  UNRECOGNIZED,
}

type InvoicesProps = {
  nodes: [
    {
      id: string;
      code: string;
      site: {
        id: string;
        name: string;
        profitShares: {
          landOwnerGain: number;
          isPercentage: boolean;
        };
      };
      fitAmount: number;
      startTime: Date;
      endTime: Date;
      receivableCharges: [
        {
          name: string;
          amount: number;
        }
      ];
      payableCharges: [
        {
          name: string;
          amount: number;
        }
      ];
      total: number;
      dueAt: Date;
      createdAt: Date;
      sentAt: Date;
      paidAt: Date;
      completedAt: Date;
      status: Invoice_Status;
      clientInformation: string;
    }
  ];
};

const InvoiceTable = ({
  invoicesInfos,
  isLoading,
  reFetch,
  queryMore,
  handleSorting,
  updateCheckedState,
  prevSelectedOpt,
  prevPayload,
  prevKeyword,
  prevDateRange,
}: any) => {
  const navigate = useNavigate();
  const isClient = localStorage.getItem("sf_Role") == "true";
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [isInvoiceAsc, setIsInvoiceAsc] = useState(true);
  const [isDueAsc, setIsDueAsc] = useState(true);
  const [isIssueAsc, setIsIssueAsc] = useState(true);
  const [openMoreAction, setOpenMoreAction] = useState({
    isOpened: false,
    currentRow: -1,
  });
  const [openPreviewReceipt, setOpenPreviewReceipt] = useState({
    isOpened: false,
    currentRow: -1,
  });

  const tableRef = useRef<HTMLTableElement>(null);
  const toast = useToast();
  const { t } = useTranslation();

  const onChange = (x: any) => {
    if (!checkedItems.includes(x)) {
      setCheckedItems((cur) => [...cur, x]);
    } else {
      setCheckedItems((cur) => cur.filter((y) => y !== x));
    }
  };

  const nonSelectableList = invoicesInfos?.filter(
    (x: any) => x.status !== "DRAFT" && !x.customTemplateCode
  );

  const allChecked =
    checkedItems?.length > 0 &&
    checkedItems?.length === nonSelectableList?.length;

  const onChangeAll = () => {
    if (allChecked) {
      setCheckedItems([]);
    } else {
      let tempList: any[] = [];

      nonSelectableList.map((x: any) => tempList.push(x));
      setCheckedItems(tempList);
    }
  };

  const backToTop = () => {
    tableRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    updateCheckedState(checkedItems);
    return () => {};
  }, [checkedItems]);

  // const defaultSortList = []
  //   .concat(invoicesInfos)
  //   ?.sort((a: any, b: any) => (a.startTime > b.startTime ? 1 : -1));

  return (
    <TableContainer
      flex={1}
      m="15px"
      maxHeight="70vh"
      // mb="20px"
      // height="600px"
      overflowY={"auto"}
      ref={tableRef}
      onScroll={() => {
        // queryMore();
        if (tableRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = tableRef.current;

          if (scrollTop + clientHeight + 5 > scrollHeight && !isLoading) {
            queryMore();
          }
        }
      }}
    >
      {(invoicesInfos?.length > 0 && (
        <>
          <Table
            variant="simple"
            borderTopRadius="20px"
            boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
            size="sm"
            backgroundColor={"WT"}
          >
            <Thead position="sticky" top="0px" bg="WT" zIndex="5">
              <Tr height="40px">
                <Th position="sticky" top="0px" bg="WT" zIndex="5">
                  <Checkbox
                    disabled={nonSelectableList.length == 0}
                    colorScheme="blue"
                    borderColor={returnByTheme()}
                    onChange={onChangeAll}
                    isChecked={allChecked}
                  />
                </Th>
                <Th
                  position="sticky"
                  top="0px"
                  bg="WT"
                  zIndex="5"
                  alignItems={"center"}
                  onClick={() => {
                    setIsInvoiceAsc(!isInvoiceAsc);
                    setIsIssueAsc(true);
                    setIsDueAsc(true);
                    isInvoiceAsc
                      ? handleSorting(PaginateInvoiceArgsSort.InvoiceCodeDes)
                      : handleSorting(PaginateInvoiceArgsSort.InvoiceCodeAsc);
                  }}
                >
                  <Flex
                    alignItems={"center"}
                    cursor="pointer"
                    position="sticky"
                    top="0px"
                    bg="WT"
                    zIndex="5"
                  >
                    {t("TXT_Invoice_Invoice_ID")}
                    {isInvoiceAsc ? (
                      <ChevronDownIcon
                        width="20px"
                        height="20px"
                        // color={isInvoiceAsc ? "themeOrange" : "BK"}
                      />
                    ) : (
                      <ChevronUpIcon
                        width="20px"
                        height="20px"
                        // color={!isInvoiceAsc ? "themeOrange" : "BK"}
                      />
                    )}
                  </Flex>
                </Th>
                <Th position="sticky" top="0px" bg="WT" zIndex="5">
                  {isClient
                    ? t("TXT_Invoice_Contractor")
                    : t("TXT_Invoice_Client")}
                </Th>
                <Th position="sticky" top="0px" bg="WT" zIndex="5">
                  {t("TXT_Invoice_Contact")}
                </Th>
                <Th
                  position="sticky"
                  top="0px"
                  bg="WT"
                  zIndex="5"
                  onClick={() => {
                    setIsInvoiceAsc(true);
                    setIsIssueAsc(true);
                    setIsDueAsc(!isDueAsc);
                    isDueAsc
                      ? handleSorting(PaginateInvoiceArgsSort.DueDateOldest)
                      : handleSorting(PaginateInvoiceArgsSort.DueDateLatest);
                  }}
                >
                  <Flex alignItems={"center"} cursor="pointer">
                    {t("TXT_Invoice_Due_date")}
                    {isDueAsc ? (
                      <ChevronDownIcon
                        width="20px"
                        height="20px"
                        // color={isDueAsc ? "themeOrange" : "BK"}
                      />
                    ) : (
                      <ChevronUpIcon
                        width="20px"
                        height="20px"
                        // color={!isDueAsc ? "themeOrange" : "BK"}
                      />
                    )}
                  </Flex>
                </Th>
                <Th
                  position="sticky"
                  top="0px"
                  bg="WT"
                  zIndex="5"
                  onClick={() => {
                    setIsInvoiceAsc(true);
                    setIsDueAsc(true);
                    setIsIssueAsc(!isIssueAsc);
                    isIssueAsc
                      ? handleSorting(PaginateInvoiceArgsSort.Oldest)
                      : handleSorting(PaginateInvoiceArgsSort.Latest);
                  }}
                >
                  <Flex alignItems={"center"} cursor="pointer">
                    {t("TXT_Invoice_Issue_date")}
                    {isIssueAsc ? (
                      <ChevronDownIcon
                        width="20px"
                        height="20px"
                        // color={isIssueAsc ? "themeOrange" : "BK"}
                      />
                    ) : (
                      <ChevronUpIcon
                        width="20px"
                        height="20px"
                        // color={!isIssueAsc ? "themeOrange" : "BK"}
                      />
                    )}
                  </Flex>
                </Th>
                <Th position="sticky" top="0px" bg="WT" zIndex="5">
                  {t("TXT_Invoice_Type")}
                </Th>
                <Th position="sticky" top="0px" bg="WT" zIndex="5">
                  {t("TXT_Invoice_Amount")}
                </Th>
                <Th position="sticky" top="0px" bg="WT" zIndex="5">
                  {t("TXT_Invoice_Status")}
                </Th>
                <Th
                  position="sticky"
                  top="0px"
                  bg="WT"
                  zIndex="5"
                  textAlign="center"
                >
                  {t("TXT_Receipt")}
                </Th>
                <Th
                  position="sticky"
                  top="0px"
                  bg="WT"
                  zIndex="5"
                  textAlign="center"
                >
                  {t("TXT_Invoice_Bill")}
                </Th>
                {/* <Th alignItems="center">
                  <Flex alignItems="center" justifyContent="center">
                    {t("TXT_Receipt")}
                  </Flex>
                </Th>
                <Th alignItems="center">
                  <Flex alignItems="center" justifyContent="center">
                    {t("TXT_Invoice_Bill")}
                  </Flex>
                </Th> */}
                <Th
                  position="sticky"
                  top="0px"
                  bg="WT"
                  zIndex="5"
                  alignItems="center"
                >
                  <Flex alignItems="center" justifyContent="center">
                    {t("TXT_Invoice_Actions")}
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {invoicesInfos?.map((data: any, i: number) => {
                // {invoicesInfos?.invoices?.nodes?.map((data: any) => {
                const isChecked = checkedItems.includes(data);
                const invoiceType =
                  data?.total >= 0
                    ? t("TXT_Option_Credit")
                    : t("TXT_Option_Debit");
                const clientInfos = data?.clientInformation?.split(/\r?\n/);

                const amountDisplayText =
                  data?.total >= 0
                    ? `HK$ ${data?.total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : `-HK$ ${Math.abs(data?.total).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`;

                return (
                  <Tr key={data.id}>
                    <Td
                      zIndex={1}
                      // onClick={(e) => {
                      //   e.stopPropagation();
                      // }}
                    >
                      <Checkbox
                        disabled={data.status == "DRAFT"}
                        onChange={(e) => {
                          onChange(data);
                        }}
                        colorScheme="blue"
                        borderColor={returnByTheme()}
                        isChecked={isChecked}
                      />
                    </Td>
                    <Td
                      onClick={() => {
                        navigate(`/invoice/detail/${data.id}`, {
                          state: {
                            infos: data,
                            prevSelectedOpt: prevSelectedOpt,
                            prevPayload: prevPayload,
                            prevKeyword: prevKeyword,
                            prevDateRange: prevDateRange,
                          },
                        });
                      }}
                    >
                      <Box
                        _hover={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: returnByTheme(),
                        }}
                      >
                        {data.code}
                      </Box>
                    </Td>
                    <Td>
                      {isClient
                        ? data?.site.contractor.name
                        : clientInfos
                        ? clientInfos?.[0]
                        : "-"}
                    </Td>
                    <Td>
                      {isClient
                        ? data?.site?.contractor?.phone?.phone
                        : clientInfos
                        ? clientInfos[clientInfos?.length - 1]
                        : "-"}
                    </Td>
                    <Td>{moment(data?.dueAt).format("D MMM, YYYY")}</Td>
                    <Td>{moment(data?.createdAt).format("D MMM, YYYY")}</Td>
                    {/* <Td>{moment(data?.startTime).format("D MMM,YYYY")}</Td> */}
                    {/* for default checking sorting*/}
                    <Td
                      textColor={invoiceType === "Debit" ? "RD.3" : "#1A9990"}
                    >
                      {invoiceType} {t("TXT_Note")}
                    </Td>
                    <Td>{amountDisplayText}</Td>
                    <Td>
                      <Tag
                        backgroundColor={
                          Status[data.status as keyof typeof Status]
                        }
                        borderRadius="25px"
                        textColor="#FFF"
                        px="10px"
                        py="5px"
                      >
                        {data.status}
                      </Tag>
                    </Td>
                    <Td position="relative">
                      <Flex
                        cursor={data.receipt?.url ? "pointer" : "default"}
                        justifyContent="center"
                        // _hover={{ bg: "DISABLEDTEXT" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!data.receipt?.url) {
                            return;
                          }
                          setOpenPreviewReceipt({
                            isOpened: true,
                            currentRow: i,
                          });
                        }}
                        zIndex={1}
                      >
                        <PreviewReciptIcon
                          stroke={data.receipt?.url ? "#8E8E8E" : "#F0F0F0"}
                        />
                      </Flex>
                      {openPreviewReceipt?.isOpened === true &&
                        openPreviewReceipt?.currentRow === i && (
                          <PreviewReceiptModal
                            receiptData={data}
                            closePreview={() =>
                              setOpenPreviewReceipt({
                                isOpened: false,
                                currentRow: -1,
                              })
                            }
                          />
                        )}
                    </Td>
                    <Td>
                      <Flex
                        cursor="pointer"
                        justifyContent="center"
                        // _hover={{ bg: "DISABLEDTEXT" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.location = data?.bill.file.url;
                        }}
                        zIndex={1}
                      >
                        <DownloadBillIcon />
                      </Flex>
                    </Td>
                    <Td alignItems="center" justifyContent="center">
                      <Flex justifyContent="center" position={"relative"}>
                        <Button
                          backgroundColor="#0000"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenMoreAction({
                              isOpened:
                                openMoreAction?.currentRow === i
                                  ? !openMoreAction?.isOpened
                                  : true,
                              currentRow: i,
                            });
                          }}
                          zIndex={1}
                        >
                          <FiMoreHorizontal />
                        </Button>

                        {openMoreAction?.isOpened === true &&
                          openMoreAction?.currentRow === i && (
                            <MoreActionModal
                              invoiceData={data}
                              isClient={isClient}
                              closeMenu={() =>
                                setOpenMoreAction({
                                  isOpened: false,
                                  currentRow: -1,
                                })
                              }
                              reFetch={() => reFetch()}
                              prevSelectedOpt={prevSelectedOpt}
                              prevPayload={prevPayload}
                              prevKeyword={prevKeyword}
                              prevDateRange={prevDateRange}
                              navigate={navigate}
                            />
                          )}
                      </Flex>
                    </Td>
                    {/* <LinkOverlay href={`/invoice/detail/${data.id}`}/> */}
                  </Tr>
                );
              })}

              <Tr
                flex={1}
                backgroundColor={"WT"}
                pt={3}
                pb={10}
                justifyContent={"center"}
                // boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
              >
                <Td textAlign={"center"} colSpan={11}>
                  {(isLoading && (
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color={isClient ? "themeOrange" : "blue.500"}
                      size="sm"
                    />
                  )) || (
                    <>
                      {t("TXT_Invoice_All")} {invoicesInfos?.length}{" "}
                      {t("TXT_Results")}
                    </>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Flex
            position={"absolute"}
            bottom="50px"
            right="0"
            pr="20px"
            pl="5px"
            bg={returnByTheme()}
            py="5px"
            alignItems={"center"}
            borderTopLeftRadius="25px"
            borderBottomLeftRadius="25px"
            onClick={() => backToTop()}
            zIndex="5"
            _hover={{ cursor: "pointer" }}
          >
            <BackTopBtn width="25px" height="25px" />
            <Text color={"WT"} ml="10px">
              {t("TXT_Back_to_top")}
            </Text>
          </Flex>
        </>
      )) || (
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          borderTopRadius="20px"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          backgroundColor={"WT"}
          flexDirection={"column"}
          pt={3}
          pb={10}
        >
          <NoResultIcon stroke={returnByTheme()} />
          <Text color={returnByTheme()}>
            {t("TXT_Invoice_No_Invoice_found")}
          </Text>
          <Text fontWeight={"bold"}>{t("TXT_Invoice_No_Result_Desc")}</Text>
        </Flex>
      )}
    </TableContainer>
  );
};

const PreviewReceiptModal = (props: any) => {
  const [showReceiptModal, setShowReceiptModal] = useState(false);

  const previewReceipt = useRef<any>(null);
  const { t } = useTranslation();

  useOutsideClick({
    ref: previewReceipt,
    handler: () => props?.closePreview(),
  });

  return (
    <Flex
      ref={previewReceipt}
      // display={props?.isVisible ? "flex" : "none"}
      backgroundColor={"WT"}
      position="absolute"
      right="30px"
      // top="25px"
      flexDirection={"column"}
      zIndex={30}
      borderWidth={1}
      borderColor="GREY"
      borderTopRadius={12}
      minW="240px"
      overflow="hidden"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex flexDirection={"column"}>
        <Flex
          bg={returnByTheme()}
          p="10px"
          color="WT"
          justifyContent="space-between"
        >
          {t("TXT_Receipt")}
          <Icon
            as={AiOutlineClose}
            width="20px"
            height="20px"
            cursor="pointer"
            onClick={() => props.closePreview()}
          />
        </Flex>
        <Flex bg="WT" pt="20px" px="20px" borderBottomColor="#E0E0E0">
          {t("TXT_Invoice_Receipt_preview_desc")}
        </Flex>
        <Flex
          p="20px"
          onClick={() => {
            setShowReceiptModal(true);
          }}
          cursor="pointer"
          color="themeBlue"
          alignItems="center"
        >
          <Image
            src={require("../../icons/general/receiptLogo.png")}
            width="20px"
            height="20px"
            mr="10px"
          />
          <Modal
            isOpen={showReceiptModal}
            onClose={() => setShowReceiptModal(false)}
            isCentered
          >
            <ModalOverlay />
            <ModalContent borderTopRadius="18px">
              <ModalHeader
                borderTopRadius="15px"
                backgroundColor={returnByTheme()}
              >
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text color="WT">{t("TXT_Receipt")}</Text>
                  <Flex
                    onClick={() => setShowReceiptModal(false)}
                    cursor="pointer"
                  >
                    <WhiteCloseBtn width="25px" height="25px" />
                  </Flex>
                </Flex>
              </ModalHeader>
              <ModalBody pb={10}>
                <Image src={props?.receiptData.receipt.url} />
              </ModalBody>
            </ModalContent>
          </Modal>
          {props?.receiptData.receipt.originalName}
        </Flex>
      </Flex>
    </Flex>
  );
};

const MoreActionModal = (props: any) => {
  const token = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token!);
  const companyId = decodedToken.company?.id;

  const [isShowSendModal, setIsShowSendModal] = useState(false);
  const [senderEmail, setSenderEmail] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(``);

  const moreActionRef = useRef<any>(null);
  const { t } = useTranslation();
  const toast = useToast();

  const invoiceTitle =
    props.invoiceData?.total > 0
      ? t("TXT_Option_Credit") + " " + t("TXT_Note")
      : t("TXT_Option_Debit") + " " + t("TXT_Note");

  const { data: companyData } = useQuery(CompanyDocument, {
    variables: {
      id: companyId,
    },
    onCompleted: (data) => {
      const company = data.company;
      setMessage(`Dear customer:  
    
    Your invoice has been ready.If you have any question, 
    
    please call our customer service hotline at (852) ${company?.phone?.phone} (Hong Kong).`);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const sendPayload = {
    variables: {
      input: {
        id: props.invoiceData.id,
        receiverEmail: receiverEmail
          ? receiverEmail
          : props.invoiceData.site?.landOwner?.email,
        subject: `${invoiceTitle} #${moment(props.invoiceData.startTime).format(
          "DD-MMM-YYYY"
        )}`,
        content: message,
      },
    },
  };

  const [sendInvoice, { data: sendInvoiceStatus, loading: sendingInvoice }] =
    useMutation(SendInvoiceDocument, {
      onCompleted: (x) => {
        if (x) {
          return toast({
            position: "bottom-right",
            title: `Invoice sent successfully`,
            status: "success",
          });
        }
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });

  const [removeCustomInvoice, { data: rmStatus, loading: removingInvoice }] =
    useMutation(RemoveInvoiceDocument, {
      onCompleted: (x) => {
        props.reFetch();
        props?.closeMenu();
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });

  useOutsideClick({
    ref: moreActionRef,
    handler: (e) => {
      props?.closeMenu();
    },
  });

  return (
    <Flex
      ref={moreActionRef}
      // display={props?.isVisible ? "flex" : "none"}
      backgroundColor={"WT"}
      position="absolute"
      right={0}
      top={10}
      flexDirection={"column"}
      zIndex={30}
      borderWidth={1}
      borderColor="GREY"
      borderRadius={10}
      overflow="hidden"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex flexDirection={"column"}>
        {!props.isClient && (
          <Flex alignItems={"center"} py="5px">
            <Button
              backgroundColor="WT"
              flex={1}
              justifyContent={"flex-start"}
              onClick={(e) => {
                if (props.invoiceData.site?.landOwner) {
                  sendInvoice(sendPayload);
                } else {
                  setIsShowSendModal(true);
                }
              }}
            >
              <SendInvoiceIcon />
              <Text color={"GREY"} marginLeft="5px">
                {t("TXT_Invoice_Send_invoice")}
              </Text>
            </Button>
          </Flex>
        )}

        {!props.isClient && props.invoiceData.customTemplateCode && (
          <Flex alignItems={"center"} py="5px">
            <Button
              backgroundColor="WT"
              flex={1}
              justifyContent={"flex-start"}
              onClick={(e) => {
                removeCustomInvoice({
                  variables: {
                    input: props.invoiceData.id,
                  },
                });
              }}
            >
              <Icon as={FiTrash} width="25px" height="25px" color="#8E8E8E" />
              <Text color={"GREY"} marginLeft="5px">
                {t("TXT_Invoice_delete_invoice")}
              </Text>
            </Button>
          </Flex>
        )}

        {!props.isClient && (
          <Flex alignItems={"center"}>
            <Button
              backgroundColor="WT"
              flex={1}
              justifyContent={"flex-start"}
              onClick={() => {
                props?.navigate(
                  `/invoice/detail/edit/${props?.invoiceData?.id}`,
                  {
                    state: {
                      infos: props?.invoiceData,
                      prevSelectedOpt: props?.prevSelectedOpt,
                      prevPayload: props?.prevPayload,
                      prevKeyword: props?.prevKeyword,
                      prevDateRange: props?.prevDateRange,
                    },
                  }
                );
              }}
            >
              <EditInvoiceIcon />
              <Text color={"GREY"} marginLeft="5px">
                {t("TXT_Invoice_Edit_invoice")}
              </Text>
            </Button>
          </Flex>
        )}
        <Flex alignItems={"center"}>
          <Button
            backgroundColor="WT"
            flex={1}
            justifyContent={"flex-start"}
            onClick={() => {
              window.location = props?.invoiceData.file.url;
            }}
          >
            <DownloadIcon width="25px" height="25px" color="GREY" />
            <Text color={"GREY"} marginLeft="5px">
              {t("TXT_Invoice_Download_invoice")}
            </Text>
          </Button>
        </Flex>
      </Flex>

      <PopUpModal
        isShow={isShowSendModal}
        onClose={() => setIsShowSendModal(false)}
        headerColor={returnByTheme()}
        headerText="Send invoice"
        closeOnOverlayClick={false}
      >
        <Flex flexDirection="column" ref={moreActionRef}>
          <Text fontSize="15px" color={returnByTheme()}>
            {t("TXT_From")}
          </Text>
          <Input
            disabled
            value={companyData?.company?.email}
            flex={3}
            color={returnByTheme()}
            bg={"#D9DDE0"}
            onChange={(e) => setSenderEmail(e.target.value)}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            padding="5px"
          />
          <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
            {t("TXT_Send")} {t("TXT_To")}
          </Text>
          <Input
            flex={3}
            value={receiverEmail}
            onChange={(e) => setReceiverEmail(e.target.value)}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            padding="5px"
          />
          <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
            {t("TXT_Subject")}
          </Text>
          <Input
            flex={3}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            padding="5px"
          />
          <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
            {t("TXT_Message")}
          </Text>
          <Textarea
            flex={3}
            value={message}
            size="sm"
            onChange={(e) => setMessage(e.target.value)}
            minHeight="150px"
            borderRadius="10px"
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
          />
          <Button
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            disabled={sendingInvoice}
            onClick={() => {
              sendInvoice(sendPayload);
            }}
            marginTop="20px"
            borderRadius="40px"
          >
            <IconContext.Provider value={{ color: "#FFF", size: "24px" }}>
              <FiSend />
              <Text paddingLeft="10px" color="WT">
                {t("TXT_Send")}
              </Text>
            </IconContext.Provider>
          </Button>
        </Flex>
      </PopUpModal>
    </Flex>
  );
};

export default InvoiceTable;
