import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store";
import storage from "src/utils/storage";

interface State {
  userId?: string;
  token?: string;
  fun: string;
}

const initialState: State = {
  fun: "ignore me",
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    init: (state) => {
      state = initialState;
    },
    setUserId: (
      state,
      { payload: userId }: PayloadAction<string | undefined>
    ) => {
      state.userId = userId;
    },
    setToken: (
      state,
      { payload: token }: PayloadAction<string | undefined>
    ) => {
      state.token = token;
    },

    justForTest: (state, action) => {
      state.fun = action.payload;
    },
  },
});

export const actions = slice.actions;

// TODO: Further auth control like login,usertype,token handling,etc.
export const useStore = () => {
  const state = useSelector((rs: RootState) => rs.AppStore);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const setUserId = (userId?: string) => dispatch(actions.setUserId(userId));
  const setToken = (token?: string) => dispatch(actions.setToken(token));

  const justForTest = (langCode: string) =>
    dispatch(actions.justForTest(langCode));

  const logout = () => {
    storage.clearToken();
    // storage.clearUserRole();
    dispatch(actions.setUserId(undefined));
    dispatch(actions.setToken(undefined));
    localStorage.removeItem("token");
    localStorage.removeItem("sf_Role");
    localStorage.removeItem("userId");
    localStorage.removeItem("meData");
    localStorage.removeItem("sf_Investor");
    navigate("/login", { replace: true });
  };

  const init = () => dispatch(actions.init());

  return { ...state, setUserId, init, logout, setToken, justForTest };
};

export default slice.reducer;
