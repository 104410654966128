import { Flex, keyframes, Icon, Divider } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import React from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import { BsChevronDoubleDown, BsChevronDoubleUp } from "react-icons/bs";
import { RiFileDownloadLine } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CalculatorHistory = ({ historyList }: { historyList: Array<any> }) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        width={"100%"}
        flexDirection={"column"}
        mb="10px"
        p="20px"
        fontSize={18}
        fontWeight={"bold"}
      >
        {t("TXT_Calculator_calc_history")}
      </Flex>
      {historyList.map((h, i) => {
        return <ExpandSection key={i} />;
      })}
    </>
  );
};

const ExpandSection = () => {
  const [isExpand, setIsExpand] = useState(false);
  const { t } = useTranslation();

  const expandAnimationKeyframes = keyframes`
  from {opacity:0;}
  to {opacity:1;}
`;

  const animation = `${expandAnimationKeyframes} 0.5s ease-in-out `;
  return (
    <Flex
      backgroundColor={"WT"}
      borderRadius={"5px"}
      boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
      width={"100%"}
      flexDirection={"column"}
      py="10px"
      mb="10px"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb="10px"
        px="20px"
      >
        <Flex alignItems="center">
          <Flex color="GREY">{t("TXT_Create_date")}:</Flex>
          {moment(new Date()).format("DD MMM, YYYY")} by &nbsp;
          <Flex color="#459EE3">xxx</Flex>
        </Flex>
        <Flex
          alignItems="center"
          bg="#00A5B3"
          borderRadius="15px"
          py="5px"
          px="10px"
          color="WT"
          gap="10px"
          fontWeight="600"
        >
          <FaCheck /> {t("TXT_Calculator_current_version")}
        </Flex>
      </Flex>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
        px="20px"
      >
        <Flex
          alignItems="center"
          bg="DISABLEDBG"
          borderRadius="4px"
          py="5px"
          px="10px"
          color="#459EE3"
          border="1px solid #459EE3"
          gap="10px"
          cursor="pointer"
          onClick={() => setIsExpand(!isExpand)}
        >
          {t("TXT_Details")}
          {(isExpand && <BsChevronDoubleUp />) || <BsChevronDoubleDown />}
        </Flex>
        <Flex alignItems="center" gap="10px">
          <Flex
            alignItems="center"
            gap="5px"
            color="GREY"
            cursor="pointer"
            // _hover={{ textDecoration: "underline" }}
          >
            <Icon as={RiFileDownloadLine} width="25px" height="25px" />
            {t("TXT_Calculator_download_report")}
          </Flex>
          <Flex
            alignItems="center"
            color="RD.3"
            gap="5px"
            cursor="pointer"
            // _hover={{ textDecoration: "underline" }}
          >
            <Icon as={AiOutlineCloseCircle} width="25px" height="25px" />
            {t("TXT_Remove")}
          </Flex>
        </Flex>
      </Flex>

      {/* {isExpand && ( */}
      <Flex
        flexDir="column"
        display={isExpand ? "flex" : "none"}
        animation={animation}
      >
        <Divider mx="20px" width="calc(100% - 40px)" />
        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Sharing_ratio")}
          </Flex>
          <Flex flex={3}>
            {t("TXT_Project_Client_ratio")}: 10% +{" "}
            {t("TXT_Project_Investor_ratio")}: 90% = 100%
          </Flex>
        </Flex>
        <Divider mx="20px" width="calc(100% - 40px)" />

        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Calculator_total_revenue_after_share")}
          </Flex>
          <Flex flex={3}>HK$1234</Flex>
        </Flex>
        <Divider mx="20px" width="calc(100% - 40px)" />

        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Calculator_irr")}
          </Flex>
          <Flex flex={3}>10.48%</Flex>
        </Flex>
        <Divider mx="20px" width="calc(100% - 40px)" />
        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Calculator_ori")}
          </Flex>
          <Flex flex={3}>72.65%</Flex>
        </Flex>
        <Divider mx="20px" width="calc(100% - 40px)" />

        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Calculator_payback_period")}
          </Flex>
          <Flex flex={3}>{` 6 /year(s)`}</Flex>
        </Flex>
        <Divider mx="20px" width="calc(100% - 40px)" />

        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Calculator_total_cost")}
          </Flex>
          <Flex flex={3}>HK$ 342,375</Flex>
        </Flex>

        <Flex bg="DISABLEDBG" fontWeight="bold" p="20px">
          {t("TXT_Calculator_estimate_electricity_data")}
        </Flex>
        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Calculator_total_capacity")}
          </Flex>
          <Flex flex={3}>{`22,825 Watt(s)`}</Flex>
        </Flex>
        <Divider mx="20px" width="calc(100% - 40px)" />

        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Calculator_pv_capacity")}
          </Flex>
          <Flex flex={3}>22.83 kWp</Flex>
        </Flex>
        <Divider mx="20px" />

        <Flex p="20px">
          <Flex flex={2} color="GREY">
            {t("TXT_Calculator_first_year_watt_multiplier")}
          </Flex>
          <Flex flex={3}>1.16kWh</Flex>
        </Flex>
      </Flex>
      {/* )} */}
    </Flex>
  );
};

export default CalculatorHistory;
