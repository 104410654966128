import _, { size } from "lodash";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Progress,
  Spinner,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import {
  BrightSunshinesDocument,
  SiteStatus,
  SunshinesDocument,
} from "../../graphql/generated";
import { useTranslation } from "react-i18next";
import { returnByTheme } from "src/utils/util";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { IoIosArrowDown } from "react-icons/io";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import BrightSunShineBarChart from "./BrightSunShineBarChart";

const SunsetAndSunshineRow = () => {
  const [searchSunDate, setSearchSunDate] = useState(new Date());
  const [searchBrightSunDate, setSearchBrightSunDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());

  const [sunDataList, setSunDataList] = useState<any>();
  const [brightSunDataList, setBrightSunDataList] = useState<any>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const [getSunData, { loading: sunLoading }] = useLazyQuery(
    SunshinesDocument,

    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setSunDataList(data.sunshines);
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: "Fail to get sunshine data",
          status: "error",
        });
      },
    }
  );

  const [getBrightSunData, { loading: brightSunLoading }] = useLazyQuery(
    BrightSunshinesDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setBrightSunDataList(data.brightSunshines.nodes);
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: "Fail to get brightSunshine data",
          status: "error",
        });
      },
    }
  );

  useEffect(() => {
    getSunData({
      variables: {
        filter: {
          startDate: moment(searchSunDate).format("YYYY-M"),
          endDate: moment(searchSunDate).add(1, "month").format("YYYY-M"),
        },
        today: true,
        limit: 40,
      },
    });

    getBrightSunData({
      variables: {
        filter: {
          // startDate: "2022-11",
          // endDate: "2022-12",
          startDate: moment(searchBrightSunDate).format("YYYY-M"),
          endDate: moment(searchBrightSunDate).add(1, "month").format("YYYY-M"),
        },
        today: true,
        limit: 40,
      },
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (brightSunDataList?.[0]?.brightSunshine == null) {
      getBrightSunData({
        variables: {
          filter: {
            // startDate: "2022-11",
            // endDate: "2022-12",
            startDate: moment(searchBrightSunDate)
              .subtract(1, "month")
              .format("YYYY-M"),
            endDate: moment(searchBrightSunDate).format("YYYY-M"),
          },
          today: true,
          limit: 40,
        },
      });

      const d = new Date(searchBrightSunDate);
      const pastMonth = d.setMonth(d.getMonth() - 1);
      setSearchBrightSunDate(new Date(pastMonth));
      setMaxDate(new Date(pastMonth));
    }
    return () => {};
  }, [brightSunDataList]);

  return (
    <Flex gap="20px">
      <Flex
        flex={1}
        py="10px"
        px="20px"
        borderRadius="8px"
        boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
        bg="WT"
        flexDir="column"
        overflow="hidden"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          mb="20px"
        >
          <Flex fontWeight="bold">{t("TXT_Dashboard_sunset_title")}</Flex>
          <Flex>
            <DatePicker
              selected={searchSunDate}
              customInput={
                <Flex
                  alignItems="center"
                  gap="10px"
                  cursor="pointer"
                  color="#459ee3"
                  borderRadius="25px"
                  border="1px solid #459ee3"
                  py="5px"
                  px="15px"
                  onClick={(e) => e.preventDefault()}
                >
                  {moment(searchSunDate).format("MMM,YYYY")}
                  <IoIosArrowDown />
                </Flex>
              }
              onChange={(newDate: any) => {
                setSearchSunDate(newDate);
                getSunData({
                  variables: {
                    filter: {
                      startDate: moment(newDate).format("YYYY-M"),
                      endDate: moment(newDate).add(1, "month").format("YYYY-M"),
                    },
                    today: true,
                    limit: 40,
                  },
                });
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </Flex>
        </Flex>

        <Flex>
          {(sunLoading && (
            <Flex flex={1} justifyContent="center" py="20px">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color={"blue.500"}
                size="sm"
              />
            </Flex>
          )) || (
            <Flex gap="30px" overflow="hidden">
              {/* sticky column */}
              <Flex minW="80px">
                <Flex
                  flexDir="column"
                  color="#459ee3"
                  fontWeight="bold"
                  alignItems="center"
                  gap="15px"
                >
                  <Flex fontWeight="bold" pb="5px" borderBottom="1px solid">
                    {t("TXT_Project_Today")}
                  </Flex>
                  <Flex gap="10px" alignItems="center">
                    <Image
                      src={require("../../icons/weather/sunRiseIcon.png")}
                    />
                    {sunDataList?.today?.sunRise}
                  </Flex>
                  <Flex gap="10px" alignItems="center">
                    <Image
                      src={require("../../icons/weather/sunTranIcon.png")}
                    />
                    {sunDataList?.today?.sunTran}
                  </Flex>
                  <Flex gap="10px" alignItems="center">
                    <Image
                      src={require("../../icons/weather/sunSetIcon.png")}
                    />
                    {sunDataList?.today?.sunSet}
                  </Flex>
                </Flex>
              </Flex>
              {/* compare section*/}
              <Flex overflowX="scroll" gap="30px">
                {sunDataList?.nodes?.map((data: any, i: number) => {
                  return (
                    <Flex
                      flexDir="column"
                      key={i}
                      minW="80px"
                      alignItems="center"
                      gap="15px"
                    >
                      <Flex color="GREY" fontWeight="bold" pb="5px">
                        {moment(data.date).format("DD ddd")}
                      </Flex>
                      <Flex gap="10px" color="GREY" alignItems="center">
                        <Image
                          src={require("../../icons/weather/sunRiseIcon.png")}
                        />
                        {data.sunRise}
                      </Flex>
                      <Flex gap="10px" color="GREY" alignItems="center">
                        <Image
                          src={require("../../icons/weather/sunTranIcon.png")}
                        />
                        {data.sunTran}
                      </Flex>
                      <Flex gap="10px" color="GREY" alignItems="center">
                        <Image
                          src={require("../../icons/weather/sunSetIcon.png")}
                        />
                        {data.sunSet}
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex
        flex={1}
        py="10px"
        px="20px"
        borderRadius="8px"
        boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
        bg="WT"
        flexDir="column"
        overflow="hidden"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          mb="20px"
        >
          <Flex fontWeight="bold">{t("TXT_Dashboard_sunshine_title")}</Flex>
          <Flex>
            <DatePicker
              selected={searchBrightSunDate}
              maxDate={maxDate}
              customInput={
                <Flex
                  alignItems="center"
                  gap="10px"
                  cursor="pointer"
                  color="#459ee3"
                  borderRadius="25px"
                  border="1px solid #459ee3"
                  py="5px"
                  px="15px"
                  onClick={(e) => e.preventDefault()}
                >
                  {moment(searchBrightSunDate).format("MMM,YYYY")}
                  <IoIosArrowDown />
                </Flex>
              }
              onChange={(newDate: any) => {
                setSearchBrightSunDate(newDate);
                getBrightSunData({
                  variables: {
                    filter: {
                      startDate: moment(newDate).format("YYYY-M"),
                      endDate: moment(newDate).add(1, "month").format("YYYY-M"),
                    },
                    today: true,
                    limit: 40,
                  },
                });
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </Flex>
        </Flex>

        <Flex>
          {(brightSunLoading && (
            <Flex flex={1} justifyContent="center" py="20px">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color={"blue.500"}
                size="sm"
              />
            </Flex>
          )) || (
            <Flex gap="30px" overflow="scroll">
              <BrightSunShineBarChart brightSunShineData={brightSunDataList} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SunsetAndSunshineRow;
