import {
  Box,
  Input,
  Text,
  Flex,
  Button,
  Icon,
  useToast,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import FormAddressInput from "src/components/createProjectForm/FormAddressInput";
import FormFilesInput from "src/components/createProjectForm/FormFilesInput";

import { ReactComponent as UploadImageIcon } from "src/icons/general/uploadImageIcon.svg";
import { ReactComponent as UploadFilesIcon } from "src/icons/general/uploadFilesIcon.svg";

import { IPersonalInfoData } from "../../../type/IAccountManageInterface";
import { uploadDoc, returnByTheme } from "../../../utils/util";

import _ from "lodash";
import { GiAnticlockwiseRotation } from "react-icons/gi";
import EditModal from "src/pages/project/EditModal";
import FormPwInput from "src/components/createProjectForm/FormPwInput";
import { useMutation } from "@apollo/client";
import {
  ForgetPasswordDocument,
  ResetPasswordDocument,
  VerifyOtpDocument,
} from "src/graphql/generated";
import StyledButton from "src/pages/auth/StyledButton";

interface IPersonalInfoProps {
  personalInfoData: IPersonalInfoData;
  updatePersonalInfoState: (value: any) => void;
  userEmail: string;
  isOwner: boolean;
}

const GeneralDetailSection = ({
  personalInfoData,
  updatePersonalInfoState,
  userEmail,
  isOwner,
}: IPersonalInfoProps) => {
  const { t } = useTranslation();
  const [isShowResetPwModal, setIsShowResetPwModal] = useState(false);

  const getUploadDetail = async (key: string, value: any) => {
    const uploadedDoc = await uploadDoc(value);

    const newImageObj = value.map((f: any, i: number) => {
      return {
        // url: uploadedDoc.resut[i].url,
        // url: "https://solar-monster-dev-storage.s3.ap-southeast-1.amazonaws.com/sites/VfcqR540t0eS5SBDcuKFC/project_images/nyc.jpeg?AWSAccessKeyId=AKIAZXOZTAYNZKXDY7UB&Expires=1667897571&Signature=S4EQuMkM8qiZwb5UqdCzP5KwFp8%3D",
        url: uploadedDoc.result[i].url
          ? uploadedDoc.result[i].url
          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Tkm2I2eZS-dnuXspUUvm8WiB07J0XE8f4A&usqp=CAU",
        filePath: uploadedDoc.result[i].filePath,
        originalName: uploadedDoc.result[i].originalName,
      };
    });

    const finalImageObj =
      key == "photo"
        ? personalInfoData?.photo
          ? _.concat(personalInfoData?.photo, newImageObj)
          : newImageObj
        : key == "companyLogo"
        ? personalInfoData?.companyLogo
          ? _.concat(personalInfoData?.companyLogo, newImageObj)
          : newImageObj
        : personalInfoData?.companyChop
        ? _.concat(personalInfoData?.companyChop, newImageObj)
        : newImageObj;
    if (uploadedDoc.result) {
      getNewState(key, newImageObj);
    }
  };

  const getNewState = (key: string, value: any) => {
    updatePersonalInfoState({
      ...personalInfoData,
      [key]: value,
    });
  };

  return (
    <>
      {/*  */}
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        mb="20px"
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        // flex={1}
        flexDirection={"column"}
      >
        <SectionTitle title={t("TXT_Account_Personal_info")} />

        {/* profile photo section */}
        <FormFilesInput
          inputLabel={t("TXT_Account_Profile_photo")}
          limitedType=".jpg,.png"
          litmitedDesc={t("TXT_Desc_accept_jpg_and_png_only")}
          filesList={personalInfoData?.photo}
          allowMulti={false}
          onUploadFiles={(file: Array<File>) => {
            getUploadDetail("photo", file);
            // getNewState("projectImages", file);
          }}
          onDeleteFile={(index: number) => {
            updatePersonalInfoState({
              ...personalInfoData,
              photo: [],
            });
          }}
          uploadBtnIcon={<UploadImageIcon />}
          uploadBtnText={t("TXT_Desc_click_to_choose_image")}
        />

        {/* username */}
        <FormTextInput
          placeHolder={t("TXT_Username")}
          inputLabel={t("TXT_Username")}
          onChange={(value: string) => getNewState("userName", value)}
          inpputtedValue={personalInfoData?.userName}
        />

        {/* phone*/}
        <FormTextInput
          placeHolder={t("TXT_Phone")}
          inputLabel={t("TXT_Phone")}
          onChange={(value: string) => {
            getNewState("phone", value);
          }}
          maxLen={8}
          inpputtedValue={personalInfoData?.phone}
        />

        <FormTextInput
          placeHolder={t("TXT_Email")}
          inputLabel={t("TXT_Email")}
          onChange={(value: string) => {
            getNewState("email", value);
          }}
          isDisable
          inpputtedValue={personalInfoData?.email}
        />

        {/* <FormTextInput
          placeHolder={t("TXT_Company")}
          inputLabel={t("TXT_Company")}
          onChange={(value: string) => {
            getNewState("company", value);
          }}
          isDisable={isOwner ? false : true}
          inpputtedValue={personalInfoData?.company}
        /> */}

        <FormTextInput
          placeHolder={t("TXT_Website")}
          inputLabel={t("TXT_Website")}
          onChange={(value: string) => {
            getNewState("website", value);
          }}
          inpputtedValue={personalInfoData?.website}
        />
      </Flex>

      {/* security section */}
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        mb="20px"
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        // flex={1}
        flexDirection={"column"}
      >
        <SectionTitle title={t("TXT_Account_Security")} />
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          p="20px"
        >
          <Flex color="GREY" mb="20px">
            {t("TXT_Account_Reset_pw_desc")}
          </Flex>
          <Flex
            alignItems="center"
            color={returnByTheme()}
            p="5px 10px"
            borderRadius="25px"
            borderWidth="1px"
            borderColor={returnByTheme()}
            cursor="pointer"
            onClick={() => setIsShowResetPwModal(true)}
          >
            <Icon as={GiAnticlockwiseRotation} mr="10px" /> {t("TXT_Reset_pw")}
          </Flex>
        </Flex>
      </Flex>

      {/* address sction */}
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        mb="20px"
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        // flex={1}
        flexDirection={"column"}
      >
        <SectionTitle title={t("TXT_Address")} />
        <FormAddressInput
          inputLabel={t("TXT_Address")}
          isRequired={true}
          disableAll={!isOwner}
          addressArr={personalInfoData?.address}
          areaSelectedValue={
            personalInfoData?.address
              ? personalInfoData?.address[1].replaceAll("-", "_").toUpperCase()
              : ""
          }
          distSelectedValue={
            personalInfoData?.address
              ? personalInfoData?.address[2].replaceAll("-", "_").toUpperCase()
              : ""
          }
          buildingInputValue={
            personalInfoData?.address
              ? personalInfoData?.address[3].replaceAll("-", "_").toUpperCase()
              : ""
          }
          areaOnchange={(value: string) => {
            let areaArr = personalInfoData?.address!;
            areaArr[1] = value;

            getNewState("address", areaArr);
          }}
          distOnchange={(value: string) => {
            let distArr = personalInfoData?.address!;
            distArr[2] = value;

            getNewState("address", distArr);
          }}
          placeHolder={t("TXT_Desc_fill_street_or_building")}
          buildingOnchange={(value: string) => {
            let buildingArr = personalInfoData?.address!;
            buildingArr[3] = value;

            getNewState("address", buildingArr);
          }}
        />
      </Flex>

      {/* images for invoice sction */}
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        mb="20px"
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"

        flexDirection={"column"}
      >
        <SectionTitle title={t("TXT_Images")} />
        <FormFilesInput
          watchOnly={!isOwner}
          inputLabel={t("TXT_Company_logo")}
          limitedType=".jpg,.png"
          litmitedDesc={t("TXT_Desc_accept_jpg_and_png_only")}
          filesList={personalInfoData?.companyLogo}
          allowMulti={false}
          onUploadFiles={(file: Array<File>) => {
            getUploadDetail("companyLogo", file);
            // getNewState("projectImages", file);
          }}
          onDeleteFile={(index: number) => {
            updatePersonalInfoState({
              ...personalInfoData,
              companyLogo: [],
            });
          }}
          uploadBtnIcon={<UploadImageIcon />}
          uploadBtnText={t("TXT_Desc_click_to_choose_image")}
        />
        <FormFilesInput
          watchOnly={!isOwner}
          inputLabel={t("TXT_Company_chop")}
          limitedType=".jpg,.png"
          litmitedDesc={t("TXT_Desc_accept_jpg_and_png_only")}
          filesList={personalInfoData?.companyChop}
          allowMulti={false}
          onUploadFiles={(file: Array<File>) => {
            getUploadDetail("companyChop", file);
            // getNewState("projectImages", file);
          }}
          onDeleteFile={(index: number) => {
            updatePersonalInfoState({
              ...personalInfoData,
              companyChop: [],
            });
          }}
          uploadBtnIcon={<UploadImageIcon />}
          uploadBtnText={t("TXT_Desc_click_to_choose_image")}
        />
      </Flex>
      {isShowResetPwModal && (
        <ResetPwModal
          isShow={isShowResetPwModal}
          onClose={() => setIsShowResetPwModal(false)}
          userEmail={userEmail}
        />
      )}
    </>
  );
};

const ResetPwModal = ({ isShow, onClose, userEmail }: any) => {
  const { t } = useTranslation();
  const [newPw, setNewPw] = useState("");
  const [confirmNewPw, setConfirmNewPw] = useState("");
  const [isOtpPass, setIsOtpPass] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const toast = useToast();

  const [getOtp, { data: otpStatus }] = useMutation(ForgetPasswordDocument, {
    onCompleted: (success) => {
      return toast({
        position: "bottom-right",
        title: `Otp sent successfully`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Fail to get otp code, Error: ${error}`,
        status: "error",
      });
    },
  });

  const [verifyOtp, { data: verifyOtpStatus, loading: verifyingOtp }] =
    useMutation(VerifyOtpDocument, {
      onCompleted: (success) => {
        setIsOtpPass(true);
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: `otp code or email incorrect, Error: ${error}`,
          status: "error",
        });
      },
    });

  const [resetPw, { data: resetPwStatus, loading: resetingPw }] = useMutation(
    ResetPasswordDocument,
    {
      onCompleted: (success) => {
        toast({
          position: "bottom-right",
          title: `Password reset successfully`,
          status: "success",
        });
        onClose();
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: `Oh , something wrong, please try again, Error: ${error}`,
          status: "error",
        });
      },
    }
  );

  // useEffect(() => {
  //   getOtp({
  //     variables: {
  //       input: {
  //         email: userEmail,
  //       },
  //     },
  //   });

  //   return () => {
  //     setIsOtpPass(false);
  //   };
  // }, []);

  return (
    <EditModal
      isShow={isShow}
      onClose={() => onClose()}
      headerColor={returnByTheme()}
      headerText={t("TXT_Account_Change_pw_desc")}
      size="xl"
      bodyPx="0"
    >
      {(isOtpPass && (
        <>
          <Flex flexDir="column" width="100%">
            <FormPwInput
              inputType="password"
              placeHolder={t("TXT_New_password")}
              inputLabel={t("TXT_New_password")}
              // isRequired={true}
              onChange={(value: string) => {
                setNewPw(value);
              }}
              inpputtedValue={newPw}
            />
            <FormPwInput
              inputType="password"
              placeHolder={t("TXT_Confirm_password")}
              inputLabel={t("TXT_Confirm_password")}
              // isRequired={true}
              onChange={(value: string) => {
                setConfirmNewPw(value);
              }}
              inpputtedValue={confirmNewPw}
            />
          </Flex>
          <Flex flexDirection="row" width="100%" px="20px">
            <Button
              backgroundColor="WT"
              _hover={{ backgroundColor: "WT" }}
              onClick={() => onClose()}
              marginTop="20px"
              borderRadius="10px"
              flex={1}
              borderColor="BK"
              borderWidth="1px"
            >
              <Text color="BK">{t("TXT_Cancel")}</Text>
            </Button>
            <Button
              backgroundColor={returnByTheme()}
              _hover={{ backgroundColor: returnByTheme() }}
              disabled={resetingPw}
              isLoading={resetingPw}
              loadingText={t("Loading")}
              spinnerPlacement="end"
              onClick={() => {
                if (newPw !== confirmNewPw) {
                  toast({
                    position: "bottom-right",
                    title: `Password are not match`,
                    status: "error",
                  });
                } else {
                  resetPw({
                    variables: {
                      input: {
                        otp: otpCode,
                        email: userEmail,
                        password: newPw,
                      },
                    },
                  });
                }
              }}
              marginTop="20px"
              borderRadius="10px"
              flex={1}
              marginLeft="20px"
            >
              <Text color="WT">{t("TXT_Save_change")}</Text>
            </Button>
          </Flex>
        </>
      )) || (
        <Flex px="20px" flexDir="column" alignItems="center">
          <Text fontWeight="bold" fontSize="32px">
            {t("TXT_Signup_forgotPW_desc_1")}
          </Text>
          <Text
            color={returnByTheme()}
            fontWeight={400}
            fontSize="18px"
            mb="10px"
          >
            {t("TXT_Signup_forgotPW_desc_2")}
          </Text>
          <Flex
            // width="50%"
            alignItems="center"
            justifyContent="center"
            alignSelf="center"
            experimental_spaceX="8"
          >
            <PinInput
              size="lg"
              placeholder=""
              colorScheme="blue"
              onComplete={(e) => setOtpCode(e)}
            >
              <PinInputField
                borderColor={returnByTheme()}
                borderWidth={2}
                paddingY={8}
              />
              <PinInputField
                borderColor={returnByTheme()}
                borderWidth={2}
                paddingY={8}
              />
              <PinInputField
                borderColor={returnByTheme()}
                borderWidth={2}
                paddingY={8}
              />
              <PinInputField
                borderColor={returnByTheme()}
                borderWidth={2}
                paddingY={8}
              />
            </PinInput>
          </Flex>
          <Flex justifyContent="center" gap="20px">
            <Button
              backgroundColor="GN.2"
              _hover={{ backgroundColor: "GN.1" }}
              disabled={verifyingOtp}
              isLoading={verifyingOtp}
              loadingText={t("Loading")}
              spinnerPlacement="end"
              minH="40px"
              minW="120px"
              onClick={() =>
                getOtp({
                  variables: {
                    input: {
                      email: userEmail,
                    },
                  },
                })
              }
              marginTop="20px"
              borderRadius="10px"
              flex={1}
            >
              <Text color="WT">{t("TXT_Get_my_otp")}</Text>
            </Button>
            <Button
              backgroundColor={returnByTheme()}
              _hover={{ backgroundColor: returnByTheme() }}
              disabled={verifyingOtp}
              isLoading={verifyingOtp}
              loadingText={t("Loading")}
              spinnerPlacement="end"
              minH="40px"
              minW="120px"
              onClick={() => {
                verifyOtp({
                  variables: {
                    input: {
                      email: userEmail,
                      otp: otpCode,
                    },
                  },
                });
              }}
              marginTop="20px"
              borderRadius="10px"
              flex={1}
            >
              <Text color="WT">{t("TXT_Next")}</Text>
            </Button>
          </Flex>
        </Flex>
      )}
    </EditModal>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default GeneralDetailSection;
