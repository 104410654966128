import { Text, Input, Button, Flex } from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import { IInvoicePaymentMethodData } from "../../../type/IInoviceInfoInterface";
import { ReactComponent as TrashIcon } from "src/icons/general/trashIcon.svg";
import FormSelectInput from "src/components/createProjectForm/FormSelectInput";
import { useTranslation } from "react-i18next";
import { returnByTheme } from "../../../utils/util";

interface IInvoicePayMethodProps {
  invoicePaymentData: Array<IInvoicePaymentMethodData>;
  updateInvocePaymentState: (value: any) => void;
  isPreviewMode?: boolean;
  onClose?: () => void;
  onlyForDebit?: boolean;
  // addMoreSection?: (clone: any) => void;
  // removeSection?: (clone: any) => void;
}

const PayMethodSection = ({
  invoicePaymentData,
  updateInvocePaymentState,
  isPreviewMode = false,
  onClose = () => {},
  onlyForDebit = true,
}: // addMoreSection,
// removeSection,
IInvoicePayMethodProps) => {
  const { t } = useTranslation();
  // console.log("invoice payment section state");
  // console.log(invoicePaymentData);

  const [previewState, setPreviewState] = useState<
    Array<IInvoicePaymentMethodData>
  >([{ payMethod: "Bank transfer", accNum: "" }]);

  const displayData = isPreviewMode ? previewState : invoicePaymentData;

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(invoicePaymentData);
    }
    return () => {};
  }, []);

  const paymentOption = [
    { value: "Bank transfer", name: t("TXT_Bank_transfer") },
    { value: "FPS", name: t("TXT_FPS") },
  ];

  const getNewState = (key: string, i: number, value: string | number) => {
    let cloneArr = displayData.map((obj, index) => {
      if (index == i) {
        return { ...obj, [key]: value };
      } else {
        return obj;
      }
    });

    if (isPreviewMode) {
      setPreviewState(cloneArr);
    } else {
      updateInvocePaymentState(cloneArr);
    }
  };

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        width={"100%"}
        flexDirection={"column"}
        mt="20px"
      >
        {!isPreviewMode && (
          <Flex
            alignItems={"center"}
            borderBottomColor={"DISABLEDBG"}
            borderBottomWidth={1}
          >
            <SectionTitle title={t("TXT_Payment_method")} />

            {onlyForDebit && (
              <Flex fontSize={"10px"} color="GREY">
                {t("TXT_Desc_only_view_in_debit_note")}
              </Flex>
            )}
          </Flex>
        )}

        {displayData &&
          displayData?.map((d, i) => {
            return (
              <Flex
                key={i}
                borderBottomColor={"DISABLEDBG"}
                borderBottomWidth={1}
                mx="20px"
                py="20px"
                flexWrap={"wrap"}
                justifyContent={"space-between"}
              >
                <FormSelectInput
                  options={paymentOption}
                  isRequired={true}
                  selectedValue={d?.payMethod}
                  onChange={(value: string) => {
                    getNewState("payMethod", i, value);
                  }}
                />

                <Flex width={"10px"}></Flex>

                <Input
                  placeholder={t("TXT_Account_number")}
                  flex={1}
                  px="10px"
                  py="5px"
                  borderColor={returnByTheme()}
                  borderRadius="5px"
                  borderWidth={1}
                  //   justifyContent={"space-between"}
                  value={d?.accNum}
                  onChange={(e) => getNewState("accNum", i, e.target?.value)}
                />

                <Flex width={"10px"}></Flex>

                <TrashIcon
                  height={"100%"}
                  cursor={"pointer"}
                  onClick={() => {
                    const newArr = invoicePaymentData?.filter(
                      (t, index) => i !== index
                    );
                    isPreviewMode
                      ? setPreviewState(newArr)
                      : updateInvocePaymentState(newArr);
                  }}
                />
              </Flex>
              // <Box
              //   key={i}
              //   // fontWeight={"bold"}
              //   borderBottomColor={"DISABLEDBG"}
              //   borderBottomWidth={1}
              // >
              //   <FormTextInput
              //     placeHolder={"Manager name"}
              //     inputLabel={"Name"}
              //     isRequired={true}
              //     onChange={(value: string) => {
              //       getNewState("name", i, value);
              //     }}
              //     inpputtedValue={d?.payMethod}
              //   />
              //   <FormTextInput
              //     placeHolder={"mobile / office"}
              //     inputLabel={"Phone"}
              //     isRequired={true}
              //     onChange={(value: string) => {
              //       getNewState("phone", i, value);
              //     }}
              //     inpputtedValue={d?.accNum}
              //   />
              //   <TrashIcon
              //     cursor={"pointer"}
              //     onClick={() => {
              //       const newArr = invoicePaymentData?.filter(
              //         (t, index) => i !== index
              //       );
              //       removeSection(newArr);
              //     }}
              //   />
              // </Box>
            );
          })}

        {/* add section btn */}
        <Flex p={"20px"}>
          <Flex
            borderRadius={"5px"}
            borderColor={returnByTheme()}
            borderWidth={1}
            onClick={() => {
              // let clone = [...displayData];

              let clone = [
                {
                  payMethod: "TXT_Bank_transfer",
                  accNum: "",
                },
              ];
              if (displayData) {
                clone = [
                  ...displayData,
                  {
                    payMethod: "TXT_Bank_transfer",
                    accNum: "",
                  },
                ];
              }
              isPreviewMode
                ? setPreviewState(clone)
                : updateInvocePaymentState(clone);
            }}
            px="20px"
            py="3px"
            color={returnByTheme()}
            alignItems={"center"}
            cursor={"pointer"}
          >
            + {t("TXT_Add")}
          </Flex>
        </Flex>
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updateInvocePaymentState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
    >
      {title}
    </Flex>
  );
};

export default PayMethodSection;
