import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Icon,
  useToast,
  useOutsideClick,
  Image,
  CircularProgress,
  CircularProgressLabel,
  Progress,
  ModalHeader,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WhiteCloseBtn } from "src/icons/general/whiteCloseBtn.svg";

import { returnByTheme, returnByColor } from "../../../utils/util";
import DatePicker from "react-datepicker";
import { IoIosArrowDown } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import { RiFileDownloadLine } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";
import _ from "lodash";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AiOutlineClose } from "react-icons/ai";
import {
  DownreportDocument,
  ListInvestmentDocument,
} from "src/graphql/generated";
import { FiClock } from "react-icons/fi";

const ProjectCalcOverviewSection = ({ siteId, calcData }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const [comparisonDate, setComparisonDate] = useState(new Date());
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [getVarianceReport, { data: report }] = useLazyQuery(
    DownreportDocument,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
      // onError: (error) => {
      //   return toast({
      //     position: "bottom-right",
      //     title: t("TXT_Error_general_err_message"),
      //     status: "error",
      //   });
      // },
    }
  );

  useEffect(() => {
    if (siteId) {
      // getCalcData({
      //   variables: {
      //     siteId: siteId,
      //   },
      // });
      getVarianceReport({
        variables: {
          projectId: siteId,
        },
      });
    }

    return () => {};
  }, [siteId]);

  const totalCapcity = calcData?.numberOfPanel * calcData?.wattOfPanel;

  const pvCapcity = (calcData?.numberOfPanel * calcData?.wattOfPanel) / 1000;

  const firstYearWattMultiplier =
    ((totalCapcity *
      ((calcData?.dailySolarIrradiation * calcData?.panelEfficiency) / 100) *
      365) /
      1000 /
      totalCapcity) *
    (1 - calcData?.panelDegradationFirstYear / 100) *
    (1 - calcData?.panelDegradationSecondYear / 100);

  const getOrdinalNumber = () => {
    const startYear = moment(calcData.projectStartDate).format("YYYY");
    const endYear = moment(calcData.projectEndDate).format("YYYY");
    const currYear = moment(new Date()).format("YYYY");

    const totalRunningYear = Number(endYear) - Number(startYear); // 2033 - 2023 = 10
    const upcomingYear = Number(endYear) - Number(currYear); // 2033 - 2026 = 7
    const thisYear = totalRunningYear - upcomingYear; // 10 - 7 = 3
    // date out of range
    if (
      Number(currYear) < Number(startYear) ||
      Number(currYear) > Number(endYear)
    ) {
      console.log("out of range");
      return `Ended or not start yet`;
    }

    if (thisYear == 0) {
      return `1st year (${moment(new Date()).format("YYYY")})`;
    } else if (thisYear == 1) {
      return `2nd year (${moment(new Date()).format("YYYY")})`;
    } else {
      return `3rd  year (${moment(new Date()).format("YYYY")})`;
    }
  };

  const countFitProduction = () => {
    const currYrdata = calcData?.forecastFitProduction.filter(
      (fp: any) => fp.period.substr(0, 4) == moment(new Date()).format("YYYY")
    );
    if (currYrdata) {
      return {
        target: currYrdata[0]?.amount ?? 0,
        now: 0, //
        percentage: 0 / currYrdata[0]?.amount ?? 0,
      };
    } else {
      return {
        target: 0,
        now: 0, //
        percentage: 0,
      };
    }
  };

  // console.log(`calcData`);
  // console.log(calcData);

  const getFitProductionNowData = () => {
    const currYearMonthlyPowerArr = calcData?.allMonthlyPower?.filter(
      (monthData: any) => {
        return monthData.year == moment(new Date()).format("YYYY");
        // return monthData.year == "2022";
      }
    );
    if (currYearMonthlyPowerArr?.length > 0) {
      const currYearrFitProductionData =
        currYearMonthlyPowerArr[0]?.power.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        );

      return currYearrFitProductionData;
    } else {
      return 0;
    }
  };

  const getComparisonData = (allMonthlyArr: any, forecastArr: any) => {
    const currYearMonthlyPowerArr = allMonthlyArr?.filter((monthData: any) => {
      return monthData.year == moment(comparisonDate).format("YYYY");
      // return monthData.year == "2022";
    });

    const currYearForcastArr = forecastArr?.filter((data: any) => {
      // return monthData.year == moment(new Date()).format("YYYY");
      return moment(comparisonDate, 'YYYY-MM').isBetween(
        moment(data.period.substring(0, 7)),
        moment(data.period.substring(10)),
        undefined,
        '[]',
      )
      // return data.period.substr(0, 4) == moment(comparisonDate).format("YYYY");
    });

    if (currYearMonthlyPowerArr?.length > 0 && currYearForcastArr?.length > 0) {
      // console.log(`currYearForcastArr`);
      // console.log(currYearForcastArr);
      // console.log(`currYearMonthlyPowerArr`);
      // console.log(currYearMonthlyPowerArr);

      const monthIndex = Number(moment(comparisonDate).format("M")) - 1;
      const yearIndex = Number(moment(comparisonDate).format("YYYY"));

      const kWhPerDay = parseFloat(
        (currYearForcastArr?.[0].amount / 365).toFixed(2)
      );

      const daysOfMonth = new Date(
        comparisonDate.getFullYear(),
        comparisonDate.getMonth() + 1,
        0
      ).getDate();

      const actualData = currYearMonthlyPowerArr?.[0].power[monthIndex]; // monthly actual data for current year
      // const estimateData =
      //   (Number(currYearForcastArr?.[0].amount.toFixed(2)) / 365 ?? 0) *
      //   daysOfMonth; // monthly estimate data for current year

      const estimateData = parseFloat((daysOfMonth * kWhPerDay).toFixed(2));
      // monthly estimate data for current year

      // month actual data /
      const varianceData =
        (currYearMonthlyPowerArr?.[0].power[monthIndex] /
          (daysOfMonth * kWhPerDay) -
          1) *
        100;

      // console.log(`actualData`);
      // console.log(typeof actualData);
      // console.log(`estimateData`);
      // console.log(typeof estimateData);
      // console.log(`varianceData`);
      // console.log(typeof varianceData);

      return {
        actual: actualData ?? 0,
        estimate: estimateData ?? 0,
        variance: varianceData ?? 0,
        // actual: 0,
        // estimate: 0,
        // variance: 0,
      };
    } else {
      return {
        actual: 0,
        estimate: 0,
        variance: 0,
      };
    }
  };

  return (
    <Flex
      py="10px"
      borderRadius="8px"
      boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
      bg={"WT"}
      flexDir="column"
    >
      <Flex px="10px" color={returnByTheme()} fontWeight="bold">
        {t("TXT_Calculator_overview")}
      </Flex>
      <Flex fontSize="12px" color="DISABLEDTEXT" px="10px">
        {t("TXT_Calculator_ref_only_desc")}
      </Flex>
      <Flex bg="DISABLEDBG" height="1px" my="10px"></Flex>

      {(calcData && (
        <>
          <Flex flexDir="column" alignItems="center">
            <Flex color="GREY" mb="5px">
              {t("TXT_Calculator_total_revenue_after_share")}
            </Flex>
            <Flex alignItems="center">
              <Flex fontSize="18px" fontWeight="bold">
                {calcData?.totalRevenue.toFixed(2).toLocaleString() ?? "-"}{" "}
                &nbsp;
              </Flex>
              <Flex fontSize="14px" color="DISABLEDTEXT">
                HK$
              </Flex>
            </Flex>
            <Flex bg="DISABLEDTEXT" height="1px" m="10px"></Flex>
            <TotalBoxRow
              label={"IRR"}
              value={calcData?.irr.toFixed(2).toLocaleString() ?? "0"}
              unit="%"
            />
            <TotalBoxRow
              label={"ROI"}
              value={calcData?.roi.toFixed(2).toLocaleString() ?? "0"}
              unit="%"
            />
            <TotalBoxRow
              label={t("TXT_Calculator_payback_period")}
              value={calcData?.paybackPeriod.toFixed(2).toLocaleString() ?? "0"}
              unit="/year(s)"
            />
            <TotalBoxRow
              label={t("TXT_Calculator_total_cost")}
              value={calcData?.totalCost.toFixed(2).toLocaleString() ?? "0"}
              unit="HK$"
            />

            <Flex
              py="5px"
              px="10px"
              bg="#004B92"
              color="WT"
              borderRadius="4px"
              alignItems="center"
              justifySelf="center"
              justifyContent="center"
              gap="10px"
              minWidth="220px"
              m="auto"
              mt="30px"
              cursor="pointer"
              // onClick={() => setShowDownloadModal(true)}
              onClick={() => {
                const win: Window = window;

                if (calcData?.file?.url) {
                  win.location = calcData?.file?.url;
                }
              }}
              position="relative"
            >
              <RiFileDownloadLine color="#ffffff" />
              {t("TXT_Calculator_download_investment_report")}
            </Flex>
          </Flex>
          <Flex bg="DISABLEDBG" height="1px" my="10px"></Flex>

          <Box mx="10px">
            <Flex color={returnByTheme()} fontWeight="bold" mb="10px">
              {t("TXT_Calculator_estimate_electricity_data")}
            </Flex>
            <Swiper
              modules={[EffectFade, Navigation]}
              spaceBetween={0}
              navigation={true}
              effect="fade"
              // loop={true}
            >
              <SwiperSlide>
                <Flex justifyContent="space-between" bg="WT">
                  <Flex
                    // boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                    p="10px"
                    flexDirection="column"
                    flex={1}
                    justifyContent="space-between"
                    alignItems="center"
                    borderRight="1px solid #F0F0F0"
                  >
                    <Image
                      src={require("../../../icons/general/pjDetailCalcIcon1.png")}
                    />

                    <Box
                      textAlign="center"
                      color={"#004b92"}
                      flexDirection="column"
                      flex={1}
                      width={"100%"}
                      mb="5px"
                    >
                      {t("TXT_Calculator_total_capacity")}
                    </Box>

                    <Box fontSize="25px" textAlign="center" fontWeight="bold">
                      {totalCapcity ?? "-"}
                    </Box>
                    <Flex
                      fontSize="10px"
                      justifyContent="space-between"
                      width="80px"
                      color="GREY"
                    >
                      <Box>{t("TXT_Units")}</Box>
                      <Box>{"Watt(s)"}</Box>
                    </Flex>
                  </Flex>

                  <Flex
                    // boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                    p="10px"
                    flexDirection="column"
                    flex={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Image
                      src={require("../../../icons/general/pjDetailCalcIcon2.png")}
                    />

                    <Box
                      textAlign="center"
                      // color="RD.3"
                      color={"#004b92"}
                      flexDirection="column"
                      flex={1}
                      width={"100%"}
                      mb="5px"
                    >
                      {t("TXT_Calculator_pv_capacity")}
                    </Box>

                    <Box fontSize="25px" textAlign="center" fontWeight="bold">
                      {pvCapcity ?? "-"}
                    </Box>
                    <Flex
                      fontSize="10px"
                      justifyContent="space-between"
                      width="80px"
                      color="GREY"
                    >
                      <Box>{t("TXT_Units")}</Box>
                      <Box>kWp</Box>
                    </Flex>
                  </Flex>
                </Flex>
              </SwiperSlide>

              <SwiperSlide>
                <Flex justifyContent="space-between" bg="WT">
                  <Flex
                    // boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                    p="10px"
                    flexDirection="column"
                    flex={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Image
                      src={require("../../../icons/general/pjDetailCalcIcon3.png")}
                    />
                    <Flex
                      textAlign="center"
                      color={"#004b92"}
                      flexDirection="column"
                      width="120px"
                      mb="5px"
                    >
                      <Box>
                        {t("TXT_Calculator_first_year_watt_multiplier")}
                      </Box>
                    </Flex>
                    <Box fontSize="25px" textAlign="center" fontWeight="bold">
                      {firstYearWattMultiplier.toFixed(2) ?? "-"}
                    </Box>
                    <Flex
                      fontSize="10px"
                      justifyContent="space-between"
                      width="80px"
                      color="GREY"
                    >
                      <Box>{t("TXT_Units")}</Box>
                      <Box>kWh</Box>
                    </Flex>
                  </Flex>
                  <Flex flex={1}></Flex>
                </Flex>
              </SwiperSlide>
            </Swiper>
          </Box>
          <Flex bg="DISABLEDBG" height="1px" my="10px"></Flex>
          <Flex
            py="5px"
            px="10px"
            bg="#004B92"
            color="WT"
            borderRadius="4px"
            alignItems="center"
            justifySelf="center"
            justifyContent="center"
            gap="10px"
            minWidth="220px"
            m="auto"
            cursor="pointer"
            // onClick={() => setShowDownloadModal(true)}
            onClick={() => {
              const win: Window = window;
              if (report?.downloadVarianceReport?.url) {
                win.location = report?.downloadVarianceReport?.url;
              }
            }}
            position="relative"
          >
            <RiFileDownloadLine color="#ffffff" />
            {t("TXT_Calculator_download_site_power_report")}
          </Flex>
          <Flex bg="DISABLEDBG" height="1px" my="10px"></Flex>
          <Flex flexDir="column" mx="10px">
            <Flex color={returnByTheme()} fontWeight="bold">
              {t("TXT_Calculator_fit_production")}
            </Flex>
            <Flex alignItems="center" fontSize="14px">
              <Flex color="GREY"> {t("TXT_Calculator_annual")}: </Flex>
              <Flex>{getOrdinalNumber()}</Flex>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" gap="10px">
              <Flex
                flex={1}
                alignItems="center"
                borderRadius="12px"
                border="1px solid #F0F0F0"
                py="20px"
                px="10px"
                justifyContent="space-between"
              >
                <Flex color="GREY">{t("TXT_Calculator_now")}:</Flex>
                <Flex alignItems="center">
                  <Flex color="#459EE3" fontSize="20px" fontWeight="bold">
                    {/* {calcData.allMonthlyPower.reduce} */}
                    {getFitProductionNowData().toLocaleString()}
                  </Flex>
                  <Flex color="GREY">&nbsp; kWh</Flex>
                </Flex>
              </Flex>
              <CircularProgress
                value={Number(
                  (
                    (getFitProductionNowData() / countFitProduction().target) *
                    100
                  ).toFixed(1)
                )}
                color="blue.300"
                size="100px"
                capIsRound
              >
                <CircularProgressLabel>
                  <Flex flexDir="column">
                    <Text fontWeight="bold" color="#459EE3" lineHeight={1}>
                      {(
                        (getFitProductionNowData() /
                          countFitProduction().target) *
                        100
                      ).toFixed(1) + "%"}
                    </Text>
                    <Text fontSize="10px">{t("TXT_Invoice_Completed")}</Text>
                  </Flex>
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex fontSize="14px" color="GREY">
              {t("TXT_Calculator_target")}:{" "}
              {countFitProduction().target.toLocaleString()} kWh
            </Flex>
          </Flex>
          <Flex bg="DISABLEDBG" height="1px" m="10px"></Flex>
          <Flex m="10px" flexDir="column">
            <Flex
              alignItems="center"
              gap="10px"
              justifyContent="space-between"
              mb="20px"
            >
              <Flex color={returnByTheme()} fontWeight="bold">
                {t("TXT_Calculator_comparison_by_month")}
              </Flex>
              <Flex>
                <DatePicker
                  selected={comparisonDate}
                  customInput={
                    <Flex
                      alignItems="center"
                      gap="10px"
                      cursor="pointer"
                      color="#459ee3"
                      borderRadius="25px"
                      border="1px solid #459ee3"
                      py="5px"
                      px="15px"
                      onClick={(e) => e.preventDefault()}
                    >
                      {moment(comparisonDate).format("MMM")}
                      <IoIosArrowDown />
                    </Flex>
                  }
                  onChange={(newDate: any) => {
                    setComparisonDate(newDate);
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </Flex>
            </Flex>

            <Flex justifyContent="space-between" flexWrap="wrap">
              <Flex alignItems="center" color="GREY">
                {t("TXT_Calculator_actual")} &nbsp;
                <Text color="#459EE3" fontWeight="bold">
                  {getComparisonData(
                    calcData?.allMonthlyPower,
                    calcData.forecastFitProduction
                  )?.actual.toFixed(2)}
                </Text>
                &nbsp;kWh
              </Flex>
              <Flex alignItems="center" color="GREY">
                {t("TXT_Calculator_estimate")}&nbsp;
                <Text color="BK" fontWeight="bold">
                  {getComparisonData(
                    calcData?.allMonthlyPower,
                    calcData.forecastFitProduction
                  )?.estimate.toFixed(2)}
                </Text>
                &nbsp;kWh
              </Flex>
            </Flex>
            <Progress
              value={
                (getComparisonData(
                  calcData?.allMonthlyPower,
                  calcData.forecastFitProduction
                )?.actual /
                  getComparisonData(
                    calcData?.allMonthlyPower,
                    calcData.forecastFitProduction
                  )?.estimate) *
                100
              }
              colorScheme="twitter"
              borderRadius="12px"
            />
            <Flex fontSize="10px" color="GREY">
              {(
                (getComparisonData(
                  calcData?.allMonthlyPower,
                  calcData.forecastFitProduction
                )?.actual /
                  getComparisonData(
                    calcData?.allMonthlyPower,
                    calcData.forecastFitProduction
                  )?.estimate) *
                100
              ).toFixed(2) + "%"}{" "}
              {t("TXT_Invoice_Completed")}
            </Flex>
            <Flex bg="DISABLEDBG" height="1px" my="10px"></Flex>
          </Flex>
          <Flex alignItems="center" mx="10px" justifyContent="space-between">
            <Flex alignItems="center" justifyContent="space-between" gap="5px">
              {t("TXT_Calculator_variance")}
              <Flex color="RD.3" fontWeight="bold">
                {getComparisonData(
                  calcData?.allMonthlyPower,
                  calcData.forecastFitProduction
                )?.variance.toFixed(2)}{" "}
                %
              </Flex>
            </Flex>

            {/* <Flex
              color="#004B92"
              alignItems="center"
              justifySelf="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => {
                // const win: Window = window;
                // if (report?.downloadVarianceReport?.url) {
                //   win.location = report?.downloadVarianceReport?.url;
                // }
              }}
            >
              <RiFileDownloadLine color="#004B92" />
              {t("TXT_Report")}
            </Flex> */}
          </Flex>

          {/* {showDownloadModal && (
            <DownloadReportModal
              closePreview={() => setShowDownloadModal(false)}
              reportUrl={report?.downloadVarianceReport?.url}
            />
          )} */}
        </>
      )) || (
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          gap="5px"
          mb="20px"
        >
          <Icon as={FiClock} width="122px" height="122px" color="#004B92" />
          <Flex fontSize="18px" fontWeight="bold" color="themeBlue">
            {t("TXT_Calculator_preparing_your_plan")}
          </Flex>
          <Flex>{t("TXT_Calculator_preparing_desc")}</Flex>
        </Flex>
      )}
    </Flex>
  );
};

const TotalBoxRow = ({
  label,
  value = "0",
  unit,
}: {
  label: string;
  value: string;
  unit: string;
}) => {
  return (
    <Flex justifyContent="space-between" mt="15px" px="10px" width="100%">
      <Flex alignItems="center" color="GREY">
        {label}
      </Flex>
      <Flex alignItems="baseline" gap="5px">
        <Text color="BK" fontSize="28px" fontWeight="bold">
          {value == "0" ? "--" : value}
        </Text>
        <Text color="GREY">{unit}</Text>
      </Flex>
    </Flex>
  );
};

const DownloadReportModal = (props: any) => {
  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);

  const previewReceipt = useRef<any>(null);
  const { t } = useTranslation();

  useOutsideClick({
    ref: previewReceipt,
    handler: () => props?.closePreview(),
  });

  return (
    <Flex
      ref={previewReceipt}
      // display={props?.isVisible ? "flex" : "none"}
      backgroundColor={"WT"}
      position="relative"
      top="10px"
      // right="30px"
      // top="25px"
      flexDirection={"column"}
      zIndex={30}
      borderWidth={1}
      borderColor="GREY"
      borderTopRadius={12}
      overflow="hidden"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex flexDirection={"column"}>
        <Flex
          bg={returnByTheme()}
          p="10px"
          color="WT"
          justifyContent="space-between"
        >
          {t("TXT_Calculator_download_report")}
          <Icon
            as={AiOutlineClose}
            width="20px"
            height="20px"
            cursor="pointer"
            onClick={() => props.closePreview()}
          />
        </Flex>
        <Flex p="10px">{t("TXT_Calculator_overview")}</Flex>
        <Flex height="1px" bg="GREY"></Flex>

        <Flex
          p="20px"
          color="themeBlue"
          alignItems="center"
          flexDir="column"
          gap="10px"
        >
          {[1, 2, 3].map((report, i) => {
            return (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                key={i}
              >
                <Flex alignItems="center">
                  <SiMicrosoftexcel />
                  <Text
                    noOfLines={1}
                    textOverflow="ellipsis"
                    ml="10px"
                    maxW="250px"
                  >
                    {`fake , not ready yet`}
                  </Text>
                </Flex>
                <Flex
                  borderRadius="25px"
                  border="1px solid #F0F0F0"
                  padding="5px"
                  cursor="pointer"
                  onClick={() => {
                    console.log("download report");
                  }}
                >
                  <RiFileDownloadLine color="#004B92" />
                </Flex>
              </Flex>
            );
          })}
        </Flex>

        {props?.reportUrl && (
          <>
            <Flex p="10px">{t("TXT_Calculator_variance_report")}</Flex>
            <Flex height="1px" bg="GREY"></Flex>
            <Flex
              p="20px"
              color="themeBlue"
              alignItems="center"
              flexDir="column"
              gap="10px"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Flex alignItems="center">
                  <SiMicrosoftexcel />
                  <Text
                    noOfLines={1}
                    textOverflow="ellipsis"
                    ml="10px"
                    maxW="250px"
                  >{`Variance Report`}</Text>
                </Flex>
                <Flex
                  borderRadius="25px"
                  border="1px solid #F0F0F0"
                  padding="5px"
                  cursor="pointer"
                  onClick={() => {
                    window.location = props?.reportUrl;
                  }}
                >
                  <RiFileDownloadLine color="#004B92" />
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default ProjectCalcOverviewSection;
