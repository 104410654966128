import {
  Box,
  Input,
  Text,
  Flex,
  InputRightElement,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import { split } from "lodash";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";
import { returnByTheme } from "../../utils/util";

interface ISelectProps {
  placeHolder: string;
  isRequired?: boolean;
  inputLabel?: string;
  hints?: string;
  onChange: (value: string) => void;
  inpputtedValue?: string;
  isDisable?: boolean;
  addComma?: boolean;
  maxLen?: number;
  leftIcon?: any;
  rightIcon?: any;
}

const FormNumberTextInput = ({
  placeHolder,
  isRequired = false,
  inputLabel,
  hints,
  onChange,
  inpputtedValue,
  isDisable,
  addComma,
  maxLen,
  leftIcon,
  rightIcon,
}: ISelectProps) => {
  const addCommas = (value: string | undefined) => {
    if (value) {
      const strLen = value?.length;
      let trimedText = value;
      if (value.indexOf("0") === 0) {
        trimedText = value.substring(1, strLen);
      }

      return trimedText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // let trimedArr = trimedText.split(".");

      // let beforeDotText = trimedArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // let finalText = beforeDotText + beforeDotText?.[1];
      // console.log(beforeDotText + beforeDotText?.[1]);
      // return finalText;
    } else {
      return value;
    }
  };

  return (
    <Flex>
      <Flex
        flex={1}
        flexDirection={"row"}
        py="10px"
        px="20px"
        justifyContent={"space-between"}
        gap="5px"
      >
        {/* label section */}
        <Flex flex={2} fontSize={15} color={"GREY"}>
          {isRequired ? (
            <Box>
              <Text display={"inline"}>{inputLabel}&nbsp;</Text>
              <Text color={"RD.1"} display={"inline"}>
                *
              </Text>
            </Box>
          ) : (
            <Flex>{inputLabel}</Flex>
          )}
        </Flex>
        {/* text input section */}
        <Flex flex={3} position="relative">
          <InputGroup flexDirection={"column"}>
            {leftIcon && (
              <InputLeftElement
                children={leftIcon}
                width={"fit-content"}
                height={"fit-content"}
                top={"5px"}
                left={"10px"}
                background={"WT"}
              />
            )}
            {/* <NumericFormat
              value={inpputtedValue}
              thousandSeparator=","
              placeholder={placeHolder}
              style={{
                padding: "5px 10px",
                borderColor: "#004b92",
                borderRadius: "5px",
                borderWidth: 1,
              }}
              onValueChange={(v) => {
                onChange(v.value);
              }}
            /> */}

            <Input
              disabled={isDisable}
              placeholder={placeHolder}
              _placeholder={{ color: "DISABLEDTEXT" }}
              flex={1}
              px="10px"
              py="5px"
              maxLength={maxLen}
              borderColor={returnByTheme()}
              borderRadius="5px"
              borderWidth={1}
              justifyContent={"space-between"}
              value={addComma ? addCommas(inpputtedValue) : inpputtedValue}
              onChange={(e) => {
                onChange(
                  addComma
                    ? e.target?.value.replace(/[^0-9]+/g, "")
                    : e.target?.value.replace(/[^0-9\.]+/g, "")
                );
              }}
            />
            {rightIcon && (
              <InputRightElement
                children={rightIcon}
                width={"fit-content"}
                height={"fit-content"}
                top={"5px"}
                right={"10px"}
                background={"WT"}
              />
            )}
            {hints && (
              <Flex fontSize="10px" color={"GREY"}>
                {hints}
              </Flex>
            )}
          </InputGroup>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FormNumberTextInput;
