import {
  Box,
  Input,
  Textarea,
  Flex,
  Text,
  Image,
  Icon,
  Button,
  useOutsideClick,
} from "@chakra-ui/react";
import _ from "lodash";
import { createRef, useEffect, useRef, useState } from "react";
import { Calendar } from "react-date-range";
import { FiCalendar, FiFlag } from "react-icons/fi";
import DateRangePicker from "src/components/DateRangePicker";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashIcon } from "src/icons/general/trashIcon.svg";
import { ReactComponent as SendIcon } from "src/icons/general/sendLetterIcon.svg";
import { ReactComponent as UploadAttachIcon } from "src/icons/general/uploadAttachIcon.svg";
import { ReactComponent as PDFIcon } from "src/icons/general/pdfIcon.svg";
import { IProjectTaskData } from "../../../type/IBaseInfotInterface";
import {
  returnByTheme,
  returnByColor,
  uploadDoc,
  isEmptyString,
} from "../../../utils/util";
import moment from "moment";
import React from "react";

interface IProjectTaskProps {
  taskData: Array<IProjectTaskData>;
  updateProjectTaskState: (value: any) => void;
  isEditMode?: boolean;
  isPreviewMode?: boolean;
  onClose?: () => void;
}

const ProjectTaskSection = ({
  taskData,
  updateProjectTaskState,
  isEditMode = false,
  isPreviewMode = false,
  onClose = () => {},
}: IProjectTaskProps) => {
  const { t } = useTranslation();
  const [isShowDatePicker, setIsShowDatePicker] = useState({
    isShow: false,
    index: -1,
  });

  const [isShowPriority, setIsShowPriority] = useState({
    isShow: false,
    index: -1,
  });
  const [indexArr, setIndexArr] = useState({ taskIndex: 0, commentIndex: 0 });

  const [previewState, setPreviewState] = useState<Array<IProjectTaskData>>([]);
  const [uploadRef, setUploadRef] = React.useState<any>([]);
  // const [commentUploadRef, setCommentUploadRef] = React.useState<any>([]);
  const commentUploadRef = useRef<any>(null);
  const [dateRangePickerRef, setdateRangePickerRef] = React.useState<any>([]);
  const [priorityRef, setPriorityRef] = React.useState<any>([]);

  const displayData = isPreviewMode ? previewState : taskData;
  // console.log(`taskData`);
  // console.log(displayData);

  useOutsideClick({
    ref: priorityRef,
    handler: (e: any) => {},
  });

  const priorityArr = [
    { text: t("TXT_Project_priority"), color: "#F0F0F0", value: "none" },
    {
      text: t("TXT_Project_high_priority"),
      color: "rgba(231, 27, 43, 1)",
      value: "high",
    },
    {
      text: t("TXT_Project_middle_priority"),
      color: "rgba(247, 152, 70, 1)",
      value: "middle",
    },
    {
      text: t("TXT_Project_low_priority"),
      color: "rgba(16, 62, 98, 1)",
      value: "low",
    },
  ];

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(taskData);
    }
    return () => {};
  }, []);

  useEffect(() => {
    // add or remove refs
    setUploadRef((elRefs: any) =>
      Array(displayData.length)
        .fill(0)
        .map((_, i) => elRefs[i] || createRef())
    );

    // setCommentUploadRef((elRefs: any) => {
    //   Array(displayData.length)
    //     .fill(0)
    //     .map((_, taskI) => {
    //       Array(displayData[taskI]?.comment?.length)
    //         .fill(0)
    //         .map((_, commentI) => {
    //           elRefs[taskI][commentI] || createRef();
    //         });
    //     });
    // });

    setdateRangePickerRef((elRefs: any) =>
      Array(displayData.length)
        .fill(0)
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [displayData]);

  // attachment handler
  const filesUploadOnchange = async (event: any, index: number) => {
    const uploadedDoc = await uploadDoc(event?.target?.files);

    const arrOfFiles = Array.from(event?.target?.files).map((f: any, i) => {
      return {
        // url: uploadedDoc.result[i].url,
        url: uploadedDoc.result[i].url
          ? uploadedDoc.result[i].url
          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Tkm2I2eZS-dnuXspUUvm8WiB07J0XE8f4A&usqp=CAU",
        filePath: uploadedDoc.result[i].filePath,
        originalName: uploadedDoc.result[i].originalName,
      };
    });

    const arrOfExistFiles =
      displayData[index]?.attachment?.length > 0
        ? displayData[index]?.attachment.map((f: any, i) => {
            return f;
          })
        : [];
    getNewState("attachment", index, [...arrOfExistFiles, ...arrOfFiles]);

    uploadRef[index].current.value = "";
  };

  // comment attachment handler
  const addCommentAttachment = async (
    event: any,
    taskIndex: number,
    commentIndex: number
  ) => {
    const uploadedDoc = await uploadDoc(event?.target?.files);

    const arrOfFiles = Array.from(event?.target?.files).map((f: any, i) => {
      return {
        // url: uploadedDoc.result[i].url,
        url: uploadedDoc.result[i].url
          ? uploadedDoc.result[i].url
          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Tkm2I2eZS-dnuXspUUvm8WiB07J0XE8f4A&usqp=CAU",
        filePath: uploadedDoc.result[i].filePath,
        originalName: uploadedDoc.result[i].originalName,
      };
    });

    // const arrOfExistFiles = displayData[taskIndex]?.comment?.[commentIndex]
    //   ?.commentAttachment
    //   ? displayData[taskIndex]?.comment?.[commentIndex]?.commentAttachment!.map(
    //       (f: any, i) => {
    //         return f;
    //       }
    //     )
    //   : [];

    // const newFilesArr = [...arrOfExistFiles, ...arrOfFiles];

    // new comment attachment arr
    let newCommentArrWithAttactment = {
      ...displayData[taskIndex].comment![commentIndex],
      commentAttachment: arrOfFiles,
    };

    // new comment arr
    let cloneCommentArr = displayData[taskIndex].comment!.map((comment, i) => {
      if (i == commentIndex) {
        return newCommentArrWithAttactment;
      } else {
        return comment;
      }
    });

    getNewState("comment", taskIndex, cloneCommentArr);

    commentUploadRef.current.value = "";
  };

  const getNewState = (key: string, i: number, value: any) => {
    let cloneArr = displayData.map((obj, index) => {
      if (index == i) {
        return { ...obj, [key]: value };
      } else {
        return obj;
      }
    });

    if (isPreviewMode) {
      setPreviewState(cloneArr);
    } else {
      updateProjectTaskState(cloneArr);
    }

    // updateProjectTaskState(cloneArr);
  };

  const addComment = (index: number) => {
    if (
      displayData?.[index]?.tempComment?.toString().trim().length === 0 ||
      displayData?.[index]?.tempComment == ""
    ) {
      return;
    }

    let newCommentArr = [
      ...(displayData[index]?.comment ?? []),
      {
        message: displayData?.[index]?.tempComment!,
        commentDate: new Date(),
        user: "YOU",
      },
    ];

    let newTaskObj = {
      ...displayData[index],
      tempComment: "",
      comment: newCommentArr,
    };

    let cloneArr = displayData.map((obj, i) => {
      if (i == index) {
        return newTaskObj;
      } else {
        return obj;
      }
    });

    if (isPreviewMode) {
      setPreviewState(cloneArr);
    } else {
      updateProjectTaskState(cloneArr);
    }
    // getNewState(index.toString(), index, newTaskObj);
    // getNewState("tempComment", index, "");
  };
  // console.log(`task displayData`);
  // console.log(displayData);
  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        //   flex={1}
        width={"100%"}
        flexDirection={"column"}
        mt="20px"
      >
        {!isPreviewMode && (
          <SectionTitle title={t("TXT_Project_Project_tasks")} />
        )}

        {displayData &&
          displayData?.map((d, taskDataIndex) => {
            const dateText =
              d?.date?.[0]?.endDate !== null &&
              d?.date?.[0]?.startDate !== d?.date?.[0]?.endDate
                ? `${moment(d?.date?.[0]?.startDate).format(
                    "D MMM, YYYY"
                  )} - ${moment(d?.date?.[0]?.endDate).format("D MMM, YYYY")}`
                : `${moment(d?.date?.[0]?.startDate).format("D MMM, YYYY")}`;
            const sortedComment = displayData?.[taskDataIndex]?.comment?.sort(
              (a, b) => {
                return b?.commentDate?.valueOf() - a?.commentDate?.valueOf();
              }
            );
            return (
              <Flex
                key={taskDataIndex}
                borderBottomColor={"DISABLEDBG"}
                borderBottomWidth={1}
                mx="20px"
                py="20px"
                flexWrap={"wrap"}
                justifyContent={"space-between"}
              >
                <Input
                  display={"none"}
                  ref={uploadRef[taskDataIndex]}
                  multiple={true}
                  type={"file"}
                  accept={".jpg,.png,.pdf"}
                  onChange={(e) => {
                    filesUploadOnchange(e, taskDataIndex);
                  }}
                />
                <Flex
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  flex={1}
                >
                  <Flex position="relative">
                    <Flex
                      flex={1}
                      px="10px"
                      py="5px"
                      borderColor={returnByTheme()}
                      borderRadius="5px"
                      borderWidth={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>{dateText}</Box>
                      <Icon
                        as={FiCalendar}
                        color={returnByTheme()}
                        cursor="pointer"
                        width="20px"
                        height="20px"
                        onClick={(e) => {
                          setIsShowDatePicker({
                            isShow: true,
                            index: taskDataIndex,
                          });
                        }}
                      />
                      {isShowDatePicker &&
                        isShowDatePicker.index == taskDataIndex && (
                          <DateRangePicker
                            dateRangePickerRef={
                              dateRangePickerRef[taskDataIndex]
                            }
                            dateRange={d?.date}
                            onClose={() => {
                              setIsShowDatePicker({ isShow: false, index: -1 });
                            }}
                            applyDateFilter={(newRange: any) => {
                              getNewState("date", taskDataIndex, newRange);
                              setIsShowDatePicker({ isShow: false, index: -1 });
                            }}
                          />
                        )}
                    </Flex>
                    <Flex
                      position="relative"
                      flex={0.5}
                      px="10px"
                      py="5px"
                      ml="10px"
                      bg={
                        d.priority == "none"
                          ? "WT"
                          : d.priority == "high"
                          ? "rgba(231, 27, 43, 0.25)"
                          : d.priority == "middle"
                          ? "rgba(247, 152, 70, 0.25)"
                          : "rgba(16, 62, 98, 0.25)"
                      }
                      borderColor={returnByTheme()}
                      borderRadius="5px"
                      borderWidth={1}
                      justifyContent={"space-between"}
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsShowPriority({
                          isShow: true,
                          index: taskDataIndex,
                        });
                      }}
                    >
                      <Flex
                        gap="5px"
                        // color={returnByTheme()}
                        alignItems={"center"}
                        color={
                          d.priority == "none"
                            ? returnByTheme()
                            : d.priority == "high"
                            ? "rgba(231, 27, 43, 1)"
                            : d.priority == "middle"
                            ? "rgba(247, 152, 70, 1)"
                            : "rgba(16, 62, 98, 1)"
                        }
                      >
                        <FiFlag />
                        <Flex>
                          {d.priority
                            ? t(`TXT_Project_${d.priority}_priority`)
                            : t("TXT_Project_priority")}
                        </Flex>
                      </Flex>

                      {isShowPriority.isShow &&
                        isShowPriority.index == taskDataIndex && (
                          <Flex
                            position="absolute"
                            flexDir="column"
                            top="40px"
                            left="0"
                            width="100%"
                            borderColor={returnByTheme()}
                            borderRadius="5px"
                            borderWidth={1}
                            bg="WT"
                            zIndex={3}
                            onMouseLeave={(e) => {
                              e.stopPropagation();
                              setIsShowPriority({
                                isShow: false,
                                index: taskDataIndex,
                              });
                            }}
                          >
                            {priorityArr.map((p, i) => {
                              return (
                                <Flex
                                  key={i}
                                  alignItems="center"
                                  gap="10px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    getNewState(
                                      "priority",
                                      taskDataIndex,
                                      p.value
                                    );
                                    setIsShowPriority({
                                      isShow: false,
                                      index: taskDataIndex,
                                    });
                                  }}
                                  p="5px"
                                  _hover={{
                                    cursor: "pointer",
                                    bg: "DISABLEDBG",
                                  }}
                                  color="GREY"
                                >
                                  <Flex
                                    bg={p.color}
                                    width="10px"
                                    height="10px"
                                    borderRadius="50%"
                                  ></Flex>
                                  {p.text}
                                </Flex>
                              );
                            })}
                          </Flex>
                        )}
                    </Flex>
                    <Flex ml="10px" justifyContent={"flex-end"}>
                      <UploadAttachIcon
                        cursor="pointer"
                        onClick={() => {
                          uploadRef[taskDataIndex]?.current?.click();
                        }}
                      />
                      <Flex width={"10px"}></Flex>
                      <TrashIcon
                        cursor={"pointer"}
                        onClick={() => {
                          const newArr = displayData?.filter(
                            (t, index) => taskDataIndex !== index
                          );
                          isPreviewMode
                            ? setPreviewState(newArr)
                            : updateProjectTaskState(newArr);
                        }}
                      />
                    </Flex>
                  </Flex>

                  <Box height="15px"></Box>

                  <Input
                    placeholder={"Title"}
                    _placeholder={{ color: "DISABLEDTEXT" }}
                    flex={1}
                    px="10px"
                    py="5px"
                    borderColor={returnByTheme()}
                    borderRadius="5px"
                    borderWidth={1}
                    //   justifyContent={"space-between"}
                    value={d?.title ? d?.title : ""}
                    onChange={(e) =>
                      getNewState("title", taskDataIndex, e.target?.value)
                    }
                  />
                  <Box height="15px"></Box>

                  <Textarea
                    value={d?.desc ? d?.desc : ""}
                    resize={"none"}
                    minHeight="150px"
                    placeholder="Description"
                    _placeholder={{ color: "DISABLEDTEXT" }}
                    pt="10px"
                    px="10px"
                    pb="30px"
                    borderColor={returnByTheme()}
                    onChange={(e) =>
                      getNewState("desc", taskDataIndex, e.target?.value)
                    }
                  />

                  <Box height="15px"></Box>

                  {/* attachment section */}
                  {displayData?.[taskDataIndex]?.attachment && (
                    <Flex>
                      <Flex
                        flex={2}
                        color={"GREY"}
                        fontSize="12px"
                        pt={"10px"}
                        mr="10px"
                      >
                        {t("TXT_Project_Attachments")}
                      </Flex>
                      <Flex flex={3} flexDirection={"column"}>
                        {displayData?.[taskDataIndex]?.attachment?.map(
                          (a: any, attachmentIndex) => {
                            // const imageUrl = URL.createObjectURL(a?.file);
                            const extensionName = a?.originalName
                              .split(".")
                              .pop();
                            return (
                              <Flex
                                key={attachmentIndex}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                borderBottomColor={"DISABLEDBG"}
                                py="10px"
                              >
                                <Flex mr={"10px"}>
                                  {(extensionName !== "pdf" && (
                                    <Image
                                      width={"30px"}
                                      height={"30px"}
                                      src={a.url}
                                      borderRadius={"5px"}
                                      fit={"cover"}
                                      loading={"lazy"}
                                    />
                                  )) || <PDFIcon />}

                                  <Flex alignItems={"center"} ml="5px">
                                    <Text
                                      ml="5px"
                                      noOfLines={1}
                                      textOverflow="ellipsis"
                                      maxW="250px"
                                    >
                                      {a?.originalName}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <TrashIcon
                                  cursor={"pointer"}
                                  onClick={() => {
                                    const newArr = displayData?.[
                                      taskDataIndex
                                    ]?.attachment?.filter(
                                      (image, selectedAttachIndex) =>
                                        selectedAttachIndex !== attachmentIndex
                                    );

                                    getNewState(
                                      "attachment",
                                      taskDataIndex,
                                      newArr?.map((file: any, i) => {
                                        return file;
                                      })
                                    );
                                  }}
                                />
                              </Flex>
                            );
                          }
                        )}
                      </Flex>
                    </Flex>
                  )}

                  {/* comment input section */}

                  {!isEditMode && (
                    <Flex
                      py="20px"
                      borderTop={"1px"}
                      borderTopColor={"DISABLEDBG"}
                    >
                      <Flex
                        position={"relative"}
                        marginRight="10px"
                        flex={0.3}
                        alignItems="center"
                        flexDirection={"column"}
                      >
                        <Flex
                          width="45px"
                          minHeight="45px"
                          maxHeight="45px"
                          bg={"red.300"}
                          borderRadius={"50%"}
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Flex
                            textAlign={"center"}
                            fontWeight={"light"}
                            color={returnByTheme()}
                          >
                            YOU
                          </Flex>
                        </Flex>
                        {/* <Flex
                            width={"1px"}
                            height={"100%"}
                            my="10px"
                            bg={returnByTheme()}
                          ></Flex> */}
                      </Flex>

                      <Flex flexDirection="column" flex={3}>
                        <Box
                          border={"1px solid"}
                          borderColor={returnByTheme()}
                          borderRadius={"5px"}
                          px="10px"
                          pb="5px"
                        >
                          <Textarea
                            border={"none"}
                            outline={"none"}
                            boxShadow={"none"}
                            focusBorderColor={"none"}
                            value={d?.tempComment ? d?.tempComment : ""}
                            resize={"none"}
                            minHeight="150px"
                            placeholder={t("TXT_Project_leave_a_comment")}
                            _placeholder={{ color: "DISABLEDTEXT" }}
                            maxLength={150}
                            px="0"
                            pb="5px"
                            onChange={(e) =>
                              getNewState(
                                "tempComment",
                                taskDataIndex,
                                e?.target?.value
                              )
                            }
                          />
                          <Flex
                            // position={"absolute"}
                            // bottom="20px"
                            // zIndex={1}
                            // mx="10px"
                            // backgroundColor={"WT"}
                            // width="calc(100% - 20px)"
                            height="20px"
                          >
                            <Flex
                              justifyContent={"space-between"}
                              width="100%"
                              alignItems={"center"}
                            >
                              <Flex
                                onClick={() => {
                                  addComment(taskDataIndex);
                                }}
                                cursor="pointer"
                              >
                                <SendIcon />
                              </Flex>
                              <Box>{d?.tempComment?.length}/150</Box>
                            </Flex>
                          </Flex>
                        </Box>
                        <Box fontSize="10px" color="GREY">
                          {t("TXT_Project_Comment_input_hints")}
                        </Box>
                      </Flex>
                    </Flex>
                  )}

                  {/* comment history section */}
                  {sortedComment?.map((c, commentIndex) => {
                    const commentDate = moment(c?.commentDate).format(
                      "D MMM, YYYY"
                    );

                    return (
                      <Flex key={commentIndex}>
                        <Flex
                          position={"relative"}
                          marginRight="10px"
                          flex={0.3}
                          alignItems="center"
                          flexDirection={"column"}
                        >
                          <Flex
                            position="absolute"
                            width="45px"
                            minHeight="45px"
                            maxHeight="45px"
                            bg={"green.300"}
                            borderRadius={"50%"}
                            // padding={"5px"}
                            justifyContent="center"
                            alignItems={"center"}
                            // mt={"50%"}
                          >
                            <Flex
                              textAlign={"center"}
                              fontWeight={"light"}
                              color={returnByTheme()}
                            >
                              YOU
                            </Flex>
                          </Flex>
                          {commentIndex !== sortedComment.length - 1 && (
                            <Flex
                              width={"1px"}
                              height={"100%"}
                              bg={returnByTheme()}
                            ></Flex>
                          )}
                        </Flex>
                        <Flex flex={3} flexDirection={"column"} mb="20px">
                          <Flex
                            color={"GREY"}
                            fontSize={"14px"}
                            justifyContent={"space-between"}
                            alignItems={"flex-end"}
                            mb="5px"
                          >
                            <Flex>
                              {c?.user} , {commentDate}
                            </Flex>
                            {!isEditMode && (
                              <Flex gap="10px">
                                {/* comment attachment upload input */}
                                <Input
                                  display={"none"}
                                  ref={commentUploadRef}
                                  multiple={true}
                                  type={"file"}
                                  accept={".jpg,.png,.pdf"}
                                  onChange={(e) => {
                                    addCommentAttachment(
                                      e,
                                      indexArr.taskIndex,
                                      indexArr.commentIndex
                                    );
                                  }}
                                />
                                <UploadAttachIcon
                                  cursor="pointer"
                                  onClick={() => {
                                    commentUploadRef?.current?.click();
                                    setIndexArr({
                                      taskIndex: taskDataIndex,
                                      commentIndex: commentIndex,
                                    });
                                  }}
                                />
                                {/* delete comment button */}
                                <TrashIcon
                                  cursor={"pointer"}
                                  onClick={() => {
                                    const newArr = displayData?.[
                                      taskDataIndex
                                    ]?.comment?.filter(
                                      (comment, selectedCommentIndex) =>
                                        selectedCommentIndex !== commentIndex
                                    );

                                    getNewState(
                                      "comment",
                                      taskDataIndex,
                                      newArr
                                    );
                                  }}
                                />
                              </Flex>
                            )}
                          </Flex>
                          <Flex
                            p="10px"
                            borderRadius="10px"
                            background={"DISABLEDBG"}
                          >
                            {c?.message ? (
                              c?.message
                            ) : (
                              <Text color="DISABLEDTEXT">
                                {c?.user}{" "}
                                {t("TXT_Project_Deleted_comment_desc")}
                              </Text>
                            )}
                          </Flex>
                          {/* preview comment attachment */}
                          {c.commentAttachment?.map(
                            (a: any, attachmentIndex) => {
                              // const imageUrl = URL.createObjectURL(a?.file);
                              const extensionName = a?.originalName
                                .split(".")
                                .pop();
                              return (
                                <Flex
                                  key={attachmentIndex}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  borderBottomColor={"DISABLEDBG"}
                                  py="10px"
                                >
                                  <Flex mr={"10px"}>
                                    {(extensionName !== "pdf" && (
                                      <Image
                                        width={"30px"}
                                        height={"30px"}
                                        src={a.url}
                                        borderRadius={"5px"}
                                        fit={"cover"}
                                        loading={"lazy"}
                                      />
                                    )) || <PDFIcon />}

                                    <Flex alignItems={"center"} ml="5px">
                                      <Text
                                        ml="5px"
                                        noOfLines={1}
                                        textOverflow="ellipsis"
                                        maxW="250px"
                                      >
                                        {a?.originalName}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  <TrashIcon
                                    cursor={"pointer"}
                                    onClick={() => {
                                      const remainingAttachArr = displayData[
                                        taskDataIndex
                                      ].comment![
                                        commentIndex
                                      ].commentAttachment!.filter(
                                        (image, selectedCommentIndex) =>
                                          selectedCommentIndex !==
                                          attachmentIndex
                                      );

                                      // rebuild a task obj
                                      let cloneCommentArr = displayData[
                                        taskDataIndex
                                      ].comment!.map((comment, i) => {
                                        if (i == commentIndex) {
                                          return {
                                            ...comment,
                                            commentAttachment:
                                              remainingAttachArr,
                                          };
                                        } else {
                                          return comment;
                                        }
                                      });

                                      getNewState(
                                        "comment",
                                        taskDataIndex,
                                        cloneCommentArr
                                      );
                                    }}
                                  />
                                </Flex>
                              );
                            }
                          )}
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
            );
          })}

        {/* add section btn */}
        {displayData?.length < 10 && (
          <Flex p={"20px"}>
            <Flex
              borderRadius={"5px"}
              borderColor={returnByTheme()}
              borderWidth={1}
              onClick={() => {
                let clone = [...displayData];

                clone = [
                  ...clone,
                  {
                    title: "",
                    date: [
                      {
                        startDate: new Date(),
                        endDate: null,
                        key: "selection",
                      },
                    ],
                    desc: "",
                    comment: [],
                    attachment: [],
                  },
                ];
                isPreviewMode
                  ? setPreviewState(clone)
                  : updateProjectTaskState(clone);
              }}
              px="20px"
              py="3px"
              color={returnByTheme()}
              alignItems={"center"}
              cursor={"pointer"}
            >
              + {t("TXT_Add")}
            </Flex>
          </Flex>
        )}
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updateProjectTaskState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default ProjectTaskSection;
