import {
  Box,
  Input,
  Text,
  Flex,
  InputRightElement,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import { ReactComponent as ShowIcon } from "src/icons/auth/show.svg";
import { ReactComponent as HideIcon } from "src/icons/auth/hide.svg";
import { returnByTheme, returnByColor } from "../../utils/util";

interface ISelectProps {
  placeHolder: string;
  isRequired?: boolean;
  inputLabel?: string;
  hints?: string;
  onChange: (value: string) => void;
  inpputtedValue?: string;
  isDisable?: boolean;
  inputType?: string;
  leftIcon?: any;
  rightIcon?: any;
}

const FormPwInput = ({
  placeHolder,
  isRequired = false,
  inputLabel,
  hints,
  onChange,
  inpputtedValue,
  isDisable,
  inputType,
  leftIcon,
  rightIcon,
}: ISelectProps) => {
  const [showPw, setShowPw] = useState(true);

  return (
    <Flex>
      <Flex
        flex={1}
        flexDirection={"row"}
        py="10px"
        px="20px"
        justifyContent={"space-between"}
        gap="5px"
      >
        {/* label section */}
        <Flex flex={2} fontSize={15} color={"GREY"}>
          {isRequired ? (
            <Box>
              <Text display={"inline"}>{inputLabel}&nbsp;</Text>
              <Text color={"RD.1"} display={"inline"}>
                *
              </Text>
            </Box>
          ) : (
            <Flex>{inputLabel}</Flex>
          )}
        </Flex>
        {/* text input section */}
        <Flex flex={3} position="relative">
          <InputGroup flexDirection={"column"}>
            {leftIcon && (
              <InputLeftElement
                children={leftIcon}
                width={"fit-content"}
                height={"fit-content"}
                top={"5px"}
                left={"10px"}
                background={"WT"}
              />
            )}
            <Input
              type={showPw ? "text" : "password"}
              disabled={isDisable}
              placeholder={placeHolder}
              _placeholder={{ color: "DISABLEDTEXT" }}
              flex={1}
              px="10px"
              py="5px"
              borderColor={returnByTheme()}
              borderRadius="5px"
              borderWidth={1}
              justifyContent={"space-between"}
              value={inpputtedValue}
              onChange={(e) => onChange(e.target?.value)}
            />
            {rightIcon && (
              <InputRightElement
                children={rightIcon}
                width={"fit-content"}
                height={"fit-content"}
                top={"5px"}
                right={"10px"}
                background={"WT"}
              />
            )}
            {hints && (
              <Flex fontSize="10px" color={"GREY"}>
                {hints}
              </Flex>
            )}

            {inputType == "password" && (
              <Box
                zIndex={1}
                position={"absolute"}
                right={"10px"}
                top={"6px"}
                onClick={() => {
                  setShowPw(!showPw);
                }}
              >
                {(showPw && (
                  <ShowIcon
                    stroke={returnByColor()}
                    width="20px"
                    height="20px"
                  />
                )) || (
                  <HideIcon
                    stroke={returnByColor()}
                    width="20px"
                    height="20px"
                  />
                )}
              </Box>
            )}
          </InputGroup>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FormPwInput;
