import BaseInput from "./BaseInput";
import { ReactComponent as PasswordIcon } from "src/icons/auth/password.svg";
import { ReactComponent as ConfirmIcon } from "src/icons/auth/confirm-password.svg";
import { ReactComponent as ShowIcon } from "src/icons/auth/show.svg";
import { ReactComponent as HideIcon } from "src/icons/auth/hide.svg";
import { ChangeEventHandler, useState } from "react";
import { returnByTheme } from "../utils/util";
import { Box } from "@chakra-ui/react";

type Props = {
  id?: string;
  value?: string;
  title?: string;
  isShowWarning?: boolean;
  warning?: string;
  color?: string;
  confirm?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  backgroundColor?: string;
  borderColor?: string;
  autoComplete?: boolean;
};

const PasswordInput = ({
  id,
  value,
  title,
  isShowWarning,
  warning,
  color = returnByTheme(),
  confirm,
  onChange,
  backgroundColor,
  borderColor,
  autoComplete,
}: Props) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <BaseInput
      color={color}
      id={id}
      value={value}
      title={title}
      type={isShowPassword ? "text" : "password"}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      left={
        confirm ? (
          <ConfirmIcon stroke="currentcolor" />
        ) : (
          <PasswordIcon stroke="currentcolor" />
        )
      }
      right={
        <Box cursor="pointer" onClick={() => setIsShowPassword((v) => !v)}>
          {isShowPassword ? (
            <HideIcon stroke="currentcolor" />
          ) : (
            <ShowIcon stroke="currentcolor" />
          )}
        </Box>
      }
      isShowWarning={isShowWarning}
      warning={warning}
      onChange={onChange}
      autoComplete={autoComplete}
    />
  );
};

export default PasswordInput;
