import * as React from "react";
import * as ReactDOM from "react-dom/client";
 
import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  Flex,
} from "@chakra-ui/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { App } from "src/app/App";
import { useStore } from "src/app/App.store";
 
import "./index.scss";
import "./i18n";
import { store } from "./store";
import { colors } from "./theme";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { WebSocketLink } from "@apollo/client/link/ws";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  // uri: process.env.REACT_APP_API_URL,
  uri:process.env.REACT_APP_CUSTOM_ENV === "dev"
    ? "https://api-dev.solar-monster.io/public/graphql"
    : "https://api.solar-monster.io/public/graphql",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : "",
    },
  };
});

const wsLink = new WebSocketLink(
  //   new SubscriptionClient("ws://api-dev.solar-monster.io/public/subscriptions", {
  new SubscriptionClient(
    process.env.REACT_APP_CUSTOM_ENV === "dev"
      ? "wss://api-dev.solar-monster.io/public/graphql"
      : "wss://api.solar-monster.io/public/graphql",
    // process.env.REACT_APP_WEBSOCKET_URL!,
    {
      reconnect: true,
      lazy: true,
      // inactivityTimeout: 30000,
      connectionParams: {
        authorization: localStorage.getItem("token")
          ? `Bearer ${localStorage.getItem("token")}`
          : "",
      },
    }
  )
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

const theme = extendTheme({
  colors,
});

// console.log(`process.env`);
// console.log(process.env);
// console.log(process.env.REACT_APP_CUSTOM_ENV);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <HelmetProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </HelmetProvider>
        </BrowserRouter>
      </ChakraProvider>
    </ApolloProvider>
  </React.StrictMode>
);
