import {
  Box,
  Input,
  Text,
  Flex,
  Button,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";
import projectStatus from "../../../json/projectStatus.json";
import { useTranslation } from "react-i18next";
import FormSelectInput from "../../../components/createProjectForm/FormSelectInput";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import FormAddressInput from "src/components/createProjectForm/FormAddressInput";
import FormFilesInput from "src/components/createProjectForm/FormFilesInput";

import { ReactComponent as UploadImageIcon } from "src/icons/general/uploadImageIcon.svg";
import { ReactComponent as UploadFilesIcon } from "src/icons/general/uploadFilesIcon.svg";

import { IProjectDetailData } from "../../../type/IBaseInfotInterface";
import { FiCalendar } from "react-icons/fi";
import { uploadDoc } from "../../../utils/util";
import DatePicker from "src/components/DatePicker";
import moment from "moment";
import { isEmptyString, returnByTheme } from "src/utils/util";
import ProjectProgressSelectInput from "src/components/createProjectForm/ProjectProgressSelectInput";
import { useQuery } from "@apollo/client";
import { ListSiteTypesDocument } from "src/graphql/generated";
import _ from "lodash";

interface IProjectDetailProps {
  projectDetailData: IProjectDetailData;
  updateProjectDetailState: (value: any) => void;
  isPreviewMode?: boolean;
  isEditMode?: boolean;
  onClose?: () => void;
}

const ProjectDetailSection = ({
  projectDetailData,
  updateProjectDetailState,
  isPreviewMode = false,
  isEditMode = false,
  onClose = () => {},
}: IProjectDetailProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [previewState, setPreviewState] = useState<IProjectDetailData>({
    progress: "",
    fitCommencementDate: undefined,
    code: "",
    name: "",
    typeOfSite: "",
    address: [t("TXT_Hong_Kong"), "", "", ""],
  });
  const dateRangePickerRef = useRef(null);

  const displayData = isPreviewMode ? previewState : projectDetailData;

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(projectDetailData);
    }
    return () => {};
  }, []);

  const { data: allSiteType } = useQuery(ListSiteTypesDocument, {
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const getUploadDetail = async (key: string, value: any) => {
    const uploadedDoc = await uploadDoc(value);

    const newImageObj = value.map((f: any, i: number) => {
      return {
        // url: uploadedDoc.resut[i].url,
        // url: "https://solar-monster-dev-storage.s3.ap-southeast-1.amazonaws.com/sites/VfcqR540t0eS5SBDcuKFC/project_images/nyc.jpeg?AWSAccessKeyId=AKIAZXOZTAYNZKXDY7UB&Expires=1667897571&Signature=S4EQuMkM8qiZwb5UqdCzP5KwFp8%3D",
        url: uploadedDoc.result[i].url
          ? uploadedDoc.result[i].url
          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Tkm2I2eZS-dnuXspUUvm8WiB07J0XE8f4A&usqp=CAU",
        filePath: uploadedDoc.result[i].filePath,
        originalName: uploadedDoc.result[i].originalName,
      };
    });

    const finalImageObj =
      key == "projectImages"
        ? displayData?.projectImages
          ? _.concat(displayData?.projectImages, newImageObj)
          : newImageObj
        : displayData?.companyDocuments
        ? _.concat(displayData?.companyDocuments, newImageObj)
        : newImageObj;

    if (uploadedDoc.result) {
      getNewState(key, finalImageObj);
    }
  };

  const getNewState = (key: string, value: any) => {
    if (isPreviewMode) {
      setPreviewState({
        ...displayData,
        [key]: value,
      });
    } else {
      updateProjectDetailState({
        ...displayData,
        [key]: value,
      });
    }
  };

  const isProjectDetailPass = () => {
    if (
      isEmptyString(displayData.progress) ||
      isEmptyString(displayData.code) ||
      isEmptyString(displayData.name) ||
      isEmptyString(displayData.typeOfSite) ||
      isEmptyString(displayData.address[1]) ||
      isEmptyString(displayData?.address[2]) ||
      isEmptyString(displayData?.address[3])
    ) {
      return false;
    } else if (
      displayData.progress == "Completion" &&
      displayData.fitCommencementDate == undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  isProjectDetailPass();
  // console.log(`displayData in project detail`);
  // console.log(displayData);

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        // flex={1}
        width="100%"
        flexDirection={"column"}
      >
        {!isPreviewMode && (
          <SectionTitle title={t("TXT_Project_project_detail")} />
        )}

        {/* project progress section */}
        {isEditMode ? (
          <ProjectProgressSelectInput
            options={projectStatus}
            inputLabel={"TXT_Project_project_progress"}
            isRequired={true}
            selectedValue={displayData?.progress}
            onChange={(value: string) => getNewState("progress", value)}
            bgColor={returnByTheme()}
          />
        ) : (
          <FormSelectInput
            options={projectStatus}
            inputLabel={"TXT_Project_project_progress"}
            isRequired={true}
            selectedValue={displayData?.progress}
            onChange={(value: string) => getNewState("progress", value)}
            bgColor={returnByTheme()}
          />
        )}

        {displayData.progress == "completedAt" && (
          <Flex
            flex={1}
            flexDirection={"row"}
            px="20px"
            py="10px"
            bg={returnByTheme()}
            justifyContent={"space-between"}
            gap="5px"
          >
            <Flex flex={2} fontSize={15} color={"WT"}>
              <Box>
                <Text display={"inline"} color="WT">
                  {t("TXT_Project_fit_commencement_date")} &nbsp;
                </Text>
                <Text color={"RD.1"} display={"inline"}>
                  *
                </Text>
              </Box>
            </Flex>
            <Flex flex={3}>
              <Flex
                flex={1}
                px="10px"
                py="5px"
                borderColor={returnByTheme()}
                borderRadius="5px"
                borderWidth={1}
                justifyContent={"space-between"}
                alignItems="center"
                bg="WT"
                position="relative"
              >
                <Flex>
                  {displayData?.fitCommencementDate
                    ? moment(displayData?.fitCommencementDate).format(
                        "DD MMM, YYYY"
                      )
                    : ""}
                </Flex>
                <Icon
                  as={FiCalendar}
                  color={returnByTheme()}
                  cursor="pointer"
                  width="20px"
                  height="20px"
                  onClick={() => {
                    setIsShowDatePicker(true);
                  }}
                />
                {isShowDatePicker && (
                  <DatePicker
                    dateRangePickerRef={dateRangePickerRef}
                    onClose={() => setIsShowDatePicker(false)}
                    selectedDate={displayData?.fitCommencementDate}
                    setDate={(item: any) =>
                      getNewState("fitCommencementDate", item)
                    }
                  />
                )}
                {/* {isShowDatePicker && (
                  <DateRangePicker
                    dateRangePickerRef={dateRangePickerRef}
                    dateRange={displayData.fitCommencementDate}
                    onClose={() => {
                      setIsShowDatePicker(false);
                    }}
                    applyDateFilter={(newRange: any) => {
                      getNewState("fitCommencementDate", newRange);
                      setIsShowDatePicker(false);
                    }}
                  />
                )} */}
              </Flex>
            </Flex>
          </Flex>
        )}

        {/* project code section */}
        <FormTextInput
          isDisable={isEditMode}
          placeHolder={t("TXT_Project_project_code")}
          inputLabel={t("TXT_Project_project_code")}
          isRequired={true}
          onChange={(value: string) => getNewState("code", value)}
          inpputtedValue={displayData?.code}
          maxLen={12}
          limitSymbol={true}
          hints={t("TXT_Project_project_code_hint")}
        />

        {/* project name section */}
        <FormTextInput
          placeHolder={t("TXT_Project_project_name")}
          inputLabel={t("TXT_Project_project_name")}
          isRequired={true}
          onChange={(value: string) => getNewState("name", value)}
          inpputtedValue={displayData?.name}
        />

        {/* type of site section */}
        <FormSelectInput
          options={
            allSiteType
              ? allSiteType?.listSiteTypes?.siteTypes.map((st) => {
                  return {
                    value: st.name.toLowerCase().replaceAll("_", "-"),
                    name: `TXT_Option_${st.name}`,
                  };
                })
              : []
          }
          inputLabel={t("TXT_Project_Type_of_site")}
          isRequired={true}
          onChange={(value: string) => getNewState("typeOfSite", value)}
          selectedValue={displayData?.typeOfSite}
        />

        {/* address section */}
        <FormAddressInput
          inputLabel={t("TXT_Address")}
          isRequired={true}
          addressArr={displayData?.address}
          areaSelectedValue={displayData?.address[1]}
          distSelectedValue={displayData?.address[2]}
          buildingInputValue={displayData?.address[3]}
          areaOnchange={(value: string) => {
            let areaArr = displayData?.address;
            areaArr[1] = value;

            getNewState("address", areaArr);
          }}
          distOnchange={(value: string) => {
            let distArr = displayData?.address;
            distArr[2] = value;

            getNewState("address", distArr);
          }}
          placeHolder={t("TXT_Desc_fill_street_or_building")}
          buildingOnchange={(value: string) => {
            let buildingArr = displayData?.address;
            buildingArr[3] = value;

            getNewState("address", buildingArr);
          }}
        />

        {/* latitude and longitude section */}
        {/*<Flex>
          <Flex
            flex={1}
            flexDirection={"row"}
            p="20px"
            justifyContent={"space-between"}
            gap="5px"
          >
       
            <Flex flex={2} fontSize={15} color={"GREY"}>
              Latitude - Longitude
            </Flex>
             
            <Flex flex={3}>
              <Input
                type={"number"}
                placeholder={"Latitude"}
                flex={1}
                px="10px"
                py="5px"
                borderColor={returnByTheme()}
                borderRadius="5px"
                borderWidth={1}
                justifyContent={"space-between"}
                onChange={(e) => {
                  getNewState(
                    "latitude",
                    e.target?.value.replace(/[^0-9\.]+/g, "")
                  );
                }}
                value={displayData?.latitude}
              />
              <Flex width="20px"></Flex>
              <Input
                type={"number"}
                placeholder={"Longitude"}
                flex={1}
                px="10px"
                py="5px"
                borderColor={returnByTheme()}
                borderRadius="5px"
                borderWidth={1}
                justifyContent={"space-between"}
                onChange={(e) => {
                  getNewState(
                    "longitude",
                    e.target?.value.replace(/[^0-9\.]+/g, "")
                  );
                }}
                value={displayData?.longitude}
              />
            </Flex>
          </Flex>
        </Flex> /*}

        <Flex height="1px" background={"DISABLEDBG"} mx="20px"></Flex>

        {/* project image section */}

        <FormFilesInput
          inputLabel={t("TXT_Project_project_image")}
          limitedType=".jpg,.png"
          litmitedDesc={t("TXT_Desc_accept_jpg_and_png_only")}
          filesList={displayData?.projectImages}
          allowMulti={true}
          onUploadFiles={(file: Array<File>) => {
            getUploadDetail("projectImages", file);
            // getNewState("projectImages", file);
            // updateProjectDetailState({
            //   ...projectDetailData,
            //   projectImages: file,
            // });
          }}
          onDeleteFile={(index: number) => {
            const newArr = displayData?.projectImages?.filter(
              (image, i) => i !== index
            );

            getNewState(
              "projectImages",
              newArr?.map((image, i) => {
                return image;
              })
            );
            // updateProjectDetailState({
            //   ...projectDetailData,
            //   projectImages: newArr,
            // });
          }}
          uploadBtnIcon={<UploadImageIcon />}
          uploadBtnText={t("TXT_Desc_click_to_choose_image")}
        />

        <Flex height="1px" background={"DISABLEDBG"} mx="20px"></Flex>

        {/* company doucment section */}
        <FormFilesInput
          displayType="list"
          inputLabel={t("TXT_Project_Company_documents")}
          limitedType=".jpg,.png,.pdf"
          litmitedDesc={t("TXT_Desc_accept_jpgNpng_2mb")}
          filesList={displayData?.companyDocuments}
          allowMulti={true}
          onUploadFiles={(file: Array<File>) => {
            getUploadDetail("companyDocuments", file);
            // getNewState("companyDocuments", file);
            // updateProjectDetailState({
            //   ...projectDetailData,
            //   companyDocuments: file,
            // });
          }}
          onDeleteFile={(index: number) => {
            const newArr = displayData?.companyDocuments?.filter(
              (image, i) => i !== index
            );

            getNewState(
              "companyDocuments",
              newArr?.map((image, i) => {
                return image;
              })
            );
            // updateProjectDetailState({
            //   ...projectDetailData,
            //   companyDocuments: newArr,
            // });
          }}
          uploadBtnIcon={<UploadFilesIcon />}
          uploadBtnText={t("TXT_Desc_click_to_choose_file")}
        />
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            disabled={!isProjectDetailPass()}
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updateProjectDetailState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default ProjectDetailSection;
