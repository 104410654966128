import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Text,
  Box,
  Icon,
  useToast,
  useOutsideClick,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { returnByTheme, returnByColor } from "../../../utils/util";

import _ from "lodash";
import moment from "moment";
import PopUpModal from "src/components/PopUpModal";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { PieChart, Pie, Cell } from "recharts";
const ProjectSharingPieChart = ({ siteInfo }: any) => {
  const isClient = localStorage.getItem("sf_Role") == "true";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  // calc sharing ratio
  const calcSharingRatio = () => {
    const profitShareRatio = siteInfo?.site?.profitShares[0];
    if (profitShareRatio?.minimumGain) {
    }

    const clientRatio = profitShareRatio?.minimumGain
      ? Math.max(profitShareRatio?.minimumGain, profitShareRatio?.landOwnerGain)
      : profitShareRatio?.landOwnerGain!;

    const ratioArr = [
      { name: "Client", value: 100 - clientRatio },
      { name: "Investor", value: clientRatio },
    ];

    return ratioArr;
  };

  return (
    <Flex
      p="10px"
      borderRadius="8px"
      boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
      bg={"WT"}
    >
      <Flex flexDirection="column" flex={1}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex p="10px" color={returnByTheme()} fontWeight="bold">
            {t("TXT_Project_Share_ratio")}
          </Flex>
          <Flex alignItems="center">
            <Flex color="GREY">{t("TXT_Project_Start_date")}: &nbsp;</Flex>
            <Flex>
              {moment(siteInfo?.site?.createdAt).format("DD MMM, YYYY")}
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent="center">
          {(isClient && <ClientPieChart ratioData={calcSharingRatio()} />) || (
            <CustomPieChart ratioData={calcSharingRatio()} />
          )}
        </Flex>
        <Flex flexDir="column" gap="10px">
          <Flex>
            <Image
              src={
                isClient
                  ? require("../../../icons/general/yourRatioCircle.png")
                  : require("../../../icons/general/clientRatioCircle.png")
              }
              mr="10px"
            />
            {isClient ? `Your's ratio` : t("TXT_Project_Client_ratio")}
          </Flex>
          <Flex>
            <Image
              src={
                isClient
                  ? require("../../../icons/general/investorRatioCircleInGrey.png")
                  : require("../../../icons/general/investorRatioCircle.png")
              }
              mr="10px"
            />
            {t("TXT_Project_Investor_ratio")}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const CustomPieChart = ({ ratioData }: any) => {
  const { t } = useTranslation();
  const COLORS = [returnByColor(), "#69B3E3"];
  const [activeIndex, setActiveIndex] = useState(0);
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        // x={x}
        y={y}
        x={x > cx ? x - innerRadius / 2 : x + innerRadius / 2}
        // y={y > cy ? y - innerRadius / 2 : y - innerRadius / 2}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontWeight: "bold" }}
      >
        {percent !== 0 ? `${(percent * 100).toFixed(0)}%` : <></>}
      </text>
    );
  };

  return (
    // <ResponsiveContainer width="100%" height="100%">
    <PieChart width={200} height={200}>
      <Pie
        data={ratioData}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        activeIndex={activeIndex}
        // activeShape={renderActiveShape}
        startAngle={0}
        outerRadius={80}
        innerRadius={30}
        labelLine={false}
        paddingAngle={0}
        label={renderCustomizedLabel}
        // onMouseEnter={(_, index) => setActiveIndex(index)}
      >
        {ratioData.map((entry: any, index: number) => {
          return <Cell key={`cell-${index}`} fill={COLORS[index].toString()} />;
        })}
      </Pie>
      <text
        x={100}
        y={100}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={returnByColor()}
        style={{ fontWeight: "bold" }}
      >
        {t("TXT_Ratio")}
      </text>
    </PieChart>
    // </ResponsiveContainer>
  );
};

const ClientPieChart = ({ ratioData }: any) => {
  const { t } = useTranslation();
  const COLORS = [returnByColor(), "#C3CED9"];
  const [activeIndex, setActiveIndex] = useState(0);
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        // x={x}
        y={y}
        x={x > cx ? x - innerRadius / 2 : x + innerRadius / 2}
        // y={y > cy ? y - innerRadius / 2 : y - innerRadius / 2}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontWeight: "bold" }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    // <ResponsiveContainer width="100%" height="100%">
    <PieChart width={200} height={200}>
      <Pie
        data={ratioData}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        activeIndex={activeIndex}
        // activeShape={renderActiveShape}
        startAngle={0}
        outerRadius={75}
        innerRadius={40}
        labelLine={false}
        paddingAngle={0}
        // label={renderCustomizedLabel}
        // onMouseEnter={(_, index) => setActiveIndex(index)}
      >
        {ratioData.map((entry: any, index: number) => (
          <Cell key={`cell-${index}`} fill={COLORS[index].toString()} />
        ))}
      </Pie>

      <text
        x={100}
        y={95}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={returnByColor()}
        style={{ fontWeight: "bold", fontSize: "22px" }}
      >
        {ratioData[0].value}%
      </text>
      <text
        x={100}
        y={115}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={returnByColor()}
        style={{ fontWeight: "600", fontSize: "14px" }}
      >
        {t("TXT_Ratio")}
      </text>
    </PieChart>
    // </ResponsiveContainer>
  );
};

export default ProjectSharingPieChart;
