import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Icon,
  useToast,
  useOutsideClick,
  Image,
  ModalHeader,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  CloseButton,
  Input,
  RadioGroup,
  Radio,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { returnByTheme, returnByColor } from "../../../utils/util";

import _ from "lodash";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import CurrencyInput from "react-currency-input-field";
import { useMutation } from "@apollo/client";
import {
  CreateInvestmentDocument,
  UpdateInvestmentDocument,
} from "src/graphql/generated";

const ProjectCalculatorPanel = ({
  siteId,
  sharingRatioData,
  fitRate,
  investorId,
}: any) => {
  const { t } = useTranslation();

  const [showCalcModal, setShowCalcModal] = useState(false);

  return (
    <Flex
      py="10px"
      borderRadius="8px"
      boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
      bg={"WT"}
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex flexDir="column">
        <Flex px="10px" color={returnByTheme()} fontWeight="bold">
          {t("TXT_Calculator")}
        </Flex>
        <Flex fontSize="12px" color="DISABLEDTEXT" px="10px">
          {t("TXT_Calculator_title")}
        </Flex>
      </Flex>
      <Flex
        bg="#459ee3"
        borderRadius="25px"
        color="WT"
        p="5px 10px"
        mx="10px"
        cursor="pointer"
        onClick={() => setShowCalcModal(true)}
      >
        {t("TXT_Start")}
      </Flex>
      {showCalcModal && (
        <CalcPopup
          isShow={showCalcModal}
          onClose={() => setShowCalcModal(false)}
          sharingRatioData={sharingRatioData}
          fixedRatioData={0}
          fitRate={fitRate}
          siteId={siteId}
          investorId={investorId}
        />
      )}
    </Flex>
  );
};

type CalcProps = {
  isShow: boolean;
  onClose: Function;
  sharingRatioData: Array<any>;
  fixedRatioData: number;
  fitRate: number;
  siteId: string;
  investorId: string;
};

const CalcPopup = ({
  isShow,
  onClose,
  sharingRatioData,
  fixedRatioData,
  fitRate,
  siteId,
  investorId,
}: CalcProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("meData")!);
  const myCompanyID = loginData?.company?.id;

  const [pjStartDate, setPjStartDate] = useState(new Date());
  const [noOfPanel, setNoOfPanel] = useState(1);
  const [wattOfPanel, setWattOfPanel] = useState(0);
  const [estimateCostData, setEstimateCostData] = useState<{
    type: "price" | "irr";
    value: number;
  }>({
    type: "price",
    value: 1,
  });
  const [dailySolarIrr, setDailySolarIrr] = useState<number>(4.28);
  const [panelEffeciency, setPanelEffeciency] = useState<number>(75.0);
  const [firstYrDegradation, setFirstYrDegradation] = useState(0);
  const [secondYrDegradation, setSecondYrDegradation] = useState(0);
  const [otherYrDegradation, setOtherYrDegradation] = useState(0);
  const [profitTaxRate, setProfitTaxRate] = useState<number>(16.5);
  const [customFitRate, setCustomFitRate] = useState<number>(0);
  const [rateToContractor, setRateToContractor] = useState(0);

  const [createInvestment, { loading: creating }] = useMutation(
    CreateInvestmentDocument,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        toast({
          position: "bottom-right",
          title: t("Submit success."),
          status: "success",
        });
        onClose();
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );
  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isShow}
      onClose={() => onClose()}
      isCentered
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent borderTopRadius="18px">
        <ModalHeader borderTopRadius="15px" backgroundColor={"#004B92"}>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text color="WT"> {t("TXT_Calculator")}</Text>
            <CloseButton onClick={() => onClose()} color="WT" />
          </Flex>
        </ModalHeader>
        <ModalBody pt="0px" px="0px" pb="20px">
          <Flex bg="DISABLEDBG" p="20px" fontWeight="bold" mb="20px">
            {t("TXT_Calculator_panel_desc")}
          </Flex>
          {/* <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          > */}
          <Flex flex={1} px="20px" alignItems="center" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Project_Start_date")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex>
                <DatePicker
                  selected={pjStartDate}
                  maxDate={new Date("2033 12 31")}
                  customInput={
                    <Flex
                      width="fit-content"
                      alignItems="center"
                      gap="10px"
                      cursor="pointer"
                      color="#459ee3"
                      borderRadius="25px"
                      border="1px solid #459ee3"
                      py="5px"
                      px="15px"
                      onClick={(e) => e.preventDefault()}
                    >
                      {moment(pjStartDate).format("MMM, YYYY")}
                      <IoIosArrowDown />
                    </Flex>
                  }
                  onChange={(newDate: any) => {
                    setPjStartDate(newDate);
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </Flex>
              <Flex fontWeight="bold">to December 2023</Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="center" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_number_of_panel")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Button
                disabled={noOfPanel <= 0}
                onClick={() => {
                  //   if (noOfPanel <= 0) return;
                  setNoOfPanel(Number(noOfPanel ?? 0) - 1);
                }}
              >
                -
              </Button>
              <CurrencyInput
                className="calc-popup-input"
                style={{ border: "1px solid #459ee3", borderRadius: "4px" }}
                // defaultValue={1000}
                value={noOfPanel}
                decimalsLimit={2}
                onValueChange={(value: any, name) => {
                  setNoOfPanel(value);
                }}
              />
              <Button
                disabled={noOfPanel >= 1000}
                onClick={() => {
                  //   if (noOfPanel >= 1000) return;
                  setNoOfPanel(Number(noOfPanel ?? 0) + 1);
                }}
              >
                +
              </Button>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="center" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_watt_of_each_panel")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Button
                disabled={wattOfPanel <= 0}
                onClick={() => {
                  //   if (noOfPanel <= 0) return;
                  setWattOfPanel(Number(wattOfPanel ?? 0) - 1);
                }}
              >
                -
              </Button>
              <CurrencyInput
                className="calc-popup-input"
                style={{ border: "1px solid #459ee3", borderRadius: "4px" }}
                // defaultValue={1000}
                value={wattOfPanel}
                decimalsLimit={2}
                onValueChange={(value: any, name) => {
                  setWattOfPanel(value);
                }}
              />
              <Button
                disabled={wattOfPanel >= 1000}
                onClick={() => {
                  //   if (noOfPanel >= 1000) return;
                  setWattOfPanel(Number(wattOfPanel ?? 0) + 1);
                }}
              >
                +
              </Button>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="center" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Sharing_ratio")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex flex={1} flexDir="column" color="DISABLEDTEXT">
                <Flex
                  bg="DISABLEDBG"
                  border="1px solid #E8E8E8"
                  justifyContent="space-between"
                  p="5px"
                  borderRadius="5px"
                >
                  {sharingRatioData[0].landOwnerGain} {`%`}
                </Flex>
                <Flex fontSize="10px">{t("TXT_Project_Client_ratio")}</Flex>
              </Flex>
              <Flex mx="5px">+</Flex>
              <Flex flex={1} flexDir="column" color="DISABLEDTEXT">
                <Flex
                  bg="DISABLEDBG"
                  border="1px solid #E8E8E8"
                  justifyContent="space-between"
                  p="5px"
                  borderRadius="5px"
                >
                  {100 - sharingRatioData[0].landOwnerGain} {`%`}
                </Flex>
                <Flex fontSize="10px">{t("TXT_Project_Investor_ratio")}</Flex>
              </Flex>
              <Flex fontWeight="bold"> = 100%</Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_estimate_cost")}
            </Flex>
            <Flex flex={3} flexDir="column" alignItems="center" gap="10px">
              <Flex mr="auto">
                <RadioGroup
                  onChange={(value: any) => {
                    setEstimateCostData({
                      type: value,
                      value: estimateCostData.value,
                    });
                  }}
                  value={estimateCostData.type}
                >
                  <Stack direction="row">
                    <Radio colorScheme="blue" value="price">
                      {t("TXT_Calculator_price")}
                    </Radio>
                    <Radio colorScheme="blue" value="irr">
                      {t("TXT_Calculator_irr")}
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>

              <Flex
                justifyContent="space-between"
                // height="45px"
                borderRadius="4px"
                border="1px solid #459ee3"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  className="calc-popup-input"
                  // defaultValue={1000}
                  value={estimateCostData.value}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setEstimateCostData({
                      type: estimateCostData.type,
                      value: value,
                    });
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  HK$/W
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_average_daily_solar_irradiation")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex
                justifyContent="space-between"
                // height="45px"
                borderRadius="4px"
                border="1px solid #459ee3"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  className="calc-popup-input"
                  // defaultValue={1000}
                  value={dailySolarIrr}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setDailySolarIrr(value);
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  kWh/m2
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_panel_effeciency")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex
                justifyContent="space-between"
                // height="45px"
                borderRadius="4px"
                border="1px solid #459ee3"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  className="calc-popup-input"
                  // defaultValue={1000}
                  value={panelEffeciency}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setPanelEffeciency(value);
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  %
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_panel_effeciency")} -{" "}
              {t("TXT_Calculator_first_year")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex
                justifyContent="space-between"
                // height="45px"
                borderRadius="4px"
                border="1px solid #459ee3"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  className="calc-popup-input"
                  // defaultValue={1000}
                  value={firstYrDegradation}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setFirstYrDegradation(value);
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  %
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_panel_effeciency")} -{" "}
              {t("TXT_Calculator_second_year")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex
                justifyContent="space-between"
                // height="45px"
                borderRadius="4px"
                border="1px solid #459ee3"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  className="calc-popup-input"
                  // defaultValue={1000}
                  value={secondYrDegradation}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setSecondYrDegradation(value);
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  %
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_other_degradation")} -{" "}
              {t("TXT_Calculator_every_year")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex
                justifyContent="space-between"
                // height="45px"
                borderRadius="4px"
                border="1px solid #459ee3"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  className="calc-popup-input"
                  // defaultValue={1000}
                  value={otherYrDegradation}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setOtherYrDegradation(value);
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  %
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_profit_tax_rate")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex
                justifyContent="space-between"
                // height="45px"
                borderRadius="4px"
                border="1px solid #459ee3"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  className="calc-popup-input"
                  // defaultValue={1000}
                  value={profitTaxRate}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setProfitTaxRate(value);
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  %
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_fit_rate")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex
                justifyContent="space-between"
                borderRadius="4px"
                border="1px solid #E8E8E8"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  //   disabled
                  className="calc-popup-input"
                  value={fitRate !== 0 ? fitRate : customFitRate}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setCustomFitRate(value);
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  HK$/W
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} px="20px" alignItems="baseline" gap="10px" mb="20px">
            <Flex flex={2} color="GREY">
              {t("TXT_Calculator_rate_to_contractor")}
            </Flex>
            <Flex flex={3} alignItems="center" gap="10px">
              <Flex
                justifyContent="space-between"
                // height="45px"
                borderRadius="4px"
                border="1px solid #459ee3"
                overflow="hidden"
                width="100%"
              >
                <CurrencyInput
                  className="calc-popup-input"
                  // defaultValue={1000}
                  value={rateToContractor}
                  decimalsLimit={2}
                  onValueChange={(value: any, name) => {
                    setRateToContractor(value);
                  }}
                />
                <Flex
                  color="GREY"
                  justifyContent="center"
                  alignItems="center"
                  mr="10px"
                >
                  HK$/W
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex flexDirection="row" width="100%" px="20px">
            <Button
              backgroundColor="WT"
              _hover={{ backgroundColor: "WT" }}
              onClick={() => onClose()}
              marginTop="20px"
              borderRadius="10px"
              flex={1}
              borderColor="BK"
              borderWidth="1px"
            >
              <Text color="BK">{t("TXT_Cancel")}</Text>
            </Button>
            <Button
              // disabled={displayData.length <= 0}
              backgroundColor={returnByTheme()}
              _hover={{ backgroundColor: returnByTheme() }}
              onClick={() => {
                createInvestment({
                  variables: {
                    input: {
                      investorId: investorId,
                      siteId: siteId,
                      projectStartDate:
                        moment(pjStartDate).format("01-MMM-YYYY"),
                      projectEndDate: moment(new Date(2033, 11, 31)).format(
                        "31-MMM-YYYY"
                      ),
                      numberOfPanel: Number(noOfPanel) ?? 0,
                      wattOfPanel: Number(wattOfPanel) ?? 0,
                      sharingRatio: sharingRatioData[0].landOwnerGain ?? 0,
                      fixedRent: Number(fixedRatioData) ?? 0,
                      estimatePrice:
                        estimateCostData.type == "price"
                          ? Number(estimateCostData.value)
                          : undefined,
                      estimateIrr:
                        estimateCostData.type == "irr"
                          ? Number(estimateCostData.value)
                          : undefined,
                      dailySolarIrradiation: Number(dailySolarIrr) ?? 0,
                      panelEfficiency: Number(panelEffeciency) ?? 0,
                      panelDegradationFirstYear:
                        Number(firstYrDegradation) ?? 0,
                      panelDegradationSecondYear:
                        Number(secondYrDegradation) ?? 0,
                      otherDegradation: Number(otherYrDegradation) ?? 0,
                      taxRate: Number(profitTaxRate) ?? 0,
                      fitRate: fitRate !== 0 ? fitRate : Number(customFitRate),
                      omRate: Number(rateToContractor) ?? 0,
                    },
                  },
                });
              }}
              marginTop="20px"
              borderRadius="10px"
              flex={1}
              marginLeft="20px"
            >
              <Text color="WT">{t("TXT_Calculator_calculate")}</Text>
            </Button>
          </Flex>
          {/* </Flex> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProjectCalculatorPanel;
