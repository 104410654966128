import { Box, Input, Text, Flex } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import TextPreviewRow from "../../../components/createProjectForm/TextPreviewRow";
import { returnByTheme, returnByColor } from "../../../utils/util";
import { ReactComponent as EditIcon } from "src/icons/general/editIcon.svg";

import { IProjectManagerData } from "../../../type/IBaseInfotInterface";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface IProjecManagerProps {
  pmData: Array<any>;
  editPmDetailAction: () => void;
}

const PMDetailPreview = ({
  pmData,
  editPmDetailAction,
}: IProjecManagerProps) => {
  // console.log("pm detail section preview");
  // console.log(pmData);
  const { t } = useTranslation();

  return (
    <Box mb="30px">
      <SectionTitle
        title={t("TXT_Project_BItitle_project_manager")}
        editAction={() => editPmDetailAction()}
      />
      <Flex
        // key={i}
        backgroundColor={"WT"}
        borderRadius={"5px"}
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        px="20px"
        flexDirection={"column"}
      >
        {/* {pmData &&
          pmData?.length > 0 &&
          pmData?.map((m, i) => {
            return ( */}
        <Box>
          {/* <TextPreviewRow label="Name" value={m?.name} hadBorder={false} />
              <TextPreviewRow label="Phone" value={m.phone} /> */}
          <TextPreviewRow
            label={t("TXT_Project_Staff")}
            value={pmData.map((pm) => pm?.name).join(", ")}
            hadBorder={false}
          />
        </Box>
        {/* );
          })} */}
      </Flex>
    </Box>
  );
};

const SectionTitle = ({
  title,
  editAction,
}: {
  title: string;
  editAction: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb="5px">
      <Flex fontSize={18} fontWeight={"bold"}>
        {title}
      </Flex>
      <Flex
        cursor={"pointer"}
        borderRadius="4px"
        borderWidth="1px"
        borderColor={returnByTheme()}
        bg="WT"
        color={returnByTheme()}
        alignItems={"center"}
        px="20px"
        py="2px"
        onClick={() => editAction()}
      >
        <EditIcon stroke={returnByColor()} />
        <Box ml="5px">{t("TXT_Edit")}</Box>
      </Flex>
    </Flex>
  );
};

export default PMDetailPreview;
