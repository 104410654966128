import {
  Box,
  Input,
  Text,
  Flex,
  Button,
  Icon,
  Checkbox,
  Image,
  Radio,
  Stack,
  RadioGroup,
  useOutsideClick,
  useToast,
  propNames,
} from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import {
  FiAlertTriangle,
  FiTrash,
  FiUserPlus,
  FiMoreHorizontal,
} from "react-icons/fi";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseLine } from "react-icons/ri";
import { ReactComponent as EditIcon } from "src/icons/general/editInvoiceIcon.svg";
import { ReactComponent as TrashIcon } from "src/icons/general/trashIcon.svg";
import { ReactComponent as ViewStaffIcon } from "src/icons/general/viewStaffIcon.svg";
import { IInvoiceConfig } from "../../../type/IAccountManageInterface";
import {
  convertAsAvatarTitle,
  randomBgColor,
  returnByTheme,
} from "src/utils/util";
import { useLazyQuery, useMutation } from "@apollo/client";
import EditModal from "src/pages/project/EditModal";
import PopUpModal from "src/components/PopUpModal";
import { IconContext } from "react-icons";
import {
  CreateUserDocument,
  DeleteUserDocument,
  UpdateUserDocument,
  UsersDocument,
} from "src/graphql/generated";

const SubAccountSection = ({
  allowGiveAdmin = false,
}: {
  allowGiveAdmin?: boolean;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);

  const [openMoreAction, setOpenMoreAction] = useState({
    isOpened: false,
    currentRow: -1,
  });
  const loginData = JSON.parse(localStorage.getItem("meData")!);
  const myCompanyID = loginData?.company?.id;

  const [getStaffList, { data: staffList }] = useLazyQuery(UsersDocument, {
    variables: {
      filter: {
        companyId: myCompanyID,
      },
    },
    onCompleted: (data) => {
      setOpenMoreAction({
        isOpened: false,
        currentRow: -1,
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  useEffect(() => {
    getStaffList();
    return () => {};
  }, []);

  return (
    <Flex borderRadius="5px" flexDirection={"column"}>
      <Flex
        bg={returnByTheme()}
        p="20px"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="5px"
      >
        <Flex flexDir="column" color="WT">
          <Text fontWeight={"bold"}>{t("TXT_Project_Staff")}</Text>
          <Text>{t("TXT_Account_Give_staff_access_desc")}</Text>
        </Flex>
        <Flex
          bg="WT"
          p="10px"
          borderRadius="25px"
          justifyContent="center"
          alignItems="center"
          minW="200px"
          cursor="pointer"
          onClick={() => setShowAddStaffModal(true)}
        >
          <Icon
            as={FiUserPlus}
            color={returnByTheme()}
            width="20px"
            height="20px"
          />
          <Text color={returnByTheme()} ml="10px">
            {t("TXT_Account_Add_staff")}
          </Text>
        </Flex>
      </Flex>

      <Flex py="20px" fontWeight="bold">
        {t("TXT_Account_Staff_member_total")}
      </Flex>

      {/* sorting bar */}
      <Flex bg="WT" p="20px" mb="5px" borderRadius="5px">
        <Flex
          flex={5}
          alignItems="center"
          // cursor="pointer"
          onClick={() => {
            // console.log("sort by member");
          }}
        >
          <Text fontWeight="medium">{t("TXT_Member")}</Text>
          <Icon as={RiArrowDownSLine} ml="5px" width="20px" height="20px" />
        </Flex>
        <Flex
          flex={2}
          alignItems="center"
          cursor="pointer"
          onClick={() => {
            // console.log("sort by grade");
          }}
        >
          <Text fontWeight="medium">{t("TXT_Grade")}</Text>
          <Icon as={RiArrowDownSLine} ml="5px" width="20px" height="20px" />
        </Flex>
        <Flex flex={1} justifyContent="center" fontWeight="medium">
          {t("TXT_Invoice_Actions")}
        </Flex>
      </Flex>

      {staffList?.users.nodes.map((staff, i) => {
        return (
          <Flex
            key={i}
            bg="WT"
            p="20px"
            my="5px"
            borderRadius="5px"
            alignItems="center"
          >
            <Flex flex={5} alignItems="center">
              <Flex
                borderRadius="50%"
                width="45px"
                height="45px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                border="1px solid #f0f0f0"
                bg={
                  staff.profileImage?.url
                    ? `url(${staff.profileImage?.url})`
                    : randomBgColor()
                }
                bgSize="contain"
                bgPos="center"
                bgRepeat="no-repeat"
              >
                {!staff.profileImage?.url && (
                  <Text color="WT">
                    {convertAsAvatarTitle(staff?.name ?? staff?.email)}
                  </Text>
                )}
              </Flex>
              <Flex flexDir="column" ml="10px">
                <Text fontWeight="bold">{staff.name}</Text>
                <Text color="GREY">{staff.email}</Text>
              </Flex>
            </Flex>
            <Flex flex={2} color="GREY">
              {staff.roles[0]}
            </Flex>
            <Flex
              position="relative"
              flex={1}
              justifyContent="center"
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation();
                setOpenMoreAction({
                  isOpened:
                    openMoreAction?.currentRow === i
                      ? !openMoreAction?.isOpened
                      : true,
                  currentRow: i,
                });
              }}
            >
              {(openMoreAction?.isOpened === true &&
                openMoreAction?.currentRow === i && (
                  <Icon
                    as={RiCloseLine}
                    width="30px"
                    height="30px"
                    color="GREY"
                  />
                )) || (
                <Icon
                  as={FiMoreHorizontal}
                  width="30px"
                  height="30px"
                  color="GREY"
                />
              )}
              {openMoreAction?.isOpened === true &&
                openMoreAction?.currentRow === i && (
                  <MoreActionModal
                    staffData={staff}
                    closeMenu={() =>
                      setOpenMoreAction({
                        isOpened: false,
                        currentRow: -1,
                      })
                    }
                    updateStaffList={() => getStaffList()}
                    allowGiveAdmin={allowGiveAdmin}
                  />
                )}
            </Flex>
          </Flex>
        );
      })}

      {/* add staff modal */}
      <AddStaffModal
        isShow={showAddStaffModal}
        onClose={() => setShowAddStaffModal(false)}
        getStaffList={() => getStaffList()}
        allowGiveAdmin={allowGiveAdmin}
      />
    </Flex>
  );
};

const AddStaffModal = ({
  isShow,
  onClose,
  getStaffList,
  allowGiveAdmin,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [value, setValue] = useState("admin");

  const loginData = JSON.parse(localStorage.getItem("meData")!);
  const myCompanyID = loginData?.company?.id;

  const [addStaff, { loading: addingStaff }] = useMutation(CreateUserDocument, {
    onCompleted: (data) => {
      onClose();
      getStaffList();
      return toast({
        position: "bottom-right",
        title: `Added one staff`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  return (
    <EditModal
      isShow={isShow}
      onClose={() => onClose()}
      headerColor={returnByTheme()}
      headerText={t("TXT_Account_Add_staff")}
      size="xl"
      bodyPx="0"
    >
      <Flex flexDir="column" width="100%">
        <Flex alignItems="center" px="20px">
          <Flex flex={2} fontSize={15} color={"GREY"}>
            <Box>
              <Text display={"inline"}>{t("TXT_Email")} &nbsp;</Text>
              <Text color={"RD.1"} display={"inline"}>
                *
              </Text>
            </Box>
          </Flex>
          <Flex flex={3}>
            <Input
              placeholder={t("TXT_Email")}
              _placeholder={{ color: "DISABLEDTEXT" }}
              flex={1}
              px="10px"
              borderColor={returnByTheme()}
              borderRadius="5px"
              borderWidth={1}
              justifyContent={"space-between"}
              value={email}
              onChange={(e) => {
                setEmail(e.target?.value);
              }}
            />
          </Flex>
        </Flex>

        <Flex my="10px" bg="DISABLEDBG" p="20px" fontWeight="bold">
          {t("TXT_Account_Select_user_grade_desc")}
        </Flex>

        <RadioGroup
          defaultValue="1"
          px="20px"
          onChange={setValue}
          value={value}
        >
          <Stack>
            {allowGiveAdmin && (
              <>
                <Radio value="admin">{t("TXT_Account_admin")}</Radio>
                <Flex color="GREY">
                  <Text ml="25px">{t("TXT_Account_Administrator_desc")}</Text>
                </Flex>
              </>
            )}
            <Radio value="editor">{t("TXT_Account_editor")}</Radio>
            <Flex color="GREY">
              <Text ml="25px">{t("TXT_Account_Editor_desc")}</Text>
            </Flex>
            <Radio value="viewer">{t("TXT_Account_viewer")}</Radio>
            <Flex color="GREY">
              <Text ml="25px">{t("TXT_Account_Viewer_desc")}</Text>
            </Flex>
          </Stack>
        </RadioGroup>
      </Flex>
      <Flex flexDirection="row" width="100%" px="20px">
        <Button
          backgroundColor="WT"
          _hover={{ backgroundColor: "WT" }}
          onClick={() => onClose()}
          marginTop="20px"
          borderRadius="10px"
          flex={1}
          borderColor="BK"
          borderWidth="1px"
        >
          <Text color="BK">{t("TXT_Cancel")}</Text>
        </Button>
        <Button
          backgroundColor={returnByTheme()}
          _hover={{ backgroundColor: returnByTheme() }}
          isLoading={addingStaff}
          loadingText={t("TXT_Loading")}
          spinnerPlacement="end"
          onClick={() => {
            addStaff({
              variables: {
                input: {
                  email: email,
                  companyId: myCompanyID,
                  roles: [value],
                },
              },
            });
          }}
          marginTop="20px"
          borderRadius="10px"
          flex={1}
          marginLeft="20px"
        >
          <Text color="WT">{t("TXT_Account_Send_invite")}</Text>
        </Button>
      </Flex>
    </EditModal>
  );
};

const EditStaffModal = ({
  isShow,
  onClose,
  staffData,
  updateStaffList,
  allowGiveAdmin,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [value, setValue] = useState(staffData.roles[0]);
  const [updateStaff, { loading: updatingStaff }] = useMutation(
    UpdateUserDocument,
    {
      onCompleted: (data) => {
        onClose();
        updateStaffList();
        return toast({
          position: "bottom-right",
          title: `${staffData.email} was updated`,
          status: "success",
        });
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  return (
    <EditModal
      isShow={isShow}
      onClose={() => onClose()}
      headerColor={returnByTheme()}
      headerText={t("TXT_Account_Edit_staff")}
      size="xl"
      bodyPx="0"
    >
      <Flex flexDir="column" width="100%">
        <Flex alignItems="center" px="20px">
          <Flex flex={2} fontSize={15} color={"GREY"}>
            <Box>
              <Text display={"inline"}>{t("TXT_Email")} &nbsp;</Text>
              <Text color={"RD.1"} display={"inline"}>
                *
              </Text>
            </Box>
          </Flex>
          <Flex flex={3} py="5px">
            {staffData.email}
          </Flex>
        </Flex>

        <Flex my="10px" bg="DISABLEDBG" p="20px" fontWeight="bold">
          {t("TXT_Account_Select_user_grade_desc")}
        </Flex>

        <RadioGroup
          defaultValue="1"
          px="20px"
          onChange={setValue}
          value={value}
        >
          <Stack>
            {allowGiveAdmin && (
              <>
                <Radio value="admin">{t("TXT_Account_admin")}</Radio>
                <Flex color="GREY">
                  <Text ml="25px">{t("TXT_Account_Administrator_desc")}</Text>
                </Flex>
              </>
            )}
            <Radio value="editor">{t("TXT_Account_editor")}</Radio>
            <Flex color="GREY">
              <Text ml="25px">{t("TXT_Account_Editor_desc")}</Text>
            </Flex>
            <Radio value="viewer">{t("TXT_Account_viewer")}</Radio>
            <Flex color="GREY">
              <Text ml="25px">{t("TXT_Account_Viewer_desc")}</Text>
            </Flex>
          </Stack>
        </RadioGroup>
      </Flex>
      <Flex flexDirection="row" width="100%" px="20px">
        <Button
          backgroundColor="WT"
          _hover={{ backgroundColor: "WT" }}
          onClick={() => onClose()}
          marginTop="20px"
          borderRadius="10px"
          flex={1}
          borderColor="BK"
          borderWidth="1px"
        >
          <Text color="BK">{t("TXT_Cancel")}</Text>
        </Button>
        <Button
          backgroundColor={returnByTheme()}
          _hover={{ backgroundColor: returnByTheme() }}
          isLoading={updatingStaff}
          loadingText={t("TXT_Loading")}
          spinnerPlacement="end"
          onClick={() => {
            updateStaff({
              variables: {
                input: {
                  id: staffData.id,
                  roles: [value],
                },
              },
            });
          }}
          marginTop="20px"
          borderRadius="10px"
          flex={1}
          marginLeft="20px"
        >
          <Text color="WT">{t("TXT_Confirm")}</Text>
        </Button>
      </Flex>
    </EditModal>
  );
};

const MoreActionModal = (props: any) => {
  const [showViewStaffModal, setShowViewStaffModal] = useState(false);
  const [showEditStaffModal, setShowEditStaffModal] = useState(false);
  const [showDeleteStaffModal, setShowDeleteStaffModal] = useState(false);
  const moreActionRef = useRef<any>(null);
  const { t } = useTranslation();
  const toast = useToast();

  const [deleteStaff, { loading: removingStaff }] = useMutation(
    DeleteUserDocument,
    {
      onCompleted: (data) => {
        setShowDeleteStaffModal(false);
        props.updateStaffList();
        return toast({
          position: "bottom-right",
          title: `${props.staffData.email} was deleted`,
          status: "success",
        });
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  return (
    <Flex
      ref={moreActionRef}
      // display={props?.isVisible ? "flex" : "none"}
      backgroundColor={"WT"}
      position="absolute"
      right={0}
      top={10}
      flexDirection={"column"}
      zIndex={30}
      borderWidth={1}
      borderColor="GREY"
      borderRadius={10}
      overflow="hidden"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex flexDirection={"column"}>
        <Flex alignItems={"center"}>
          <Button
            backgroundColor="WT"
            flex={1}
            justifyContent={"flex-start"}
            onClick={(e) => setShowViewStaffModal(true)}
          >
            <ViewStaffIcon width="27px" height="27px" />
            <Text color={"GREY"} marginLeft="5px">
              {t("TXT_Account_View_detail")}
            </Text>
          </Button>
        </Flex>

        <Flex alignItems={"center"}>
          <Button
            backgroundColor="WT"
            flex={1}
            justifyContent={"flex-start"}
            onClick={() => setShowEditStaffModal(true)}
          >
            <EditIcon />
            <Text color={"GREY"} marginLeft="5px">
              {t("TXT_Account_Edit_member")}
            </Text>
          </Button>
        </Flex>

        <Flex alignItems={"center"}>
          <Button
            backgroundColor="WT"
            flex={1}
            justifyContent={"flex-start"}
            onClick={() => setShowDeleteStaffModal(true)}
          >
            <Icon as={FiTrash} width="25px" height="25px" color="GREY" />
            <Text color={"GREY"} marginLeft="5px">
              {t("TXT_Account_Delete_member")}
            </Text>
          </Button>
        </Flex>
      </Flex>

      {/* View staff modal */}
      <PopUpModal
        isShow={showViewStaffModal}
        onClose={() => setShowViewStaffModal(false)}
        headerColor={returnByTheme()}
        headerText={t("TXT_Account_Staff_detail")}
      >
        <Flex flexDirection="column" pt="10px">
          <Flex flex={5} alignItems="center" gap="10px" mb="20px">
            <Flex
              bg={randomBgColor()}
              borderRadius="50%"
              width="100px"
              height="100px"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="28px" color="WT" fontWeight="bold">
                {convertAsAvatarTitle(
                  props.staffData.name ?? props.staffData.email
                )}
              </Text>
            </Flex>
            <Flex flexDir="column" ml="10px" gap="5px">
              <Text fontSize="25px" fontWeight="bold">
                {props.staffData.name}
              </Text>
              <Flex
                fontSize="18px"
                color="WT"
                bg={returnByTheme()}
                py="5px"
                px="10px"
                borderRadius="5px"
              >
                <Text>{t("TXT_Grade")}: </Text>
                <Text fontWeight="bold">
                  {t(`TXT_Account_${props.staffData.roles[0]}`)}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex py="20px" borderTopWidth="1px">
            <Flex flex={2} color="GREY">
              {t("TXT_Email")}
            </Flex>
            <Flex flex={3} justifyContent="flex-end" fontWeight="bold">
              {props.staffData.email}
            </Flex>
          </Flex>

          <Flex py="20px" borderTopWidth="1px">
            <Flex flex={2} color="GREY">
              {t("TXT_Phone")}
            </Flex>
            <Flex flex={3} justifyContent="flex-end" fontWeight="bold">
              {props.staffData.phone?.areaCode} {props.staffData.phone?.phone}
            </Flex>
          </Flex>

          <Flex py="20px" borderTopWidth="1px">
            <Flex flex={2} color="GREY">
              {t("TXT_Account_Project_count")}
            </Flex>
            <Flex flex={3} justifyContent="flex-end" fontWeight="bold">
              3
            </Flex>
          </Flex>
        </Flex>
      </PopUpModal>

      {/* edit staff modal */}
      <EditStaffModal
        isShow={showEditStaffModal}
        onClose={() => setShowEditStaffModal(false)}
        staffData={props.staffData}
        updateStaffList={() => props.getStaffList}
        allowGiveAdmin={props.allowGiveAdmin}
      />

      {/* delete member modal */}
      <PopUpModal
        isShow={showDeleteStaffModal}
        onClose={() => setShowDeleteStaffModal(false)}
        headerColor={"RD.3"}
        headerText={t("TXT_Alert")}
      >
        <Flex flexDirection="column">
          <Flex flexDirection="column" gap="10px" alignItems="center">
            <IconContext.Provider value={{ color: "#E4513F", size: "100px" }}>
              <FiAlertTriangle />
            </IconContext.Provider>
            <Flex fontWeight="bold">
              {props.staffData.email}{" "}
              {t("TXT_Account_Will_lose_all_access_desc")}
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" gap="10px">
            <Button
              flex={1}
              backgroundColor="WT"
              border="1px solid #000000"
              // _hover={{ backgroundColor: {returnByTheme()} }}
              // disabled={loading}
              onClick={() => setShowDeleteStaffModal(false)}
              marginTop="20px"
              borderRadius="40px"
            >
              {t("TXT_No_keep_it")}
            </Button>
            <Button
              flex={1}
              color="WT"
              backgroundColor={"RD.3"}
              _hover={{ backgroundColor: "RD.3" }}
              disabled={removingStaff}
              onClick={() => {
                deleteStaff({
                  variables: {
                    id: props.staffData.id,
                  },
                });
              }}
              marginTop="20px"
              borderRadius="40px"
            >
              {t("TXT_Yes_delete")}
            </Button>
          </Flex>
        </Flex>
      </PopUpModal>
    </Flex>
  );
};

export default SubAccountSection;
