import {
  Box,
  Button,
  Text,
  Flex,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useRef } from "react";
import { useEffect, useState } from "react";

import { returnByTheme } from "../../../utils/util";
import { ReactComponent as ArrowDownBlack } from "src/icons/general/chevron-down-black.svg";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { UsersDocument } from "src/graphql/generated";
interface IProjecManagerProps {
  pmData: Array<string>;
  updatePMDetailState: (value: Array<string>) => void;
  // addMoreSection?: (clone: any) => void;
  isPreviewMode?: boolean;
  onClose?: () => void;
}

const ProjectManagerSection = ({
  pmData,
  updatePMDetailState,
  // addMoreSection,
  isPreviewMode = false,
  onClose = () => {},
}: IProjecManagerProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  // console.log("pm section state");
  // console.log(pmData);
  const [toggle, setToggle] = useState(false);
  const ref = useRef(null);
  const loginData = JSON.parse(localStorage.getItem("meData")!);
  const myCompanyID = loginData?.company?.id;

  const { data: staffList } = useQuery(UsersDocument, {
    variables: {
      filter: {
        companyId: myCompanyID,
      },
    },
    onCompleted: (data) => {},
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  useOutsideClick({
    ref: ref,
    handler: () => setToggle(false),
  });

  const [previewState, setPreviewState] = useState<Array<any>>([]);
  const displayData = isPreviewMode ? previewState : pmData;

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(pmData);
    }
    return () => {};
  }, []);

  const getNewState = (value: any) => {
    // let cloneArr = displayData.map((obj, index) => {
    //   if (index == i) {
    //     return { ...obj, [key]: value };
    //   } else {
    //     return obj;
    //   }
    // });

    const isFound = displayData.some((element) => {
      if (element.id === value.id) {
        return true;
      }

      return false;
    });

    let cloneArr = [...displayData];
    if (isFound) {
      cloneArr = displayData.filter((o) => o.id !== value.id);
    } else {
      cloneArr = [...displayData, value];
    }

    if (isPreviewMode) {
      setPreviewState(cloneArr);
    } else {
      updatePMDetailState(cloneArr);
    }
  };

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        width={"100%"}
        flexDirection={"column"}
        mt="20px"
      >
        {!isPreviewMode && (
          <SectionTitle title={t("TXT_Project_Project_manager")} />
        )}

        {/* {displayData &&
          displayData?.map((d, i) => {
            return ( */}
        <Flex
          // key={i}
          // fontWeight={"bold"}
          borderBottomColor={"DISABLEDBG"}
          borderBottomWidth={1}
          justifyContent="space-between"
          alignItems="center"
          flexDir="column"
        >
          <Flex
            flex={1}
            flexDirection={"row"}
            px="20px"
            py="10px"
            // px="20px"
            // py="10px"
            justifyContent={"space-between"}
            ref={ref}
            gap="5px"
            width="100%"
          >
            {/* label section */}
            <Flex flex={2} fontSize={15} color={"GREY"}>
              <Text display={"inline"}>{t("TXT_Project_Staff")} &nbsp;</Text>
              {/* <Text color={"RD.1"} display={"inline"}>
                *
              </Text> */}
            </Flex>

            {/* select list section */}
            <Flex position={"relative"} flex={3}>
              <Flex
                flex={1}
                onClick={() => setToggle(!toggle)}
                px="10px"
                py="5px"
                borderColor={returnByTheme()}
                borderRadius="5px"
                borderWidth={1}
                justifyContent={"space-between"}
              >
                {/* {selectedValue ? selectedValue : options[0]?.label}{" "}
            <ArrowDownBlack /> */}
                {/* {selectedValue ? selectedValue : "Please select one option"} */}
                {t("TXT_Project_Select_staff")}
                <ArrowDownBlack />
              </Flex>
              <Flex
                position={"absolute"}
                top={"40px"}
                right={0}
                flex={1}
                width={"100%"}
                display={toggle ? "flex" : "none"}
                flexDirection={"column"}
                zIndex={1}
                backgroundColor={"WT"}
                borderBottomRadius="5px"
                borderWidth={1}
                borderColor={returnByTheme()}
              >
                {staffList?.users.nodes.map((o, i) => {
                  let isSelected = displayData.map((d) => d.id).includes(o.id);
                  // let isSelected = true;

                  return (
                    <Flex
                      key={i}
                      justifyContent={"space-between"}
                      onClick={() => {
                        getNewState(
                          { id: o?.id, name: o?.name }
                          // name: o?.name,
                          // email: o?.email,
                          // phone: o?.phone?.phone,
                          // companyId: o?.companyId,
                        );
                        // updatePMDetailState(o?.label);
                        setToggle(false);
                      }}
                      my="5px"
                      mx="10px"
                      _hover={{
                        cursor: "pointer",
                      }}
                    >
                      <Text
                        color={"GREY"}
                        _hover={{
                          color: returnByTheme(),
                        }}
                      >
                        {o?.name ?? `no name ${i}`}
                      </Text>
                      {isSelected && <CheckIcon color={"GREY"} w={3} h={4} />}
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            flex={1}
            py="5px"
            px="10px"
            bg="WT"
            flexWrap="wrap"
            border="1px solid #004b92"
            borderRadius="8px"
            mx="20px"
            mb="20px"
            gap="10px"
            width="calc(100% - 40px)"
            minH="45px"
          >
            {displayData.map((d, i) => {
              return (
                <Flex
                  key={i}
                  py="5px"
                  px="10px"
                  alignItems="center"
                  gap="10px"
                  bg="DISABLEDTEXT"
                  borderRadius="8px"
                >
                  {staffList?.users.nodes
                    .filter((tempPm, i) => {
                      return tempPm.id == d?.id;
                    })
                    .map((pm) => {
                      return pm.name;
                    })}
                  {/* {d} */}
                  <CloseIcon
                    cursor="pointer"
                    _hover={{
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      getNewState(d);
                    }}
                  />
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            // disabled={displayData.length <= 0}
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updatePMDetailState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default ProjectManagerSection;
