import { Box, Flex, Image } from "@chakra-ui/react";

import { ReactComponent as PDFIcon } from "src/icons/general/pdfIcon.svg";
import { returnByColor } from "src/utils/util";
import _ from "lodash";

interface IFileUploadProps {
  label: string;
  filesList?: any;
  hadBorder?: boolean;
}

const FilePreviewRow = ({
  label,
  filesList,
  hadBorder = true,
}: IFileUploadProps) => {
  return (
    <Flex
      borderTop={hadBorder ? "1px" : "0"}
      borderColor="DISABLEDTEXT"
      py={"20px"}
    >
      <Flex flex={2} color="GREY">
        {label}
      </Flex>
      <Flex flex={3}>
        <Flex
          flexWrap={"wrap"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          width={"100%"}
        >
          {filesList &&
            filesList.map((a: any, i: number) => {
              // const imageUrl = URL.createObjectURL(a.file);
              const extensionName = a?.originalName.split(".").pop();
              return (
                <Flex
                  key={i}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  borderBottomColor={"DISABLEDBG"}
                  py="10px"
                >
                  <Flex mr={"10px"}>
                    {(extensionName !== "pdf" && (
                      <Image
                        width={"30px"}
                        height={"30px"}
                        src={a.url}
                        borderRadius={"5px"}
                        fit={"cover"}
                        loading={"lazy"}
                      />
                    )) || <PDFIcon />}

                    <Flex alignItems={"center"} ml="5px">
                      <Flex
                        fontWeight={"normal"}
                        maxWidth={"200px"}
                        overflow={"hidden"}
                      >
                        {a?.originalName}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilePreviewRow;
