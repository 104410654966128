import {
  Flex,
  Text,
  Select,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Box,
  ModalOverlay,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import addressSelection from "../json/addressSelection.json";
import {
  ReactNode,
  MouseEventHandler,
  useState,
  useEffect,
  useRef,
} from "react";
import { returnByTheme } from "../utils/util";
import { CheckIcon } from "@chakra-ui/icons";
import {
  ISelectFilterOption,
  IAppliedFilterOption,
} from "../type/IFilterOptInterface";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { ListSiteRegionsDocument } from "src/graphql/generated";

type SelectProps = {
  filterName?: string;
  onClose: () => void;
  options?: ISelectFilterOption;
  applyAction: (prosp: any) => void;
  appliedFilterOpt: IAppliedFilterOption;
  filterListRef?: any;
};

const FilterSelectOptions = ({
  filterName = "TXT_Select_all",
  onClose,
  options,
  applyAction,
  appliedFilterOpt,
  filterListRef,
}: SelectProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedOptions, setSelectedOptions] =
    useState<IAppliedFilterOption>(appliedFilterOpt);

  const [getRegionList, { data: regionList }] = useLazyQuery(
    ListSiteRegionsDocument,
    {
      fetchPolicy: "cache-first",
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: `Fail to fetch region list`,
          status: "error",
        });
      },
    }
  );

  const isSelectedAll = options?.list?.every((e) =>
    selectedOptions?.[options?.type]?.includes(e?.value)
  );

  const updateSelectedOption = (value: string) => {
    const type = options?.type!;

    if (selectedOptions?.[type].includes(value)) {
      let filteredArr = selectedOptions?.[type]?.filter((o) => o !== value);

      setSelectedOptions({ ...selectedOptions, [type]: filteredArr });
    } else {
      let newArr = [...selectedOptions?.[type], value];

      setSelectedOptions({ ...selectedOptions, [type]: newArr });
    }
  };

  const selectAllOptions = () => {
    if (isSelectedAll) {
      const currentFilterListValue = options?.list?.map((o) => o.value);
      const removedFilter = selectedOptions?.[options?.type!]?.filter(
        (e) => !currentFilterListValue?.includes(e)
      );
      setSelectedOptions({
        ...selectedOptions,
        [options?.type!]: removedFilter,
      });
    } else {
      // get remaining option value
      const remainingOption = options?.list
        ?.filter((e) => !selectedOptions?.[options?.type!]?.includes(e?.value))
        ?.map((o, i) => o?.value);
      if (remainingOption) {
        const combinedArr = _.concat(
          selectedOptions?.[options?.type!],
          remainingOption
        );
        setSelectedOptions({
          ...selectedOptions,
          [options?.type!]: combinedArr,
        });
      }
    }
  };

  // for district area use
  const selectAllAreaOptions = (areaIndex: number) => {
    let isSelectedAllArea = regionList?.listSiteRegions.regions[
      areaIndex
    ].districts?.every((e) => selectedOptions?.["district"]?.includes(e?.name));
    if (isSelectedAllArea) {
      const currentFilterListValue = regionList?.listSiteRegions.regions[
        areaIndex
      ].districts.map((o) => o.name);
      const removedFilter = selectedOptions?.[options?.type!]?.filter(
        (e) => !currentFilterListValue?.includes(e)
      );

      setSelectedOptions({
        ...selectedOptions,
        [options?.type!]: removedFilter,
      });
    } else {
      // get remaining option value
      const remainingOption = regionList?.listSiteRegions.regions[
        areaIndex
      ].districts
        ?.filter((e) => !selectedOptions?.[options?.type!]?.includes(e?.name))
        ?.map((o, i) => o?.name);
      if (remainingOption) {
        const combinedArr = _.concat(
          selectedOptions?.[options?.type!],
          remainingOption
        );
        setSelectedOptions({
          ...selectedOptions,
          [options?.type!]: combinedArr,
        });
      }
    }
  };

  useEffect(() => {
    if (options?.type == "district") {
      getRegionList();
    }

    return () => {};
  }, [options?.type]);

  useEffect(() => {
    setSelectedOptions({ ...appliedFilterOpt });
    return () => {};
  }, [appliedFilterOpt]);

  useOutsideClick({
    ref: filterListRef,
    handler: () => {
      setSelectedOptions({ ...appliedFilterOpt });
      onClose();
    },
  });

  // console.log(selectedOptions);
  // console.log(appliedFilterOpt);

  return (
    <Flex
      ref={filterListRef}
      backgroundColor={"WT"}
      position="absolute"
      top="55px"
      left="0px"
      zIndex={8}
      borderWidth={1}
      borderColor={returnByTheme()}
      borderTopRadius={12}
      flexDir="column"
      minW="240px"
      width="100%"
      p="10px"
      overflow="auto"
      maxHeight="500px"
    >
      {(options?.type !== "district" && (
        <Flex flexDirection={"column"}>
          <Flex
            onClick={() => selectAllOptions()}
            flex={1}
            justifyContent={"space-between"}
            borderBottomColor={returnByTheme()}
            borderBottomWidth="1px"
            my="5px"
            cursor="pointer"
          >
            <Text flex={1} color={returnByTheme()}>
              {t(filterName)}
            </Text>
            {isSelectedAll && <CheckIcon color={returnByTheme()} w={3} h={4} />}
          </Flex>

          <Flex flex={1} flexDirection={"column"}>
            {options &&
              options?.list?.length > 0 &&
              options?.list?.map((o, i) => {
                const isSelected = selectedOptions?.[options?.type]?.includes(
                  o?.value
                );

                let renderText = "";

                switch (options?.type) {
                  case "invoiceClient":
                    renderText = o.value;
                    break;
                  case "projectManager":
                  case "typeOfSite":
                    renderText = t(`TXT_Option_${o?.value}`);
                    break;
                  case "projectStatus":
                    renderText = t(`TXT_PjOption_${o?.value}`);
                    break;

                  default:
                    renderText = t(`TXT_Option_${o?.value}`);
                    break;
                }
                // options?.type == "invoiceClient" ||
                // options?.type == "projectManager" ||
                // options.type == "typeOfSite"
                //   ? convertDisplayText(o?.name)
                //   : options?.type == "projectStatus"
                //   ? t(`TXT_PjOption_${o?.value}`)
                //   : t(`TXT_Option_${o?.value}`);
                // console.log(options?.type == "projectStatus");
                // console.log(`TXT_Option_${o.value}`);
                // console.log(`${o.value}`);
                return (
                  <Flex
                    key={i}
                    justifyContent={"space-between"}
                    onClick={() => {
                      updateSelectedOption(o?.value);
                    }}
                    my="5px"
                    _hover={{
                      cursor: "pointer",
                    }}
                  >
                    <Text
                      color={"GREY"}
                      _hover={{
                        color: returnByTheme(),
                      }}
                    >
                      {renderText}
                    </Text>
                    {isSelected && <CheckIcon color={"GREY"} w={3} h={4} />}
                  </Flex>
                );
              })}
          </Flex>
        </Flex>
      )) || (
        <Flex flexDirection={"column"}>
          <Flex
            // onClick={selectAllOptions}
            flex={1}
            justifyContent={"space-between"}
            borderBottomColor={returnByTheme()}
            borderBottomWidth="1px"
            my="5px"
            // cursor="pointer"
          >
            <Text flex={1} color={returnByTheme()} fontWeight="bold">
              {t("TXT_All_districts")}
            </Text>
            {isSelectedAll && <CheckIcon color={returnByTheme()} w={3} h={4} />}
          </Flex>

          <Flex flex={1} flexDirection={"column"}>
            {regionList?.listSiteRegions.regions.map((area, areaIndex) => {
              let isSelectedAllArea = area.districts?.every((e) =>
                selectedOptions?.["district"]?.includes(e?.name)
              );

              return (
                <Box key={`area${areaIndex}`}>
                  <Flex
                    onClick={() => selectAllAreaOptions(areaIndex)}
                    flex={1}
                    justifyContent={"space-between"}
                    borderBottomColor={"GREY"}
                    borderBottomWidth="1px"
                    mt="10px"
                    pb="5px"
                    cursor="pointer"
                  >
                    <Text flex={1} color="GREY" fontWeight="bold">
                      {t(`TXT_${area.name.toLowerCase()}`)}
                    </Text>
                    {isSelectedAllArea && (
                      <CheckIcon color={returnByTheme()} w={3} h={4} />
                    )}
                  </Flex>
                  {area.districts.map((o, optionIndex) => {
                    let isSelected = selectedOptions?.["district"]?.includes(
                      o?.name
                    );

                    return (
                      <Flex
                        key={optionIndex}
                        justifyContent={"space-between"}
                        onClick={() => {
                          updateSelectedOption(o?.name);
                        }}
                        my="5px"
                        _hover={{
                          cursor: "pointer",
                        }}
                      >
                        <Text
                          color={"GREY"}
                          _hover={{
                            color: returnByTheme(),
                          }}
                        >
                          {t(`TXT_Option_${o.name.toLowerCase()}`)}
                        </Text>
                        {isSelected && <CheckIcon color={"GREY"} w={3} h={4} />}
                      </Flex>
                    );
                  })}
                </Box>
              );
            })}
          </Flex>
        </Flex>
      )}
      <Button
        height="34px"
        backgroundColor="WT"
        borderWidth="1px"
        borderColor={returnByTheme()}
        width="100%"
        mt="5px"
        borderRadius="12px"
        onClick={() => {
          applyAction(selectedOptions);
        }}
      >
        <Text color={returnByTheme()}>{t("TXT_Apply")}</Text>
      </Button>
    </Flex>
  );
};

export default FilterSelectOptions;
