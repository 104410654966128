import {
  Box,
  chakra,
  Flex,
  Text,
  Textarea,
  Button,
  Image,
  useToast,
  Img,
  Checkbox,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IconContext } from "react-icons";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../icons/general/success.svg";
import { ReactComponent as UploadImageIcon } from "src/icons/general/uploadImageIcon.svg";
import PopUpModal from "src/components/PopUpModal";
import {
  FiSend,
  FiAlertTriangle,
  FiArrowLeft,
  FiCalendar,
  FiZoomIn,
  FiZoomOut,
} from "react-icons/fi";
import { Document, Page, pdfjs } from "react-pdf";

import {
  returnByTheme,
  returnByColor,
  convertDisplayText,
  removeUselessKeyForObj,
  isEmptyString,
  uploadDoc,
} from "../../utils/util";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import FormFilesInput from "src/components/createProjectForm/FormFilesInput";
import {
  ICreateInvoiceImgState,
  ICreateInvoiceInfoState,
  ICreateInvoiceEleinfoState,
  ICreateInvoicePayMethodState,
} from "../../type/IInvoiceCreateInterface";
import FormSelectInput from "src/components/createProjectForm/FormSelectInput";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import DateRangePicker from "src/components/DateRangePicker";
import PayMethodSection from "../project/stepThreeForm/PayMethodSection";
import { IInvoicePaymentMethodData } from "src/type/IInoviceInfoInterface";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  RiArrowDropUpFill,
  RiFileDownloadLine,
  RiSaveLine,
} from "react-icons/ri";
import {
  CompanyDocument,
  CreateTemplateInvoiceDocument,
  GetDefaultTemplateInvoiceDocument,
  GetSitesByContractorIdDocument,
  RemoveInvoiceDocument,
  SendInvoiceDocument,
  TemplateCode,
  UpdatePreviewInvoiceDocument,
} from "src/graphql/generated";
import DatePicker from "src/components/DatePicker";
import { current } from "@reduxjs/toolkit";

const InvoiceCreate = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token!);
  const companyId = decodedToken.company?.id;

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({
    key: TemplateCode.Standard,
    color: "#000000",
  });
  const [myCompanyData, setMyCompanyData] = useState<any>();
  const [isShowIssueDatePicker, setIsShowIssueDatePicker] = useState(false);
  const [isShowDueDatePicker, setIsShowDueDatePicker] = useState(false);
  const [isShowEleDatePicker, setIsShowEleDatePicker] = useState(false);
  const issueDateRangePickerRef = useRef<any>();
  const dueDateRangePickerRef = useRef<any>();
  const eleDateRangePickerRef = useRef<any>();

  const [isShowSendModal, setIsShowSendModal] = useState(false);
  //const [isShowAlertModal, setIsShowAlertModal] = useState(false);
  // const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  // const [senderEmail, setSenderEmail] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(` `);

  const [relatedProjectList, setRelatedProjectList] = useState<any>([]);

  const [invoiceImg, setInvoiceImg] = useState<ICreateInvoiceImgState>();
  const [invoiceCheckOpt, setInvoiceCheckOpt] = useState({
    isSynchronize: false,
    setAsDefaultContent: false,
  });

  const [basicInfo, setBasicInfo] = useState<ICreateInvoiceInfoState>({
    relatedID: "None",
    invoiceID: "",
    invoiceFrom: "",
    invoiceTo: "",
    issueDate: new Date(),
    dueDate: new Date(),
  });

  const [eleDetails, setEleDetails] = useState<ICreateInvoiceEleinfoState>({
    accountNumber: "",
    dateRange: [
      {
        startDate: new Date(),
        endDate: null,
        key: "selection",
      },
    ],
    meterNoFit: "",
    meterNo: "",
    receiableItem: [{ name: "", amount: 0 }],
    payableItem: [{ name: "", amount: 0 }],
  });

  const [invoicePaymentData, setInvoicePaymentData] = useState<
    Array<IInvoicePaymentMethodData>
  >([{ payMethod: "Bank transfer", accNum: "" }]);

  const [isShowAlertModal, setIsShowAlertModal] = useState(false);
  const isClient = localStorage.getItem("sf_Role") == "true";
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const totalStep = [
    t("TXT_Invoice_Create_select_template"),
    t("TXT_Invoice_Create_info"),
    t("TXT_Preview"),
  ];

  const templateOpt = [
    {
      key: TemplateCode.Standard,
      displayName: t("TXT_Invoice_Standard"),
      colorGuide: [
        "#000000",
        "#8E8E8E",
        "#F0F0F0",
        "#E4513F",
        "#e89baf",
        "#1A9990",
        "#daedec",
      ],
    },
    {
      key: TemplateCode.Template1,
      displayName: t("TXT_Invoice_Template") + "1",
      colorGuide: [
        "#000000",
        "#8E8E8E",
        "#F0F0F0",
        "#F4891E",
        "#f5ca9f",
        "#459EE3",
        "#cbe2f5",
      ],
    },
    {
      key: TemplateCode.Template2,
      displayName: t("TXT_Invoice_Template") + "2",
      colorGuide: [
        "#000000",
        "#8E8E8E",
        "#F0F0F0",
        "#D70A8E",
        "#ed9dd1",
        "#004B92",
        "#cad5e0",
      ],
    },
  ];

  const templatePreviewImg = [
    [
      require("../../images/standardDN.png"),
      require("../../images/standardCN.png"),
    ],
    [
      require("../../images/template1DN.png"),
      require("../../images/template1CN.png"),
    ],
    [
      require("../../images/template2DN.png"),
      require("../../images/template2CN.png"),
    ],
  ];

  useEffect(() => {
    getDefaultTemplate();
    getMyCompany();
    return () => {};
  }, []);

  useEffect(() => {
    if (basicInfo.relatedID !== "None") {
      setBasicInfo({
        ...basicInfo,
        invoiceID: "",
      });
    }

    return () => {};
  }, [basicInfo.relatedID]);

  const getUploadDetail = async (key: string, value: any) => {
    const uploadedDoc = await uploadDoc(value);

    const newImageObj = value.map((f: any, i: number) => {
      return {
        // url: uploadedDoc.resut[i].url,
        // url: "https://solar-monster-dev-storage.s3.ap-southeast-1.amazonaws.com/sites/VfcqR540t0eS5SBDcuKFC/project_images/nyc.jpeg?AWSAccessKeyId=AKIAZXOZTAYNZKXDY7UB&Expires=1667897571&Signature=S4EQuMkM8qiZwb5UqdCzP5KwFp8%3D",
        url: uploadedDoc.result[i].url
          ? uploadedDoc.result[i].url
          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Tkm2I2eZS-dnuXspUUvm8WiB07J0XE8f4A&usqp=CAU",
        filePath: uploadedDoc.result[i].filePath,
        originalName: uploadedDoc.result[i].originalName,
      };
    });

    if (uploadedDoc.result) {
      setInvoiceImg({
        ...invoiceImg,
        [key]: newImageObj,
      });
    }
  };

  const [getMyCompany, { data: companyData }] = useLazyQuery(CompanyDocument, {
    variables: {
      id: companyId,
    },
    onCompleted: (data) => {
      const company = data.company;
      setMyCompanyData(company);
      setMessage(`Dear customer:  

Your invoice has been ready.If you have any question, 

please call our customer service hotline at (852) ${company?.phone?.phone} (Hong Kong).`);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  // get related project list
  const { data: pjlistByBN } = useQuery(GetSitesByContractorIdDocument, {
    onCompleted: (data) => {
      const noneOptArr = [{ value: "None", name: "None" }];

      const pjListselectOpt = data?.getSitesByContractorId?.map((list) => {
        return {
          value: list?.id,
          name: list?.code,
        };
      });

      const finalPjListArr = [...noneOptArr, ...(pjListselectOpt ?? [])];

      setRelatedProjectList(finalPjListArr);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  // get old version invoice
  const [
    getDefaultTemplate,
    { data: defaultTemplate, loading: loadingDefaultTemplate },
  ] = useLazyQuery(GetDefaultTemplateInvoiceDocument, {
    onCompleted: (data) => {
      if (
        data.getDefaultTemplateInvoice == null ||
        data.getDefaultTemplateInvoice == undefined
      )
        return;

      const defaultTmpData = data?.getDefaultTemplateInvoice;

      setSelectedTemplate({
        key: defaultTmpData?.customTemplateCode!,
        color: "#000000",
      });

      setInvoiceImg({
        companyLogo: defaultTmpData?.companyLogo
          ? [
              {
                originalName: defaultTmpData?.companyLogo?.originalName!,
                filePath: defaultTmpData?.companyLogo?.filePath!,
                url: defaultTmpData?.companyLogo?.url!,
              },
            ]
          : [],
        companyChop: defaultTmpData?.companyChop
          ? [
              {
                originalName: defaultTmpData?.companyChop?.originalName!,
                filePath: defaultTmpData?.companyChop?.filePath!,
                url: defaultTmpData?.companyChop?.url!,
              },
            ]
          : [],
      });

      setBasicInfo({
        relatedID: "None",
        // invoiceID: defaultTmpData?.code,
        invoiceID: "",
        invoiceFrom: defaultTmpData?.senderInformation!,
        invoiceTo: defaultTmpData?.clientInformation ?? "",
        issueDate: defaultTmpData?.createdAt,
        dueDate: defaultTmpData?.dueAt,
      });

      setEleDetails({
        accountNumber: defaultTmpData?.electricityDetails?.accountNumber ?? "",
        dateRange: [
          {
            startDate: defaultTmpData?.electricityDetails?.startTime,
            endDate: defaultTmpData?.electricityDetails?.endTime,
            key: "selection",
          },
        ],
        meterNoFit: defaultTmpData?.electricityDetails?.meterFitNumber ?? "",
        meterNo: defaultTmpData?.electricityDetails?.meterNumber ?? "",
        receiableItem: defaultTmpData?.receivableCharges.map((rc) => {
          return {
            name: rc.name,
            amount: rc.amount,
          };
        }),
        payableItem: defaultTmpData?.payableCharges.map((pc) => {
          return {
            name: pc.name,
            amount: pc.amount,
          };
        }),
      });

      setInvoiceCheckOpt({
        isSynchronize: false,
        setAsDefaultContent: defaultTmpData.default ? true : false,
      });

      setInvoicePaymentData(
        defaultTmpData?.paymentMethods.map((pm) => {
          return { payMethod: pm.method, accNum: pm.accountInformation };
        }) ?? []
      );
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [genPreviewInvoice, { data: invoicePreview, loading: geningInvoice }] =
    useMutation(CreateTemplateInvoiceDocument, {
      onCompleted: (x) => {
        setCurrentStep(currentStep + 1);
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });

  const [
    updateCustomInvoice,
    { data: updatedInvoiceStatus, loading: savingAsDraft },
  ] = useMutation(UpdatePreviewInvoiceDocument, {
    onCompleted: (x) => {
      navigate("/invoice");
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [
    removeCustomInvoiceWithBack,
    { data: rmStatusWithBack, loading: removingInvoiceWithBack },
  ] = useMutation(RemoveInvoiceDocument, {
    onCompleted: (x) => {
      setCurrentStep(currentStep - 1);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [sendInvoice, { data: sendInvoiceStatus, loading: sendingInvoice }] =
    useMutation(SendInvoiceDocument, {
      onCompleted: (x) => {
        toast({
          position: "bottom-right",
          title: `invoice sent`,
          status: "success",
        });
        navigate("/invoice");
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });

  const [
    removeCustomInvoiceWithCancel,
    { data: rmStatusWithCancel, loading: removingInvoiceWithCancel },
  ] = useMutation(RemoveInvoiceDocument, {
    onCompleted: (x) => {
      navigate("/invoice");
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  return (
    <>
      <Helmet>
        <title>Solar Monster - Custom invoce</title>
      </Helmet>
      {/* sticky header section */}
      <Flex
        flex={1}
        flexDirection="row"
        backgroundColor="WT"
        alignItems="center"
        justifyContent="center"
        py="10px"
        position="sticky"
        top="0px"
        zIndex={2}
        boxShadow="0px 1px 5px 0px rgba(0, 0, 0, 0.05)"
      >
        <Flex position="absolute" left="20px">
          <Flex
            color="BK"
            alignItems="center"
            fontWeight={"bold"}
            onClick={() => setIsShowAlertModal(true)}
            cursor="pointer"
          >
            <FiArrowLeft color="GREY" />
            <Text color="GREY" mx="10px">
              {t("TXT_Invoice_Add_new_invoice")}
            </Text>
          </Flex>
        </Flex>

        <Flex flexDirection="row" alignItems="center" height="40px">
          <Button
            py="15px"
            px="30px"
            backgroundColor="WT"
            borderWidth="1px"
            borderRadius="40px"
            borderColor={returnByTheme()}
            width="100%"
            onClick={() => setIsShowAlertModal(true)}
          >
            <IconContext.Provider
              value={{ color: returnByColor(), size: "25px" }}
            >
              <Flex color={returnByTheme()}>{t("TXT_Cancel")}</Flex>
            </IconContext.Provider>
          </Button>
          <Button
            py="15px"
            px="30px"
            backgroundColor={returnByTheme()}
            borderWidth="1px"
            borderRadius="40px"
            borderColor="WT"
            width="100%"
            marginLeft="10px"
            onClick={() => {
              setIsShowSendModal(true);
            }}
            disabled={currentStep !== 2}
          >
            <IconContext.Provider value={{ color: "#FFF", size: "25px" }}>
              <Flex color="WT">{t("TXT_Send")}</Flex>
            </IconContext.Provider>
          </Button>
        </Flex>
      </Flex>

      {/* form section */}
      <Flex
        backgroundColor={"#e5e5e5"}
        flex={1}
        justifyContent={"center"}
        maxW="1920px"
        mx="auto"
        maxH="calc(100vh - 60px)"
        overflow="hidden"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          pt="80px"
          px={10}
          // flex={0.4}
        >
          <Text fontSize={28} fontWeight={"bold"} color={returnByTheme()}>
            {t("TXT_Invoice_Create_title")}
          </Text>
          <Text mt={30} fontSize={24} fontWeight={"semibold"} color={"#8e8e8e"}>
            {t("TXT_Project_Fill_all_field_togo")}
          </Text>
          {/* step bar */}
          <Flex flexDirection={"column"} mt={100}>
            {totalStep.map((s, i) => {
              const isCurrentStep = currentStep === i;
              return (
                <Flex
                  alignItems={"center"}
                  mb="40px"
                  key={`step${i}`}
                  // allow jump
                  onClick={() => setCurrentStep(i)}
                >
                  <Flex flexDirection={"column"} position={"relative"}>
                    {(isCurrentStep && (
                      <Flex
                        position={"absolute"}
                        top={"-10px"}
                        left={"-6px"}
                        width={"20px"}
                        height={"20px"}
                        backgroundColor={"WT"}
                        borderRadius={"25px"}
                        borderWidth={"6px"}
                        borderColor={returnByTheme()}
                        zIndex={1}
                      ></Flex>
                    )) || (
                      <Flex
                        position={"absolute"}
                        top={"-4px"}
                        width={"8px"}
                        height={"8px"}
                        backgroundColor={"WT"}
                        borderRadius={"25px"}
                        zIndex={1}
                      ></Flex>
                    )}

                    {i !== totalStep.length - 1 && (
                      <Flex
                        borderRadius={"5px"}
                        position={"absolute"}
                        top={"-8px"}
                        left={"-1px"}
                        width={"10px"}
                        height={"80px"}
                        // backgroundColor={"#b8b4b4"}
                        background={
                          i === currentStep - 1
                            ? // F79846 -> EACD29
                              isClient
                              ? "linear-gradient(#F79846, #EACD29)"
                              : "linear-gradient(#004b92, #69B3E3)"
                            : i < currentStep - 1
                            ? returnByTheme()
                            : "#b4b4b4"
                        }
                      ></Flex>
                    )}
                  </Flex>
                  <Text
                    color={isCurrentStep ? returnByTheme() : "#8e8e8e"}
                    // fontWeight={isCurrentStep ? "bold" : "normal"}
                    fontWeight={"bold"}
                    marginLeft={"20px"}
                  >
                    {s}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex m="20px" overflowY="auto" maxWidth="750px" w="750px">
          {currentStep === 0 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <Flex p="20px" bg="WT" flexDir="column">
                <Flex
                  mb="20px"
                  pb="20px"
                  fontWeight="bold"
                  borderBottomColor="DISABLEDTEXT"
                  borderBottomWidth="1px"
                >
                  {t("TXT_Invoice_Template")}
                </Flex>
                <Flex flexDir="column" gap="20px">
                  {templateOpt.map((tmp, i) => {
                    return (
                      <Flex
                        justifyContent="space-between"
                        key={i}
                        pb="20px"
                        borderBottom="1px solid #F0F0F0"
                      >
                        <Flex flexDir="column" justifyContent="space-between">
                          <Flex cursor="pointer">
                            {(selectedTemplate.key == tmp.key && (
                              <Img
                                src={require("../../icons/general/selectedRadioBtn.png")}
                              />
                            )) || (
                              <Img
                                onClick={() =>
                                  setSelectedTemplate({
                                    key: tmp.key,
                                    color: tmp.colorGuide[0],
                                  })
                                }
                                src={require("../../icons/general/emptyRadioBtn.png")}
                              />
                            )}
                            <Flex ml="10px"> {tmp.displayName}</Flex>
                          </Flex>
                          <Flex
                            px="12px"
                            py="8px"
                            bg="WT"
                            flexDir="column"
                            justifyContent="center"
                            alignItems="center"
                            border="1px solid #F0F0F0"
                          >
                            <Flex color="GREY" mb="10px">
                              {t("TXT_Invoice_Color_guide")}
                            </Flex>
                            <Flex>
                              {tmp.colorGuide.map((c, colorIndex) => {
                                return (
                                  <Flex flexDir="column" key={colorIndex}>
                                    <Flex
                                      bg={c}
                                      width="25px"
                                      height="25px"

                                      // onClick={() =>
                                      //   setSelectedTemplate({
                                      //     key: tmp.key,
                                      //     color: c,
                                      //   })
                                      // }
                                    ></Flex>
                                    {/* {selectedTemplate.color == c &&
                                      selectedTemplate.key == tmp.key && (
                                        <Icon
                                          as={RiArrowDropUpFill}
                                          width="25px"
                                          height="25px"
                                          color="red"
                                        />
                                      )} */}
                                  </Flex>
                                );
                              })}
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex gap="20px">
                          {templatePreviewImg[i].map((img, imgIndex) => {
                            return (
                              <Flex border="1px solid #8E8E8E" key={imgIndex}>
                                <Img src={img} />
                              </Flex>
                            );
                          })}
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent="center">
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  //   disabled={!ableToSubmit}
                  isLoading={loadingDefaultTemplate}
                  loadingText={t("TXT_Next")}
                  spinnerPlacement="end"
                  disabled={loadingDefaultTemplate}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  {t("TXT_Next")}
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === 1 && (
            <Flex width={"100%"} flexDirection={"column"}>
              {/* invoice image section */}
              <Flex
                backgroundColor={"WT"}
                borderRadius={"5px"}
                mb="20px"
                // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
                // flex={1}
                flexDirection={"column"}
              >
                <SectionTitle title={t("TXT_Images")} />
                <FormFilesInput
                  inputLabel={t("TXT_Company_logo")}
                  limitedType=".jpg,.png"
                  litmitedDesc={t("TXT_Desc_accept_jpg_and_png_only")}
                  litmitedDesc2={t("TXT_Desc_resolution")}
                  filesList={invoiceImg?.companyLogo}
                  allowMulti={false}
                  onUploadFiles={(file: Array<File>) => {
                    getUploadDetail("companyLogo", file);
                  }}
                  onDeleteFile={(index: number) => {
                    setInvoiceImg({
                      ...invoiceImg,
                      companyLogo: undefined,
                    });
                  }}
                  uploadBtnIcon={<UploadImageIcon />}
                  uploadBtnText={t("TXT_Desc_click_to_choose_image")}
                />
                <Box height="1px" mx="20px" bg="DISABLEDBG"></Box>
                <FormFilesInput
                  inputLabel={t("TXT_Company_chop")}
                  limitedType=".jpg,.png"
                  litmitedDesc={t("TXT_Desc_accept_jpg_and_png_only")}
                  litmitedDesc2={t("TXT_Desc_resolution")}
                  filesList={invoiceImg?.companyChop}
                  allowMulti={false}
                  onUploadFiles={(file: Array<File>) => {
                    getUploadDetail("companyChop", file);
                  }}
                  onDeleteFile={(index: number) => {
                    setInvoiceImg({
                      ...invoiceImg,
                      companyChop: undefined,
                    });
                  }}
                  uploadBtnIcon={<UploadImageIcon />}
                  uploadBtnText={t("TXT_Desc_click_to_choose_image")}
                />
                <Box height="1px" mx="20px" bg="DISABLEDBG"></Box>
                <Flex p="20px">
                  <Checkbox
                    checked={invoiceCheckOpt?.isSynchronize}
                    onChange={() =>
                      setInvoiceCheckOpt({
                        ...invoiceCheckOpt,
                        isSynchronize: !invoiceCheckOpt?.isSynchronize,
                      })
                    }
                  >
                    {t("TXT_Invoice_Create_sync_image_desc")}
                  </Checkbox>
                </Flex>
              </Flex>

              {/* basic info section */}
              <Flex
                backgroundColor={"WT"}
                borderRadius={"5px"}
                mb="20px"
                // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
                // flex={1}
                flexDirection={"column"}
              >
                <SectionTitle title={t("TXT_Project_Basic_info")} />
                <FormSelectInput
                  options={relatedProjectList}
                  inputLabel={t("TXT_Invoice_Relate_to_project")}
                  selectedValue={basicInfo?.relatedID}
                  onChange={(value: string) =>
                    setBasicInfo({
                      ...basicInfo,
                      relatedID: value,
                    })
                  }
                />

                <FormTextInput
                  isRequired={basicInfo.relatedID == "None"}
                  isDisable={basicInfo.relatedID !== "None"}
                  placeHolder={""}
                  inputLabel={t("TXT_Invoice_Invoice_ID")}
                  onChange={(value: string) =>
                    setBasicInfo({
                      ...basicInfo,
                      invoiceID: value,
                    })
                  }
                  inpputtedValue={basicInfo?.invoiceID}
                  hints={
                    basicInfo.relatedID !== "None"
                      ? "* Invoice id will auto gen by system"
                      : ""
                  }
                />

                <Flex px="20px" mb="20px" gap="5px">
                  <Flex flex={2} fontSize={15} color={"GREY"}>
                    <Box>
                      <Text display={"inline"}>
                        {t("TXT_Invoice_Issue_date")}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex flex={3} flexDir="column">
                    <Flex
                      flex={1}
                      position="relative"
                      px="10px"
                      py="5px"
                      borderColor={returnByTheme()}
                      borderRadius="5px"
                      borderWidth={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>
                        {moment(basicInfo.issueDate).format("D MMM, YYYY")}
                        {/* {basicInfo.issueDate?.[0]?.endDate !== null &&
                        basicInfo.issueDate?.[0]?.startDate !==
                          basicInfo.issueDate?.[0]?.endDate
                          ? `${moment(
                              basicInfo.issueDate?.[0]?.startDate
                            ).format("D MMM, YYYY")} - ${moment(
                              basicInfo.issueDate?.[0]?.endDate
                            ).format("D MMM, YYYY")}`
                          : `${moment(
                              basicInfo.issueDate?.[0]?.startDate
                            ).format("D MMM, YYYY")}`} */}
                      </Box>
                      <Icon
                        as={FiCalendar}
                        color={returnByTheme()}
                        cursor="pointer"
                        width="20px"
                        height="20px"
                        onClick={(e) => {
                          setIsShowIssueDatePicker(true);
                        }}
                      />
                      {isShowIssueDatePicker && (
                        <DatePicker
                          // isRangeOnly={true}
                          dateRangePickerRef={issueDateRangePickerRef}
                          selectedDate={basicInfo?.issueDate}
                          onClose={() => {
                            setIsShowIssueDatePicker(false);
                          }}
                          setDate={(newRange: any) => {
                            setBasicInfo({
                              ...basicInfo,
                              issueDate: newRange,
                            });
                            setIsShowIssueDatePicker(false);
                          }}
                        />
                      )}
                    </Flex>
                  </Flex>
                </Flex>

                <FormTextInput
                  placeHolder={""}
                  isRequired
                  isTextArea
                  inputLabel={t("TXT_Invoice_from")}
                  onChange={(value: string) =>
                    setBasicInfo({
                      ...basicInfo,
                      invoiceFrom: value,
                    })
                  }
                  inpputtedValue={basicInfo.invoiceFrom}
                />

                <FormTextInput
                  placeHolder={""}
                  isTextArea
                  inputLabel={t("TXT_Invoice_to")}
                  onChange={(value: string) =>
                    setBasicInfo({
                      ...basicInfo,
                      invoiceTo: value,
                    })
                  }
                  inpputtedValue={basicInfo.invoiceTo}
                />

                <Flex px="20px" mb="20px" gap="5px">
                  <Flex flex={2} fontSize={15} color={"GREY"}>
                    <Box>
                      <Text display={"inline"}>
                        {t("TXT_Invoice_Due_date")}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex flex={3} flexDir="column">
                    <Flex
                      flex={1}
                      position="relative"
                      px="10px"
                      py="5px"
                      borderColor={returnByTheme()}
                      borderRadius="5px"
                      borderWidth={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>
                        {moment(basicInfo.dueDate).format("D MMM, YYYY")}
                        {/* {basicInfo.issueDate?.[0]?.endDate !== null &&
                        basicInfo.issueDate?.[0]?.startDate !==
                          basicInfo.issueDate?.[0]?.endDate
                          ? `${moment(
                              basicInfo.issueDate?.[0]?.startDate
                            ).format("D MMM, YYYY")} - ${moment(
                              basicInfo.issueDate?.[0]?.endDate
                            ).format("D MMM, YYYY")}`
                          : `${moment(
                              basicInfo.issueDate?.[0]?.startDate
                            ).format("D MMM, YYYY")}`} */}
                      </Box>
                      <Icon
                        as={FiCalendar}
                        color={returnByTheme()}
                        cursor="pointer"
                        width="20px"
                        height="20px"
                        onClick={(e) => {
                          setIsShowDueDatePicker(true);
                        }}
                      />
                      {isShowDueDatePicker && (
                        <DatePicker
                          // isRangeOnly={true}
                          dateRangePickerRef={dueDateRangePickerRef}
                          selectedDate={basicInfo?.dueDate}
                          onClose={() => {
                            setIsShowDueDatePicker(false);
                          }}
                          setDate={(newRange: any) => {
                            setBasicInfo({
                              ...basicInfo,
                              dueDate: newRange,
                            });
                            setIsShowDueDatePicker(false);
                          }}
                        />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* electricity detail section */}
              <Flex
                backgroundColor={"WT"}
                borderRadius={"5px"}
                flexDirection={"column"}
              >
                <SectionTitle title={t("TXT_Project_Electricity_details")} />

                <FormTextInput
                  placeHolder={""}
                  inputLabel={t("TXT_Account_number")}
                  onChange={(value: string) =>
                    setEleDetails({
                      ...eleDetails,
                      accountNumber: value,
                    })
                  }
                  inpputtedValue={eleDetails?.accountNumber}
                />

                <Flex px="20px" mb="20px" gap="5px">
                  <Flex flex={2} fontSize={15} color={"GREY"}>
                    <Box>
                      <Text display={"inline"}>{t("TXT_Date_range")}</Text>
                    </Box>
                  </Flex>
                  <Flex flex={3} flexDir="column">
                    <Flex
                      flex={1}
                      position="relative"
                      px="10px"
                      py="5px"
                      borderColor={returnByTheme()}
                      borderRadius="5px"
                      borderWidth={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>
                        {eleDetails?.dateRange?.[0]?.endDate !== null &&
                        eleDetails.dateRange?.[0]?.startDate !==
                          eleDetails.dateRange?.[0]?.endDate
                          ? `${moment(
                              eleDetails.dateRange?.[0]?.startDate
                            ).format("D MMM, YYYY")} - ${moment(
                              eleDetails.dateRange?.[0]?.endDate
                            ).format("D MMM, YYYY")}`
                          : `${moment(
                              eleDetails.dateRange?.[0]?.startDate
                            ).format("D MMM, YYYY")}`}
                      </Box>
                      <Icon
                        as={FiCalendar}
                        color={returnByTheme()}
                        cursor="pointer"
                        width="20px"
                        height="20px"
                        onClick={(e) => {
                          setIsShowEleDatePicker(true);
                        }}
                      />
                      {isShowEleDatePicker && (
                        <DateRangePicker
                          isRangeOnly={true}
                          dateRangePickerRef={eleDateRangePickerRef}
                          dateRange={eleDetails?.dateRange}
                          onClose={() => {
                            setIsShowEleDatePicker(false);
                          }}
                          applyDateFilter={(newRange: any) => {
                            setEleDetails({
                              ...eleDetails,
                              dateRange: newRange,
                            });
                            setIsShowEleDatePicker(false);
                          }}
                        />
                      )}
                    </Flex>
                    <Flex fontSize="12px" color="GREY">
                      * Please select a range
                    </Flex>
                  </Flex>
                </Flex>

                <FormTextInput
                  placeHolder={""}
                  inputLabel={t("TXT_Project_Meter_id_fit")}
                  onChange={(value: string) =>
                    setEleDetails({
                      ...eleDetails,
                      meterNoFit: value,
                    })
                  }
                  inpputtedValue={eleDetails?.meterNoFit}
                />

                <FormTextInput
                  placeHolder={""}
                  inputLabel={t("TXT_Project_Meter_id")}
                  onChange={(value: string) =>
                    setEleDetails({
                      ...eleDetails,
                      meterNo: value,
                    })
                  }
                  inpputtedValue={eleDetails?.meterNo}
                />
                {/* receivable count section */}
                <Flex
                  bg="DISABLEDBG"
                  p="20px"
                  fontSize="15px"
                  fontWeight="bold"
                >
                  {t("TXT_Receivable_count")}
                </Flex>
                <Flex p="20px" flexDir="column">
                  <Flex gap="5px" mb="20px">
                    <Flex flex={1} fontWeight="bold">
                      {t("TXT_Invoice_items")}
                    </Flex>
                    <Flex flex={1} fontWeight="bold">
                      {t("TXT_Value")}
                    </Flex>
                  </Flex>
                  {eleDetails.receiableItem?.map((item, rci) => {
                    return (
                      <Flex gap="10px" mb="20px" key={rci}>
                        <Flex flex={1}>
                          <Input
                            _placeholder={{ color: "DISABLEDTEXT" }}
                            flex={1}
                            px="10px"
                            py="5px"
                            borderColor={returnByTheme()}
                            borderRadius="5px"
                            borderWidth={1}
                            justifyContent={"space-between"}
                            value={item.name}
                            onChange={(e) => {
                              let cloneArr = eleDetails?.receiableItem!.map(
                                (obj, index) => {
                                  if (index == rci) {
                                    return {
                                      ...obj,
                                      name: e.target.value,
                                    };
                                  } else {
                                    return obj;
                                  }
                                }
                              );

                              setEleDetails({
                                ...eleDetails,
                                receiableItem: cloneArr,
                              });
                            }}
                          />
                        </Flex>
                        <Flex flex={1}>
                          <InputGroup flexDirection={"column"}>
                            <InputLeftElement
                              children={<Flex color={"GREY"}>HK$</Flex>}
                              width={"fit-content"}
                              height={"fit-content"}
                              top={"7px"}
                              left={"10px"}
                              background={"WT"}
                            />
                            <Input
                              _placeholder={{ color: "DISABLEDTEXT" }}
                              flex={1}
                              px="10px"
                              py="5px"
                              borderColor={returnByTheme()}
                              borderRadius="5px"
                              borderWidth={1}
                              justifyContent={"space-between"}
                              value={!item.name ? 0 : item.amount}
                              disabled={!item.name}
                              onChange={(e) => {
                                let cloneArr = eleDetails?.receiableItem!.map(
                                  (obj, index) => {
                                    if (index == rci) {
                                      return {
                                        ...obj,
                                        amount: Number(
                                          e.target?.value.replace(
                                            /[^0-9\.]+/g,
                                            ""
                                          )
                                        ),
                                      };
                                    } else {
                                      return obj;
                                    }
                                  }
                                );

                                setEleDetails({
                                  ...eleDetails,
                                  receiableItem: cloneArr,
                                });
                              }}
                            />
                          </InputGroup>
                        </Flex>
                      </Flex>
                    );
                  })}
                  <Flex height="1px" bg="DISABLEDBG"></Flex>
                  <Flex py={"20px"}>
                    <Flex
                      borderRadius={"5px"}
                      borderColor={returnByTheme()}
                      borderWidth={1}
                      onClick={() => {
                        let clone = [
                          ...(eleDetails.receiableItem ?? []),
                          {
                            name: "",
                            amount: 0,
                          },
                        ];

                        setEleDetails({
                          ...eleDetails,
                          receiableItem: clone,
                        });
                      }}
                      px="20px"
                      py="3px"
                      color={returnByTheme()}
                      alignItems={"center"}
                      cursor={"pointer"}
                    >
                      + {t("TXT_Add")}
                    </Flex>
                  </Flex>
                </Flex>

                {/* payable count section */}
                <Flex
                  bg="DISABLEDBG"
                  p="20px"
                  fontSize="15px"
                  fontWeight="bold"
                >
                  {t("TXT_Payable_count")}
                </Flex>
                <Flex p="20px" flexDir="column">
                  <Flex gap="5px" mb="20px">
                    <Flex flex={1} fontWeight="bold">
                      {t("TXT_Invoice_items")}
                    </Flex>
                    <Flex flex={1} fontWeight="bold">
                      {t("TXT_Value")}
                    </Flex>
                  </Flex>
                  {eleDetails.payableItem?.map((item, rci) => {
                    return (
                      <Flex gap="10px" mb="20px" key={rci}>
                        <Flex flex={1}>
                          <Input
                            _placeholder={{ color: "DISABLEDTEXT" }}
                            flex={1}
                            px="10px"
                            py="5px"
                            borderColor={returnByTheme()}
                            borderRadius="5px"
                            borderWidth={1}
                            justifyContent={"space-between"}
                            value={item.name}
                            onChange={(e) => {
                              let cloneArr = eleDetails?.payableItem!.map(
                                (obj, index) => {
                                  if (index == rci) {
                                    return {
                                      ...obj,
                                      name: e.target.value,
                                    };
                                  } else {
                                    return obj;
                                  }
                                }
                              );

                              setEleDetails({
                                ...eleDetails,
                                payableItem: cloneArr,
                              });
                            }}
                          />
                        </Flex>
                        <Flex flex={1}>
                          <InputGroup flexDirection={"column"}>
                            <InputLeftElement
                              children={<Flex color={"GREY"}>HK$</Flex>}
                              width={"fit-content"}
                              height={"fit-content"}
                              top={"7px"}
                              left={"10px"}
                              background={"WT"}
                            />
                            <Input
                              _placeholder={{ color: "DISABLEDTEXT" }}
                              flex={1}
                              px="10px"
                              py="5px"
                              borderColor={returnByTheme()}
                              borderRadius="5px"
                              borderWidth={1}
                              justifyContent={"space-between"}
                              value={!item.name ? 0 : item.amount}
                              disabled={!item.name}
                              onChange={(e) => {
                                let cloneArr = eleDetails?.payableItem!.map(
                                  (obj, index) => {
                                    if (index == rci) {
                                      return {
                                        ...obj,
                                        amount: Number(
                                          e.target?.value.replace(
                                            /[^0-9\.]+/g,
                                            ""
                                          )
                                        ),
                                      };
                                    } else {
                                      return obj;
                                    }
                                  }
                                );

                                setEleDetails({
                                  ...eleDetails,
                                  payableItem: cloneArr,
                                });
                              }}
                            />
                          </InputGroup>
                        </Flex>
                      </Flex>
                    );
                  })}
                  <Flex height="1px" bg="DISABLEDBG"></Flex>
                  <Flex py={"20px"}>
                    <Flex
                      borderRadius={"5px"}
                      borderColor={returnByTheme()}
                      borderWidth={1}
                      onClick={() => {
                        let clone = [
                          ...(eleDetails.payableItem ?? []),
                          {
                            name: "",
                            amount: 0,
                          },
                        ];

                        setEleDetails({
                          ...eleDetails,
                          payableItem: clone,
                        });
                      }}
                      px="20px"
                      py="3px"
                      color={returnByTheme()}
                      alignItems={"center"}
                      cursor={"pointer"}
                    >
                      + {t("TXT_Add")}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* payment method section */}
              <PayMethodSection
                invoicePaymentData={invoicePaymentData}
                updateInvocePaymentState={(newState: any) =>
                  setInvoicePaymentData(newState)
                }
                onlyForDebit={false}
              />

              <Flex
                p="20px"
                backgroundColor={"WT"}
                borderRadius={"5px"}
                mt="20px"
                fontWeight="bold"
              >
                <Checkbox
                  isChecked={invoiceCheckOpt?.setAsDefaultContent}
                  onChange={() =>
                    setInvoiceCheckOpt({
                      ...invoiceCheckOpt,
                      setAsDefaultContent:
                        !invoiceCheckOpt?.setAsDefaultContent,
                    })
                  }
                >
                  {t("TXT_Desc_set_as_default_content")}
                </Checkbox>
              </Flex>

              {/* bottom section */}
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"} gap="20px">
                <Button
                  backgroundColor={"transparent"}
                  borderWidth={1}
                  borderColor={"BK"}
                  color="BK"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  {t("TXT_Back")}
                </Button>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  // disabled={!ableToSubmit}
                  isLoading={geningInvoice}
                  loadingText={t("Loading")}
                  spinnerPlacement="end"
                  disabled={
                    !basicInfo.invoiceFrom ||
                    (basicInfo.relatedID == "None" && !basicInfo.invoiceID)
                  }
                  onClick={() => {
                    const newReceiableItemArr =
                      eleDetails.receiableItem?.filter((ri) => {
                        if (ri.name) {
                          return {
                            name: ri.name,
                            amount: ri.name ? ri.amount : 0,
                          };
                        }
                      });

                    const newPayableItemArr = eleDetails.payableItem?.filter(
                      (ri) => {
                        if (ri.name) {
                          return {
                            name: ri.name,
                            amount: ri.name ? ri.amount : 0,
                          };
                        }
                      }
                    );

                    const newPaymentMethodArr = invoicePaymentData
                      .filter((pm) => pm.accNum)
                      .map((pm) => {
                        return {
                          method: pm.payMethod,
                          accountInformation: pm.accNum,
                        };
                      });

                    let payload = {
                      variables: {
                        input: {
                          template: selectedTemplate.key,
                          companyLogo:
                            invoiceImg?.companyLogo &&
                            invoiceImg?.companyLogo?.length > 0
                              ? {
                                  filePath:
                                    invoiceImg?.companyLogo?.[0]?.filePath,
                                  originalName:
                                    invoiceImg?.companyLogo?.[0]?.originalName,
                                }
                              : undefined,
                          companyChop:
                            invoiceImg?.companyChop &&
                            invoiceImg?.companyChop?.length > 0
                              ? {
                                  filePath:
                                    invoiceImg?.companyChop?.[0]?.filePath,
                                  originalName:
                                    invoiceImg?.companyChop?.[0]?.originalName,
                                }
                              : undefined,
                          synchronizeCompanyImages:
                            invoiceCheckOpt.isSynchronize,
                          siteId:
                            relatedProjectList.filter(
                              (l: any) => basicInfo.relatedID == l.name
                            )[0].value ?? "none",
                          code: basicInfo.invoiceID,
                          companyId: companyId,
                          issueDate: basicInfo.issueDate,
                          dueAt: basicInfo.dueDate,
                          invoiceFrom: basicInfo.invoiceFrom ?? "",
                          invoiceTo: basicInfo.invoiceTo,
                          electricityDetails: {
                            accountNumber: eleDetails.accountNumber,
                            startTime: eleDetails.dateRange[0].startDate,
                            endTime: eleDetails.dateRange[0].endDate,
                            meterNumber: eleDetails.meterNo,
                            meterFitNumber: eleDetails.meterNoFit,
                          },
                          receivableCharges: newReceiableItemArr ?? [],
                          payableCharges: newPayableItemArr ?? [],
                          paymentMethods: newPaymentMethodArr,
                          setAsDefault: invoiceCheckOpt.setAsDefaultContent,
                        },
                      },
                    };

                    genPreviewInvoice(payload);
                  }}
                >
                  {t("TXT_Next")}
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === 2 && (
            <Flex flexDirection={"column"}>
              {/* invoice image section */}
              <Flex
                backgroundColor={"WT"}
                borderRadius={"5px"}
                // p="20px"
                position="relative"
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                minH="500px"
                minW="500px"
              >
                <PdfViewer
                  pdfUrl={invoicePreview?.createTemplateInvoice.file.url}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  pageNumber={pageNumber}
                  saveAsDraft={() =>
                    updateCustomInvoice({
                      variables: {
                        input: invoicePreview?.createTemplateInvoice?.id!,
                      },
                    })
                  }

                  // zoomSize={zoomSize}
                />
              </Flex>
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"} gap="20px">
                <Button
                  backgroundColor={"transparent"}
                  borderWidth={1}
                  borderColor={"BK"}
                  color="BK"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  onClick={() => {
                    removeCustomInvoiceWithBack({
                      variables: {
                        input: invoicePreview?.createTemplateInvoice.id!,
                      },
                    });
                  }}
                >
                  {t("TXT_Back")}
                </Button>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  // disabled={!ableToSubmit}
                  // isLoading={loading}
                  loadingText={t("TXT_Saving_dot")}
                  spinnerPlacement="end"
                  //   disabled={!ableToStep3}
                  onClick={() => {
                    setIsShowSendModal(true);
                  }}
                >
                  {t("TXT_Send")}
                </Button>
              </Flex>
            </Flex>
          )}

          {/* leave alert popup */}
          <PopUpModal
            isShow={isShowAlertModal}
            onClose={() => {
              setIsShowAlertModal(false);
            }}
            headerColor="RD.3"
            headerText={t("TXT_Invoice_Add_new_invoice")}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <IconContext.Provider value={{ color: "#E4513F", size: "100px" }}>
                <FiAlertTriangle />
              </IconContext.Provider>
              <Text color="#E4513F" fontWeight="700">
                {t("TXT_Hey_wait")}
              </Text>
              <Text color="#000" textAlign="center" fontWeight="600">
                {t("TXT_Leave_popup_desc")}
              </Text>
              <Flex flexDirection="row" width="100%">
                <Button
                  backgroundColor="WT"
                  _hover={{ backgroundColor: "WT" }}
                  onClick={() => setIsShowAlertModal(false)}
                  marginTop="20px"
                  borderRadius="40px"
                  flex={1}
                  borderColor="#000"
                  borderWidth="1px"
                >
                  <Text color="#000">{t("TXT_Stay")}</Text>
                </Button>
                <Button
                  backgroundColor="RD.3"
                  _hover={{ backgroundColor: "RD.3" }}
                  onClick={() => {
                    if (currentStep !== 2) {
                      navigate("/invoice");
                    } else {
                      removeCustomInvoiceWithCancel({
                        variables: {
                          input: invoicePreview?.createTemplateInvoice.id!,
                        },
                      });
                    }
                  }}
                  marginTop="20px"
                  borderRadius="40px"
                  flex={1}
                  marginLeft="20px"
                >
                  <Text color="WT">{t("TXT_Leave")}</Text>
                </Button>
              </Flex>
            </Flex>
          </PopUpModal>

          <PopUpModal
            isShow={isShowSendModal}
            onClose={() => setIsShowSendModal(false)}
            headerColor={returnByTheme()}
            headerText="Send invoice"
          >
            <Flex flexDirection="column">
              <Text fontSize="15px" color={returnByTheme()}>
                {t("TXT_From")}
              </Text>
              <Input
                disabled
                value={myCompanyData?.email}
                flex={3}
                color={returnByTheme()}
                bg={"#D9DDE0"}
                // onChange={(e) => setSenderEmail(e.target.value)}
                boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
                padding="5px"
              />
              <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
                {t("TXT_Send")} {t("TXT_To")}
              </Text>
              <Input
                flex={3}
                value={receiverEmail}
                onChange={(e) => setReceiverEmail(e.target.value)}
                boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
                padding="5px"
              />
              <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
                {t("TXT_Subject")}
              </Text>
              <Input
                flex={3}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
                padding="5px"
              />
              <Text fontSize="15px" color={returnByTheme()} marginTop="10px">
                {t("TXT_Message")}
              </Text>
              <Textarea
                flex={3}
                value={message}
                size="sm"
                onChange={(e) => setMessage(e.target.value)}
                minHeight="150px"
                borderRadius="10px"
                boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
              />
              <Button
                backgroundColor={returnByTheme()}
                _hover={{ backgroundColor: returnByTheme() }}
                disabled={sendingInvoice}
                onClick={() => {
                  sendInvoice({
                    variables: {
                      input: {
                        id: invoicePreview?.createTemplateInvoice.id!,
                        receiverEmail: receiverEmail,
                        subject: subject,
                        content: message,
                      },
                    },
                  });
                }}
                marginTop="20px"
                borderRadius="40px"
              >
                <IconContext.Provider value={{ color: "#FFF", size: "24px" }}>
                  <FiSend />
                  <Text paddingLeft="10px" color="WT">
                    {t("TXT_Send")}
                  </Text>
                </IconContext.Provider>
              </Button>
            </Flex>
          </PopUpModal>

          {/* <PopUpModal
            isShow={isShowSuccessModal}
            onClose={() => {
              setIsShowSuccessModal(false);
            }}
            headerColor={returnByTheme()}
            headerText="Message"
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <SuccessIcon width="100px" height="100px" />
              <Text textAlign="center" fontWeight="700" color={returnByTheme()}>
                {t("TXT_Success")}
              </Text>
              <Text textAlign="center" fontWeight="700" paddingTop="15px">
                {t("TXT_Invoice_already_user_desc")}
              </Text>
              <Text textAlign="center" fontSize="15px" color="#8E8E8E">
                {t("TXT_Invoice_contact_you_desc")}
              </Text>

              <Flex
                flexDirection="column"
                backgroundColor="#F0F0F0"
                width="90%"
                marginTop="20px"
              >
                <Text> {t("TXT_Name")}</Text>
                <Text> {t("TXT_Email")}</Text>
                <Text> {t("TXT_Company_name")}</Text>
                <Text> {t("TXT_Phone")}</Text>
              </Flex>
              <Button
                backgroundColor="WT"
                _hover={{ backgroundColor: "WT" }}
                onClick={() => {
                  setIsShowSuccessModal(false);
                  setIsShowSendModal(false);
                }}
                marginTop="20px"
                borderRadius="40px"
                flex={1}
                borderColor="#000"
                borderWidth="1px"
                width="50%"
                py="10px"
              >
                <Text color="#000">{t("TXT_Close")}</Text>
              </Button>
            </Flex>
          </PopUpModal> */}
        </Flex>
      </Flex>
    </>
  );
};

const PdfViewer = React.memo(
  ({ pdfUrl, onDocumentLoadSuccess, pageNumber, saveAsDraft }: any) => {
    const [zoomSize, setZoomSize] = useState(1.2);
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
      <>
        <Document
          file={{
            url: pdfUrl,
            headers: { "Access-Control-Allow-Origin": "*" },
          }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log("Inside Error", error)}
        >
          <Page pageNumber={pageNumber} scale={zoomSize} />
        </Document>
        <Flex
          flex={1}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          bottom="-45px"
          left="0"
          right="0"
          //backgroundColor="#FFA"
          zIndex={2}
          py="5px"
        >
          <Flex
            //p="5px"
            px="10px"
            h="60px"
            flexDirection="row"
            backgroundColor="WT"
            borderWidth="1px"
            borderRadius="15px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
            alignItems="center"
            justifyContent="center"
            gap="10px"
          >
            <IconContext.Provider value={{ color: "#8E8E8E", size: "35px" }}>
              <Flex cursor="pointer">
                <FiZoomIn
                  onClick={() => {
                    setZoomSize((cur) => (cur < 2 ? cur + 0.1 : cur));
                  }}
                />
              </Flex>

              <Flex cursor="pointer">
                <FiZoomOut
                  onClick={() => {
                    setZoomSize((cur) => (cur > 0.7 ? cur - 0.1 : cur));
                  }}
                />
              </Flex>
            </IconContext.Provider>
            <Flex
              alignItems="center"
              fontSize="18px"
              textColor="GREY"
              borderLeft="1px solid #F0F0F0"
              paddingLeft="10px"
              cursor="pointer"
              onClick={() => {
                window.location.href = pdfUrl;
              }}
              fontWeight="bold"
            >
              <Icon
                as={RiFileDownloadLine}
                width="25px"
                height="25px"
                mr="5px"
              />
              {t("Download")}
            </Flex>
            <Flex
              alignItems="center"
              fontSize="18px"
              textColor="GREY"
              borderLeft="1px solid #F0F0F0"
              paddingLeft="10px"
              cursor="pointer"
              onClick={() => {
                saveAsDraft();
              }}
              fontWeight="bold"
            >
              <Icon as={RiSaveLine} width="25px" height="25px" mr="5px" />
              {t("Save Draft")}
            </Flex>
          </Flex>
        </Flex>
      </>
    );
  },
  (prevProps: any, nextProps: any) => true
);

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default InvoiceCreate;
