import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Image,
  Input,
  useToast,
  useOutsideClick,
  Icon,
} from "@chakra-ui/react";
import { FiSearch, FiBell } from "react-icons/fi";
import { returnByTheme } from "src/utils/util";
import { useSubscription } from "@apollo/client";
import { NotificationAddedDocument } from "src/graphql/generated";
import RightCornerTool from "./RightCornerTool";

const GeneralTopHeader = ({ headerTitle, hadRightCornerTools }: any) => {
  return (
    <Flex
      backgroundColor="WT"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
      px="20px"
      minH="60px"
    >
      <Flex>
        <Text fontWeight="bold">{headerTitle}</Text>
      </Flex>
      {hadRightCornerTools && (
        <>
          <Flex></Flex>
          <RightCornerTool />
        </>
      )}
    </Flex>
  );
};

export default GeneralTopHeader;
