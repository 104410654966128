import { Box, Input, Text, Flex } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import TextPreviewRow from "../../../components/createProjectForm/TextPreviewRow";
import FilePreviewRow from "../../../components/createProjectForm/FilePreviewRow";
import { returnByTheme, returnByColor } from "../../../utils/util";
import { ReactComponent as EditIcon } from "src/icons/general/editIcon.svg";
import { convertDisplayText } from "../../../utils/util";
import { IProjectDetailData } from "../../../type/IBaseInfotInterface";
import { useTranslation } from "react-i18next";

interface IProjectDetailProps {
  projectDetailData: IProjectDetailData;
  editProjectDetailAction: () => void;
}

const ProjectDetailPreview = ({
  projectDetailData,
  editProjectDetailAction,
}: IProjectDetailProps) => {
  // console.log("project detail section preview");
  // console.log(projectDetailData);
  const { t } = useTranslation();

  const {
    address,
    code,
    latitude,
    longitude,
    name,
    progress,
    typeOfSite,
    projectImages,
    companyDocuments,
  } = projectDetailData;

  // const addressAsString = address
  //   .map((a, i) => {
  //     if (a) {
  //       return i !== 3 ? t(`TXT_Option_${a.toLowerCase()}`) : a;
  //     } else {
  //       return "-";
  //     }
  //   })
  //   .reverse()
  //   .join(", ");

  const addressAsString = `${address[3]} , ${t(
    `TXT_Option_${address[2].toLowerCase()}`
  )} , ${t(`TXT_${address[1].toLowerCase()}`)} , ${address[0]}`;

  const statusTextArr: { [key: string]: string } = {
    startedAt: t("TXT_PjOption_Started"),
    approvalLetterSentAt: t("TXT_PjOption_ApprovalLetterSent"),
    acknowledgedAt: t("TXT_PjOption_Acknowledged"),
    hardwareInstalledAt: t("TXT_PjOption_HardwareInstalled"),
    systemTestedAt: t("TXT_PjOption_SystemTested"),
    fitMeterInstalledAt: t("TXT_PjOption_FitMeterInstalled"),
    completedAt: t("TXT_PjOption_Completed"),
    contractEndedAt: t("TXT_PjOption_ContractEnded"),
  };
  // console.log(progress);
  // console.log(typeOfSite);

  return (
    <Box mb="30px">
      <SectionTitle
        title={t("TXT_Project_BItitle_project_detail")}
        editAction={() => editProjectDetailAction()}
      />
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        px="20px"
        flexDirection={"column"}
      >
        <TextPreviewRow
          label={t("TXT_Project_project_progress")}
          value={statusTextArr[progress]}
          hadBorder={false}
        />
        <TextPreviewRow label={t("TXT_Project_project_code")} value={code} />
        <TextPreviewRow label={t("TXT_Project_project_name")} value={name} />
        <TextPreviewRow
          label={t("TXT_Project_Type_of_site")}
          value={t(
            `TXT_Option_${typeOfSite?.toUpperCase()?.replaceAll("-", "_")}`
          )}
        />
        <TextPreviewRow label={t("TXT_Address")} value={addressAsString} />
        {/* <TextPreviewRow
          label="Latitude - Longitude"
          value={`${latitude ? latitude : ""} - ${longitude ? longitude : ""}`}
        /> */}
        {projectImages && projectImages?.length > 0 && (
          <FilePreviewRow
            label={t("TXT_Project_project_image")}
            filesList={projectImages}
          />
        )}
        {companyDocuments && companyDocuments?.length > 0 && (
          <FilePreviewRow
            label={t("TXT_Project_Company_documents")}
            filesList={companyDocuments}
          />
        )}
      </Flex>
    </Box>
  );
};

const SectionTitle = ({
  title,
  editAction,
}: {
  title: string;
  editAction: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb="5px">
      <Flex fontSize={18} fontWeight={"bold"}>
        {title}
      </Flex>
      <Flex
        cursor={"pointer"}
        borderRadius="4px"
        borderWidth="1px"
        borderColor={returnByTheme()}
        bg="WT"
        color={returnByTheme()}
        alignItems={"center"}
        px="20px"
        py="2px"
        onClick={() => editAction()}
      >
        <EditIcon stroke={returnByColor()} />
        <Box ml="5px">{t("TXT_Edit")}</Box>
      </Flex>
    </Flex>
  );
};

export default ProjectDetailPreview;
