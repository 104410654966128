import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  InputGroup,
  Input,
  useToast,
  useOutsideClick,
  keyframes,
  Textarea,
  Image,
} from "@chakra-ui/react";

import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import { BsChat } from "react-icons/bs";
import { ReactComponent as DarkCloseBtn } from "src/icons/general/darkCloseBtn.svg";
import { ReactComponent as WhiteCloseBtn } from "src/icons/general/whiteCloseBtn.svg";
import { ReactComponent as PDFIcon } from "src/icons/general/pdfIcon.svg";
import { ReactComponent as TrashIcon } from "src/icons/general/trashIcon.svg";
import { ReactComponent as SendIcon } from "src/icons/general/sendLetterIcon.svg";
import { ReactComponent as UploadAttachIcon } from "src/icons/general/uploadAttachIcon.svg";

import _ from "lodash";
import moment, { Moment } from "moment";
import {
  returnByTheme,
  returnByColor,
  convertAsAvatarTitle,
  isEmptyString,
  uploadDoc,
  randomBgColor,
} from "../../utils/util";
import PopUpModal from "src/components/PopUpModal";
import { useTranslation } from "react-i18next";
import {
  CommentsDocument,
  CreateCommentDocument,
  GetmeDocument,
  RemoveCommentDocument,
  UpdateCommentDocument,
} from "src/graphql/generated";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FiFlag } from "react-icons/fi";

const TaskSwiper = ({ task }: any) => {
  const [currentTask, setCurrentTask] = useState(0);
  const [currentTaskArr, setCurrentTaskArr] = useState(0);

  const isClient = localStorage.getItem("sf_Role") == "true";
  const toast = useToast();
  const [isShowCommentModal, setIsShowCommentModal] = useState({
    curr: -1,
    show: false,
  });

  const { t } = useTranslation();

  const animationKeyframes = keyframes`
  0% {opacity:0.2;}
  30% {opacity:0.4;}
  70% {opacity:0.6;}
  100% {opacity:1;}
`;

  const animation = `${animationKeyframes} 1s ease-in-out `;

  useEffect(() => {
    task.map((t: any, i: any) => {
      const curr = moment(new Date());
      const start = moment(t?.startTime);
      const end = moment(t?.endTime).add(1, "day");
      // set default display current
      if (curr.isBetween(start, end, "day")) {
        setCurrentTask(i >= 5 ? i - 5 : i);

        // const locatedIndex = Math.ceil((i + 1) / 5) - 1;

        const locatedIndex = i < 5 ? 0 : 1;

        setCurrentTaskArr(locatedIndex);
      }
    });

    return () => {};
  }, []);

  const hadPrevTaskArr = currentTaskArr == 1 && task?.length > 5;
  const hadNextTaskArr = currentTaskArr == 0 && task?.length > 5;

  const displayTaskArr =
    currentTaskArr !== 0 ? task?.slice(5) : task?.slice(0, 5);

  const priorityText =
    displayTaskArr[currentTask]?.priority == "HIGH_PRIORITY"
      ? "high"
      : displayTaskArr[currentTask]?.priority == "LOW_PRIORITY"
      ? "low"
      : "none";

  return (
    <Flex flexDirection="column">
      {/* controller */}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        p="5px"
        borderRadius="25px"
        boxShadow="0px 0px 3px 3px rgba(0, 0, 0, 0.05)"
      >
        <Flex
          pointerEvents={hadPrevTaskArr ? "auto" : "none"}
          cursor="pointer"
          width="35px"
          height="35px"
          borderRadius="25px"
          borderColor={hadPrevTaskArr ? returnByTheme() : "DISABLEDTEXT"}
          borderWidth="1px"
          justifyContent="center"
          alignItems="center"
          mr="5px"
          onClick={() => {
            setCurrentTask(0);
            setCurrentTaskArr(0);
          }}
        >
          <AiOutlineLeft
            color={hadPrevTaskArr ? returnByColor() : "#CFCFCF"}
            width="30px"
            height="30px"
          />
        </Flex>

        <Flex flex={1}>
          {displayTaskArr.map((t: any, i: number) => {
            const isCurr = i == currentTask;
            return (
              <Flex
                key={i}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                onClick={() => setCurrentTask(i)}
                bg={isCurr ? returnByTheme() : "WT"}
                color={isCurr ? "WT" : "GREY"}
                width="45px"
                height="45px"
                borderRadius="25px"
                cursor="pointer"
                mx="2px"
              >
                <Text fontSize="10px" lineHeight="1.2">
                  {moment(t.startTime).format("MMM")}
                </Text>
                <Text fontWeight="bold" lineHeight="1.2">
                  {moment(t.startTime).format("DD")}
                </Text>
              </Flex>
            );
          })}
        </Flex>

        <Flex
          pointerEvents={hadNextTaskArr ? "auto" : "none"}
          cursor="pointer"
          width="35px"
          height="35px"
          borderRadius="25px"
          borderColor={hadNextTaskArr ? returnByTheme() : "DISABLEDTEXT"}
          borderWidth="1px"
          justifyContent="center"
          alignItems="center"
          ml="5px"
          onClick={() => {
            setCurrentTask(0);
            setCurrentTaskArr(1);
          }}
        >
          <AiOutlineRight
            color={hadNextTaskArr ? returnByColor() : "#CFCFCF"}
            width="30px"
            height="30px"
          />
        </Flex>
      </Flex>

      {displayTaskArr[currentTask] && (
        <Flex
          flexDirection="column"
          flex={1}
          key={`task-${currentTask}`}
          animation={animation}
          transition="width 0.5s, height 0.5s, opacity 0.5s 0.5s"
          borderRadius="10px"
          boxShadow="0px 0px 3px 3px rgba(0, 0, 0, 0.05)"
          mt="10px"
          mb="30px"
          p="10px"
        >
          <Flex>
            {/* task info section */}
            <Flex flexDirection="column" mr="10px" flex={1}>
              <Flex>
                <Flex flex={1} fontWeight="bold" mb="5px">
                  {displayTaskArr[currentTask]?.title}
                </Flex>
                {priorityText !== "none" && (
                  <Flex
                    // flex={1}
                    px="5px"
                    bg={
                      priorityText == "high"
                        ? "rgba(231, 27, 43, 0.25)"
                        : priorityText == "low"
                        ? "rgba(16, 62, 98, 0.25)"
                        : "rgba(247, 152, 70, 0.25)"
                    }
                    borderRadius="5px"
                  >
                    <Flex
                      gap="5px"
                      // color={returnByTheme()}
                      alignItems={"center"}
                      color={
                        priorityText == "high"
                          ? "rgba(231, 27, 43, 1)"
                          : priorityText == "low"
                          ? "rgba(16, 62, 98, 1)"
                          : "rgba(247, 152, 70, 1)"
                      }
                    >
                      <FiFlag />
                      <Flex fontSize="12px">
                        {t(`TXT_Project_${priorityText}_priority`)}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex fontSize="12px" color="GREY" mb="20px">
                <Text
                  noOfLines={2}
                  textOverflow="ellipsis"
                  wordBreak="break-word"
                >
                  {displayTaskArr[currentTask]?.description}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" fontSize="10px">
                <GetCurrStatusText
                  startTime={displayTaskArr[currentTask]?.startTime}
                  endTime={displayTaskArr[currentTask]?.endTime}
                />
                {/* comment code here */}

                {!isClient && (
                  <Flex
                    color="GREY"
                    cursor="pointer"
                    _hover={{ textDecoration: "underline" }}
                    onClick={() => {
                      setIsShowCommentModal({
                        curr: currentTask,
                        show: true,
                      });
                    }}
                  >
                    {displayTaskArr[currentTask].comments?.length > 0 ? (
                      <>
                        {displayTaskArr[currentTask].comments.length}{" "}
                        {t("TXT_Project_Comments")}
                      </>
                    ) : (
                      <Flex
                        justifyContent="space-between"
                        alignItems="baseline"
                      >
                        <BsChat />
                        <Text ml="5px">{t("TXT_Project_Comment")}</Text>
                      </Flex>
                    )}

                    {currentTask == isShowCommentModal.curr &&
                      isShowCommentModal.show && (
                        <CommentsModal
                          isShow={
                            currentTask >= 5
                              ? currentTask - 5
                              : currentTask == isShowCommentModal.curr &&
                                isShowCommentModal.show
                          }
                          onClose={() =>
                            setIsShowCommentModal({ curr: -1, show: false })
                          }
                          taskId={displayTaskArr[currentTask].id}
                        />
                      )}
                  </Flex>
                )}
              </Flex>
            </Flex>
            {/* date section */}
            <Flex fontWeight="medium" textAlign="center">
              {displayTaskArr[currentTask].startTime && (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Flex
                    fontSize={
                      displayTaskArr[currentTask].endTime ? "10px" : "15px"
                    }
                  >
                    {moment(displayTaskArr[currentTask].startTime).format(
                      "MMM"
                    )}
                  </Flex>
                  <Flex
                    fontSize={
                      displayTaskArr[currentTask].endTime ? "18px" : "25px"
                    }
                  >
                    {moment(displayTaskArr[currentTask].startTime).format("DD")}
                  </Flex>
                  <Flex
                    fontSize={
                      displayTaskArr[currentTask].endTime ? "10px" : "15px"
                    }
                  >
                    {moment(displayTaskArr[currentTask].startTime).format(
                      "YYYY"
                    )}
                  </Flex>
                </Flex>
              )}

              {displayTaskArr[currentTask].startTime !==
                displayTaskArr[currentTask].endTime &&
                displayTaskArr[currentTask].endTime && (
                  <>
                    <Flex my="auto" mx="5px">
                      -
                    </Flex>
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex fontSize="10px">
                        {moment(displayTaskArr[currentTask].endTime).format(
                          "MMM"
                        )}
                      </Flex>
                      <Flex fontSize="18px">
                        {moment(displayTaskArr[currentTask].endTime).format(
                          "DD"
                        )}
                      </Flex>
                      <Flex fontSize="10px">
                        {moment(displayTaskArr[currentTask].endTime).format(
                          "YYYY"
                        )}
                      </Flex>
                    </Flex>
                  </>
                )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

// display current status text
const GetCurrStatusText = ({ startTime, endTime }: any) => {
  const curr = moment(new Date());
  const start = moment(startTime);
  const end = moment(endTime).add(1, "day");
  const { t } = useTranslation();

  if (curr.isBefore(start, "day")) {
    return (
      <Flex alignItems="center">
        <GoPrimitiveDot color="#F26524" />
        <Flex fontSize="10px" color="#F26524">
          {t("TXT_Project_Upcoming")}
        </Flex>
      </Flex>
    );
  } else if (curr.isBetween(start, end, "day") || curr.isSame(start, "day")) {
    return (
      <Flex alignItems="center">
        <GoPrimitiveDot color="#00A5B3" />
        <Flex fontSize="10px" color="#00A5B3">
          {t("TXT_Project_Current")}
        </Flex>
      </Flex>
    );
  } else if (curr.isAfter(end, "day")) {
    return (
      <Flex alignItems="center">
        <GoPrimitiveDot color={returnByColor()} />
        <Flex fontSize="10px" color={returnByColor()}>
          {t("TXT_Project_Ended")}
        </Flex>
      </Flex>
    );
  } else {
    return <>-</>;
  }
};

const CommentsModal = ({ isShow, onClose, taskId }: any) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [tempComment, setTempComment] = useState("");
  const [comments, setComments] = useState<any>([]);
  const [uploadRefIndex, setUploadRefIndex] = useState(0);
  const commentUploadRef = useRef<any>(null);

  const [getAllComments, { data: allComments }] = useLazyQuery(
    CommentsDocument,
    {
      fetchPolicy: "network-only",
      onCompleted: (success) => {
        setComments(success?.comments);
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  useEffect(() => {
    getAllComments({
      variables: {
        taskId: taskId,
      },
    });
    return () => {};
  }, []);

  const updateTempCommentSection = () => {
    addComment({
      variables: {
        input: {
          taskId: taskId,
          userId: myId, // todo allow user add comment
          message: tempComment,
        },
      },
    });
  };

  const deleteComment = (index: number, commentId: string) => {
    removeComment({
      variables: {
        commentId: commentId,
      },
    });
  };
  const [addComment, { loading, error }] = useMutation(CreateCommentDocument, {
    onCompleted: (x) => {
      getAllComments({
        variables: {
          taskId: taskId,
        },
      });
      return toast({
        position: "bottom-right",
        title: `One comment added`,
        status: "success",
      });
    },
    onError: (error) => {
      //navigate(`/invoice`);
      return toast({
        position: "bottom-right",
        title: `Update Error: ${error}`,
        status: "error",
      });
    },
  });

  const [removeComment] = useMutation(RemoveCommentDocument, {
    onCompleted: (x) => {
      getAllComments({
        variables: {
          taskId: taskId,
        },
      });
      return toast({
        position: "bottom-right",
        title: `One comment deleted`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Unable to delete comment, Error: ${error}`,
        status: "error",
      });
    },
  });

  const [updateComment] = useMutation(UpdateCommentDocument, {
    onCompleted: (x) => {
      getAllComments({
        variables: {
          taskId: taskId,
        },
      });
      return toast({
        position: "bottom-right",
        title: `attachment uploaded`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Unable to add attachment, Error: ${error}`,
        status: "error",
      });
    },
  });

  const addCommentAttachment = async (event: any, commentIndex: number) => {
    const uploadedDoc = await uploadDoc(event?.target?.files);

    const arrOfFiles = Array.from(event?.target?.files).map((f: any, i) => {
      return {
        // url: uploadedDoc.result[i].url,
        // url: uploadedDoc.result[i].url
        //   ? uploadedDoc.result[i].url
        //   : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Tkm2I2eZS-dnuXspUUvm8WiB07J0XE8f4A&usqp=CAU",
        filePath: uploadedDoc.result[i].filePath,
        originalName: uploadedDoc.result[i].originalName,
      };
    });

    const originFiles = comments[commentIndex].attachments.map((f: any) => {
      return {
        filePath: f.filePath,
        originalName: f.originalName,
      };
    });

    updateComment({
      variables: {
        input: {
          id: comments[commentIndex].id,
          message: comments[commentIndex].message,
          attachments: [...originFiles, ...arrOfFiles],
        },
      },
    });

    commentUploadRef.current.value = "";
  };

  const { data: meData } = useQuery(GetmeDocument, {
    fetchPolicy: "network-only",
    onCompleted: (x) => {},
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const myId = meData?.me.user
    ? meData?.me?.user?.id!
    : meData?.me?.company?.id!;
  const myCompanyId = meData?.me?.company?.id!;

  return (
    <PopUpModal
      isShow={isShow}
      onClose={() => onClose()}
      headerColor={returnByTheme()}
      headerText={t("TXT_Project_Comments")}
      size="lg"
    >
      <Flex py="20px">
        <Flex
          position={"relative"}
          marginRight="10px"
          flex={0.3}
          alignItems="center"
          flexDirection={"column"}
        >
          <Flex
            width="45px"
            minHeight="45px"
            maxHeight="45px"
            bg={"red.300"}
            borderRadius={"50%"}
            justifyContent="center"
            alignItems={"center"}
          >
            <Flex
              textAlign={"center"}
              fontWeight={"light"}
              color={returnByTheme()}
            >
              YOU
            </Flex>
          </Flex>
          {/* <Flex
            width={"1px"}
            height={"100%"}
            my="10px"
            bg={returnByTheme()}
          ></Flex> */}
        </Flex>

        <Flex flexDirection="column" flex={3}>
          <Box
            border={"1px solid"}
            borderColor={returnByTheme()}
            borderRadius={"5px"}
            px="10px"
            pb="5px"
          >
            <Textarea
              border={"none"}
              outline={"none"}
              boxShadow={"none"}
              focusBorderColor={"none"}
              value={tempComment}
              resize={"none"}
              minHeight="150px"
              placeholder={t("TXT_Project_leave_a_comment")}
              _placeholder={{ color: "DISABLEDTEXT" }}
              maxLength={150}
              px="0"
              pb="5px"
              onChange={(e) => setTempComment(e.target.value)}
            />
            <Flex height="20px">
              <Flex
                justifyContent={"space-between"}
                width="100%"
                alignItems={"center"}
              >
                <Flex
                  onClick={() => {
                    if (isEmptyString(tempComment)) {
                      return;
                    }
                    updateTempCommentSection();
                  }}
                  cursor="pointer"
                >
                  <SendIcon />
                </Flex>
                <Box>0/150</Box>
              </Flex>
            </Flex>
          </Box>
          <Box fontSize="10px" color="GREY">
            {t("TXT_Project_Comment_input_hints")}
          </Box>
        </Flex>
      </Flex>

      {/* comment history section */}
      {comments?.map((c: any, commentIndex: number) => {
        const isSameUser = c?.user?.id == myId;
        const newUserObj =
          c.user.__typename == "Company"
            ? {
                name: c.user?.companyName,
                email: c.user?.companyEmail,
                url: c.user?.profileImage?.url,
              }
            : {
                name: c.user?.userName,
                email: c.user?.userEmail,
                url: c.user?.profileImage?.url,
              };

        return (
          <Flex key={commentIndex}>
            <Flex
              position={"relative"}
              marginRight="10px"
              flex={0.3}
              alignItems="center"
              flexDirection={"column"}
            >
              <Flex
                position="absolute"
                width="45px"
                minHeight="45px"
                maxHeight="45px"
                borderRadius={"50%"}
                border="1px solid #f0f0f0"
                justifyContent="center"
                alignItems={"center"}
                bg={
                  newUserObj?.url && !isSameUser
                    ? `url(${newUserObj?.url})`
                    : randomBgColor()
                }
                bgSize="contain"
                bgPos="center"
                bgRepeat="no-repeat"
              >
                {(!newUserObj?.url || isSameUser) && (
                  <Flex
                    textAlign={"center"}
                    fontWeight={"light"}
                    color={"WT"}
                    // color={randomColor()}
                  >
                    {isSameUser ? "YOU" : convertAsAvatarTitle(newUserObj.name)}
                  </Flex>
                )}
              </Flex>
              {commentIndex !== comments.length - 1 && (
                <Flex width={"1px"} height={"100%"} bg={returnByTheme()}></Flex>
              )}
            </Flex>
            <Flex flex={3} flexDirection={"column"} mb="20px">
              <Flex
                color={"GREY"}
                fontSize={"14px"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                mb="5px"
              >
                <Flex>
                  {newUserObj.name} ,{" "}
                  {moment(c.createdAt).format("DD MMM, YYYY")}
                </Flex>
                {isSameUser && c.message !== null && (
                  <Flex gap="10px">
                    <Input
                      display={"none"}
                      ref={commentUploadRef}
                      multiple={true}
                      type={"file"}
                      accept={".jpg,.png,.pdf"}
                      onChange={(e) => {
                        addCommentAttachment(e, uploadRefIndex);
                      }}
                    />
                    <UploadAttachIcon
                      cursor="pointer"
                      onClick={() => {
                        commentUploadRef?.current?.click();
                        setUploadRefIndex(commentIndex);
                      }}
                    />
                    <TrashIcon
                      cursor={"pointer"}
                      onClick={() => {
                        deleteComment(commentIndex, c.id);
                      }}
                    />
                  </Flex>
                )}
              </Flex>
              <Flex p="10px" borderRadius="10px" background={"DISABLEDBG"}>
                {c?.message ? (
                  c?.message
                ) : (
                  <Text color="DISABLEDTEXT">
                    {c?.user} {t("TXT_Project_Deleted_comment_desc")}
                  </Text>
                )}
              </Flex>
              {c.attachments?.map((a: any, attachmentIndex: number) => {
                // const imageUrl = URL.createObjectURL(a?.file);
                const extensionName = a?.originalName.split(".").pop();
                return (
                  <Flex
                    key={attachmentIndex}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    borderBottomColor={"DISABLEDBG"}
                    py="10px"
                  >
                    <Flex mr={"10px"}>
                      {(extensionName !== "pdf" && (
                        <Image
                          width={"30px"}
                          height={"30px"}
                          src={a.url}
                          borderRadius={"5px"}
                          fit={"cover"}
                          loading={"lazy"}
                        />
                      )) || <PDFIcon />}

                      <Flex alignItems={"center"} ml="5px">
                        <Text
                          ml="5px"
                          noOfLines={1}
                          textOverflow="ellipsis"
                          maxW="250px"
                        >
                          {a?.originalName}
                        </Text>
                      </Flex>
                    </Flex>
                    <TrashIcon
                      cursor={"pointer"}
                      onClick={() => {
                        // get latest comment
                        const remainingAttachArr = comments[
                          commentIndex
                        ]?.attachments.filter(
                          (f: any, selectedIndex: number) => {
                            return selectedIndex !== attachmentIndex;
                          }
                        );

                        updateComment({
                          variables: {
                            input: {
                              id: comments[commentIndex].id,
                              message: comments[commentIndex].message ?? "",
                              attachments: remainingAttachArr.map((f: any) => {
                                return {
                                  filePath: f.filePath,
                                  originalName: f.originalName,
                                };
                              }),
                            },
                          },
                        });
                      }}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </PopUpModal>
  );
};

export default TaskSwiper;
