import _ from "lodash";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Progress,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  convertAsAvatarTitle,
  randomBgColor,
  returnByTheme,
} from "src/utils/util";
import { useNavigate } from "react-router-dom";
import { FiClock, FiFlag } from "react-icons/fi";
import { TasksDocument, UsersDocument } from "src/graphql/generated";
import PopUpModal from "src/components/PopUpModal";

const CurrentTaskRow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [showViewStaffModal, setShowViewStaffModal] = useState({
    isShow: false,
    index: -1,
  });

  const [showPriority, setShowPriority] = useState({
    isShow: false,
    index: -1,
  });

  const loginData = JSON.parse(localStorage.getItem("meData")!);
  const myCompanyID = loginData?.company?.id;

  const { data: taskData } = useQuery(TasksDocument, {
    onCompleted: (data) => {},
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const { data: staffList } = useQuery(UsersDocument, {
    variables: {
      filter: {
        companyId: myCompanyID,
      },
    },
    onCompleted: (data) => {},
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: "Fail to get staff list",
        status: "error",
      });
    },
  });

  const priorityArr = {
    high: {
      text: t("TXT_Project_high_priority"),
      color: "rgba(231, 27, 43, 1)",
      bg: "rgba(230, 151, 158)",
    },
    middle: {
      text: t("TXT_Project_middle_priority"),
      color: "rgba(247, 152, 70, 1)",
      bg: "rgba(240, 195, 156)",
    },
    low: {
      text: t("TXT_Project_low_priority"),
      color: "rgba(16, 62, 98, 1)",
      bg: "rgba(158, 205, 240)",
    },
  };

  return (
    <Flex justifyContent="space-between" gap="20px">
      <Flex
        flex={6}
        py="10px"
        px="20px"
        borderRadius="8px"
        boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
        bg="WT"
        gap="10px"
        maxH="calc(500px - 40px)"
        overflow="auto"
      >
        <Flex flexDir="column" flex={1} mb="20px">
          <Flex justifyContent="space-between">
            <Flex fontWeight="bold">{t("TXT_Dashboard_current_tasks")}</Flex>

            <Flex color="#004B92" alignItems="center" gap="5px">
              <FiClock />
              {t("TXT_Project_Today")}{" "}
              {moment(new Date()).format("DD MMM,YYYY")}
            </Flex>
          </Flex>

          {/* dashboard current task here */}
          <Flex mt="10px" flexDir="column">
            {(taskData?.tasks?.nodes && taskData?.tasks?.nodes?.length > 0 && (
              <>
                {taskData?.tasks.nodes.map((currTask, i) => {
                  const isToday = moment(currTask.endTime).isSame(
                    moment(),
                    "day"
                  );
                  return (
                    <Flex
                      key={i}
                      px="20px"
                      bg="#F7FAFA"
                      borderRadius="12px"
                      alignItems="center"
                      mb="15px"
                      justifyContent="space-between"
                    >
                      {/* section 1 */}
                      <Flex flex={2} gap="10px" alignItems="center">
                        <Image src={require("../../icons/general/clock.png")} />
                        <Flex alignItems="center" fontSize="14px">
                          <Text> {t("TXT_Dashboard_due_in")} &nbsp;</Text>
                          <Text
                            fontWeight="bold"
                            color={isToday ? "RD.2" : "#459EE3"}
                          >
                            {isToday
                              ? t("TXT_Today")
                              : moment(currTask.endTime)
                                  .diff(moment(), "day")
                                  .toString()}
                            &nbsp;
                            {t("TXT_Dashboard_days_left")}
                          </Text>
                        </Flex>
                      </Flex>

                      {/* priority bar */}
                      <Flex
                        position="relative"
                        my="10px"
                        bg={
                          currTask.priority == "HIGH_PRIORITY"
                            ? "#e7311b"
                            : currTask.priority == "LOW_PRIORITY"
                            ? "rgba(16, 62, 98, 0.25)"
                            : "#CFCFCF"
                        }
                        width={
                          currTask.priority == "HIGH_PRIORITY" ||
                          currTask.priority == "LOW_PRIORITY"
                            ? "5px"
                            : "1px"
                        }
                        borderRadius={
                          currTask.priority == "HIGH_PRIORITY" ||
                          currTask.priority == "LOW_PRIORITY"
                            ? "8px"
                            : "none"
                        }
                        height="calc(100% - 20px)"
                        onMouseEnter={() => {
                          if (
                            currTask.priority == "HIGH_PRIORITY" ||
                            currTask.priority == "LOW_PRIORITY"
                          ) {
                            setShowPriority({ isShow: true, index: i });
                          }
                        }}
                        onMouseLeave={() => {
                          if (
                            currTask.priority == "HIGH_PRIORITY" ||
                            currTask.priority == "LOW_PRIORITY"
                          ) {
                            setShowPriority({ isShow: false, index: -1 });
                          }
                        }}
                      >
                        {showPriority.index == i && showPriority.isShow && (
                          <Flex
                            gap="5px"
                            position="absolute"
                            top="-30px"
                            right="-55px"
                            p="5px"
                            borderRadius="6px"
                            zIndex={1}
                            justifyContent="center"
                            alignItems="center"
                            fontSize="12px"
                            minW="110px"
                            color={
                              currTask.priority == "HIGH_PRIORITY"
                                ? priorityArr.high.color
                                : currTask.priority == "LOW_PRIORITY"
                                ? priorityArr.low.color
                                : priorityArr.middle.color
                            }
                            bg={
                              currTask.priority == "HIGH_PRIORITY"
                                ? priorityArr.high.bg
                                : currTask.priority == "LOW_PRIORITY"
                                ? priorityArr.low.bg
                                : priorityArr.middle.bg
                            }
                          >
                            <FiFlag />
                            {currTask.priority == "HIGH_PRIORITY"
                              ? priorityArr.high.text
                              : currTask.priority == "LOW_PRIORITY"
                              ? priorityArr.low.text
                              : priorityArr.middle.text}
                          </Flex>
                        )}
                      </Flex>

                      {/* section 2 */}
                      <Flex
                        flex={3}
                        flexDir="column"
                        px="10px"
                        overflow="hidden"
                      >
                        <Flex
                          alignItems="center"
                          flexDir="row"
                          justifyContent="space-between"
                        >
                          <Flex
                            flex={1}
                            fontWeight="bold"
                            noOfLines={1}
                            textOverflow="ellipsis"
                          >
                            {currTask.title}
                          </Flex>
                          <Flex fontSize="12px">
                            <Flex>Start from &nbsp;</Flex>
                            <Flex color="#459EE3">
                              {moment(currTask.startTime).format("DD MMM,YYYY")}
                            </Flex>
                          </Flex>
                        </Flex>
                        <Text
                          noOfLines={1}
                          textOverflow="ellipsis"
                          fontSize="14px"
                          color="GREY"
                        >
                          {currTask.description}
                        </Text>
                      </Flex>

                      {/* section 3 */}

                      {/* projectStatusArr[0]?.status == "contractEndedAt"
            ? returnByTheme()
            : item?.sitePower
            ? "LB.4"
            : "GN.2" */}
                      <Flex
                        flex={3}
                        borderLeft={
                          i % 2 ? "8px solid #69B3E3" : "8px solid #3faca7"
                        }
                        py="10px"
                        pl="10px"
                      >
                        <Flex alignItems="center" width="100%" gap="10px">
                          <Flex flexDir="column" flex={1}>
                            <Flex
                              fontWeight="bold"
                              noOfLines={1}
                              textOverflow="ellipsis"
                            >
                              {currTask.siteCode}
                            </Flex>
                            <Text
                              noOfLines={1}
                              textOverflow="ellipsis"
                              fontSize="14px"
                              color="GREY"
                            >
                              {currTask.siteName}
                            </Text>
                          </Flex>

                          <Flex
                            color="#459EE3"
                            _hover={{ cursor: "pointer", color: "#004B92" }}
                            onClick={() => {
                              navigate(`/project/detail/${currTask.siteCode}`, {
                                state: {
                                  totalIDs: "noneed",
                                  currId: currTask?.siteId,
                                  // status: currTask.siteStatus,
                                },
                              });
                            }}
                          >
                            {t("TXT_Details")}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  );
                })}
              </>
            )) || <Flex>No tasks.</Flex>}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flex={3}
        py="10px"
        px="20px"
        borderRadius="8px"
        boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
        bg="WT"
        gap="10px"
        maxHeight="calc(500px - 40px)"
        // overflow="hidden"
      >
        <Flex flexDir="column" flex={1}>
          <Flex fontWeight="bold">{t("TXT_Dashboard_staff_panel")}</Flex>
          <Flex overflow="scroll" flexDir="column" my="10px">
            {staffList?.users.nodes.map((staff, i) => {
              return (
                <Flex
                  key={i}
                  borderBottom="1px solid #F0F0F0"
                  justifyContent="space-between"
                  gap="10px"
                >
                  <Flex alignItems="center" width="60%">
                    <Flex
                      // bg="blue.400"
                      borderRadius="50%"
                      minWidth="45px"
                      height="45px"
                      border="1px solid #f0f0f0"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      bg={
                        staff.profileImage?.url
                          ? `url(${staff.profileImage?.url})`
                          : randomBgColor()
                      }
                      bgSize="contain"
                      bgPos="center"
                      bgRepeat="no-repeat"
                    >
                      {!staff.profileImage?.url && (
                        <Text color="WT">
                          {convertAsAvatarTitle(staff?.name ?? staff?.email)}
                        </Text>
                      )}
                    </Flex>
                    <Flex flexDir="column" ml="10px">
                      <Text
                        fontWeight="bold"
                        noOfLines={1}
                        textOverflow="ellipsis"
                      >
                        {staff?.name}
                      </Text>
                      <Text color="GREY" noOfLines={1} textOverflow="ellipsis">
                        {staff?.email}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex
                    alignItems="center"
                    gap="10px"
                    bg="WT"
                    justifyContent="flex-end"
                  >
                    <Flex
                      flexDir="column"
                      borderRadius="8px"
                      border="1px solid #f0f0f0"
                    >
                      <Flex
                        color="WT"
                        bg={returnByTheme()}
                        py="3px"
                        px="8px"
                        overflow="hidden"
                        fontSize="12px"
                      >
                        {t("TXT_Project")}
                      </Flex>
                      <Flex fontWeight="bold" justifyContent="center" bg="WT">
                        {staff?.permittedSites.length}
                      </Flex>
                    </Flex>
                    <Flex>
                      <Image
                        cursor="pointer"
                        src={require("../../icons/general/viewStaff.png")}
                        onClick={() =>
                          setShowViewStaffModal({
                            isShow: true,
                            index: i,
                          })
                        }
                      />
                    </Flex>
                  </Flex>

                  <PopUpModal
                    isShow={
                      showViewStaffModal.index == i && showViewStaffModal.isShow
                    }
                    onClose={() =>
                      setShowViewStaffModal({
                        isShow: false,
                        index: -1,
                      })
                    }
                    headerColor={returnByTheme()}
                    headerText={t("TXT_Account_Staff_detail")}
                  >
                    <Flex flexDirection="column" pt="10px">
                      <Flex flex={5} alignItems="center" gap="10px" mb="20px">
                        <Flex
                          borderRadius="50%"
                          width="100px"
                          height="100px"
                          justifyContent="center"
                          alignItems="center"
                          overflow="hidden"
                          border="1px solid #f0f0f0"
                          bg={
                            staff.profileImage?.url
                              ? `url(${staff.profileImage?.url})`
                              : randomBgColor()
                          }
                          bgSize="contain"
                          bgPos="center"
                          bgRepeat="no-repeat"
                        >
                          {!staff.profileImage?.url && (
                            <Text color="WT">
                              {convertAsAvatarTitle(
                                staff?.name ?? staff?.email
                              )}
                            </Text>
                          )}
                        </Flex>
                        <Flex flexDir="column" ml="10px" gap="5px">
                          <Text fontSize="25px" fontWeight="bold">
                            {staff?.name ?? "-"}
                          </Text>
                          <Flex
                            fontSize="18px"
                            color="WT"
                            bg={returnByTheme()}
                            py="5px"
                            px="10px"
                            borderRadius="5px"
                          >
                            <Text>{t("TXT_Grade")}: </Text>
                            <Text fontWeight="bold">
                              {t(`TXT_Account_${staff.roles[0]}`)}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>

                      <Flex py="20px" borderTopWidth="1px">
                        <Flex flex={2} color="GREY">
                          {t("TXT_Email")}
                        </Flex>
                        <Flex
                          flex={3}
                          justifyContent="flex-end"
                          fontWeight="bold"
                        >
                          {staff.email}
                        </Flex>
                      </Flex>

                      <Flex py="20px" borderTopWidth="1px">
                        <Flex flex={2} color="GREY">
                          {t("TXT_Phone")}
                        </Flex>
                        <Flex
                          flex={3}
                          justifyContent="flex-end"
                          fontWeight="bold"
                        >
                          {staff.phone?.areaCode} {staff.phone?.phone}
                        </Flex>
                      </Flex>

                      <Flex py="20px" borderTopWidth="1px">
                        <Flex flex={2} color="GREY">
                          {t("TXT_Account_Project_count")}
                        </Flex>
                        <Flex
                          flex={3}
                          justifyContent="flex-end"
                          fontWeight="bold"
                        >
                          {staff?.permittedSites.length}
                        </Flex>
                      </Flex>
                    </Flex>
                  </PopUpModal>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CurrentTaskRow;
