import {
  Box,
  chakra,
  Flex,
  Text,
  Textarea,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";

import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IconContext } from "react-icons";
import { useLocation, useNavigate } from "react-router-dom";
import addressSelection from "../../json/addressSelection.json";

import ProjectDetailSection from "./stepOneForm/ProjectDetailSection";
import InvestorDetailSection from "./stepOneForm/InvestorDetailSection";
import ClientDetailSection from "./stepOneForm/ClientDetailSection";
import ProjectManagerSection from "./stepOneForm/ProjectManagerSection";
import ProjectTaskSection from "./stepOneForm/ProjectTaskSection";

import EleBillAccSection from "./stepTwoForm/EleBillAccSection";
import EleDetailSection from "./stepTwoForm/EleDetailSection";

import ToClientSection from "./stepThreeForm/ToClientSection";
import PayMethodSection from "./stepThreeForm/PayMethodSection";

import ProjectDetailPreview from "./stepFourForm/ProjectDetailPreview";
import ProjectTasksPreview from "./stepFourForm/ProjectTasksPreview";
import InvestorDetailPreview from "./stepFourForm/InvestorDetailPreview";
import ClientDetailPreview from "./stepFourForm/ClientDetailPreview";
import PMDetailPreview from "./stepFourForm/PMDetailPreview";

import BillAccDetailPreview from "./stepFourForm/BillAccDetailPreview";
import EleDetailPreview from "./stepFourForm/EleDetailPreview";

import ToClientPreview from "./stepFourForm/ToClientPreview";
import PayMethodPreview from "./stepFourForm/PayMethodPreview";

import {
  IProjectDetailData,
  IInvestorDetailData,
  IClientDetailData,
  IProjectManagerData,
  IProjectTaskData,
} from "../../type/IBaseInfotInterface";

import {
  IBillAccDetailData,
  IEleDetailData,
} from "../../type/IBillInfoInterface";
import {
  IInvoiceClientData,
  IInvoicePaymentMethodData,
} from "../../type/IInoviceInfoInterface";
import PopUpModal from "src/components/PopUpModal";
import { FiAlertTriangle } from "react-icons/fi";
import EditModal from "./EditModal";
import {
  isEmptyString,
  returnByTheme,
  returnByColor,
  convertDisplayText,
} from "../../utils/util";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  CreateSiteDocument,
  ElectricCompanyInfoParty,
  ElectricCompanyInfoCompany,
  SiteStatus,
  Task_Priority,
  UpdateInvestmentDocument,
} from "../../graphql/generated";
import { useMutation } from "@apollo/client";
import moment from "moment";

const CreateProject = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { sharingRatio, investmentId } = (location?.state as any) ?? {};
  const toast = useToast();
  const isClient = localStorage.getItem("sf_Role") == "true";
  const [currentStep, setCurrentStep] = useState(0);
  const [isShowAlertModal, setIsShowAlertModal] = useState(false);
  const [isShowPDetailEditModal, setIsShowPDetailEditModal] = useState(false);
  const [isShowPTaskEditModal, setIsShowPTaskEditModal] = useState(false);
  const [isShowIDetailEditModal, setIsShowIDetailEditModal] = useState(false);
  const [isShowCDetailEditModal, setIsShowCDetailEditModal] = useState(false);
  const [isShowPMEditModal, setIsShowPMEditModal] = useState(false);
  const [isShowBillAccEditModal, setIsShowBillAccEditModal] = useState(false);
  const [isShowEleDetailEditModal, setIsShowEleDetailEditModal] =
    useState(false);
  const [isShowToClientEditModal, setIsShowToClientEditModal] = useState(false);
  const [isShowPaymentEditModal, setIsShowPaymentEditModal] = useState(false);
  const [isValidBillAc, setIsValidBillAc] = useState(true);

  // const [validToSubmit, setValidToSubmit] = useState(false);

  const [projectDetailData, setProjectDetailData] =
    useState<IProjectDetailData>({
      progress: "startedAt",
      fitCommencementDate: undefined,
      code: "",
      name: "",
      typeOfSite: "",
      address: [t("TXT_Hong_Kong"), "", "", ""],
    });

  const [projectTaskData, setProjectTaskData] = useState<
    Array<IProjectTaskData>
  >([
    {
      title: "",
      date: [
        {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      ],
      desc: "",
      comment: [],
      attachment: [],
    },
  ]);

  const [investorDetailData, setInvestorDetailData] = useState<
    Array<IInvestorDetailData>
  >([
    {
      // name: "",
      // phone: "",
      email: "",
      sharingType: "share",
      clientRatio: 0,
      investorRatio: 0,
      date: [
        {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      ],
    },
  ]);

  const [clientDetailData, setClientDetailData] = useState<IClientDetailData>({
    // name: "",
    // phone: "",
    email: "",
  });

  const [pmDetailData, setPmDetailData] = useState<Array<any>>([]);

  const [eleAccDetailData, setEleAccDetailData] = useState<IBillAccDetailData>({
    ownerType: "Client",
    eleCompany: "Clp",
    loginID: "",
    loginPW: "",
  });

  const [eleDetailData, setEleDetailData] = useState<IEleDetailData>({
    fitCode: "",
    meterID: "",
    fitMeterID: "",
    fitRate: 0,
    energyOutputByEC: 0,
    energyOutputByContractor: 0,
  });

  const [invoiceClientData, setInvoiceClientData] =
    useState<IInvoiceClientData>({
      name: "",
      phone: "",
      email: "",
      address: [t("TXT_Hong_Kong"), "", "", ""],
    });

  const totalStep = [
    t("TXT_Project_Basic_info"),
    t("TXT_Project_Bill_info"),
    t("TXT_Project_Invoice_info"),
    t("TXT_Project_Review_and_submit"),
  ];

  // check user role
  useEffect(() => {
    if (isClient) {
      navigate("/project");
    }

    return () => {};
  }, []);

  // pre set address
  // useEffect(() => {
  //   let distArr = projectDetailData?.address;
  //   let filteredDist =
  //     addressSelection &&
  //     addressSelection.filter(
  //       (o, i) => o?.area == projectDetailData?.address?.[1]
  //     );
  //   distArr[2] = filteredDist?.[0]?.dist?.[0]?.label;

  //   setProjectDetailData({
  //     ...projectDetailData,
  //     address: distArr,
  //   });
  //   return () => {};
  // }, [projectDetailData?.address?.[1]]);

  // control for project status is completion
  useEffect(() => {
    if (projectDetailData.progress !== "completedAt") {
      setProjectDetailData({
        ...projectDetailData,
        fitCommencementDate: undefined,
      });
    }

    return () => {};
  }, [projectDetailData.progress]);

  // pre fill email for invoice
  useEffect(() => {
    if (clientDetailData.email) {
      setInvoiceClientData({
        ...invoiceClientData,
        email: clientDetailData.email,
      });
    }

    return () => {};
  }, [clientDetailData]);

  // validation
  const isProjectDetailPass = () => {
    if (
      isEmptyString(projectDetailData.progress) ||
      isEmptyString(projectDetailData.code) ||
      isEmptyString(projectDetailData.name) ||
      isEmptyString(projectDetailData.typeOfSite) ||
      isEmptyString(projectDetailData.address[1]) ||
      isEmptyString(projectDetailData?.address[2]) ||
      isEmptyString(projectDetailData?.address[3])
    ) {
      return false;
    } else if (
      projectDetailData.progress == "Completion" &&
      projectDetailData.fitCommencementDate == undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isInvestorDetailPass = () => {
    const isAllValid = investorDetailData.map((investor: any, i) => {
      let isValidArr = Object.keys(investor).map((o) => {
        return o == "date"
          ? investor[o][0]?.startDate !== null &&
              investor[o][0]?.endDate !== null
          : investor[o] !== "";
      });
      return _.every(isValidArr, (x) => x === true);
    });

    return _.every(isAllValid, (x) => x === true);
  };

  const isClientDetailPass = () => {
    if (
      // isEmptyString(clientDetailData.name) ||
      // isEmptyString(clientDetailData.phone) ||
      isEmptyString(clientDetailData.email)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isPmDetailPass = () => {
    // const isAllValid = pmDetailData.map((pm: any, i) => {
    //   let isEmptyArr = Object.keys(pm).map((o) => {
    //     return isEmptyString(pm[o]);
    //   });
    //   return _.every(isEmptyArr, (x) => x === false);
    // });

    // return _.every(isAllValid, (x) => x === true);
    // return pmDetailData.length > 0;
    return true;
  };

  const isBillAccDetailPass = () => {
    // if (
    //   isEmptyString(eleAccDetailData.ownerType) ||
    //   isEmptyString(eleAccDetailData.loginID) ||
    //   isEmptyString(eleAccDetailData.loginPW)
    // ) {
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  };

  const isEleDetailPass = () => {
    // if (
    //   isEmptyString(eleDetailData.fitCode) ||
    //   isEmptyString(eleDetailData.meterID) ||
    //   isEmptyString(eleDetailData.fitMeterID) ||
    //   isEmptyString(eleDetailData.fitRate) ||
    //   isEmptyString(eleDetailData.energyOutputByEC) ||
    //   isEmptyString(eleDetailData.energyOutputByContractor)
    // ) {
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  };

  const isToClientDetailPass = () => {
    if (
      isEmptyString(invoiceClientData.name) ||
      isEmptyString(invoiceClientData.phone) ||
      isEmptyString(invoiceClientData.email) ||
      (!isEmptyString(invoiceClientData.address[3]) &&
        (isEmptyString(invoiceClientData.address[1]) ||
          isEmptyString(invoiceClientData.address[2])))

      // isEmptyString(invoiceClientData.address[3])
    ) {
      return false;
    } else {
      return true;
    }
  };

  const ableToStep2 =
    isProjectDetailPass() && isInvestorDetailPass() && isClientDetailPass();
  // && isPmDetailPass();

  const ableToStep3 = isBillAccDetailPass() && isEleDetailPass();

  const ableToStep4 = isToClientDetailPass();

  // console.log(`ableToStep2`);
  // console.log(ableToStep2);
  // console.log(`ableToStep3`);
  // console.log(ableToStep3);
  // console.log(`ableToStep4`);
  // console.log(ableToStep4);

  const ableToSubmit =
    isProjectDetailPass() &&
    isInvestorDetailPass() &&
    isClientDetailPass() &&
    isPmDetailPass() &&
    isBillAccDetailPass() &&
    isEleDetailPass() &&
    isToClientDetailPass();

  // console.log(`ableToSubmit`);
  // console.log(ableToSubmit);

  const [updateInvestment, { loading: starting }] = useMutation(
    UpdateInvestmentDocument,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  const [createSiteAction, { data: createSiteStatus, loading }] = useMutation(
    CreateSiteDocument,
    {
      onCompleted: (x) => {
        if (investmentId) {
          updateInvestment({
            variables: {
              input: {
                id: investmentId,
                siteId: x.createSite.id,
              },
            },
          });
        }

        toast({
          position: "bottom-right",
          title: `Project created!`,
          status: "success",
        });
        navigate("/project");
      },
      onError: (error) => {
        if (error.message == "Bill Account is not Valid") {
          setIsValidBillAc(false);
          toast({
            position: "bottom-right",
            title: `Your bill account is not valid.`,
            status: "error",
          });
        } else {
          return toast({
            position: "bottom-right",
            title: t("TXT_Error_general_err_message"),
            status: "error",
          });
        }
      },
    }
  );

  useEffect(() => {
    if (sharingRatio) {
      setInvestorDetailData([
        {
          email: "",
          sharingType: "share",
          clientRatio: 0,
          investorRatio: sharingRatio?.value,
          date: [
            {
              startDate: new Date(),
              endDate: null,
              key: "selection",
            },
          ],
        },
      ]);
    }

    return () => {};
  }, [sharingRatio]);

  const creatProjectSubmit = () => {
    const taskObj = projectTaskData.map((t) => {
      const priorityInEnum =
        t.priority == "high"
          ? Task_Priority.HighPriority
          : t.priority == "middle"
          ? Task_Priority.MiddlePriority
          : t.priority == "low"
          ? Task_Priority.LowPriority
          : Task_Priority.Unrecognized;
      return {
        startTime: t.date[0].startDate,
        endTime: t.date[0].endDate ? t.date[0].endDate : t.date[0].startDate,
        title: t.title,
        description: t.desc,
        attachments: t?.attachment
          ? t?.attachment.map((doc) => {
              return {
                filePath: doc.filePath,
                originalName: doc.originalName,
              };
            })
          : [],
        comments: t?.comment
          ? t?.comment.map((comment) => {
              return {
                message: comment.message,
                attachments: comment.commentAttachment?.map((img) => {
                  return {
                    filePath: img.filePath,
                    originalName: img.originalName,
                  };
                }),
              };
            })
          : [],
        priority: priorityInEnum,
      };
    });

    const projectImgObj = projectDetailData?.projectImages?.map((img) => {
      return {
        filePath: img.filePath,
        originalName: img.originalName,
      };
    });

    const projectDocObj = projectDetailData?.companyDocuments?.map((img) => {
      return {
        filePath: img.filePath,
        originalName: img.originalName,
      };
    });

    const convertStatusAsEnum = (statusText: string) => {
      switch (statusText) {
        case "startedAt":
          return SiteStatus.Started;
        case "approvalLetterSentAt":
          return SiteStatus.ApprovalLetterSent;
        case "acknowledgedAt":
          return SiteStatus.Acknowledged;
        case "hardwareInstalledAt":
          return SiteStatus.HardwareInstalled;
        case "systemTestedAt":
          return SiteStatus.SystemTested;
        case "fitMeterInstalledAt":
          return SiteStatus.FitMeterInstalled;
        case "completedAt":
          return SiteStatus.Completed;
        case "contractEndedAt":
          return SiteStatus.ContractEnded;

        default:
          return SiteStatus.Started;
      }
    };

    let payload = {
      variables: {
        input: {
          siteStatus: convertStatusAsEnum(projectDetailData.progress),
          code: projectDetailData.code,
          fitCommencementDate: projectDetailData?.fitCommencementDate,
          address: {
            // area: projectDetailData.address[0]
            //   .toLowerCase()
            //   .replaceAll("_", "-"),
            area: projectDetailData?.address[1]
              .toLowerCase()
              .replaceAll("_", "-"),
            district: projectDetailData?.address?.[2]
              .toLowerCase()
              .replaceAll("_", "-"),
            line: projectDetailData?.address[3],
            // .replaceAll("_", "-"),
          },
          name: projectDetailData.name,
          siteType: projectDetailData.typeOfSite,
          projectImages: projectImgObj,
          companyDocuments: projectDocObj,
          projectTasks: taskObj,
          investorEmail: investorDetailData[0]?.email,
          profitShares: [
            {
              startTime: investorDetailData[0]?.date[0].startDate,
              endTime: investorDetailData[0]?.date[0].endDate,
              isPercentage: true,
              minimumGain: investorDetailData[0].investorRatio,
              landOwnerGain: investorDetailData[0].clientRatio,
            },
          ],
          electricCompany:
            eleAccDetailData.loginID &&
            eleAccDetailData.loginPW &&
            isValidBillAc
              ? {
                  username: eleAccDetailData.loginID,
                  password: eleAccDetailData.loginPW,
                  type: ElectricCompanyInfoCompany[
                    eleAccDetailData.eleCompany as keyof typeof ElectricCompanyInfoCompany
                  ],
                  belongsTo:
                    eleAccDetailData.ownerType == "Client"
                      ? ElectricCompanyInfoParty.LandOwner
                      : ElectricCompanyInfoParty.Investor,
                }
              : undefined,
          landOwnerEmail: clientDetailData.email,
          electricityDetails: {
            fitApplicationCode: eleDetailData.fitCode ?? null,
            electricMeters:
              eleDetailData.meterID && eleDetailData.fitMeterID
                ? [
                    { code: eleDetailData.meterID, isFit: false },
                    { code: eleDetailData.fitMeterID, isFit: true },
                  ]
                : [],
            fitRate: Number(eleDetailData.fitRate) ?? null,
            energyOutputByCompany:
              Number(eleDetailData.energyOutputByEC) ?? null,
            energyOutputByContractor:
              Number(eleDetailData.energyOutputByContractor) ?? null,
          },
          invoiceInfos: {
            name: invoiceClientData.name ?? "client",
            email: invoiceClientData.email,
            phone: {
              areaCode: "+852",
              phone: invoiceClientData.phone,
            },
            address: invoiceClientData?.address[3]
              ? {
                  area: invoiceClientData?.address[1]
                    ?.toLowerCase()
                    .replaceAll("_", "-"),
                  district: invoiceClientData?.address[2]
                    ?.toLowerCase()
                    .replaceAll("_", "-"),
                  line: invoiceClientData?.address[3],
                  // .replaceAll("_", "-"),
                }
              : undefined,
          },
          projectManagerIds: pmDetailData.map((pmd) => pmd.id),
        },
      },
    };
    // console.log(isValidBillAc);

    if (projectDetailData.progress !== "completedAt") {
      let omitedPayload = _.omit(
        payload?.variables?.input,
        "fitCommencementDate"
      );

      // console.log(`normal submit payload`);
      // console.log(omitedPayload);
      createSiteAction(payload);
    } else {
      // console.log(`omitedPayload`);
      // console.log(payload);
      createSiteAction(payload);
    }
  };

  // console.log(projectDetailData);
  // console.log(projectTaskData);
  // console.log(investorDetailData);
  // console.log(clientDetailData);
  // console.log(pmDetailData);
  // console.log(eleAccDetailData);
  // console.log(eleDetailData.fitRate);
  // console.log(invoiceClientData);
  // console.log(invoicePaymentData);

  return (
    <>
      <Helmet>
        <title>Solar Monster - Create Project</title>
      </Helmet>
      {/* sticky header section */}
      <Flex
        flex={1}
        flexDirection="row"
        backgroundColor="WT"
        alignItems="center"
        justifyContent="center"
        py="10px"
        position="sticky"
        top="0px"
        zIndex={2}
      >
        <Flex position="absolute" left="20px">
          <Flex color="BK" alignSelf="center" fontWeight={"bold"}>
            {t("TXT_Project_Add_New_Pj")}
          </Flex>
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <Button
            py="15px"
            px="30px"
            backgroundColor="WT"
            borderWidth="1px"
            borderRadius="40px"
            borderColor={returnByTheme()}
            width="100%"
            onClick={() => setIsShowAlertModal(true)}
          >
            <IconContext.Provider
              value={{ color: returnByColor(), size: "25px" }}
            >
              <Flex color={returnByTheme()}>{t("TXT_Cancel")}</Flex>
            </IconContext.Provider>
          </Button>
          <Button
            py="15px"
            px="30px"
            backgroundColor={returnByTheme()}
            borderWidth="1px"
            borderRadius="40px"
            borderColor="WT"
            width="100%"
            marginLeft="10px"
            disabled={!ableToSubmit || loading}
            isLoading={loading}
            color="WT"
            loadingText={t("TXT_Submit")}
            spinnerPlacement="end"
            onClick={() => creatProjectSubmit()}
          >
            {t("TXT_Submit")}
          </Button>
        </Flex>
      </Flex>

      {/* form section */}
      <Flex
        backgroundColor={"#e5e5e5"}
        flex={1}
        justifyContent={"center"}
        px="20px"
        maxW="1920px"
        mx="auto"
        maxH="calc(100vh - 60px)"
        overflow="hidden"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"flex-end"}
          mr="20px"
          pt="80px"
          px={10}
        >
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Text fontSize={28} fontWeight={"bold"} color={returnByTheme()}>
              {t("TXT_Project_Create_your_pj")}
            </Text>
            <Text
              mt={30}
              fontSize={24}
              fontWeight={"semibold"}
              color={"#8e8e8e"}
            >
              {t("TXT_Project_Fill_all_field_togo")}
            </Text>
            {/* step bar */}
            <Flex flexDirection={"column"} mt={"100px"}>
              {totalStep.map((s, i) => {
                const isCurrentStep = currentStep === i;
                return (
                  <Flex
                    alignItems={"center"}
                    mb="40px"
                    key={`step${i}`}
                    // allow jump
                    // onClick={() => setCurrentStep(i)}
                  >
                    <Flex flexDirection={"column"} position={"relative"}>
                      {(isCurrentStep && (
                        <Flex
                          position={"absolute"}
                          top={"-10px"}
                          left={"-6px"}
                          width={"20px"}
                          height={"20px"}
                          backgroundColor={"WT"}
                          borderRadius={"25px"}
                          borderWidth={"6px"}
                          borderColor={returnByTheme()}
                          zIndex={1}
                        ></Flex>
                      )) || (
                        <Flex
                          position={"absolute"}
                          top={"-4px"}
                          width={"8px"}
                          height={"8px"}
                          backgroundColor={"WT"}
                          borderRadius={"25px"}
                          zIndex={1}
                        ></Flex>
                      )}

                      {i !== totalStep.length - 1 && (
                        <Flex
                          borderRadius={"5px"}
                          position={"absolute"}
                          top={"-8px"}
                          left={"-1px"}
                          width={"10px"}
                          height={"80px"}
                          // backgroundColor={"#b8b4b4"}
                          background={
                            i === currentStep - 1
                              ? // F79846 -> EACD29
                                isClient
                                ? "linear-gradient(#F79846, #EACD29)"
                                : "linear-gradient(#004b92, #69B3E3)"
                              : i < currentStep - 1
                              ? returnByTheme()
                              : "#b4b4b4"
                          }
                        ></Flex>
                      )}
                    </Flex>
                    <Text
                      color={isCurrentStep ? returnByTheme() : "#8e8e8e"}
                      // fontWeight={isCurrentStep ? "bold" : "normal"}
                      fontWeight={"bold"}
                      marginLeft={"20px"}
                    >
                      {s}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
        <Flex m="20px" overflowY="auto" maxWidth="750px" w="750px">
          {/* step1 form */}
          {currentStep === 0 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <ProjectDetailSection
                projectDetailData={projectDetailData}
                updateProjectDetailState={(value: any) => {
                  setProjectDetailData(value);
                }}
              />

              <ProjectTaskSection
                taskData={projectTaskData}
                updateProjectTaskState={(newValue: any) => {
                  setProjectTaskData(newValue);
                }}
              />

              <InvestorDetailSection
                investorData={investorDetailData}
                updateInvestorDetailState={(
                  newValue: Array<IInvestorDetailData>
                ) => {
                  setInvestorDetailData(newValue);
                }}
              />

              <ClientDetailSection
                clientData={clientDetailData}
                updateClientDetailState={(newValue: any) => {
                  setClientDetailData(newValue);
                }}
              />

              <ProjectManagerSection
                pmData={pmDetailData}
                updatePMDetailState={(newValue: any) => {
                  setPmDetailData(newValue);
                }}
              />

              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  disabled={!ableToStep2}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  {t("TXT_Next")}
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === 1 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <EleBillAccSection
                eleAccDetailData={eleAccDetailData}
                updateEleBillState={(newState: IBillAccDetailData) =>
                  setEleAccDetailData(newState)
                }
              />

              <EleDetailSection
                eleDetailData={eleDetailData}
                updateEleDetailState={(newState: IEleDetailData) =>
                  setEleDetailData(newState)
                }
              />
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={"transparent"}
                  borderWidth={1}
                  borderColor={"BK"}
                  color="BK"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  {t("TXT_Back")}
                </Button>
                <Flex width={"20px"}></Flex>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  disabled={!ableToStep3}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  {t("TXT_Next")}
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === 2 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <ToClientSection
                invoiceClientData={invoiceClientData}
                updateInvoiceClientState={(newState: IInvoiceClientData) => {
                  setInvoiceClientData(newState);
                }}
              />

              {/* <PayMethodSection
                invoicePaymentData={invoicePaymentData}
                updateInvocePaymentState={(newState: any) =>
                  setInvoicePaymentData(newState)
                }
              /> */}
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={"transparent"}
                  borderWidth={1}
                  borderColor={"BK"}
                  color="BK"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  {t("TXT_Back")}
                </Button>
                <Flex width={"20px"}></Flex>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  disabled={!ableToStep4}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  {t("TXT_Next")}
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === 3 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <ProjectDetailPreview
                projectDetailData={projectDetailData}
                editProjectDetailAction={() => setIsShowPDetailEditModal(true)}
              />
              <EditModal
                isShow={isShowPDetailEditModal}
                onClose={() => {
                  setIsShowPDetailEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText={t("TXT_Project_project_detail")}
                size="xl"
                bodyPx="0"
              >
                <ProjectDetailSection
                  projectDetailData={projectDetailData}
                  updateProjectDetailState={(value: any) => {
                    setProjectDetailData(value);
                  }}
                  isPreviewMode={true}
                  onClose={() => {
                    setIsShowPDetailEditModal(false);
                  }}
                />
              </EditModal>

              <ProjectTasksPreview
                projectTaskData={projectTaskData}
                editProjectTaskAction={() => setIsShowPTaskEditModal(true)}
              />
              <EditModal
                isShow={isShowPTaskEditModal}
                onClose={() => {
                  setIsShowPTaskEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText={t("TXT_Project_Project_tasks")}
                size="xl"
                bodyPx="0"
              >
                <ProjectTaskSection
                  taskData={projectTaskData}
                  updateProjectTaskState={(newValue: any) => {
                    setProjectTaskData(newValue);
                  }}
                  isPreviewMode={true}
                  onClose={() => {
                    setIsShowPTaskEditModal(false);
                  }}
                />
              </EditModal>

              <InvestorDetailPreview
                investorDetailData={investorDetailData}
                editInvestorDetailAction={() => setIsShowIDetailEditModal(true)}
              />
              <EditModal
                isShow={isShowIDetailEditModal}
                onClose={() => {
                  setIsShowIDetailEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText={t("TXT_Project_Investor_details")}
                size="xl"
                bodyPx="0"
              >
                <InvestorDetailSection
                  investorData={investorDetailData}
                  updateInvestorDetailState={(
                    newValue: Array<IInvestorDetailData>
                  ) => {
                    setInvestorDetailData(newValue);
                  }}
                  isPreviewMode={true}
                  onClose={() => {
                    setIsShowIDetailEditModal(false);
                  }}
                />
              </EditModal>

              <ClientDetailPreview
                clientData={clientDetailData}
                editClientDetailAction={() => setIsShowCDetailEditModal(true)}
              />
              <EditModal
                isShow={isShowCDetailEditModal}
                onClose={() => {
                  setIsShowCDetailEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText={t("TXT_Project_client_detail")}
                size="xl"
                bodyPx="0"
              >
                <ClientDetailSection
                  clientData={clientDetailData}
                  updateClientDetailState={(newValue: any) => {
                    setClientDetailData(newValue);
                  }}
                  isPreviewMode={true}
                  onClose={() => {
                    setIsShowCDetailEditModal(false);
                  }}
                />
              </EditModal>

              <PMDetailPreview
                pmData={pmDetailData}
                editPmDetailAction={() => setIsShowPMEditModal(true)}
              />
              <EditModal
                isShow={isShowPMEditModal}
                onClose={() => {
                  setIsShowPMEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText={t("TXT_Project_Project_manager")}
                size="xl"
                bodyPx="0"
              >
                <ProjectManagerSection
                  pmData={pmDetailData}
                  updatePMDetailState={(newValue: any) => {
                    setPmDetailData(newValue);
                  }}
                  isPreviewMode={true}
                  onClose={() => setIsShowPMEditModal(false)}
                />
              </EditModal>

              <BillAccDetailPreview
                eleAccDetailData={eleAccDetailData}
                editEleBillAction={() => setIsShowBillAccEditModal(true)}
                isValidBillAc={isValidBillAc}
              />
              <EditModal
                isShow={isShowBillAccEditModal}
                onClose={() => {
                  setIsShowBillAccEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText={t("TXT_Project_Electricity_bill_account_details")}
                size="xl"
                bodyPx="0"
              >
                <EleBillAccSection
                  eleAccDetailData={eleAccDetailData}
                  updateEleBillState={(newState: IBillAccDetailData) =>
                    setEleAccDetailData(newState)
                  }
                  isPreviewMode={true}
                  onClose={() => setIsShowBillAccEditModal(false)}
                />
              </EditModal>

              <EleDetailPreview
                eleDetailData={eleDetailData}
                editEleDetailAction={() => setIsShowEleDetailEditModal(true)}
              />
              <EditModal
                isShow={isShowEleDetailEditModal}
                onClose={() => {
                  setIsShowEleDetailEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText={t("TXT_Project_Electricity_details")}
                size="xl"
                bodyPx="0"
              >
                <EleDetailSection
                  eleDetailData={eleDetailData}
                  updateEleDetailState={(newState: IEleDetailData) =>
                    setEleDetailData(newState)
                  }
                  isPreviewMode={true}
                  onClose={() => setIsShowEleDetailEditModal(false)}
                  isValidBillAc={isValidBillAc}
                />
              </EditModal>

              <ToClientPreview
                invoiceClientData={invoiceClientData}
                editInvoiceClientAction={() => setIsShowToClientEditModal(true)}
              />
              <EditModal
                isShow={isShowToClientEditModal}
                onClose={() => {
                  setIsShowToClientEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText={t("TXT_Project_To_client")}
                size="xl"
                bodyPx="0"
              >
                <ToClientSection
                  invoiceClientData={invoiceClientData}
                  updateInvoiceClientState={(newState: IInvoiceClientData) =>
                    setInvoiceClientData(newState)
                  }
                  isPreviewMode={true}
                  onClose={() => setIsShowToClientEditModal(false)}
                />
              </EditModal>

              {/* <PayMethodPreview
                invoicePaymentData={invoicePaymentData}
                editInvocePaymentAction={() => setIsShowPaymentEditModal(true)}
              />
              <EditModal
                isShow={isShowPaymentEditModal}
                onClose={() => {
                  setIsShowPaymentEditModal(false);
                }}
                headerColor={returnByTheme()}
                headerText="Payment method"
                size="xl"
                bodyPx="0"
              >
                <PayMethodSection
                  invoicePaymentData={invoicePaymentData}
                  updateInvocePaymentState={(newState: any) =>
                    setInvoicePaymentData(newState)
                  }
                  isPreviewMode={true}
                  onClose={() => setIsShowPaymentEditModal(false)}
                />
              </EditModal> */}

              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  isLoading={loading}
                  loadingText={t("TXT_Submit")}
                  spinnerPlacement="end"
                  onClick={() => creatProjectSubmit()}
                  disabled={!ableToSubmit || loading}
                >
                  {t("TXT_Submit")}
                </Button>
              </Flex>
            </Flex>
          )}

          {/* leave alert popup */}
          <PopUpModal
            isShow={isShowAlertModal}
            onClose={() => {
              setIsShowAlertModal(false);
            }}
            headerColor="RD.3"
            headerText={t("TXT_Project_Create_project")}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <IconContext.Provider value={{ color: "#E4513F", size: "100px" }}>
                <FiAlertTriangle />
              </IconContext.Provider>
              <Text color="#E4513F" fontWeight="700">
                {t("TXT_Hey_wait")}
              </Text>
              <Text color="#000" textAlign="center" fontWeight="600">
                {t("TXT_Leave_popup_desc")}
              </Text>
              <Flex flexDirection="row" width="100%">
                <Button
                  backgroundColor="WT"
                  _hover={{ backgroundColor: "WT" }}
                  onClick={() => setIsShowAlertModal(false)}
                  marginTop="20px"
                  borderRadius="40px"
                  flex={1}
                  borderColor="#000"
                  borderWidth="1px"
                >
                  <Text color="#000">{t("TXT_Stay")}</Text>
                </Button>
                <Button
                  backgroundColor="RD.3"
                  _hover={{ backgroundColor: "RD.3" }}
                  onClick={() => navigate("/project")}
                  marginTop="20px"
                  borderRadius="40px"
                  flex={1}
                  marginLeft="20px"
                >
                  <Text color="WT">{t("TXT_Leave")}</Text>
                </Button>
              </Flex>
            </Flex>
          </PopUpModal>
        </Flex>
      </Flex>
    </>
  );
};

export default CreateProject;
