import {
  Box,
  chakra,
  Flex,
  Text,
  Textarea,
  Button,
  Image,
  useToast,
} from "@chakra-ui/react";

import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IconContext } from "react-icons";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import ProjectDetailSection from "./stepOneForm/ProjectDetailSection";
import InvestorDetailSection from "./stepOneForm/InvestorDetailSection";
import ClientDetailSection from "./stepOneForm/ClientDetailSection";
import ProjectManagerSection from "./stepOneForm/ProjectManagerSection";
import ProjectTaskSection from "./stepOneForm/ProjectTaskSection";

import EleBillAccSection from "./stepTwoForm/EleBillAccSection";
import EleDetailSection from "./stepTwoForm/EleDetailSection";

import ToClientSection from "./stepThreeForm/ToClientSection";
import PayMethodSection from "./stepThreeForm/PayMethodSection";

import {
  IProjectDetailData,
  IInvestorDetailData,
  IClientDetailData,
  IProjectManagerData,
  IProjectTaskData,
} from "../../type/IBaseInfotInterface";

import {
  IBillAccDetailData,
  IEleDetailData,
} from "../../type/IBillInfoInterface";
import {
  IInvoiceClientData,
  IInvoicePaymentMethodData,
} from "../../type/IInoviceInfoInterface";
import PopUpModal from "src/components/PopUpModal";
import { FiAlertTriangle, FiArrowLeft } from "react-icons/fi";

import {
  returnByTheme,
  returnByColor,
  getCurrProjectStatus,
  convertDisplayText,
  removeUselessKeyForObj,
  isEmptyString,
} from "../../utils/util";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  ElectricCompanyInfoCompany,
  ElectricCompanyInfoParty,
  GetSiteForEditDocument,
  SiteDocument,
  SiteStatus,
  Task_Priority,
  UpdateSiteDocument,
} from "../../graphql/generated";
import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import CalculatorHistory from "../calculator/CalculatorHistory";

const EditProject = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const location = useLocation();

  const {
    currId,
    prevSelectedOpt,
    prevPayload,
    prevSearchType,
    prevKeyword,
    prevDateRange,
  } = (location?.state as any) ?? {};
  const { projectId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [isShowAlertModal, setIsShowAlertModal] = useState(false);
  const isClient = localStorage.getItem("sf_Role") == "true";
  // const [validToSubmit, setValidToSubmit] = useState(false);

  const [projectDetailData, setProjectDetailData] =
    useState<IProjectDetailData>({
      progress: "",
      code: "",
      name: "",
      typeOfSite: "",
      address: [t("TXT_Hong_Kong"), "", "", ""],
    });

  const [projectTaskData, setProjectTaskData] = useState<any>([
    {
      title: "",
      date: [
        {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      ],
      desc: "",
      comment: [],
      attachment: [],
    },
  ]);

  const [investorDetailData, setInvestorDetailData] = useState<
    Array<IInvestorDetailData>
  >([
    {
      //   name: "",
      //   phone: "",
      email: "",
      sharingType: "share",
      clientRatio: 0,
      investorRatio: 0,
    },
  ]);

  const [clientDetailData, setClientDetailData] = useState<IClientDetailData>({
    // name: "",
    // phone: "",
    email: "",
  });

  const [pmDetailData, setPmDetailData] = useState<Array<any>>([]);

  const [eleAccDetailData, setEleAccDetailData] = useState<IBillAccDetailData>({
    ownerType: "",
    eleCompany: "Clp",
    loginID: "",
    loginPW: "",
  });

  const [eleDetailData, setEleDetailData] = useState<IEleDetailData>({
    fitCode: "",
    meterID: "",
    fitMeterID: "",
    fitRate: 0,
    energyOutputByEC: 0,
    energyOutputByContractor: 0,
  });

  const [invoiceClientData, setInvoiceClientData] =
    useState<IInvoiceClientData>({
      name: "",
      phone: "",
      email: "",
      address: [t("TXT_Hong_Kong"), "", "", ""],
    });

  const [initPayload, setInitPayload] = useState<any>();

  const totalStep = [
    {
      name: t("TXT_Project_Basic_info"),
      desc: t("TXT_Project_Edit_tab1_desc"),
      icon: require("../../icons/general/profile.png"),
    },
    {
      name: t("TXT_Project_Bill_info"),
      desc: t("TXT_Project_Edit_tab2_desc"),
      icon: require("../../icons/general/bill.png"),
    },
    {
      name: t("TXT_Project_Invoice_info"),
      desc: t("TXT_Project_Edit_tab3_desc"),
      icon: require("../../icons/general/invoice.png"),
    },
    // {
    //   name: t("TXT_Calculator_solar_calculator"),
    //   desc: t("TXT_Project_Edit_tab4_desc"),
    //   icon: require("../../icons/general/calcIcon.png"),
    // },
  ];

  // get current site info first
  const [getCurrSiteInfo, { data }] = useLazyQuery(GetSiteForEditDocument, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const siteInfo = data.site;
      const projectStatusArr = getCurrProjectStatus(siteInfo);
      console.log(siteInfo);
      // console.log(`projectStatusArr`);
      // console.log(projectStatusArr);
      // fill project detail data
      setProjectDetailData({
        progress: projectStatusArr[projectStatusArr.length - 1]?.status,
        fitCommencementDate: siteInfo?.fitCommencementDate,
        code: siteInfo?.code ? siteInfo?.code : "",
        name: siteInfo?.name ? siteInfo?.name : "",
        typeOfSite: siteInfo?.siteType?.name!,
        address: [
          t("TXT_Hong_Kong"),
          siteInfo?.address?.area?.replaceAll("-", "_").toUpperCase()!,
          siteInfo?.address?.district?.replaceAll("-", "_").toUpperCase()!,
          siteInfo?.address?.line ? siteInfo?.address?.line : "",
        ],
        projectImages: siteInfo?.projectImages
          ? siteInfo.projectImages.map((i) => {
              return {
                filePath: i.filePath,
                originalName: i.originalName,
                url: i.url,
              };
            })
          : [],

        companyDocuments: siteInfo?.companyDocuments
          ? siteInfo.companyDocuments.map((d) => {
              return {
                filePath: d.filePath,
                originalName: d.originalName,
                url: d.url,
              };
            })
          : [],
      });

      // fill task data
      const task = siteInfo?.tasks.map((t) => {
        return {
          id: t.id ? t.id : undefined,
          title: t.title,
          date: [
            {
              startDate: new Date(t?.startTime),
              endDate: new Date(t?.endTime) ?? null,
              key: "selection",
            },
          ],
          desc: t.description,
          priority:
            t.priority == "HIGH_PRIORITY"
              ? "high"
              : t.priority == "LOW_PRIORITY"
              ? "low"
              : "none",
          attachment: t?.attachments
            ? t?.attachments.map((doc) => {
                return {
                  filePath: doc.filePath,
                  originalName: doc.originalName,
                  url: doc.url,
                };
              })
            : [],
          comment: t.comments.map((c) => {
            const newUserObj =
              c.user.__typename == "Company"
                ? {
                    name: c.user?.companyName,
                    email: c.user?.companyEmail,
                    url: c.user?.profileImage?.url,
                  }
                : {
                    name: c.user?.userName,
                    email: c.user?.userEmail,
                    url: c.user?.profileImage?.url,
                  };

            return {
              message: c.message,
              commentDate: c.createdAt,
              user: newUserObj.name,
            };
          }),
        };
      });

      setProjectTaskData(task);

      // fill investor detail
      setInvestorDetailData([
        {
          // name: string;
          // phone: string;
          email: siteInfo?.investor.email!,
          sharingType: "share",
          clientRatio: siteInfo?.profitShares[0].landOwnerGain!,
          investorRatio: siteInfo?.profitShares[0].minimumGain!,
          date: [
            {
              startDate: new Date(siteInfo?.profitShares[0].startTime!),
              endDate: new Date(siteInfo?.profitShares[0].endTime!),
              key: "selection",
            },
          ],
        },
      ]);

      // fill client detail
      setClientDetailData({ email: siteInfo?.invoiceInfos.email! });
      setPmDetailData(siteInfo?.projectManagers ?? []);

      // fill ele acc detail
      setEleAccDetailData({
        ownerType:
          siteInfo?.electricCompany?.belongsTo == "INVESTOR"
            ? "Investor"
            : "Client",
        eleCompany: siteInfo?.electricCompany?.type == "CLP" ? "Clp" : "Hke",
        loginID: siteInfo?.electricCompany?.username!,
        loginPW: siteInfo?.electricCompany?.password!,
      });

      // fill ele detail
      setEleDetailData({
        fitCode: siteInfo?.electricityDetails?.fitApplicationCode
          ? siteInfo?.electricityDetails.fitApplicationCode
          : "",
        meterID: siteInfo?.electricityDetails?.electricMeters.filter(
          (meter) => {
            return meter.isFit == false;
          }
        )[0]?.code!,
        fitMeterID: siteInfo?.electricityDetails?.electricMeters.filter(
          (meter) => {
            return meter.isFit == true;
          }
        )[0]?.code!,
        fitRate: siteInfo?.electricityDetails?.fitRate ?? 0,
        energyOutputByEC:
          siteInfo?.electricityDetails?.energyOutputByCompany ?? 0,
        energyOutputByContractor:
          siteInfo?.electricityDetails?.energyOutputByContractor ?? 0,
      });

      setInvoiceClientData({
        name: siteInfo?.invoiceInfos.name!,
        phone: siteInfo?.invoiceInfos.phone.phone!,
        email: siteInfo?.invoiceInfos.email!,
        address: [
          t("TXT_Hong_Kong"),
          siteInfo?.invoiceInfos.address?.area
            ? siteInfo?.invoiceInfos.address?.area
                .replaceAll("-", "_")
                .toUpperCase()!
            : "",
          siteInfo?.invoiceInfos.address?.district
            ? siteInfo?.invoiceInfos.address?.district
                .replaceAll("-", "_")
                .toUpperCase()!
            : "",
          siteInfo?.invoiceInfos.address?.line
            ? siteInfo?.invoiceInfos.address?.line
            : "",
        ],
      });

      // store init payload
      const taskObj = task?.map((t: any) => {
        const priorityInEnum =
          t.priority == "high"
            ? Task_Priority.HighPriority
            : t.priority == "middle"
            ? Task_Priority.Unrecognized
            : t.priority == "low"
            ? Task_Priority.LowPriority
            : Task_Priority.Unrecognized;
        return {
          id: t.id ? t.id : undefined,
          startTime: t.date[0].startDate,
          endTime: t.date[0].endDate ? t.date[0].endDate : t.date[0].startDate,
          title: t.title,
          description: t.desc,
          attachments: t?.attachment
            ? t?.attachment.map((doc: any) => {
                return {
                  filePath: doc.filePath,
                  originalName: doc.originalName,
                };
              })
            : [],
          priority: priorityInEnum,
        };
      });

      const projectImgObj = siteInfo?.projectImages
        ? siteInfo.projectImages.map((i) => {
            return {
              filePath: i.filePath,
              originalName: i.originalName,
            };
          })
        : [];

      const projectDocObj = siteInfo?.companyDocuments
        ? siteInfo.companyDocuments.map((d) => {
            return {
              filePath: d.filePath,
              originalName: d.originalName,
            };
          })
        : [];

      const convertStatusAsEnum = (statusText: string) => {
        switch (statusText) {
          case "startedAt":
            return SiteStatus.Started;
          case "approvalLetterSentAt":
            return SiteStatus.ApprovalLetterSent;
          case "acknowledgedAt":
            return SiteStatus.Acknowledged;
          case "hardwareInstalledAt":
            return SiteStatus.HardwareInstalled;
          case "systemTestedAt":
            return SiteStatus.SystemTested;
          case "fitMeterInstalledAt":
            return SiteStatus.FitMeterInstalled;
          case "completedAt":
            return SiteStatus.Completed;
          case "contractEndedAt":
            return SiteStatus.ContractEnded;

          default:
            return SiteStatus.Started;
        }
      };
      const meterID = siteInfo?.electricityDetails?.electricMeters.filter(
        (meter) => {
          return meter.isFit == false;
        }
      )[0]?.code;

      const meterFitID = siteInfo?.electricityDetails?.electricMeters.filter(
        (meter) => {
          return meter.isFit == true;
        }
      )[0]?.code;

      let payload = {
        variables: {
          input: {
            id: currId,
            siteStatus: convertStatusAsEnum(projectStatusArr[0]?.status),
            code: siteInfo?.code,
            fitCommencementDate: siteInfo?.fitCommencementDate,
            address: {
              // area: projectDetailData.address[0]
              //   .toLowerCase()
              //   .replaceAll("_", "-"),
              area: siteInfo?.address?.area?.toLowerCase().replaceAll("_", "-"),
              district: siteInfo?.address?.district
                ?.toLowerCase()
                .replaceAll("_", "-"),
              line: siteInfo?.address?.line,
              // .replaceAll("_", "-"),
            },
            name: siteInfo?.name,
            siteType: siteInfo?.siteType?.name,
            projectImages: projectImgObj,
            companyDocuments: projectDocObj,
            projectTasks: removeUselessKeyForObj(taskObj),
            investorEmail: siteInfo?.investor.email,
            profitShares: [
              {
                startTime: new Date(siteInfo?.profitShares[0].startTime!),
                endTime: new Date(siteInfo?.profitShares[0].endTime!),
                isPercentage: true,
                minimumGain: siteInfo?.profitShares[0].minimumGain,
                landOwnerGain: siteInfo?.profitShares[0].landOwnerGain,
              },
            ],
            electricCompany:
              siteInfo?.electricCompany?.username &&
              siteInfo?.electricCompany?.password
                ? {
                    username: siteInfo?.electricCompany?.username,
                    password: siteInfo?.electricCompany?.password,
                    type: ElectricCompanyInfoCompany[
                      (siteInfo?.electricCompany?.type == "CLP"
                        ? "Clp"
                        : "Hke") as keyof typeof ElectricCompanyInfoCompany
                    ],
                    belongsTo:
                      siteInfo?.electricCompany?.belongsTo == "INVESTOR"
                        ? ElectricCompanyInfoParty.Investor
                        : ElectricCompanyInfoParty.LandOwner,
                  }
                : undefined,
            landOwnerEmail: siteInfo?.invoiceInfos.email,
            electricityDetails: {
              fitApplicationCode:
                siteInfo?.electricityDetails?.fitApplicationCode ?? undefined,
              electricMeters:
                meterID && meterFitID
                  ? [
                      { code: meterID, isFit: false },
                      { code: meterFitID, isFit: true },
                    ]
                  : [],
              fitRate: siteInfo?.electricityDetails?.fitRate ?? undefined,
              energyOutputByCompany:
                siteInfo?.electricityDetails?.energyOutputByCompany ??
                undefined,
              energyOutputByContractor:
                siteInfo?.electricityDetails?.energyOutputByContractor ??
                undefined,
            },
            invoiceInfos: {
              name: siteInfo?.invoiceInfos.name,
              email: siteInfo?.invoiceInfos.email,
              phone: {
                areaCode: "+852",
                phone: siteInfo?.invoiceInfos.phone.phone,
              },
              address: siteInfo?.invoiceInfos.address?.area
                ? {
                    area: siteInfo?.invoiceInfos.address?.area
                      ?.toLowerCase()
                      .replaceAll("_", "-"),
                    district: siteInfo?.invoiceInfos.address?.district
                      ?.toLowerCase()
                      .replaceAll("_", "-"),
                    line: siteInfo?.invoiceInfos.address?.line,
                    // .replaceAll("_", "-"),
                  }
                : undefined,
            },
            projectManagerIds: siteInfo?.projectManagers?.map((pmd) => pmd.id),
          },
        },
      };

      setInitPayload(payload);
      console.log(`init payload`);
      console.log(payload);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  // check user role
  useEffect(() => {
    if (isClient) {
      navigate("/project");
    }
    // console.log("current project id");
    // console.log(currId);

    getCurrSiteInfo({
      variables: {
        id: currId,
      },
    });

    return () => {};
  }, []);

  // useEffect(() => {
  //   let distArr = projectDetailData?.address;
  //   // distArr[2] = "";
  //   let filteredDist =
  //     addressSelection &&
  //     addressSelection.filter(
  //       (o, i) => o?.area == projectDetailData?.address?.[1]
  //     );
  //   distArr[2] = filteredDist?.[0]?.dist?.[0]?.label;

  //   setProjectDetailData({
  //     ...projectDetailData,
  //     address: distArr,
  //   });
  //   return () => {};
  // }, [projectDetailData?.address?.[1]]);

  // control for project status is completion
  useEffect(() => {
    if (projectDetailData.progress !== "completedAt") {
      setProjectDetailData({
        ...projectDetailData,
        fitCommencementDate: undefined,
      });
    }

    return () => {};
  }, [projectDetailData.progress]);

  // pre fill email for invoice
  useEffect(() => {
    if (clientDetailData.email) {
      setInvoiceClientData({
        ...invoiceClientData,
        email: clientDetailData.email,
      });
    }

    return () => {};
  }, [clientDetailData]);

  const [updateSiteAction, { data: updateSiteStatus, loading }] = useMutation(
    UpdateSiteDocument,
    {
      onCompleted: (x) => {
        toast({
          position: "bottom-right",
          title: `Project updated!`,
          status: "success",
        });
        navigate("/project");
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  // validation
  const isProjectDetailPass = () => {
    if (
      isEmptyString(projectDetailData.progress) ||
      isEmptyString(projectDetailData.code) ||
      isEmptyString(projectDetailData.name) ||
      isEmptyString(projectDetailData.typeOfSite) ||
      isEmptyString(projectDetailData.address[1]) ||
      isEmptyString(projectDetailData?.address[2]) ||
      isEmptyString(projectDetailData?.address[3])
    ) {
      return false;
    } else if (
      projectDetailData.progress == "Completion" &&
      projectDetailData.fitCommencementDate == undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isInvestorDetailPass = () => {
    const isAllValid = investorDetailData.map((investor: any, i) => {
      let isValidArr = Object.keys(investor).map((o) => {
        return o == "date"
          ? investor[o][0]?.startDate !== null &&
              investor[o][0]?.endDate !== null
          : investor[o] !== "";
      });
      return _.every(isValidArr, (x) => x === true);
    });

    return _.every(isAllValid, (x) => x === true);
  };

  const isClientDetailPass = () => {
    if (
      // isEmptyString(clientDetailData.name) ||
      // isEmptyString(clientDetailData.phone) ||
      isEmptyString(clientDetailData.email)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isPmDetailPass = () => {
    // const isAllValid = pmDetailData.map((pm: any, i) => {
    //   let isEmptyArr = Object.keys(pm).map((o) => {
    //     return isEmptyString(pm[o]);
    //   });
    //   return _.every(isEmptyArr, (x) => x === false);
    // });

    // return _.every(isAllValid, (x) => x === true);
    // return pmDetailData.length > 0;
    return true;
  };

  const isBillAccDetailPass = () => {
    // if (
    //   isEmptyString(eleAccDetailData.ownerType) ||
    //   isEmptyString(eleAccDetailData.loginID) ||
    //   isEmptyString(eleAccDetailData.loginPW)
    // ) {
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  };

  const isEleDetailPass = () => {
    // if (
    //   isEmptyString(eleDetailData.fitCode) ||
    //   isEmptyString(eleDetailData.meterID) ||
    //   isEmptyString(eleDetailData.fitMeterID) ||
    //   isEmptyString(eleDetailData.fitRate) ||
    //   isEmptyString(eleDetailData.energyOutputByEC) ||
    //   isEmptyString(eleDetailData.energyOutputByContractor)
    // ) {
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  };

  const isToClientDetailPass = () => {
    if (
      isEmptyString(invoiceClientData.name) ||
      isEmptyString(invoiceClientData.phone) ||
      isEmptyString(invoiceClientData.email) ||
      (!isEmptyString(invoiceClientData.address[3]) &&
        (isEmptyString(invoiceClientData.address[1]) ||
          isEmptyString(invoiceClientData.address[2])))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const ableToStep2 =
    isProjectDetailPass() && isInvestorDetailPass() && isClientDetailPass();
  // && isPmDetailPass();

  const ableToStep3 = isBillAccDetailPass() && isEleDetailPass();

  const ableToStep4 = isToClientDetailPass();

  // console.log(`ableToStep2`);
  // console.log(ableToStep2);
  // console.log(`ableToStep3`);
  // console.log(ableToStep3);
  // console.log(`ableToStep4`);
  // console.log(ableToStep4);

  const ableToSubmit =
    isProjectDetailPass() &&
    isInvestorDetailPass() &&
    isClientDetailPass() &&
    isPmDetailPass() &&
    isBillAccDetailPass() &&
    isEleDetailPass() &&
    isToClientDetailPass();

  const saveEditProject = () => {
    // address?: InputMaybe<AddressInput>;
    // code: Scalars['String'];
    // companyDocuments?: InputMaybe<Array<AssetInput>>;
    // electricCompany: ElectricCompanyInfoInput;
    // electricityDetails: ElectricityDetailInput;
    // investorEmail: Scalars['String'];
    // landOwnerEmail: Scalars['String'];
    // name: Scalars['String'];
    // profitShares?: InputMaybe<Array<ProfitShareInfoInput>>;
    // projectImages?: InputMaybe<Array<AssetInput>>;
    // projectManagerIds?: InputMaybe<Array<UserInput>>;
    // projectTasks?: InputMaybe<Array<ProjectTaskInput>>;
    // siteType: Scalars['String'];

    const taskObj = projectTaskData.map((t: any) => {
      const priorityInEnum =
        t.priority == "high"
          ? Task_Priority.HighPriority
          : t.priority == "middle"
          ? Task_Priority.Unrecognized
          : t.priority == "low"
          ? Task_Priority.LowPriority
          : Task_Priority.Unrecognized;
      return {
        id: t.id ? t.id : undefined,
        startTime: t.date[0].startDate,
        endTime: t.date[0].endDate ? t.date[0].endDate : t.date[0].startDate,
        title: t.title,
        description: t.desc,
        attachments: t?.attachment
          ? t?.attachment.map((doc: any) => {
              return {
                filePath: doc.filePath,
                originalName: doc.originalName,
              };
            })
          : [],
        priority: priorityInEnum,
      };
    });

    const projectImgObj = projectDetailData?.projectImages?.map((img) => {
      return {
        filePath: img.filePath,
        originalName: img.originalName,
      };
    });

    const projectDocObj = projectDetailData?.companyDocuments?.map((img) => {
      return {
        filePath: img.filePath,
        originalName: img.originalName,
      };
    });

    const convertStatusAsEnum = (statusText: string) => {
      switch (statusText) {
        case "startedAt":
          return SiteStatus.Started;
        case "approvalLetterSentAt":
          return SiteStatus.ApprovalLetterSent;
        case "acknowledgedAt":
          return SiteStatus.Acknowledged;
        case "hardwareInstalledAt":
          return SiteStatus.HardwareInstalled;
        case "systemTestedAt":
          return SiteStatus.SystemTested;
        case "fitMeterInstalledAt":
          return SiteStatus.FitMeterInstalled;
        case "completedAt":
          return SiteStatus.Completed;
        case "contractEndedAt":
          return SiteStatus.ContractEnded;

        default:
          return SiteStatus.Started;
      }
    };

    let payload: any = {
      variables: {
        input: {
          id: currId,
          siteStatus: convertStatusAsEnum(projectDetailData.progress),
          code: projectDetailData.code,
          fitCommencementDate: projectDetailData?.fitCommencementDate,
          address: {
            // area: projectDetailData.address[0]
            //   .toLowerCase()
            //   .replaceAll("_", "-"),
            area: projectDetailData?.address[1]
              .toLowerCase()
              .replaceAll("_", "-"),
            district: projectDetailData?.address?.[2]
              .toLowerCase()
              .replaceAll("_", "-"),
            line: projectDetailData?.address[3],
            // .replaceAll("_", "-"),
          },
          name: projectDetailData.name,
          siteType: projectDetailData.typeOfSite,
          projectImages: projectImgObj,
          companyDocuments: projectDocObj,
          projectTasks: removeUselessKeyForObj(taskObj),
          investorEmail: investorDetailData[0]?.email,
          profitShares: [
            {
              startTime: investorDetailData[0]?.date[0].startDate,
              endTime: investorDetailData[0]?.date[0].endDate,
              isPercentage: true,
              minimumGain: investorDetailData[0].investorRatio,
              landOwnerGain: investorDetailData[0].clientRatio,
            },
          ],
          electricCompany:
            eleAccDetailData.loginID && eleAccDetailData.loginPW
              ? {
                  username: eleAccDetailData.loginID,
                  password: eleAccDetailData.loginPW,
                  type: ElectricCompanyInfoCompany[
                    eleAccDetailData.eleCompany as keyof typeof ElectricCompanyInfoCompany
                  ],
                  belongsTo:
                    eleAccDetailData.ownerType == "Client"
                      ? ElectricCompanyInfoParty.LandOwner
                      : ElectricCompanyInfoParty.Investor,
                }
              : undefined,
          landOwnerEmail: clientDetailData.email,
          electricityDetails: {
            fitApplicationCode: eleDetailData.fitCode ?? null,
            electricMeters:
              eleDetailData.meterID && eleDetailData.fitMeterID
                ? [
                    { code: eleDetailData.meterID, isFit: false },
                    { code: eleDetailData.fitMeterID, isFit: true },
                  ]
                : [],
            fitRate: Number(eleDetailData.fitRate) ?? null,
            energyOutputByCompany:
              Number(eleDetailData.energyOutputByEC) ?? null,
            energyOutputByContractor:
              Number(eleDetailData.energyOutputByContractor) ?? null,
          },
          invoiceInfos: {
            name: invoiceClientData.name,
            email: invoiceClientData.email,
            phone: {
              areaCode: "+852",
              phone: invoiceClientData.phone,
            },
            address: invoiceClientData?.address[3]
              ? {
                  area: invoiceClientData?.address[1]
                    ?.toLowerCase()
                    .replaceAll("_", "-"),
                  district: invoiceClientData?.address[2]
                    ?.toLowerCase()
                    .replaceAll("_", "-"),
                  line: invoiceClientData?.address[3],
                  // .replaceAll("_", "-"),
                }
              : undefined,
          },
          projectManagerIds: pmDetailData.map((pmd) => pmd.id),
        },
      },
    };

    const getUpdatedKeys = (oldData: any, newData: any) => {
      const data = _.uniq([...Object.keys(oldData), ...Object.keys(newData)]);
      const keys = [];
      let result: any = {};
      for (const key of data) {
        if (!_.isEqual(oldData[key], newData[key]) || key == "id") {
          // keys.push(key);
          result[key] = newData[key];
        }
      }

      return result;
    };

    let oldPayload = initPayload.variables.input;
    let newPayload: any = payload.variables.input;

    if (projectDetailData.progress !== "completedAt") {
      let omitOldPayload = _.omit(oldPayload, "fitCommencementDate");
      let omitNewPayload = _.omit(newPayload, "fitCommencementDate");
      const finalSubmitPayload = getUpdatedKeys(omitOldPayload, omitNewPayload);
      updateSiteAction({
        variables: {
          input: finalSubmitPayload,
        },
      });
    } else {
      const finalSubmitPayload = getUpdatedKeys(oldPayload, newPayload);
      updateSiteAction({
        variables: {
          input: finalSubmitPayload,
        },
      });
    }
  };

  // console.log(projectDetailData);
  // console.log(projectTaskData);
  // console.log(investorDetailData);
  // console.log(clientDetailData);
  // console.log(pmDetailData);
  // console.log(eleAccDetailData);
  // console.log(eleDetailData.fitRate);
  // console.log(invoiceClientData);
  // console.log(invoicePaymentData);

  return (
    <>
      <Helmet>
        <title>Solar Monster - Edit Project</title>
      </Helmet>
      {/* sticky header section */}
      <Flex
        flex={1}
        flexDirection="row"
        backgroundColor="WT"
        alignItems="center"
        justifyContent="center"
        py="10px"
        position="sticky"
        top="0px"
        zIndex={2}
      >
        <Flex position="absolute" left="20px">
          <Flex
            color="BK"
            alignItems="center"
            fontWeight={"bold"}
            onClick={() => setIsShowAlertModal(true)}
            cursor="pointer"
          >
            <FiArrowLeft color="GREY" />
            <Text color="GREY" mx="10px">
              SN: {projectId}
            </Text>
            / {t("TXT_Project_Editing")}
          </Flex>
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <Button
            py="15px"
            px="30px"
            backgroundColor="WT"
            borderWidth="1px"
            borderRadius="40px"
            borderColor={returnByTheme()}
            width="100%"
            onClick={() => setIsShowAlertModal(true)}
          >
            <IconContext.Provider
              value={{ color: returnByColor(), size: "25px" }}
            >
              <Flex color={returnByTheme()}>{t("TXT_Cancel")}</Flex>
            </IconContext.Provider>
          </Button>
          <Button
            py="15px"
            px="30px"
            backgroundColor={returnByTheme()}
            borderWidth="1px"
            borderRadius="40px"
            borderColor="WT"
            width="100%"
            marginLeft="10px"
            onClick={() => saveEditProject()}
            isLoading={loading}
            loadingText={t("TXT_Saving_dot")}
            spinnerPlacement="end"
            // disabled={!ableToSubmit}
          >
            <IconContext.Provider value={{ color: "#FFF", size: "25px" }}>
              <Flex color="WT">{t("TXT_Save_and_close")}</Flex>
            </IconContext.Provider>
          </Button>
        </Flex>
      </Flex>

      {/* form section */}
      <Flex
        backgroundColor={"#e5e5e5"}
        flex={1}
        justifyContent={"center"}
        px="20px"
        maxW="1920px"
        mx="auto"
        maxH="calc(100vh - 60px)"
        overflow="hidden"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"flex-end"}
          pt="20px"
          px={10}
          flex={1}
        >
          {/* step bar */}
          <Flex flexDirection={"column"}>
            {totalStep.map((s, i) => {
              const isCurrentStep = currentStep === i;
              return (
                <Flex
                  key={`step${i}`}
                  onClick={() => setCurrentStep(i)}
                  bg={isCurrentStep ? "WT" : "none"}
                  borderLeft={isCurrentStep ? "5px solid #004b92" : "none"}
                  p="20px"
                  alignItems="center"
                  cursor="pointer"
                >
                  <Flex mr="20px">
                    <Image src={s.icon} objectFit="contain" />
                  </Flex>
                  <Flex flexDir="column">
                    <Flex fontSize="18px" fontWeight="bold" mb="10px">
                      {s.name}
                    </Flex>
                    <Flex color="GREY">{s.desc}</Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex m="20px" overflowY="auto" maxWidth="750px" w="750px">
          {currentStep === 0 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <ProjectDetailSection
                isEditMode={true}
                projectDetailData={projectDetailData}
                updateProjectDetailState={(value: any) => {
                  setProjectDetailData(value);
                }}
              />

              <ProjectTaskSection
                taskData={projectTaskData}
                updateProjectTaskState={(newValue: any) => {
                  setProjectTaskData(newValue);
                }}
                isEditMode
              />

              <InvestorDetailSection
                isEditMode={true}
                investorData={investorDetailData}
                updateInvestorDetailState={(
                  newValue: Array<IInvestorDetailData>
                ) => {
                  setInvestorDetailData(newValue);
                }}
              />

              <ClientDetailSection
                clientData={clientDetailData}
                updateClientDetailState={(newValue: any) => {
                  setClientDetailData(newValue);
                }}
              />

              <ProjectManagerSection
                pmData={pmDetailData}
                updatePMDetailState={(newValue: any) => {
                  setPmDetailData(newValue);
                }}
              />

              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent="center">
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  disabled={!ableToSubmit}
                  isLoading={loading}
                  loadingText={t("TXT_Saving_dot")}
                  spinnerPlacement="end"
                  // disabled={!ableToStep2}
                  onClick={() => saveEditProject()}
                >
                  {t("TXT_Save")}
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === 1 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <EleBillAccSection
                eleAccDetailData={eleAccDetailData}
                updateEleBillState={(newState: IBillAccDetailData) =>
                  setEleAccDetailData(newState)
                }
              />

              <EleDetailSection
                eleDetailData={eleDetailData}
                updateEleDetailState={(newState: IEleDetailData) =>
                  setEleDetailData(newState)
                }
              />
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  disabled={!ableToSubmit}
                  isLoading={loading}
                  loadingText={t("TXT_Saving_dot")}
                  spinnerPlacement="end"
                  //   disabled={!ableToStep3}
                  onClick={() => saveEditProject()}
                >
                  {t("TXT_Save")}
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === 2 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <ToClientSection
                invoiceClientData={invoiceClientData}
                updateInvoiceClientState={(newState: IInvoiceClientData) =>
                  setInvoiceClientData(newState)
                }
              />

              {/* <PayMethodSection
                invoicePaymentData={invoicePaymentData}
                updateInvocePaymentState={(newState: any) =>
                  setInvoicePaymentData(newState)
                }
              /> */}
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  disabled={!ableToSubmit}
                  isLoading={loading}
                  loadingText={t("TXT_Saving_dot")}
                  spinnerPlacement="end"
                  //   disabled={!ableToStep4}
                  onClick={() => saveEditProject()}
                >
                  {t("TXT_Save")}
                </Button>
              </Flex>
            </Flex>
          )}

          {currentStep === 3 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <CalculatorHistory historyList={[1, 2, 3]} />
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  disabled={!ableToSubmit}
                  isLoading={loading}
                  loadingText={t("TXT_Saving_dot")}
                  spinnerPlacement="end"
                  //   disabled={!ableToStep4}
                  // onClick={() => saveEditProject()}
                >
                  {/* {t("TXT_Save")} */}
                  not working
                </Button>
              </Flex>
            </Flex>
          )}
          {/* leave alert popup */}
          <PopUpModal
            isShow={isShowAlertModal}
            onClose={() => {
              setIsShowAlertModal(false);
            }}
            headerColor="RD.3"
            headerText={t("TXT_Project_Edit_project")}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <IconContext.Provider value={{ color: "#E4513F", size: "100px" }}>
                <FiAlertTriangle />
              </IconContext.Provider>
              <Text color="#E4513F" fontWeight="700">
                {t("TXT_Hey_wait")}
              </Text>
              <Text color="#000" textAlign="center" fontWeight="600">
                {t("TXT_Leave_popup_desc")}
              </Text>
              <Flex flexDirection="row" width="100%">
                <Button
                  backgroundColor="WT"
                  _hover={{ backgroundColor: "WT" }}
                  onClick={() => setIsShowAlertModal(false)}
                  marginTop="20px"
                  borderRadius="40px"
                  flex={1}
                  borderColor="#000"
                  borderWidth="1px"
                >
                  <Text color="#000">{t("TXT_Stay")}</Text>
                </Button>
                <Button
                  backgroundColor="RD.3"
                  _hover={{ backgroundColor: "RD.3" }}
                  onClick={
                    () => navigate(-1)
                    // navigate("/project", {
                    //   state: {
                    //     prevSelectedOpt: prevSelectedOpt,
                    //     prevPayload: prevPayload,
                    //     prevSearchType: prevSearchType,
                    //     prevKeyword: prevKeyword,
                    //     prevDateRange: prevDateRange,
                    //   },
                    // })
                  }
                  marginTop="20px"
                  borderRadius="40px"
                  flex={1}
                  marginLeft="20px"
                >
                  <Text color="WT">{t("TXT_Leave")}</Text>
                </Button>
              </Flex>
            </Flex>
          </PopUpModal>
        </Flex>
      </Flex>
    </>
  );
};

export default EditProject;
