import { Box, Input, Text, Flex } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import TextPreviewRow from "../../../components/createProjectForm/TextPreviewRow";

import { ReactComponent as EditIcon } from "src/icons/general/editIcon.svg";
import { ReactComponent as UserCheckIcon } from "src/icons/general/user-check.svg";
import { returnByTheme, returnByColor } from "../../../utils/util";
import { IBillAccDetailData } from "../../../type/IBillInfoInterface";
import { useTranslation } from "react-i18next";

interface IBillAccProps {
  eleAccDetailData: IBillAccDetailData;
  editEleBillAction: () => void;
  isValidBillAc: boolean;
}

const BillAccDetailPreview = ({
  eleAccDetailData,
  editEleBillAction,
  isValidBillAc,
}: IBillAccProps) => {
  // console.log("ele acc detail section preview");
  // console.log(eleAccDetailData);
  const { t } = useTranslation();
  const { ownerType, eleCompany, loginID, loginPW } = eleAccDetailData;

  return (
    <Box mb="30px">
      <SectionTitle
        title={t("TXT_Project_BItitle_elebill_account_detail")}
        editAction={() => editEleBillAction()}
        isValidBillAc={isValidBillAc}
      />

      <Flex
        bg={isValidBillAc ? returnByTheme() : "RD.3"}
        color={"WT"}
        borderTopRadius={"5px"}
        px="20px"
        py="5px"
        alignItems={"center"}
      >
        <UserCheckIcon />
        <Box ml="10px" fontSize="18px">
          {isValidBillAc
            ? t("TXT_Project_account_verified")
            : t("TXT_Project_account_invalid")}
        </Box>
      </Flex>
      <Flex
        backgroundColor={"WT"}
        borderBottomRadius={"5px"}
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        px="20px"
        flexDirection={"column"}
        mb="10px"
      >
        <TextPreviewRow
          label={t("TXT_Project_Account_owner")}
          value={ownerType}
          hadBorder={false}
        />
        <TextPreviewRow
          label={t("TXT_Project_Electricity_company")}
          value={eleCompany}
        />
        <TextPreviewRow label={t("TXT_Project_Login_id")} value={loginID} />
        <TextPreviewRow
          label={t("TXT_Project_Login_password")}
          value={loginPW ? `*****${loginPW.slice(-3)}` : ""}
        />
      </Flex>
    </Box>
  );
};

const SectionTitle = ({
  title,
  editAction,
  isValidBillAc,
}: {
  title: string;
  editAction: () => void;
  isValidBillAc: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb="5px">
      <Flex fontSize={18} fontWeight={"bold"}>
        {title}
      </Flex>
      {isValidBillAc && (
        <Flex
          cursor={"pointer"}
          borderRadius="4px"
          borderWidth="1px"
          borderColor={returnByTheme()}
          bg="WT"
          color={returnByTheme()}
          alignItems={"center"}
          px="20px"
          py="2px"
          onClick={() => editAction()}
        >
          <EditIcon stroke={returnByColor()} />
          <Box ml="5px">{t("TXT_Edit")}</Box>
        </Flex>
      )}
    </Flex>
  );
};

export default BillAccDetailPreview;
