import { Flex, Text } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';
import { PageMode } from './LoginPage';
import StyledButton from './StyledButton';

type SideTextProps = {
    mode?: PageMode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

const SideText = ({ mode, onClick }: SideTextProps) => {
    const title = mode === PageMode.SignIn ? 'HELLO' : 'WELCOME';

    const caption =
        mode === PageMode.SignIn ? (
            `We are a one-stop photovoltaic project and solar investment consultant`
        ) : (
            <>
                SOLAR
                <br />
                <b>MON</b>itorinh <b>S</b>ystem for <b>T</b>racking, <b>E</b>
                ifficiemy & <b>R</b>etain
            </>
        );

    return (
        <Flex
            transition="0.3s ease-out"
            position="absolute"
            w="35%"
            h="100%"
            transform={`translateX(${
                mode === PageMode.SignIn ? `${(65 / 35) * 100}%` : '0%'
            })`}
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            color="WT"
            textAlign="center"
        >
            <Text fontSize="65px" fontWeight={600}>
                {title}
            </Text>
            <Text
                fontSize="35px"
                lineHeight={2}
                fontWeight={400}
                maxW="600px"
                mb="35px"
            >
                {caption}
            </Text>
            <StyledButton ghost onClick={onClick}>
                {mode === PageMode.SignIn ? 'SIGN UP' : 'SIGN IN'}
            </StyledButton>
        </Flex>
    );
};

export default SideText;
