import { Box, Input, Text, Flex } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import TextPreviewRow from "../../../components/createProjectForm/TextPreviewRow";
import {
  returnByTheme,
  returnByColor,
  convertDisplayText,
} from "../../../utils/util";
import { ReactComponent as EditIcon } from "src/icons/general/editIcon.svg";

import { IInvoiceClientData } from "../../../type/IInoviceInfoInterface";
import { useTranslation } from "react-i18next";

interface IInvoiceClientProps {
  invoiceClientData: IInvoiceClientData;
  editInvoiceClientAction: () => void;
}

const ToClientPreview = ({
  invoiceClientData,
  editInvoiceClientAction,
}: IInvoiceClientProps) => {
  // console.log("invoice client detail section preview");
  // console.log(invoiceClientData);
  const { t } = useTranslation();

  const { name, phone, email, address } = invoiceClientData;

  const addressAsString = address
    .map((a, i) => {
      if (a) {
        return i !== 3 ? convertDisplayText(a) : a;
      } else {
        return "-";
      }
    })
    .reverse()
    .join(", ");

  return (
    <Box mb="30px">
      <SectionTitle
        title={t("TXT_Project_BItitle_toclient")}
        editAction={() => editInvoiceClientAction()}
      />
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        px="20px"
        flexDirection={"column"}
      >
        <TextPreviewRow label={t("TXT_Name")} value={name} hadBorder={false} />
        <TextPreviewRow label={t("TXT_Phone")} value={phone} />
        <TextPreviewRow label={t("TXT_Email")} value={email} />
        <TextPreviewRow label={t("TXT_Address")} value={addressAsString} />
      </Flex>
    </Box>
  );
};

const SectionTitle = ({
  title,
  editAction,
}: {
  title: string;
  editAction: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb="5px">
      <Flex fontSize={18} fontWeight={"bold"}>
        {title}
      </Flex>
      <Flex
        cursor={"pointer"}
        borderRadius="4px"
        borderWidth="1px"
        borderColor={returnByTheme()}
        bg="WT"
        color={returnByTheme()}
        alignItems={"center"}
        px="20px"
        py="2px"
        onClick={() => editAction()}
      >
        <EditIcon stroke={returnByColor()} />
        <Box ml="5px">{t("TXT_Edit")}</Box>
      </Flex>
    </Flex>
  );
};

export default ToClientPreview;
