import _ from "lodash";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  PinInput,
  PinInputField,
  Box,
  Flex,
  Text,
  Button,
  useOutsideClick,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { returnByTheme } from "../utils/util";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import moment from "moment";

type PickerProps = {
  dateRangePickerRef: any;
  onClose: Function;
  dateRange: Array<Object>;
  setDateRange?: Function;
  applyDateFilter?: (newRange: any) => void;
  isRangeOnly?: boolean;
  customClass?: string;
  maxDate?: any;
};

const DateRangePicker = ({
  dateRangePickerRef,
  onClose,
  dateRange,
  setDateRange,
  applyDateFilter,
  isRangeOnly,
  customClass,
  maxDate,
}: PickerProps) => {
  const isClient = localStorage.getItem("sf_Role") == "true";

  const [ownDateRange, setOwnDateRange] = useState<any>([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    setOwnDateRange(dateRange);
    return () => {};
  }, [dateRange]);

  useOutsideClick({
    ref: dateRangePickerRef,
    handler: (e: any) => {
      setOwnDateRange(dateRange);
      onClose(e);
    },
  });

  const isDateNull =
    ownDateRange[0].startDate == null || ownDateRange[0].endDate == null;

  const isSameDate = moment(ownDateRange[0]?.startDate).isSame(
    ownDateRange[0]?.endDate
  );

  const isDisable = isRangeOnly ? isDateNull || isSameDate : isDateNull;

  return (
    <Flex
      ref={dateRangePickerRef}
      // display={displayDropDown ? "flex" : "none"}
      backgroundColor={"WT"}
      position="absolute"
      top="55px"
      left="0px"
      zIndex={6}
      borderWidth={1}
      borderColor={returnByTheme()}
      borderTopRadius={12}
      flexDir="column"
      minW="240px"
      // width="100%"
      p="10px"
    >
      <Flex flexDirection="row" alignItems="center" paddingX="10px">
        <Text flex={1} color={returnByTheme()}>
          From:
        </Text>
        <Text flex={1} color={returnByTheme()}>
          To:
        </Text>
      </Flex>
      <DateRange
        editableDateInputs={true}
        onChange={(item) => setOwnDateRange([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={ownDateRange}
        className={isClient ? "orange" : ""}
        maxDate={maxDate}
      />
      {applyDateFilter && (
        <Button
          disabled={isDisable}
          height="34px"
          backgroundColor="WT"
          borderWidth="1px"
          borderColor={returnByTheme()}
          width="100%"
          onClick={(newRange) => applyDateFilter(ownDateRange)}
        >
          <Text color={returnByTheme()}>Done</Text>
        </Button>
      )}
    </Flex>
  );
};

export default DateRangePicker;
