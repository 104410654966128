import _ from "lodash";
import {
  Box,
  Flex,
  Text,
  Button,
  useOutsideClick,
  Icon,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
import { returnByTheme } from "../utils/util";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";

type PickerProps = {
  onClose: Function;
  dateRangePickerRef: any;
  selectedDate?: any;
  setDate: (item: any) => void;
};

const DatePicker = ({
  onClose,
  dateRangePickerRef,
  selectedDate,
  setDate,
}: PickerProps) => {
  useOutsideClick({
    ref: dateRangePickerRef,
    handler: (e: any) => {
      onClose(e);
    },
  });
  return (
    <Flex
      ref={dateRangePickerRef}
      // display={displayDropDown ? "flex" : "none"}
      backgroundColor={"WT"}
      position="absolute"
      top="55px"
      left="0px"
      zIndex={6}
      borderWidth={1}
      borderColor={returnByTheme()}
      borderTopRadius={12}
      flexDir="column"
      minW="240px"
      overflow="hidden"
      // width="100%"
      // p="10px"
    >
      <Flex
        bg={returnByTheme()}
        p="10px"
        color="WT"
        justifyContent="space-between"
      >
        {moment(selectedDate).format("ddd, DD MMM YYYY")}
        <Icon
          as={AiOutlineClose}
          width="20px"
          height="20px"
          cursor="pointer"
          onClick={() => onClose()}
        />
      </Flex>
      <Calendar
        // editableDateInputs={true}

        onChange={(item) => {
          setDate(item);
        }}
        date={selectedDate}
      />
    </Flex>
    // <Modal
    //   closeOnOverlayClick={false}
    //   isOpen={isShowDatePicker}
    //   onClose={() => onClose()}
    //   isCentered
    //   size={"sm"}
    // >
    //   <ModalOverlay />
    //   <ModalContent>
    //     <ModalHeader pb={0}>
    //       {moment(selectedDate).format("DD MMM YYYY")}
    //     </ModalHeader>
    //     <ModalCloseButton />
    //     <ModalBody>
    //       <Calendar
    //         // editableDateInputs={true}

    //         onChange={(item) => setDate(item)}
    //         date={selectedDate}
    //       />
    //     </ModalBody>
    //   </ModalContent>
    // </Modal>
  );
};

export default DatePicker;
