import { Box, Input, Text, Flex } from "@chakra-ui/react";

interface ITextPreviewRowProps {
  label: string;
  value?: string | null;
  hadBorder?: boolean;
}

const TextPreviewRow = ({
  label,
  value,
  hadBorder = true,
}: ITextPreviewRowProps) => {
  return (
    <Flex
      borderTop={hadBorder ? "1px" : "0"}
      borderColor="DISABLEDTEXT"
      py={"20px"}
    >
      <Flex flex={2} color="GREY">
        {label}
      </Flex>
      <Flex flex={3}>{value}</Flex>
    </Flex>
  );
};

export default TextPreviewRow;
