import { Flex, Icon, Image, Text } from "@chakra-ui/react";
import { useState, useEffect, useRef, createRef } from "react";
import { useTranslation } from "react-i18next";
import { returnByTheme, returnByColor } from "../utils/util";
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FiArrowLeft, FiZoomIn, FiZoomOut } from "react-icons/fi";
import { RiFileDownloadLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { IconContext } from "react-icons";

import { Document, Page, pdfjs } from "react-pdf";

const DocumentSwiper = ({ onClose, files, defaultIndex }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<any>();

  // const [isImage, setIsImage] = useState(true);
  const isClient = localStorage.getItem("sf_Role") == "true";

  const { t } = useTranslation();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  useEffect(() => {
    setCurrentIndex(defaultIndex + 1);
    return () => {};
  }, []);

  return (
    <Flex flexDir="column">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px="30px"
        py="10px"
        boxShadow="0px 2px 2px 2px rgba(0, 0, 0, 0.05)"
        position="sticky"
        top="0"
        zIndex="10"
        bg="WT"
      >
        <Flex flex={1}>
          <Flex
            alignItems="center"
            cursor="pointer"
            onClick={() => onClose()}
            _hover={{ textDecoration: "underline" }}
          >
            <FiArrowLeft />
            <Text
              noOfLines={1}
              textOverflow="ellipsis"
              fontWeight="bold"
              ml="10px"
              maxW="300px"
            >
              {files[currentIndex - 1]?.originalName}
            </Text>
          </Flex>
        </Flex>

        {!isClient && (
          <a
            style={{
              display: "flex",
              alignItems: "center",
              color: returnByColor(),
              borderRadius: "18px",
              borderColor: returnByColor(),
              borderWidth: "1px",
              padding: "5px 15px",
            }}
            href={files[currentIndex - 1]?.url}
            download={"does it work?"}
          >
            <Icon as={RiFileDownloadLine} w="20px" h="20px" mr="5px" />
            {t("TXT_Download")}
          </a>
        )}

        <Flex flex={1} justifyContent="flex-end" alignItems="center">
          <Flex
            pointerEvents="auto"
            cursor="pointer"
            width="35px"
            height="35px"
            borderRadius="25px"
            mx="10px"
            borderColor={returnByTheme()}
            borderWidth="1px"
            justifyContent="center"
            alignItems="center"
            mr="5px"
            onClick={() => {
              swiperInstance.slidePrev();

              setCurrentIndex(swiperInstance?.realIndex + 1);
            }}
          >
            <AiOutlineLeft color={returnByColor()} width="30px" height="30px" />
          </Flex>

          <Flex fontWeight="bold" mx="5px">
            {currentIndex} of {files.length}
          </Flex>
          <Flex
            pointerEvents="auto"
            cursor="pointer"
            width="35px"
            height="35px"
            borderRadius="25px"
            mx="10px"
            borderColor={returnByTheme()}
            borderWidth="1px"
            justifyContent="center"
            alignItems="center"
            mr="5px"
            onClick={() => {
              swiperInstance.slideNext();

              setCurrentIndex(swiperInstance?.realIndex + 1);
            }}
          >
            <AiOutlineRight
              color={returnByColor()}
              width="30px"
              height="30px"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        flex={1}
        maxHeight={`calc(${window.innerHeight - 50}px )`}
        // overflow={"scroll"}
        // py="50px"
        bg="DISABLEDTEXT"
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          draggable={false}
          allowTouchMove={false}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          onSlideChange={(swiper) => {
            setCurrentIndex(swiperInstance?.realIndex);
          }}
          initialSlide={defaultIndex}
          style={{ padding: "50px 0" }}
          // style={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          {files.map((file: any, index: number) => {
            // imgRef.current[index] = createRef();
            // console.log(file.url);
            return (
              <SwiperSlide key={index} style={{ overflowY: "scroll" }}>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  minH="500px"
                  // maxH="500px"
                >
                  <DocItem file={file} onClose={() => onClose()} />
                </Flex>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Flex>
    </Flex>
  );
};

const DocItem = ({ file, onClose }: any) => {
  const extensionName = file.originalName.split(".").pop();
  const isImg = extensionName == "pdf" ? false : true;

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomSize, setZoomSize] = useState(1.2);
  const { t } = useTranslation();

  // function onDocumentLoadSuccess({ numPages }: any) {
  //   setNumPages(numPages);
  // }

  // console.log(window.innerHeight);

  if (isImg) {
    return (
      <>
        <img
          // ref={imgRef.current[index]}
          className="swiper-lazy preview-image  "
          src={file.url}
          style={{
            height: "100vh",
            objectFit: "scale-down",
          }}
          alt={file.originalName}
        />
      </>
    );
  } else {
    return (
      <>
        <Document
          file={{
            url: file.url,
            headers: { "Access-Control-Allow-Origin": "*" },
          }}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
          }}
          onLoadError={(error) => console.log("Inside Error", error)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              className="pdf-page"
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderInteractiveForms={false}
              renderTextLayer={false}
              scale={zoomSize}
            />
          ))}

          <Flex
            flex={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            //backgroundColor="#FFA"
            zIndex={2}
            py="5px"
          >
            <Flex
              px="10px"
              h="60px"
              flexDirection="row"
              backgroundColor="WT"
              borderWidth="1px"
              borderRadius="15px"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
              alignItems="center"
              justifyContent="center"
              gap="10px"
            >
              <IconContext.Provider value={{ color: "#8E8E8E", size: "35px" }}>
                <Flex cursor={"pointer"}>
                  <FiZoomIn
                    onClick={() => {
                      setZoomSize((cur) => (cur < 2 ? cur + 0.1 : cur));
                    }}
                  />
                </Flex>
                <Flex cursor={"pointer"}>
                  <FiZoomOut
                    onClick={() => {
                      setZoomSize((cur) => (cur > 0.7 ? cur - 0.1 : cur));
                    }}
                  />
                </Flex>
              </IconContext.Provider>
              <Flex
                alignItems="center"
                fontSize="18px"
                textColor="GREY"
                borderLeft="1px solid #F0F0F0"
                paddingLeft="10px"
                cursor="pointer"
                onClick={() => onClose()}
              >
                <Icon as={AiOutlineClose} width="20px" height="20px" mr="5px" />
                {t("TXT_Close")}
              </Flex>
            </Flex>
          </Flex>
        </Document>
      </>
    );
  }
};

export default DocumentSwiper;
