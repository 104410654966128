import _ from "lodash";
import { Box, Flex, Grid, GridItem, Image, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { GetDashboardTotalSitePowersByContractorIdDocument, GetDashboardTotalSitePowersByInvestorIdDocument, SiteStatus } from "../../graphql/generated";
import { useTranslation } from "react-i18next";
import { returnByTheme } from "src/utils/util";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
const isInvestor = localStorage.getItem("sf_Investor") == "true";

const ProjectTotalDataRow = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const toast = useToast();

	let query = isInvestor ? GetDashboardTotalSitePowersByInvestorIdDocument : GetDashboardTotalSitePowersByContractorIdDocument;
	const { data: totalData }: {data: any} = useQuery(query, {
		onCompleted: (data) => {},
		onError: (error) => {
			return toast({
				position: "bottom-right",
				title: `Fail to get site power data`,
				status: "error",
			});
		},
	});

	return (
		<Grid templateColumns="repeat(4, 1fr)" gap="20px" overflow="auto">
			<GridItem p="10px" borderRadius="8px" boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)" bg="WT" justifyContent="space-between">
				<Flex alignItems="center" gap="15px">
					<Image src={require("../../icons/general/solarPanel.png")}></Image>
					<Flex flexDir="column" color="GREY">
						<Text>{t("TXT_Dashboard_total_generate")}</Text>
						<Flex alignItems="center" flexWrap="wrap">
							<Text color="BK" fontWeight="bold" fontSize="28px">
								{isInvestor
									? totalData?.dashboardTotalSitePowersByInvestorId?.generatedPower.toLocaleString()
									: totalData?.dashboardTotalSitePowersByContractorId?.generatedPower.toLocaleString()}
								&nbsp;
							</Text>
							<Text color="GREY"> KWh</Text>
						</Flex>
					</Flex>
				</Flex>
			</GridItem>
			<GridItem p="10px" borderRadius="8px" boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)" bg="WT" justifyContent="space-between">
				<Flex alignItems="center" gap="15px">
					<Image src={require("../../icons/general/elePlugIn.png")}></Image>
					<Flex flexDir="column" color="GREY">
						<Text>{t("TXT_Dashboard_total_usage")}</Text>
						<Flex alignItems="center" flexWrap="wrap">
							<Text color="BK" fontWeight="bold" fontSize="28px">
								{isInvestor
									? totalData?.dashboardTotalSitePowersByInvestorId?.consumedPower.toLocaleString()
									: totalData?.dashboardTotalSitePowersByContractorId?.consumedPower.toLocaleString()}
								&nbsp;
							</Text>
							<Text color="GREY"> KWh</Text>
						</Flex>
					</Flex>
				</Flex>
			</GridItem>

			<GridItem p="10px" borderRadius="8px" boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)" bg="WT" justifyContent="space-between">
				<Flex alignItems="center" gap="15px">
					<Image src={require("../../icons/general/calculator.png")}></Image>
					<Flex flexDir="column" color="GREY">
						<Text>{t("TXT_Dashboard_revenue")}</Text>
						<Flex alignItems="center" flexWrap="wrap">
							<Text color="BK" fontWeight="bold" fontSize="28px">
								{isInvestor
									? totalData?.dashboardTotalSitePowersByInvestorId?.revenue.toLocaleString()
									: totalData?.dashboardTotalSitePowersByContractorId?.revenue.toLocaleString()}
								&nbsp;
							</Text>
							<Text color="GREY"> HK$</Text>
						</Flex>
					</Flex>
				</Flex>
			</GridItem>

			<GridItem p="10px" borderRadius="8px" boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)" bg="WT" justifyContent="space-between">
				<Flex alignItems="center" gap="15px">
					<Image src={require("../../icons/general/carbonReduction.png")}></Image>
					<Flex flexDir="column" color="GREY">
						<Text>{t("TXT_Dashboard_carbon_reduction")}</Text>
						<Flex alignItems="center" flexWrap="wrap">
							<Text color="BK" fontWeight="bold" fontSize="28px">
								{isInvestor
									? Math.floor(totalData?.dashboardTotalSitePowersByInvestorId?.generatedPower! * 0.997).toLocaleString()
									: Math.floor(totalData?.dashboardTotalSitePowersByContractorId?.generatedPower! * 0.997).toLocaleString()}{" "}
								&nbsp;
								{/* {totalData?.dashboardTotalSitePowersByContractorId?.generatedPower} &nbsp; */}
							</Text>
							<Text color="GREY"> KWh/KG</Text>
						</Flex>
					</Flex>
				</Flex>
			</GridItem>
		</Grid>
	);
};

export default ProjectTotalDataRow;
