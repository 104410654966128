import { Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import SideText from "./SideText";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { returnByTheme } from "../../utils/util";

import bg1 from "src/images/auth/bg1.svg";
import bbg1 from "src/images/auth/bbg1.svg";
import bg2 from "src/images/auth/bg2.svg";
import bbg2 from "src/images/auth/bbg2.svg";
import { ReactComponent as SFLogo } from "src/icons/general/sfLogoB.svg";
import bgImg from "src/images/auth/solar-panels.png";

export enum PageMode {
  SignIn,
  SignUp,
}

const LoginPage = () => {
  const [mode, setMode] = useState<PageMode>(PageMode.SignIn);

  const backgroundImage =
    mode === PageMode.SignIn
      ? `url(${bg1}), url(${bgImg}),url(${bg2}), linear-gradient(0deg, #004b92 0%, #FCAF20 100%);`
      : `url(${bg1}), url(${bgImg}),url(${bg2}), linear-gradient(0deg, #FCAF20 0%, #004b92 90%)`;
  return (
    <>
      {/* <Text
        zIndex={20}
        position="absolute"
        left="50px"
        top="36px"
        color={mode === PageMode.SignIn ? returnByTheme() : "WT"}
        fontSize="25px"
        fontWeight={600}
        transition="0.3s ease-out"
      >
        Solar Monster
      </Text> */}
      <Flex
        align="center"
        position="absolute"
        left="20px"
        top="30px"
        zIndex={1}
      >
        <SFLogo width="80px" height="80px" />
        <Text
          // zIndex={20}
          // position="absolute"
          // left="50px"
          // top="36px"
          fontSize="36px"
          fontWeight="extrabold"
          color="WT"
          transition="0.3s ease-out"
          ml="10px"
        >
          SOLAR MONSTER
        </Text>
      </Flex>

      <Flex
        flexDir="row"
        backgroundImage={backgroundImage}
        backgroundPosition="center bottom"
        backgroundSize="cover"
        backgroundBlendMode="multiply"
        w="100%"
        h="100%"
        position="relative"
        transition="0.3s ease-out"
      >
        <Flex
          transition="0.3s ease-out"
          // position="absolute"
          w="65%"
          h="100%"
          transform={`translateX(${
            mode === PageMode.SignIn ? "0%" : `${(35 / 65) * 100}%`
          })`}
          bgColor="WT"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
        >
          {mode === PageMode.SignIn && <SignIn />}
          {mode === PageMode.SignUp && <SignUp />}
        </Flex>
        <SideText
          mode={mode}
          onClick={() =>
            setMode((v) =>
              v === PageMode.SignIn ? PageMode.SignUp : PageMode.SignIn
            )
          }
        />
      </Flex>
    </>
  );
};

export default LoginPage;
