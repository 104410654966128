import { Box, Button, Text, Flex, Tab, TabList, Tabs } from "@chakra-ui/react";
import _ from "lodash";
import FormSelectInput from "src/components/createProjectForm/FormSelectInput";
import { useEffect, useState } from "react";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import FormPwInput from "src/components/createProjectForm/FormPwInput";
import { IBillAccDetailData } from "../../../type/IBillInfoInterface";
import { useTranslation } from "react-i18next";
import { returnByTheme } from "../../../utils/util";
interface IBillAccProps {
  eleAccDetailData: IBillAccDetailData;
  updateEleBillState: (value: IBillAccDetailData) => void;
  isPreviewMode?: boolean;
  onClose?: () => void;
}

const EleBillAccSection = ({
  eleAccDetailData,
  updateEleBillState,
  isPreviewMode = false,
  onClose = () => {},
}: IBillAccProps) => {
  const { t } = useTranslation();
  // console.log("ele bill acc section state");
  // console.log(eleAccDetailData);
  const [previewState, setPreviewState] = useState<IBillAccDetailData>({
    ownerType: "",
    eleCompany: "Clp",
    loginID: "",
    loginPW: "",
  });
  const displayData = isPreviewMode ? previewState : eleAccDetailData;

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(eleAccDetailData);
    }
    return () => {};
  }, []);
  const ownerOption = [
    { value: "Client", name: "TXT_Invoice_Client" },
    { value: "Investor", name: "TXT_Invoice_Investor" },
  ];

  const getNewState = (key: string, value: string) => {
    if (isPreviewMode) {
      setPreviewState({
        ...displayData,
        [key]: value,
      });
    } else {
      updateEleBillState({
        ...displayData,
        [key]: value,
      });
    }
  };

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        width="100%"
        flexDirection={"column"}
        mt="20px"
        pb="10px"
      >
        {!isPreviewMode && (
          <SectionTitle
            title={t("TXT_Project_Electricity_bill_account_details")}
          />
        )}

        <FormSelectInput
          options={ownerOption}
          inputLabel={t("TXT_Project_Account_owner")}
          isRequired={true}
          selectedValue={displayData?.ownerType}
          onChange={(value: string) => {
            getNewState("ownerType", value);
          }}
        />

        <Flex
          flexDirection={"row"}
          py="10px"
          px="20px"
          justifyContent={"space-between"}
          gap="5px"
        >
          <Flex flex={2}>
            <Box color="GREY">
              <Text display={"inline"}>
                {t("TXT_Project_Electricity_company")}&nbsp;
              </Text>
              {/* <Text color={"RD.1"} display={"inline"}>
                *
              </Text> */}
            </Box>

            {/* <Flex color="GREY" fontSize={"15px"}>
            Electricity company<Text color={"RD.1"}> *</Text>
          </Flex> */}
          </Flex>
          <Tabs flex={3} borderRadius="5px">
            <TabList border={"none"}>
              <Tab
                flex={1}
                borderLeftRadius="10px"
                backgroundColor={
                  displayData?.eleCompany == "Clp"
                    ? returnByTheme()
                    : "DISABLEDBG"
                }
                color={"WT"}
                border={"none"}
                onClick={() => {
                  getNewState("eleCompany", "Clp");
                }}
              >
                <Flex color={"WT"}>CLP</Flex>
              </Tab>
              <Tab
                flex={1}
                borderRightRadius="10px"
                bg={
                  displayData?.eleCompany == "Hke"
                    ? returnByTheme()
                    : "DISABLEDBG"
                }
                color={"WT"}
                border={"none"}
                onClick={() => {
                  getNewState("eleCompany", "HKE");
                }}
              >
                <Flex color={"WT"}>HKE</Flex>
              </Tab>
            </TabList>
          </Tabs>
        </Flex>

        <FormTextInput
          placeHolder={t("TXT_Project_Login_id")}
          inputLabel={t("TXT_Project_Login_id")}
          // isRequired={true}
          onChange={(value: string) => {
            getNewState("loginID", value);
          }}
          inpputtedValue={displayData?.loginID}
        />

        <FormPwInput
          inputType="password"
          placeHolder={t("TXT_Project_Login_password")}
          inputLabel={t("TXT_Project_Login_password")}
          // isRequired={true}
          onChange={(value: string) => {
            getNewState("loginPW", value);
          }}
          inpputtedValue={displayData?.loginPW}
        />
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updateEleBillState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default EleBillAccSection;
