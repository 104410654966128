import { Box, Button, Text, Flex } from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import FormAddressInput from "src/components/createProjectForm/FormAddressInput";
import { useTranslation } from "react-i18next";
import { IInvoiceClientData } from "../../../type/IInoviceInfoInterface";
import FormNumberTextInput from "src/components/createProjectForm/FormNumberTextInput";
import { isEmptyString, returnByTheme } from "src/utils/util";

interface IInvoiceClientProps {
  invoiceClientData: IInvoiceClientData;
  updateInvoiceClientState: (value: IInvoiceClientData) => void;
  isPreviewMode?: boolean;
  onClose?: () => void;
}

const ToClientSection = ({
  invoiceClientData,
  updateInvoiceClientState,
  isPreviewMode = false,
  onClose = () => {},
}: IInvoiceClientProps) => {
  const { t } = useTranslation();
  // console.log("to client section state");
  // console.log(invoiceClientData);

  const [previewState, setPreviewState] = useState<IInvoiceClientData>({
    name: "",
    phone: "",
    email: "",
    address: [t("TXT_Hong_Kong"), "", "", ""],
  });
  const displayData = isPreviewMode ? previewState : invoiceClientData;

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(invoiceClientData);
    }
    return () => {};
  }, []);

  const getNewState = (key: string, value: any) => {
    if (isPreviewMode) {
      setPreviewState({
        ...displayData,
        [key]: value,
      });
    } else {
      updateInvoiceClientState({
        ...displayData,
        [key]: value,
      });
    }
  };

  const isToClientDetailPass = () => {
    if (
      isEmptyString(displayData.name) ||
      isEmptyString(displayData.phone) ||
      isEmptyString(displayData.email)
      // ||
      // isEmptyString(displayData.address[1]) ||
      // isEmptyString(displayData.address[2]) ||
      // isEmptyString(displayData.address[3])
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        width={"100%"}
        flexDirection={"column"}
        mt="20px"
        pb="10px"
      >
        {!isPreviewMode && (
          <SectionTitle title={t("TXT_Project_client_detail")} />
        )}

        <FormTextInput
          placeHolder={t("TXT_Name")}
          inputLabel={t("TXT_Name")}
          isRequired={true}
          onChange={(value: string) => {
            getNewState("name", value);
          }}
          inpputtedValue={displayData?.name}
        />

        <FormNumberTextInput
          placeHolder={t("TXT_Phone")}
          inputLabel={t("TXT_Phone")}
          isRequired={true}
          maxLen={8}
          onChange={(value: string) => {
            getNewState("phone", value);
          }}
          inpputtedValue={displayData?.phone}
        />

        <FormTextInput
          placeHolder={t("TXT_Email")}
          inputLabel={t("TXT_Email")}
          isRequired={true}
          onChange={(value: string) => {
            getNewState("email", value);
          }}
          inpputtedValue={displayData?.email}
          isDisable={true}
        />

        {/* address section */}
        <FormAddressInput
          inputLabel={t("TXT_Address")}
          // isRequired={true}
          addressArr={displayData?.address}
          areaSelectedValue={displayData?.address[1]}
          distSelectedValue={displayData?.address[2]}
          buildingInputValue={displayData?.address[3]}
          areaOnchange={(value: string) => {
            let areaArr = displayData?.address;
            areaArr[1] = value;
            getNewState("address", areaArr);
            // updateInvoiceClientState({
            //   ...displayData,
            //   address: areaArr,
            // });
          }}
          distOnchange={(value: string) => {
            let distArr = displayData?.address;
            distArr[2] = value;
            getNewState("address", distArr);
            // updateInvoiceClientState({
            //   ...displayData,
            //   address: distArr,
            // });
          }}
          placeHolder={t("TXT_Desc_fill_street_or_building")}
          buildingOnchange={(value: string) => {
            let buildingArr = displayData?.address;
            buildingArr[3] = value;
            getNewState("address", buildingArr);
            // updateInvoiceClientState({
            //   ...displayData,
            //   address: buildingArr,
            // });
          }}
        />
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            disabled={!isToClientDetailPass()}
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updateInvoiceClientState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default ToClientSection;
