import { Box, chakra, Text, useCheckbox } from '@chakra-ui/react';

import { ReactComponent as UncheckedIcon } from 'src/icons/auth/unchecked.svg';
import { ReactComponent as CheckedIcon } from 'src/icons/auth/checked.svg';
import { ChangeEvent, ReactNode } from 'react';

type Props = {
    isChecked?: boolean;
    children?: ReactNode;
    color?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const CheckBox = ({ isChecked, children, color, onChange }: Props) => {
    const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
        useCheckbox({ isChecked, onChange });
        
    return (
        <chakra.label
            display="flex"
            flexDirection="row"
            alignItems="center"
            color={color}
            cursor="pointer"
            {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Box mr="17px" {...getCheckboxProps()}>
                {state.isChecked ? (
                    <CheckedIcon stroke="currentcolor" fill="currentcolor" />
                ) : (
                    <UncheckedIcon stroke="currentcolor" />
                )}
            </Box>

            <Text userSelect="none" fontSize="25px" {...getLabelProps()}>
                {children}
            </Text>
        </chakra.label>
    );
};

export default CheckBox;
