import { Box, Input, Text, Flex } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import TextPreviewRow from "../../../components/createProjectForm/TextPreviewRow";

import { ReactComponent as EditIcon } from "src/icons/general/editIcon.svg";
import { ReactComponent as UserCheckIcon } from "src/icons/general/user-check.svg";
import { returnByTheme, returnByColor } from "../../../utils/util";
import { IEleDetailData } from "../../../type/IBillInfoInterface";
import { useTranslation } from "react-i18next";

interface IEleDetailProps {
  eleDetailData: IEleDetailData;
  editEleDetailAction: () => void;
}

const EleDetailPreview = ({
  eleDetailData,
  editEleDetailAction,
}: IEleDetailProps) => {
  // console.log("ele detail section preview");
  // console.log(eleDetailData);
  const { t } = useTranslation();

  const {
    fitCode,
    meterID,
    fitMeterID,
    fitRate,
    energyOutputByContractor,
    energyOutputByEC,
  } = eleDetailData;

  return (
    <Box mb="30px">
      <SectionTitle
        title={t("TXT_Project_BItitle_elebill_detail")}
        editAction={() => editEleDetailAction()}
      />

      <Flex
        backgroundColor={"WT"}
        borderBottomRadius={"5px"}
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        px="20px"
        flexDirection={"column"}
        mb="10px"
      >
        <TextPreviewRow
          label={t("TXT_Project_FiT_application_code")}
          value={fitCode}
          hadBorder={false}
        />
        <TextPreviewRow label={t("TXT_Project_Meter_id")} value={meterID} />
        <TextPreviewRow
          label={t("TXT_Project_Meter_id_fit")}
          value={fitMeterID}
        />
        <TextPreviewRow
          label={t("TXT_Project_Fit_rate")}
          value={`HK$ ${fitRate}`}
        />
        <TextPreviewRow
          label={t("TXT_Project_Energy_output1")}
          value={
            t("TXT_Project_Approved_generation_capacity") +
            " - " +
            energyOutputByEC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " (kW) , " +
            t("TXT_Project_Installed_capacity") +
            " - " +
            energyOutputByContractor
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " (kW)"
          }
        />
      </Flex>
    </Box>
  );
};

const SectionTitle = ({
  title,
  editAction,
}: {
  title: string;
  editAction: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb="5px">
      <Flex fontSize={18} fontWeight={"bold"}>
        {title}
      </Flex>
      <Flex
        cursor={"pointer"}
        borderRadius="4px"
        borderWidth="1px"
        borderColor={returnByTheme()}
        bg="WT"
        color={returnByTheme()}
        alignItems={"center"}
        px="20px"
        py="2px"
        onClick={() => editAction()}
      >
        <EditIcon stroke={returnByColor()} />
        <Box ml="5px">{t("TXT_Edit")}</Box>
      </Flex>
    </Flex>
  );
};

export default EleDetailPreview;
