import {
  Box,
  Input,
  Text,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Button,
  Checkbox,
  Icon,
} from "@chakra-ui/react";
import _ from "lodash";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { returnByTheme } from "../../../utils/util";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import { IInvestorDetailData } from "../../../type/IBaseInfotInterface";
import DateRangePicker from "src/components/DateRangePicker";
import { FiCalendar } from "react-icons/fi";
import moment from "moment";

interface IInvestorProps {
  investorData: Array<IInvestorDetailData>;
  updateInvestorDetailState: (value: Array<IInvestorDetailData>) => void;
  // addMoreSection?: (clone: any) => void;
  isPreviewMode?: boolean;
  onClose?: () => void;
  isEditMode?: boolean;
}

const InvestorDetailSection = ({
  investorData,
  updateInvestorDetailState,
  // addMoreSection,
  isPreviewMode = false,
  isEditMode = false,
  onClose = () => {},
}: IInvestorProps) => {
  const [isInvestor, setIsInvestor] = useState(false);
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [previewState, setPreviewState] = useState<Array<IInvestorDetailData>>([
    {
      // name: "",
      // phone: "",
      email: "",
      sharingType: "share",
      clientRatio: 0,
      investorRatio: 0,
      date: [
        {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      ],
    },
  ]);

  const dateRangePickerRef = useRef<any>();

  // console.log("investor section state");
  // console.log(investorData);
  const displayData = isPreviewMode ? previewState : investorData;
  const meData = JSON.parse(localStorage.getItem("meData")!);
  const { t } = useTranslation();

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(investorData);
    }
    return () => {};
  }, []);

  const getNewState = (key: string, i: number, value: string | number) => {
    let cloneArr = displayData.map((obj, index) => {
      if (index == i) {
        return { ...obj, [key]: value };
      } else {
        return obj;
      }
    });

    if (isPreviewMode) {
      setPreviewState(cloneArr);
    } else {
      updateInvestorDetailState(cloneArr);
    }

    // updateInvestorDetailState(cloneArr);
  };

  const autoCalcRemainingPercent = (key: string, i: number, value: number) => {
    let cloneArr = displayData.map((obj, index) => {
      const remaingingPercent = 100 - Number(value);

      if (index == i) {
        if (key == "investorRatio") {
          return {
            ...obj,
            [key]: value,
            clientRatio: remaingingPercent,
          };
        } else {
          return {
            ...obj,
            [key]: value,
            investorRatio: remaingingPercent,
          };
        }
      } else {
        return obj;
      }
    });

    if (isPreviewMode) {
      setPreviewState(cloneArr);
    } else {
      updateInvestorDetailState(cloneArr);
    }

    // updateInvestorDetailState(cloneArr);
  };

  const fillInvestorData = (i: number) => {
    setIsInvestor(!isInvestor);

    const { company, user } = meData;

    if (isInvestor) {
      let cloneArr = displayData.map((obj, index) => {
        if (index == i) {
          return { ...obj, email: "" };
        } else {
          return obj;
        }
      });
      if (isPreviewMode) {
        setPreviewState(cloneArr);
      } else {
        updateInvestorDetailState(cloneArr);
      }
    } else {
      let cloneArr = displayData.map((obj, index) => {
        if (index == i) {
          return { ...obj, email: company.email };
        } else {
          return obj;
        }
      });

      if (isPreviewMode) {
        setPreviewState(cloneArr);
      } else {
        updateInvestorDetailState(cloneArr);
      }
    }
  };

  const isInvestorDetailPass = () => {
    const isAllValid = displayData.map((investor: any, i) => {
      let isValidArr = Object.keys(investor).map((o) => {
        return investor[o] !== "";
      });
      return _.every(isValidArr, (x) => x === true);
    });

    return _.every(isAllValid, (x) => x === true);
  };

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        // flex={1}
        flexDirection={"column"}
        mt="20px"
      >
        {!isPreviewMode && (
          <SectionTitle title={t("TXT_Project_Investor_details")} />
        )}

        {displayData &&
          displayData?.map((d, i) => {
            const sharingTotal =
              Number(d?.clientRatio) + Number(d?.investorRatio);
            const dateText =
              d?.date?.[0]?.endDate !== null &&
              d?.date?.[0]?.startDate !== d?.date?.[0]?.endDate
                ? `${moment(d?.date?.[0]?.startDate).format(
                    "D MMM, YYYY"
                  )} - ${moment(d?.date?.[0]?.endDate).format("D MMM, YYYY")}`
                : `${moment(d?.date?.[0]?.startDate).format("D MMM, YYYY")}`;
            return (
              <Box
                key={i}
                // fontWeight={"bold"}
                borderBottomColor={"DISABLEDBG"}
                borderBottomWidth={1}
              >
                {!isInvestor && (
                  <>
                    {/* <FormTextInput
                      placeHolder={"Investor name / Company name"}
                      inputLabel={"Name"}
                      isRequired={true}
                      onChange={(value: string) => {
                        getNewState("name", i, value);
                      }}
                      inpputtedValue={d?.name}
                    />
                    <FormTextInput
                      placeHolder={"mobile / office"}
                      inputLabel={"Phone"}
                      isRequired={true}
                      onChange={(value: string) => {
                        getNewState("phone", i, value);
                      }}
                      inpputtedValue={d?.phone}
                    /> */}
                    <FormTextInput
                      placeHolder={t("TXT_Email")}
                      inputLabel={t("TXT_Email")}
                      isRequired={true}
                      isDisable={isEditMode}
                      onChange={(value: string) => {
                        getNewState("email", i, value);
                      }}
                      inpputtedValue={d?.email}
                    />
                  </>
                )}

                {/* ratio section */}
                <Flex
                  flex={1}
                  flexDirection={"row"}
                  py="10px"
                  px="20px"
                  justifyContent={"space-between"}
                >
                  <Flex flex={2}>
                    <Flex
                      flexDirection={"column"}
                      flex={2}
                      fontSize={15}
                      color={"GREY"}
                      flexWrap={"wrap"}
                      alignItems={"baseline"}
                    >
                      <Box width={"100%"}>
                        <Flex>
                          {t("TXT_Sharing_ratio")}&nbsp;{" "}
                          <Text color={"RD.1"}> *</Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex flex={3} flexDirection={"column"}>
                    <RadioGroup
                      mb="20px"
                      onChange={(value: string) => {
                        getNewState("sharingType", i, value);
                      }}
                      value={d?.sharingType}
                    >
                      <Stack direction="row">
                        <Radio colorScheme="blue" value="share">
                          {t("TXT_Project_Share_ratio")}
                        </Radio>
                        <Radio isDisabled colorScheme="blue" value="fix">
                          {t("TXT_Project_fix_rate")}
                        </Radio>
                      </Stack>
                    </RadioGroup>

                    <Flex alignItems={"center"}>
                      <Flex flexDirection={"column"} flex={1.5}>
                        <NumberInput
                          size="sm"
                          defaultValue={0}
                          min={0}
                          max={100}
                          precision={1}
                          step={0.1}
                          borderColor={returnByTheme()}
                          borderRadius="5px"
                          borderWidth={1}
                          onChange={(e: string) => {
                            autoCalcRemainingPercent(
                              "clientRatio",
                              i,
                              Number(e)
                            );
                          }}
                          value={d?.clientRatio}
                        >
                          <NumberInputField />
                        </NumberInput>
                        {/* <Input
                        type={"number"}
                        placeholder={"0.00"}
                        flex={1}
                        px="10px"
                        py="5px"
                        max={100}
                        min={0}
                        borderColor={returnByTheme()}
                        borderRadius="5px"
                        borderWidth={1}
                        justifyContent={"space-between"}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          convertAsNum(
                            "clientRatio",
                            i,
                            e?.target?.valueAsNumber
                          );
                        }}
                        value={d?.clientRatio}
                      /> */}
                        <Flex
                          fontSize={"10px"}
                          color={"GREY"}
                          fontWeight={"normal"}
                        >
                          {t("TXT_Project_Client_ratio")}
                        </Flex>
                      </Flex>
                      <Flex mx="5px" pb="15px">
                        +
                      </Flex>
                      <Flex flexDirection={"column"} flex={1.5}>
                        <NumberInput
                          size="sm"
                          defaultValue={0}
                          min={0}
                          max={100}
                          precision={1}
                          step={0.1}
                          borderColor={returnByTheme()}
                          borderRadius="5px"
                          borderWidth={1}
                          onChange={(e: string) => {
                            autoCalcRemainingPercent(
                              "investorRatio",
                              i,
                              Number(e)
                            );
                          }}
                          value={d?.investorRatio}
                        >
                          <NumberInputField />
                        </NumberInput>
                        {/* <Input
                        type={"number"}
                        placeholder={"0.00"}
                        flex={1}
                        px="10px"
                        py="5px"
                        max={100}
                        min={0}
                        borderColor={returnByTheme()}
                        borderRadius="5px"
                        borderWidth={1}
                        justifyContent={"space-between"}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          getNewState(
                            "investorRatio",
                            i,
                            e?.target?.valueAsNumber
                          );
                        }}
                        value={d?.investorRatio}
                      /> */}
                        <Flex
                          fontSize={"10px"}
                          color={"GREY"}
                          fontWeight={"normal"}
                        >
                          {t("TXT_Project_Investor_ratio")}
                        </Flex>
                      </Flex>

                      <Flex pl={"5px"} pb="15px" flex={1}>
                        = 100 %
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                {/* start from */}
                <Flex px="20px" mb="20px">
                  <Flex flex={2} fontSize={15} color={"GREY"}>
                    <Box>
                      <Text display={"inline"}>
                        {t("TXT_Project_sharing_period")} &nbsp;
                      </Text>
                      <Text color={"RD.1"} display={"inline"}>
                        *
                      </Text>
                    </Box>
                  </Flex>
                  <Flex flex={3} flexDir="column">
                    <Flex
                      flex={1}
                      position="relative"
                      px="10px"
                      py="5px"
                      borderColor={returnByTheme()}
                      borderRadius="5px"
                      borderWidth={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>{dateText}</Box>
                      <Icon
                        as={FiCalendar}
                        color={returnByTheme()}
                        cursor="pointer"
                        width="20px"
                        height="20px"
                        onClick={(e) => {
                          setIsShowDatePicker(true);
                        }}
                      />
                      {isShowDatePicker && (
                        <DateRangePicker
                          isRangeOnly={true}
                          dateRangePickerRef={dateRangePickerRef}
                          dateRange={d?.date}
                          onClose={() => {
                            setIsShowDatePicker(false);
                          }}
                          applyDateFilter={(newRange: any) => {
                            getNewState("date", i, newRange);
                            setIsShowDatePicker(false);
                          }}
                          maxDate={new Date("2033 12 31")}
                        />
                      )}
                    </Flex>
                    <Flex fontSize="12px" color="RD.2">
                      * {t("TXT_Desc_please_select_range")}
                    </Flex>
                  </Flex>
                </Flex>

                {/* checkbox section */}
                <Flex p="20px" borderTopColor="DISABLEDBG" borderTopWidth={1}>
                  <Checkbox
                    onChange={(e) => {
                      fillInvestorData(i);
                    }}
                    colorScheme="blue"
                    borderColor={returnByTheme()}
                    isChecked={isInvestor}
                    mr="20px"
                  />
                  {t("TXT_Project_I_am_investor_desc")}
                </Flex>
              </Box>
            );
          })}

        {/* add section btn */}
        {/* <Flex p={"20px"}>
          <Flex
            borderRadius={"5px"}
            borderColor={returnByTheme()}
            borderWidth={1}
            onClick={() => {
              let clone = [...displayData];

              clone = [
                ...clone,
                {
                  name: "",
                  phone: "",
                  email: "",
                  sharingType: "share",
                  clientRatio: 0,
                  investorRatio: 0,
                },
              ];
              isPreviewMode
                ? setPreviewState(clone)
                : updateInvestorDetailState(clone);
            }}
            px="20px"
            py="3px"
            color={returnByTheme()}
            alignItems={"center"}
            cursor={"pointer"}
            // _hover={{ color: "WT", background: {returnByTheme()} }}
          >
            + Add
          </Flex>
        </Flex> */}
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            disabled={!isInvestorDetailPass()}
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updateInvestorDetailState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default InvestorDetailSection;
