import {
  Box,
  chakra,
  Flex,
  Text,
  Textarea,
  Button,
  Image,
  useToast,
} from "@chakra-ui/react";

import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IconContext } from "react-icons";
import { useNavigate, useParams } from "react-router-dom";
import PopUpModal from "src/components/PopUpModal";
import { FiAlertTriangle, FiArrowLeft } from "react-icons/fi";
import {
  IPersonalInfoData,
  IInvoiceConfig,
} from "../../type/IAccountManageInterface";
import { IInvoicePaymentMethodData } from "src/type/IInoviceInfoInterface";
import { returnByTheme, returnByColor } from "../../utils/util";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import PersonalInfoSection from "./manageAcFormOne/GeneralDetailSection";
import PayMethodSection from "../project/stepThreeForm/PayMethodSection";

import InvoiceConfigsSection from "./manageAcFormTwo/InvoiceConfigsSection";
import SubAccountSection from "./manageAcFormThree//SubAccountSection";
import {
  CompanyDocument,
  UpdateCompanyDocument,
  UpdateUserDocument,
  UserDocument,
} from "src/graphql/generated";

const ManageAccount = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [isOwner, setIsOwner] = useState(false);
  const [isShowAlertModal, setIsShowAlertModal] = useState(false);
  const [personalInfoData, setPersonalInfoData] = useState<IPersonalInfoData>({
    photo: [],
    userName: "",
    phone: "",
    email: "",
    company: "",
    website: "",
    address: [t("TXT_Hong_Kong"), "", "", ""],
  });
  const [invoiceConfig, setInvoiceConfig] = useState<IInvoiceConfig>({
    name: {
      isShow: false,
      text: "",
    },
    phone: {
      isShow: false,
      text: "",
    },
    email: {
      isShow: false,
      text: "",
    },
    company: {
      isShow: false,
      text: "",
    },
    website: {
      isShow: false,
      text: "",
    },
    address: {
      isShow: false,
      text: "",
    },
    additionalText: {
      isShow: false,
      text: "",
    },
    logo: {
      isShow: false,
      text: "",
    },
    chop: {
      isShow: false,
      text: "",
    },
  });

  const [invoicePaymentData, setInvoicePaymentData] = useState<any>([
    { payMethod: "Bank transfer", accNum: "" },
  ]);

  // const [validToSubmit, setValidToSubmit] = useState(false);

  const totalStep = [
    {
      name: t("TXT_Account_general_detail"),
      desc: t("TXT_Account_Change_profile"),
      icon: require("../../icons/general/profile.png"),
    },
    {
      name: t("TXT_Account_invoice_detail"),
      desc: t("TXT_Account_Manage_invoice_info"),
      icon: require("../../icons/general/invoice.png"),
    },
    {
      name: t("TXT_Account_sub_account"),
      desc: t("TXT_Account_Manage_secondary_user_and_permission"),
      icon: require("../../icons/general/subAccount.png"),
    },
  ];

  const loginData = JSON.parse(localStorage.getItem("meData")!);
  const myCompanyID = loginData?.company.id;

  const [getMyCompany, { data: companyData }] = useLazyQuery(CompanyDocument, {
    variables: {
      id: myCompanyID,
    },
    onCompleted: (data) => {
      const company = data.company;

      setPersonalInfoData({
        photo: company?.profileImage
          ? [
              {
                url: company?.profileImage?.url,
                originalName: company?.profileImage?.originalName,
                filePath: company.profileImage.filePath,
              },
            ]
          : undefined,
        userName: company?.name ?? "",
        phone: company?.phone?.phone ?? "",
        email: company?.email!,
        company: company?.name ?? "",
        website: company?.website ?? "",
        address: [
          t("TXT_Hong_Kong"),
          company?.address?.area ?? "",
          company?.address?.district ?? "",
          company?.address?.line ?? "",
        ],
        companyLogo: company?.companyLogo
          ? [
              {
                url: company?.companyLogo?.url,
                originalName: company?.companyLogo?.originalName,
                filePath: company.companyLogo.filePath,
              },
            ]
          : undefined,
        companyChop: company?.companyChop
          ? [
              {
                url: company?.companyChop?.url,
                originalName: company?.companyChop?.originalName,
                filePath: company.companyChop.filePath,
              },
            ]
          : undefined,
      });
      setInvoiceConfig({
        name: {
          isShow: company?.companyInvoiceDetail?.showName ?? false,
          text: company?.name!,
        },
        phone: {
          isShow: company?.companyInvoiceDetail?.showPhone ?? false,
          text: company?.phone?.phone!,
        },
        email: {
          isShow: company?.companyInvoiceDetail?.showEmail ?? false,
          text: company?.email!,
        },
        company: {
          isShow: company?.companyInvoiceDetail?.showCompany ?? false,
          text: company?.name!,
        },
        website: {
          isShow: company?.companyInvoiceDetail?.showWebsite ?? false,
          text: company?.website ?? "",
        },
        address: {
          isShow: company?.companyInvoiceDetail?.showAddress ?? false,
          text: `${company?.address?.line!} , ${t(
            `TXT_Option_${company?.address?.district!.replaceAll("-", "_")}`
          )} , ${t(`TXT_${company?.address?.area!.replaceAll("-", "_")}`)}`,
        },
        additionalText: {
          isShow: company?.companyInvoiceDetail?.additionalScript
            ? true
            : false,
          text: company?.companyInvoiceDetail?.additionalScript ?? "",
        },
        logo: {
          isShow: company?.companyInvoiceDetail?.showCompanyLogo ?? false,
          text: company?.companyLogo?.url!,
        },
        chop: {
          isShow: company?.companyInvoiceDetail?.showCompanyChop ?? false,
          text: company?.companyChop?.url!,
        },
      });

      setInvoicePaymentData(
        company?.paymentMethods?.map((pm) => {
          return {
            payMethod: pm?.name,
            accNum: pm?.value,
          };
        })
      );
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [getParentCompany, { data: parentCompanyData }] = useLazyQuery(
    CompanyDocument,
    {
      variables: {
        id: myCompanyID,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const company = data.company;

        const parentCompanyData = {
          ...personalInfoData,
          ...{
            address: [
              t("TXT_Hong_Kong"),
              company?.address?.area ?? "",
              company?.address?.district ?? "",
              company?.address?.line ?? "",
            ],
            companyLogo: company?.companyLogo
              ? [
                  {
                    url: company?.companyLogo?.url,
                    originalName: company?.companyLogo?.originalName,
                    filePath: company.companyLogo.filePath,
                  },
                ]
              : undefined,
            companyChop: company?.companyChop
              ? [
                  {
                    url: company?.companyChop?.url,
                    originalName: company?.companyChop?.originalName,
                    filePath: company.companyChop.filePath,
                  },
                ]
              : undefined,
          },
        };

        setPersonalInfoData(parentCompanyData);

        setInvoiceConfig({
          name: {
            isShow: company?.companyInvoiceDetail?.showName ?? false,
            text: company?.name!,
          },
          phone: {
            isShow: company?.companyInvoiceDetail?.showPhone ?? false,
            text: company?.phone?.phone!,
          },
          email: {
            isShow: company?.companyInvoiceDetail?.showEmail ?? false,
            text: company?.email!,
          },
          company: {
            isShow: company?.companyInvoiceDetail?.showCompany ?? false,
            text: company?.name!,
          },
          website: {
            isShow: company?.companyInvoiceDetail?.showWebsite ?? false,
            text: company?.website ?? "",
          },
          address: {
            isShow: company?.companyInvoiceDetail?.showAddress ?? false,
            text: `${company?.address?.line!} , ${t(
              `TXT_Option_${company?.address?.district!.replaceAll("-", "_")}`
            )} , ${t(`TXT_${company?.address?.area!.replaceAll("-", "_")}`)}`,
          },
          additionalText: {
            isShow: company?.companyInvoiceDetail?.additionalScript
              ? true
              : false,
            text: company?.companyInvoiceDetail?.additionalScript ?? "",
          },
          logo: {
            isShow: company?.companyInvoiceDetail?.showCompanyLogo ?? false,
            text: company?.companyLogo?.url!,
          },
          chop: {
            isShow: company?.companyInvoiceDetail?.showCompanyChop ?? false,
            text: company?.companyChop?.url!,
          },
        });

        setInvoicePaymentData(
          company?.paymentMethods?.map((pm) => {
            return {
              payMethod: pm?.name,
              accNum: pm?.value,
            };
          })
        );
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  const [getMyUser, { data: userData }] = useLazyQuery(UserDocument, {
    onCompleted: (data) => {
      setPersonalInfoData({
        photo: data.user?.profileImage
          ? [
              {
                url: data.user?.profileImage?.url,
                originalName: data.user?.profileImage?.originalName,
                filePath: data.user.profileImage.filePath,
              },
            ]
          : undefined,
        userName: data.user?.name ?? "",
        phone: data.user?.phone?.phone ?? "",
        email: data.user?.email!,
        company: "",
        website: data.user?.website ?? "",
        // address: [
        //   t("TXT_Hong_Kong"),
        //   data.user?.address?.area ?? "",
        //   data.user?.address?.district ?? "",
        //   data.user?.address?.line ?? "",
        // ],
      });
      getParentCompany();
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [updateCompanyInfo, { loading: updatingCompany }] = useMutation(
    UpdateCompanyDocument,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        toast({
          position: "bottom-right",
          title: `Updated!`,
          status: "success",
        });
        navigate("/dashboard");
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  const [updateUserInfo, { loading: updatingUser }] = useMutation(
    UpdateUserDocument,

    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        toast({
          position: "bottom-right",
          title: `Updated!`,
          status: "success",
        });
        navigate("/dashboard");
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  // auto fill info data
  useEffect(() => {
    if (loginData.type == "User") {
      getMyUser({
        variables: {
          id: loginData?.user.id,
        },
      });
    } else {
      getMyCompany();
      setIsOwner(true);
    }

    return () => {};
  }, []);

  const updateAccountHandler = () => {
    if (loginData.type == "Company") {
      updateCompanyInfo({
        variables: {
          input: {
            id: myCompanyID,
            profileImage:
              personalInfoData.photo && personalInfoData.photo?.length > 0
                ? {
                    filePath: personalInfoData.photo[0]?.filePath,
                    originalName: personalInfoData.photo[0]?.originalName,
                  }
                : undefined,

            name: personalInfoData.userName,
            address: {
              // area: projectDetailData.address[0]
              //   .toLowerCase()
              //   .replaceAll("_", "-"),
              area: personalInfoData.address?.[1]
                .toLowerCase()
                .replaceAll("_", "-"),
              district: personalInfoData?.address?.[2]
                .toLowerCase()
                .replaceAll("_", "-"),
              line: personalInfoData?.address?.[3] ?? "-",
              // .replaceAll("_", "-"),
            },
            phone: {
              areaCode: "+852",
              phone: personalInfoData.phone ?? "",
            },
            website: personalInfoData.website,
            companyLogo:
              personalInfoData.companyLogo &&
              personalInfoData.companyLogo?.length > 0
                ? {
                    filePath: personalInfoData.companyLogo[0]?.filePath,
                    originalName: personalInfoData.companyLogo[0]?.originalName,
                  }
                : undefined,
            companyChop:
              personalInfoData.companyChop &&
              personalInfoData.companyChop?.length > 0
                ? {
                    filePath: personalInfoData.companyChop[0]?.filePath,
                    originalName: personalInfoData.companyChop[0]?.originalName,
                  }
                : undefined,
            companyInvoiceDetail: {
              companyId: myCompanyID,
              showName: invoiceConfig.name.isShow,
              showPhone: invoiceConfig.phone.isShow,
              showEmail: invoiceConfig.email.isShow,
              showCompany: invoiceConfig.company.isShow,
              showAddress: invoiceConfig.address.isShow,
              showWebsite: invoiceConfig.website.isShow,
              showCompanyLogo: invoiceConfig.logo.isShow,
              showCompanyChop: invoiceConfig.chop.isShow,
              additionalScript: invoiceConfig.additionalText.text,
            },
            paymentMethods: invoicePaymentData?.map((method: any) => {
              return {
                name: method.payMethod,
                value: method.accNum,
              };
            }),
          },
        },
      });
    } else {
      updateUserInfo({
        variables: {
          input: {
            id: loginData.user.id,
            profileImage:
              personalInfoData.photo && personalInfoData.photo?.length > 0
                ? {
                    filePath: personalInfoData.photo[0]?.filePath,
                    originalName: personalInfoData.photo[0]?.originalName,
                  }
                : undefined,

            name: personalInfoData.userName,
            // address: {
            //   // area: projectDetailData.address[0]
            //   //   .toLowerCase()
            //   //   .replaceAll("_", "-"),
            //   area: personalInfoData.address?.[1]
            //     .toLowerCase()
            //     .replaceAll("_", "-"),
            //   district: personalInfoData?.address?.[2]
            //     .toLowerCase()
            //     .replaceAll("_", "-"),
            //   line: personalInfoData?.address?.[3] ?? "-",
            //   // .replaceAll("_", "-"),
            // },
            phone: {
              areaCode: "+852",
              phone: personalInfoData.phone ?? "",
            },
            website: personalInfoData.website,
          },
        },
      });
    }
  };

  const displayTab = () => {
    if (loginData.type == "Company") {
      if (
        loginData.company!.privileges.length === 1 &&
        loginData.company!.privileges[0] == "LAND_OWNER"
      ) {
        return totalStep.slice(0, 1);
      } else {
        return totalStep;
      }
    } else {
      if (userData?.user!.roles[0] == "admin") {
        return totalStep;
      }
      if (userData?.user!.roles[0] == "editor") {
        return totalStep.slice(0, 2);
      }
      if (userData?.user!.roles[0] == "viewer") {
        return totalStep.slice(0, 1);
      }
    }
    return totalStep;
  };

  const currRoleDesc = () => {
    if (loginData.type == "Company") {
      if (
        loginData.company!.privileges.length === 1 &&
        loginData.company!.privileges[0] == "LAND_OWNER"
      ) {
        return {
          role: t("TXT_Account_viewer"),
          desc: t("TXT_Account_Viewer_desc"),
        };
      } else {
        return {
          role: t("TXT_Account_Primary_owner"),
          desc: t("TXT_Account_User_grade_desc"),
        };
      }
    } else {
      if (userData?.user!.roles[0] == "admin") {
        return {
          role: t("TXT_Account_admin"),
          desc: t("TXT_Account_Administrator_desc"),
        };
      }
      if (userData?.user!.roles[0] == "editor") {
        return {
          role: t("TXT_Account_editor"),
          desc: t("TXT_Account_Editor_desc"),
        };
      }
      if (userData?.user!.roles[0] == "viewer") {
        return {
          role: t("TXT_Account_viewer"),
          desc: t("TXT_Account_Viewer_desc"),
        };
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Solar Farm - Manage account</title>
      </Helmet>
      {/* sticky header section */}
      <Flex
        flex={1}
        flexDirection="row"
        backgroundColor="WT"
        alignItems="center"
        justifyContent="center"
        py="10px"
        position="sticky"
        top="0px"
        zIndex={2}
      >
        <Flex position="absolute" left="20px">
          <Flex
            color="BK"
            alignItems="center"
            fontWeight={"bold"}
            onClick={() => setIsShowAlertModal(true)}
            cursor="pointer"
          >
            <FiArrowLeft color="GREY" /> &nbsp;{t("TXT_Manage_account")}
          </Flex>
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <Button
            py="15px"
            px="30px"
            backgroundColor="WT"
            borderWidth="1px"
            borderRadius="40px"
            borderColor={returnByTheme()}
            width="100%"
            onClick={() => setIsShowAlertModal(true)}
          >
            <IconContext.Provider
              value={{ color: returnByColor(), size: "25px" }}
            >
              <Flex color={returnByTheme()}>{t("TXT_Cancel")}</Flex>
            </IconContext.Provider>
          </Button>
          <Button
            py="15px"
            px="30px"
            backgroundColor={returnByTheme()}
            borderWidth="1px"
            borderRadius="40px"
            borderColor="WT"
            width="100%"
            marginLeft="10px"
            onClick={() => updateAccountHandler()}
            // disabled={!ableToSubmit}
          >
            <IconContext.Provider value={{ color: "#FFF", size: "25px" }}>
              <Flex color="WT">{t("TXT_Save_and_close")}</Flex>
            </IconContext.Provider>
          </Button>
        </Flex>
      </Flex>

      {/* form section */}
      <Flex
        backgroundColor={"#e5e5e5"}
        flex={1}
        minHeight="100%"
        justifyContent={"center"}
        px="20px"
        maxW="1920px"
        mx="auto"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"flex-end"}
          pt="15px"
          px={10}
          flex={1}
        >
          {/* step bar */}
          <Flex flexDirection={"column"}>
            <Flex
              p="20px"
              bg="#F7FAFA"
              flexDir="column"
              maxW="440px"
              mb="20px"
              boxShadow="0px 0px 2px 2px rgba(0, 0, 0, 0.05)"
              borderRadius={"4px"}
            >
              <Flex>
                <Text fontWeight="bold">
                  {t("TXT_Account_User_grade")}: &nbsp;
                </Text>
                <Text
                  bg={returnByTheme()}
                  borderRadius="12px"
                  color="WT"
                  px="10px"
                >
                  {currRoleDesc()?.role}
                  {/* {t("TXT_Account_Primary_owner")} */}
                </Text>
              </Flex>
              <Flex mt="10px" color="GREY">
                {currRoleDesc()?.desc}
                {/* {t("TXT_Account_User_grade_desc")} */}
              </Flex>
            </Flex>
            {displayTab().map((s, i) => {
              const isCurrentStep = currentStep === i;
              return (
                <Flex
                  key={`step${i}`}
                  onClick={() => setCurrentStep(i)}
                  bg={isCurrentStep ? "WT" : "none"}
                  borderLeft={isCurrentStep ? "5px solid #004b92" : "none"}
                  //   borderRadius={"8px"}
                  p="20px"
                  alignItems="center"
                  cursor="pointer"
                  boxShadow={
                    isCurrentStep
                      ? "0px 0px 2px 2px rgba(0, 0, 0, 0.05)"
                      : "none"
                  }
                >
                  <Flex mr="20px">
                    <Image src={s.icon} objectFit="contain" />
                  </Flex>
                  <Flex flexDir="column">
                    <Flex fontSize="18px" fontWeight="bold" mb="10px">
                      {s.name}
                    </Flex>
                    <Flex color="GREY">{s.desc}</Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex flex={1} my={"15px"}>
          {currentStep === 0 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <PersonalInfoSection
                personalInfoData={personalInfoData}
                updatePersonalInfoState={(value: any) => {
                  setPersonalInfoData(value);
                }}
                userEmail={
                  loginData.type == "Company"
                    ? loginData?.company.email
                    : loginData.user.email
                }
                isOwner={isOwner}
              />
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Button
                backgroundColor={returnByTheme()}
                color="WT"
                borderRadius="25px"
                px="20px"
                alignSelf={"center"}
                width={"fit-content"}
                _hover={{}}
                isLoading={
                  loginData.type == "Company" ? updatingCompany : updatingUser
                }
                loadingText={t("TXT_Saving_dot")}
                spinnerPlacement="end"
                // disabled={!ableToStep2}
                onClick={() => updateAccountHandler()}
              >
                {t("TXT_Save")}
              </Button>
            </Flex>
          )}
          {currentStep === 1 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <InvoiceConfigsSection
                invoiceConfig={invoiceConfig}
                updateInvoiceConfigState={(value: any) =>
                  setInvoiceConfig(value)
                }
                isOwner={loginData.type == "Company"}
              />

              <PayMethodSection
                invoicePaymentData={invoicePaymentData}
                updateInvocePaymentState={(newState: any) =>
                  setInvoicePaymentData(newState)
                }
              />

              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  isLoading={
                    loginData.type == "Company" ? updatingCompany : updatingUser
                  }
                  loadingText={t("TXT_Saving_dot")}
                  spinnerPlacement="end"
                  //   disabled={!ableToStep3}
                  onClick={() => updateAccountHandler()}
                >
                  {t("TXT_Save")}
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === 2 && (
            <Flex width={"100%"} flexDirection={"column"}>
              <SubAccountSection allowGiveAdmin={loginData.type == "Company"} />
              <Flex height={"1px"} backgroundColor="GREY" my="30px"></Flex>
              <Flex justifyContent={"center"}>
                <Button
                  backgroundColor={returnByTheme()}
                  color="WT"
                  borderRadius="25px"
                  px="20px"
                  alignSelf={"center"}
                  width={"fit-content"}
                  _hover={{}}
                  isLoading={
                    loginData.type == "Company" ? updatingCompany : updatingUser
                  }
                  loadingText={t("TXT_Saving_dot")}
                  spinnerPlacement="end"
                  //   disabled={!ableToStep4}
                  onClick={() => updateAccountHandler()}
                >
                  {t("TXT_Save")}
                </Button>
              </Flex>
            </Flex>
          )}
          {/* leave alert popup */}
          <PopUpModal
            isShow={isShowAlertModal}
            onClose={() => {
              setIsShowAlertModal(false);
            }}
            headerColor="RD.3"
            headerText={t("TXT_Alert")}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <IconContext.Provider value={{ color: "#E4513F", size: "100px" }}>
                <FiAlertTriangle />
              </IconContext.Provider>
              <Text color="#E4513F" fontWeight="700">
                {t("TXT_Hey_wait")}
              </Text>
              <Text color="#000" textAlign="center" fontWeight="600">
                {t("TXT_Leave_popup_desc")}
              </Text>
              <Flex flexDirection="row" width="100%">
                <Button
                  backgroundColor="WT"
                  _hover={{ backgroundColor: "WT" }}
                  onClick={() => setIsShowAlertModal(false)}
                  marginTop="20px"
                  borderRadius="40px"
                  flex={1}
                  borderColor="#000"
                  borderWidth="1px"
                >
                  <Text color="#000">{t("TXT_Stay")}</Text>
                </Button>
                <Button
                  backgroundColor="RD.3"
                  _hover={{ backgroundColor: "RD.3" }}
                  onClick={() => navigate(-1)}
                  marginTop="20px"
                  borderRadius="40px"
                  flex={1}
                  marginLeft="20px"
                >
                  <Text color="WT">{t("TXT_Leave")}</Text>
                </Button>
              </Flex>
            </Flex>
          </PopUpModal>
        </Flex>
      </Flex>
    </>
  );
};

export default ManageAccount;
