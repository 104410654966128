/**
 * All colors can be found in Figma design document
 * and named as `SolarMonster - XX X`
 *
 * e.g. `SolarMonster - GN 1`  will be `GN.1` in Chakra context.
 */

export const colors = {
  WT: "#ffffff",
  BK: "#000000",
  GREY: "#8e8e8e",
  DISABLEDBG: "#F0F0F0",
  DISABLEDTEXT: "#CFCFCF",
  themeBlue: "#004B92",
  themeOrange: "#FCAF20",
  OG: {
    1: "#e38e34",
    "1A15": "#e38e3427",
    2: "#F2A100",
    "2A15": "#f7984627",
    3: "#f6b849",
    "3A15": "#f6b84927",
    4: "#f8d293",
    "4A15": "#f8d29327",
    5: "#FFF9F0",
    "5A15": "#f6dfbd27",
  },
  RD: {
    1: "#b61b43",
    "1A15": "#b61b4327",
    2: "#e7311b",
    "2A15": "#e7311b27",
    3: "#e4513f",
    "3A15": "#e4513f27",
    4: "#f9c194",
    "4A15": "#f9c19427",
    5: "#f8d8bf",
    "5A15": "#f8d8bf27",
  },
  DB: {
    1: "#0f255e",
    "1A15": "#0f255e27",
    2: "#203a97",
    "2A15": "#203a9727",
    3: "#4b6aba",
    "3A15": "#4b6aba27",
    4: "#6b8aca",
    "4A15": "#6b8aca27",
    5: "#a9c1ed",
    "5A15": "#a9c1ed27",
  },
  LB: {
    1: "#103e62",
    "1A15": "#103e6227",
    2: "#3d6481",
    "2A15": "#3d648127",
    3: "#64a2bb",
    "3A15": "#64a2bb27",
    4: "#69B3E3",
    "4A15": "#87ccdc27",
    5: "#b6e1ea",
    "5A15": "#b6e1ea27",
  },
  GN: {
    1: "#1a9990",
    "1A15": "#1a999027",
    2: "#3faca7",
    "2A15": "#3faca727",
    3: "#7ddf92",
    "3A15": "#7ddf9227",
    4: "#b9ea9b",
    "4A15": "#b9ea9b27",
    5: "#d4f3ba",
    "5A15": "#d4f3ba27",
  },
};
