import { Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";

import { useStore } from "./App.store";
import RequireAuth from "./RequireAuth";
 
import Project from "src/pages/project/Project";
import CreateProject from "src/pages/project/CreateProject";
import EditProject from "src/pages/project/EditProject";
import ProjectDetail from "src/pages/project/ProjectDetail";
import Dashboard from "src/pages/dashboard/Dashboard";
import Home from "src/pages/Home";
import LoginPage from "src/pages/auth/LoginPage";
import ManageAccount from "src/pages/setting/ManageAccount";
import SideBar from "src/components/SideBar";
import Invoice from "src/pages/invoice/Invoice";
import Calculator from "src/pages/calculator/ProjectCalculator";
import Notification from "src/pages/notification/Notification";
import SettingPage from "src/pages/setting/SettingPage";

import DevPage from "src/pages/Dev";
import { Flex, Image, useToast, Box } from "@chakra-ui/react";
import InvoiceDetail from "src/pages/invoice/InvoiceDetail";
import InvoiceEdit from "src/pages/invoice/InvoiceEdit";
import InvoiceCreate from "src/pages/invoice/InvoiceCreate";
import { useLazyQuery } from "@apollo/client";
import { CompanyPrivileges, GetmeDocument } from "src/graphql/generated";
import { useEffect } from "react";
import i18next from "i18next";

const SidebarLayout = () => {
  const location = useLocation();

  return (
    <Box>
      {/* <RequireAuth> */}
      <SideBar location={location.pathname} />
      <Outlet />
      {/* <NotificationCenterBtn /> */}
      {/* </RequireAuth> */}
    </Box>
  );
};

const NotificationCenterBtn = () => {
  return (
    <Flex
      position={"absolute"}
      bottom={"10%"}
      right="0"
      zIndex={50}
      cursor="pointer"
      onClick={() => {
        console.log("show jobs");
      }}
    >
      <Image
        src={require("../icons/general/noti.png")}
        width="50px"
        height="50px"
        borderRadius="full"
      />
    </Flex>
  );
};

export const App = () => {
  const toast = useToast();
  const { logout } = useStore();

  // const [getMe, { data: meData }] = useLazyQuery(GetmeDocument, {
  //   onCompleted: (x) => {
  //     const isClient = x.me.privileges!.includes(CompanyPrivileges.LandOwner)
  //       ? "true"
  //       : "false";

  //     localStorage.setItem("sf_Role", isClient);
  //     // localStorage.setItem("sf_Role", "true");
  //   },
  //   onError: (error) => {
  //     return toast({
  //       position: "top",
  //       title: t('TXT_Error_general_err_message'),
  //       status: "error",
  //     });
  //   },
  // });

  useEffect(() => {
    // auto logout if token expired?
    const tokenExist = localStorage.getItem("token");
    const langCode = localStorage.getItem("langCode");
    i18next.changeLanguage(langCode ?? "en");
    if (!tokenExist && window.location.pathname !== "/login") {
      console.log("token expried ,auto logout");
      logout();
    } else {
      // getMe();
    }

    return () => {};
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<LoginPage />} />
      <Route element={<SidebarLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/project" element={<Project />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/project/detail/:projectId" element={<ProjectDetail />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/setting" element={<SettingPage />} />
      </Route>
      <Route path="/invoice/detail/:invoiceId" element={<InvoiceDetail />} />
      <Route path="/invoice/detail/edit/:invoiceId" element={<InvoiceEdit />} />
      <Route path="/invoice/create" element={<InvoiceCreate />} />
      <Route path="/create/project" element={<CreateProject />} />
      <Route path="/edit/project/:projectId" element={<EditProject />} />
      <Route path="/account" element={<ManageAccount />} />

      {process.env.NODE_ENV === "development" && (
        <>
          <Route path="/dev" element={<DevPage />} />
        </>
      )}
    </Routes>
  );
};
function toast(arg0: {
  position: string;
  title: string;
  status: string;
}): void {
  throw new Error("Function not implemented.");
}
