import { Box, Input, Text, Flex } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import TextPreviewRow from "../../../components/createProjectForm/TextPreviewRow";
import FilePreviewRow from "../../../components/createProjectForm/FilePreviewRow";
import CommentsPreviewRow from "../../../components/createProjectForm/CommentsPreviewRow";
import { returnByTheme, returnByColor } from "../../../utils/util";
import { ReactComponent as EditIcon } from "src/icons/general/editIcon.svg";

import { IProjectTaskData } from "../../../type/IBaseInfotInterface";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface IProjectTaskProps {
  projectTaskData: Array<IProjectTaskData>;
  editProjectTaskAction: () => void;
}

const ProjectTasksPreview = ({
  projectTaskData,
  editProjectTaskAction,
}: IProjectTaskProps) => {
  // console.log("project task section preview");
  // console.log(projectTaskData);
  const { t } = useTranslation();

  return (
    <Box mb="30px">
      <SectionTitle
        title={t("TXT_Project_BItitle_project_task")}
        editAction={() => editProjectTaskAction()}
      />
      {projectTaskData &&
        projectTaskData.map((task, i) => {
          const dateText =
            task?.date?.[0]?.endDate !== null &&
            task?.date?.[0]?.startDate !== task?.date?.[0]?.endDate
              ? `${moment(task?.date?.[0]?.startDate).format(
                  "D MMM, YYYY"
                )} - ${moment(task?.date?.[0]?.endDate).format("D MMM, YYYY")}`
              : `${moment(task?.date?.[0]?.startDate).format("D MMM, YYYY")}`;

          // console.log(t?.attachment?.length);
          return (
            <Flex
              key={i}
              backgroundColor={"WT"}
              borderRadius={"5px"}
              boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
              px="20px"
              flexDirection={"column"}
              mb="10px"
            >
              <TextPreviewRow
                label={t("Date")}
                value={dateText}
                hadBorder={false}
              />
              <TextPreviewRow label={t("TXT_Title")} value={task?.title} />
              <TextPreviewRow
                label={t("TXT_Project_priority")}
                value={
                  task?.priority == "high"
                    ? t("TXT_Project_high_priority")
                    : task?.priority == "middle"
                    ? t("TXT_Project_middle_priority")
                    : t("TXT_Project_low_priority")
                }
              />
              <TextPreviewRow label={t("TXT_Description")} value={task?.desc} />
              {task?.attachment && task?.attachment?.length > 0 && (
                <FilePreviewRow
                  label={t("TXT_Project_Attachments")}
                  filesList={task?.attachment}
                />
              )}

              <CommentsPreviewRow
                label={t("TXT_Project_Comments")}
                comments={task?.comment}
              />
            </Flex>
          );
        })}
    </Box>
  );
};

const SectionTitle = ({
  title,
  editAction,
}: {
  title: string;
  editAction: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb="5px">
      <Flex fontSize={18} fontWeight={"bold"}>
        {title}
      </Flex>
      <Flex
        cursor={"pointer"}
        borderRadius="4px"
        borderWidth="1px"
        borderColor={returnByTheme()}
        bg="WT"
        color={returnByTheme()}
        alignItems={"center"}
        px="20px"
        py="2px"
        onClick={() => editAction()}
      >
        <EditIcon stroke={returnByColor()} />
        <Box ml="5px">{t("TXT_Edit")}</Box>
      </Flex>
    </Flex>
  );
};

export default ProjectTasksPreview;
