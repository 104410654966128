import { Box, Input, Text, Flex, Image } from "@chakra-ui/react";
import moment from "moment";
import { returnByTheme } from "../../utils/util";
import { ReactComponent as PDFIcon } from "src/icons/general/pdfIcon.svg";
import { useTranslation } from "react-i18next";
// import { IProjectDetailData } from "../../../type/IBaseInfotInterface";

interface ICommentsPreviewRowProps {
  label: string;
  comments?: {
    message?: string;
    commentDate?: any;
    user?: string;
    commentAttachment?:
      | {
          filePath: string;
          originalName: string;
          url: string;
        }[]
      | [];
  }[];
  hadBorder?: boolean;
}

const CommentsPreviewRow = ({
  label,
  comments,
  hadBorder = true,
}: ICommentsPreviewRowProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      borderTop={hadBorder ? "1px" : "0"}
      borderColor="DISABLEDTEXT"
      py={"20px"}
    >
      <Flex flex={2} color="GREY">
        {label}
      </Flex>
      <Flex flex={3} flexDirection={"column"}>
        {comments &&
          comments?.length > 0 &&
          comments.map((c, i) => {
            const dateText = moment(c?.commentDate).format("D MM, YYYY");

            return (
              <Flex
                justifyContent={"space-between"}
                mb="5px"
                key={i}
                borderBottom="1px solid #F0F0F0"
              >
                {/* <Flex alignItems={"flex-end"}> */}
                <Box display={"inline"}>
                  {c?.message ? (
                    c?.message
                  ) : (
                    <Text color="DISABLEDTEXT">
                      {c?.user} {t("TXT_Project_Deleted_comment_desc")}
                    </Text>
                  )}

                  {c?.commentAttachment?.map((a: any, attachmentIndex) => {
                    // const imageUrl = URL.createObjectURL(a?.file);
                    const extensionName = a?.originalName.split(".").pop();
                    return (
                      <Flex
                        key={attachmentIndex}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        borderBottomColor={"DISABLEDBG"}
                        py="10px"
                      >
                        <Flex mr={"10px"}>
                          {(extensionName !== "pdf" && (
                            <Image
                              width={"30px"}
                              height={"30px"}
                              src={a.url}
                              borderRadius={"5px"}
                              fit={"cover"}
                              loading={"lazy"}
                            />
                          )) || <PDFIcon />}

                          <Flex alignItems={"center"} ml="5px">
                            <Text
                              ml="5px"
                              noOfLines={1}
                              textOverflow="ellipsis"
                              maxW="250px"
                            >
                              {a?.originalName}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    );
                  })}
                  {/* <Box
                    fontSize={"12px"}
                    color={returnByTheme()}
                    ml="10px"
                    display={"inline"}
                  >
                    {dateText} -- {c?.user}
                  </Box> */}
                </Box>
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};

export default CommentsPreviewRow;
