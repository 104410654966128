import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Tabs,
  TabList,
  Tab,
  InputGroup,
  Input,
  useToast,
  useOutsideClick,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiBell, FiCalendar, FiSearch } from "react-icons/fi";
import {
  convertAsCurrPjStatus,
  convertDisplayText,
  getCurrProjectStatus,
  returnByTheme,
} from "../../utils/util";
import {
  SearchIcon,
  ChevronDownIcon,
  AddIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import FilterTag from "../../components/FilterTag";
import DateRangePicker from "../../components/DateRangePicker";
import CopyRightBar from "src/components/CopyRightBar";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  SitesDocument,
  PaginateSiteArgsSort,
  PaginateSiteArgsFilter,
  SiteStatus,
  ElectricCompanyInfoCompany,
  ListSiteRegionsDocument,
  ListSiteTypesDocument,
  RemoveSiteDocument,
  CreateCommentDocument,
} from "../../graphql/generated";
import { useStore } from "src/app/App.store";
import {
  ISelectFilterOption,
  IAppliedFilterOption,
} from "../../type/IFilterOptInterface";
import { Helmet } from "react-helmet-async";
import "../../components/css/dateRangePicker.scss";

import { ReactComponent as GridIcon } from "src/icons/general/gridIcon.svg";
import { ReactComponent as ListIcon } from "src/icons/general/listIcon.svg";
import { ReactComponent as FilterIcon } from "src/icons/general/filterIcon.svg";
import { ReactComponent as DarkCloseBtn } from "src/icons/general/darkCloseBtn.svg";
import { ReactComponent as BackTopBtn } from "src/icons/general/arrow-up-circle.svg";
import { ReactComponent as WhiteCloseBtn } from "src/icons/general/whiteCloseBtn.svg";
import { ReactComponent as NoResultIcon } from "src/icons/general/noResultIcon.svg";
import FilterSelectOptions from "src/components/FilterSelectOptions";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ProjectCard from "./ProjectCard";
import Masonry from "react-masonry-css";
import "./masonry.css";
import RightCornerTool from "src/components/RightCornerTool";

interface IProjectListQueryPayload {
  variables?: {
    filter?: PaginateSiteArgsFilter;
    sort?: PaginateSiteArgsSort;
    limit?: number;
    offset?: number;
  };
}

const Project = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    filterType,
    prevSelectedOpt,
    prevPayload,
    prevSearchType,
    prevKeyword,
    prevDateRange,
  } = (location?.state as any) ?? {};
  const isClient = localStorage.getItem("sf_Role") == "true";
  // const { justForTest, fun } = useStore();
  const { t } = useTranslation();
  const toast = useToast();

  const [filterQs, setFilterQs] = useState("");
  const [currentTab, setCurrentTab] = useState("All");
  const [sitesInfo, setSitesInfo] = useState<any>();
  const [gridMode, setGridMode] = useState(true);
  const [searchType, setSearchType] = useState({
    value: "siteCode",
    name: "TXT_Project_search_by_code",
  });
  const [search, setSearch] = useState("");
  const [showSearchTypeOpt, setShowSearchTypeOpt] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState({ isShow: false, type: "" });
  const [currDisplayTotal, setCurrDisplayTotal] = useState(10);

  const districtFilterRef = useRef(null);
  const typeOfSiteFilterRef = useRef(null);
  const projectStatusFilterRef = useRef(null);
  const eleCompanyFilterRef = useRef(null);
  const pmFilterRef = useRef(null);
  const dateRangePickerRef = useRef(null);
  const stRef = useRef(null);
  const scollToRef = useRef<any>(null);
  const cardContainerRef = useRef<any>(null);

  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useOutsideClick({
    ref: stRef,
    handler: () => setShowSearchTypeOpt(false),
  });

  const [appliedFilterOpt, setAppliedFilterOpt] =
    useState<IAppliedFilterOption>({
      district: [],
      typeOfSite: [],
      projectStatus: [],
      eleCompany: [],
      projectManager: [],
    });

  const [filterOption, setFilterOption] = useState<ISelectFilterOption>();

  // for call sites api to use
  const [queryPayload, setQueryPayload] = useState<IProjectListQueryPayload>();

  const statusTabs = [
    "All",
    "Application",
    "Installation",
    "Listed",
    "Contract end",
  ];

  const searchTypeList = [
    {
      value: "siteCode",
      name: t("TXT_Project_search_by_code"),
    },
    {
      value: "siteName",
      name: t("TXT_Project_search_by_name"),
    },
    {
      value: "taskName",
      name: t("TXT_Project_search_by_task"),
    },
  ];

  const showDateRangeTag =
    dateRange?.[0]?.startDate !== null && dateRange?.[0]?.endDate !== null;

  const genFilterQs = () => {
    let finalQ = "?";

    queryPayload?.variables?.filter &&
      Object.entries(queryPayload.variables.filter).map((obj) => {
        if (Array.isArray(obj[1])) {
          let multiParams = "";
          obj[1]?.map((v) => {
            multiParams += `${v}+`;
          });
          finalQ += `${obj[0]}=${multiParams.slice(0, -1)}&`;
        } else {
          finalQ += `${obj[0]}=${obj[1]}&`;
        }
      });

    setFilterQs(finalQ.slice(0, -1));
  };

  const convertFilterParams = (props: IAppliedFilterOption) => {
    let payload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
        },
        limit: currDisplayTotal,
      },
    };

    //district
    if (props.district?.length > 0) {
      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...payload?.variables?.filter,
            districts: props.district.map((region) => {
              return region.toLowerCase().replaceAll("_", "-");
            }),
          },
        },
      };
    }

    //type of site
    if (props.typeOfSite?.length > 0) {
      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...payload?.variables?.filter,
            siteTypes: props.typeOfSite.map((type) => {
              return type.toLowerCase().replaceAll("_", "-");
            }),
          },
        },
      };
    }

    // project status
    if (props.projectStatus?.length > 0) {
      const statusArrInEnum = props.projectStatus.map((e) => {
        return SiteStatus[e as keyof typeof SiteStatus];
      });
      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...payload?.variables?.filter,
            statuses: statusArrInEnum,
          },
        },
      };
    }

    // ele company
    if (props.eleCompany?.length > 0) {
      const eleCompanyArrInEnum = props.eleCompany.map((e) => {
        return ElectricCompanyInfoCompany[
          e as keyof typeof ElectricCompanyInfoCompany
        ];
      });

      payload = {
        ...payload,
        variables: {
          ...payload?.variables,
          filter: {
            ...payload?.variables?.filter,
            electricCompanies: eleCompanyArrInEnum,
          },
        },
      };
    }

    setAppliedFilterOpt({ ...props });
    setQueryPayload(payload);
    getAllSitesInfo(payload);
  };

  // get site type in once
  const { data: allSiteType } = useQuery(ListSiteTypesDocument, {
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  // get all site list when call
  const [getAllSitesInfo, { loading }] = useLazyQuery(SitesDocument, {
    fetchPolicy: "network-only",
    onCompleted: (pj) => {
      setSitesInfo(pj);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [getAllSitesForCount, { data: allSites }] = useLazyQuery(
    SitesDocument,
    {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );
  const [getApplicationSitesForCount, { data: allApplicationSites }] =
    useLazyQuery(SitesDocument, {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });
  const [getInstallationSitesForCount, { data: allInstallationSites }] =
    useLazyQuery(SitesDocument, {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });
  const [getListedSitesForCount, { data: allListedSites }] = useLazyQuery(
    SitesDocument,
    {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );
  const [getContractEndSitesForCount, { data: allContractEndSites }] =
    useLazyQuery(SitesDocument, {
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });

  const [addComment, { error }] = useMutation(CreateCommentDocument, {
    onCompleted: (x) => {
      console.log("comment added");
      toast({
        position: "bottom-right",
        title: `One comment added`,
        status: "success",
      });
      getAllSitesInfo();
    },
    onError: (error) => {
      //navigate(`/invoice`);
      return toast({
        position: "bottom-right",
        title: `Update Error: ${error}`,
        status: "error",
      });
    },
  });

  useEffect(() => {
    getAllSitesForCount({
      variables: {},
    });
    getApplicationSitesForCount({
      variables: {
        filter: {
          statuses: [SiteStatus.Started, SiteStatus.ApprovalLetterSent],
        },
      },
    });
    getInstallationSitesForCount({
      variables: {
        filter: {
          statuses: [
            SiteStatus.Acknowledged,
            SiteStatus.FitMeterInstalled,
            SiteStatus.HardwareInstalled,
            SiteStatus.SystemTested,
          ],
        },
      },
    });
    getListedSitesForCount({
      variables: {
        filter: {
          statuses: [SiteStatus.Completed],
        },
      },
    });
    getContractEndSitesForCount({
      variables: {
        filter: {
          statuses: [SiteStatus.ContractEnded],
        },
      },
    });

    return () => {};
  }, []);

  // remove site
  const [removeSiteAction, { data: removeSiteStatus }] = useMutation(
    RemoveSiteDocument,
    {
      onCompleted: (x) => {
        // getAllSitesInfo();

        toast({
          position: "bottom-right",
          title: `Removed project!`,
          status: "success",
        });
        window.location.reload();
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  const queryMore = () => {
    if (sitesInfo?.sites.pageInfo.hasNextPage) {
      let payload = {
        ...queryPayload,
        variables: {
          ...queryPayload?.variables,
          filter: {
            ...queryPayload?.variables?.filter,
          },
          limit: currDisplayTotal + 10,
        },
      };
      setCurrDisplayTotal(currDisplayTotal + 10);
      setQueryPayload(payload);

      getAllSitesInfo(payload);
    }
  };

  // date filter apply action
  const triggerDateFilter = (newRange: any) => {
    // if (!newRange.startDate) return;
    const startParams = moment(newRange?.[0]?.startDate).format("YYYY-MM-D");
    const endParams = moment(newRange?.[0]?.endDate).format("YYYY-MM-D");

    const newQueryPayload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
          createdAtFrom: startParams,
          createdAtTo: startParams === endParams ? null : endParams,
        },
      },
    };

    setQueryPayload(newQueryPayload);
    setDateRange(newRange);
    getAllSitesInfo(newQueryPayload);
    setIsShowDatePicker(false);
    // console.log(newQueryPayload);
  };

  const onKeywordSearch = () => {
    let removeAllSearchObj = _.omit(queryPayload?.variables?.filter, [
      "siteCode",
      "siteName",
      "taskName",
    ]);

    let payload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...removeAllSearchObj,
          [searchType.value]: search,
        },
      },
    };

    setQueryPayload(payload);
    getAllSitesInfo(payload);
  };

  // open filter options list
  const toggleFilter = (type: string) => {
    setIsShowFilter({ isShow: !isShowFilter.isShow, type: type });
    // set filter option
    let option: { value: string; name: string }[] = [];
    switch (type) {
      case "district":
        option = [{ value: "fake", name: "fake" }];
        setFilterOption({ type: type, list: option });
        break;
      case "typeOfSite":
        option = allSiteType
          ? allSiteType?.listSiteTypes.siteTypes.map((type) => {
              return { value: type.name, name: type.name };
            })
          : option;
        setFilterOption({ type: type, list: option });
        break;
      case "projectStatus":
        option = [
          { value: "Started", name: t("TXT_PjOption_Started") },
          {
            value: "ApprovalLetterSent",
            name: t("TXT_PjOption_ApprovalLetterSent"),
          },
          { value: "Acknowledged", name: t("TXT_PjOption_Acknowledged") },
          {
            value: "HardwareInstalled",
            name: t("TXT_PjOption_HardwareInstalled"),
          },
          { value: "SystemTested", name: t("TXT_PjOption_SystemTested") },
          {
            value: "FitMeterInstalled",
            name: t("TXT_PjOption_FitMeterInstalled"),
          },
          { value: "Completed", name: t("TXT_PjOption_Completed") },
          { value: "ContractEnded", name: t("TXT_PjOption_ContractEnded") },
        ];

        setFilterOption({ type: type, list: option });
        break;
      case "eleCompany":
        option = [
          { value: "Clp", name: t("TXT_Option_Clp") },
          { value: "Hke", name: t("TXT_Option_Hke") },
        ];
        setFilterOption({ type: type, list: option });
        break;
      case "projectManager":
        option = [
          { value: "pm1", name: "pm1" },
          { value: "pm2", name: "pm2" },
        ];
        setFilterOption({ type: type, list: option });
        break;

      default:
        break;
    }
  };

  const backToTop = () => {
    // console.log(scollToRef?.current);
    scollToRef?.current?.scrollIntoView({
      // top: 0,
      behavior: "smooth",
    });
  };

  // tag component for selected options
  const SelectedTag = ({ tagText, onClose }: any) => {
    return (
      <Flex
        backgroundColor={"DISABLEDTEXT"}
        mr="5px"
        px="10px"
        py="5px"
        borderRadius={8}
        alignItems="center"
      >
        <Text> {tagText}</Text>
        <Flex onClick={() => onClose()} _hover={{ opacity: 0.7 }}>
          <DarkCloseBtn />
        </Flex>
      </Flex>
    );
  };

  // refetch when change tab
  useEffect(() => {
    let payload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: {
          ...queryPayload?.variables?.filter,
        },
        limit: currDisplayTotal,
      },
    };

    if (currentTab !== "All") {
      let arrOfStatus: SiteStatus[] = [];

      switch (currentTab) {
        case "Application":
          arrOfStatus = [SiteStatus.Started, SiteStatus.ApprovalLetterSent];
          break;
        case "Installation":
          arrOfStatus = [
            SiteStatus.Acknowledged,
            SiteStatus.FitMeterInstalled,
            SiteStatus.HardwareInstalled,
            SiteStatus.SystemTested,
          ];
          break;
        case "Listed":
          arrOfStatus = [SiteStatus.Completed];
          break;
        case "Contract end":
          arrOfStatus = [SiteStatus.ContractEnded];
          break;

        default:
          break;
      }

      payload = {
        ...queryPayload,
        variables: {
          ...queryPayload?.variables,
          filter: {
            ...queryPayload?.variables?.filter,
            statuses: arrOfStatus,
          },
          limit: currDisplayTotal,
        },
      };
    }

    getAllSitesInfo(payload);

    return () => {};
  }, [currentTab]);

  // // update count total
  // useEffect(() => {
  //   getAllSiteForCount({
  //     ...queryPayload,
  //     variables: {
  //       ...queryPayload?.variables,
  //       filter: {
  //         ...queryPayload?.variables?.filter,
  //       },
  //       limit: currDisplayTotal,
  //     },
  //   });

  //   return () => {};
  // }, [currDisplayTotal]);

  //  should return arr of total
  // const totalCountForEachTab = statusTabs.map((tab, i) => {
  //   switch (tab) {
  //     case "All":
  //       return sitesInfo?.sites?.nodes.length;
  //     case "Application":
  //       const applicationLength = sitesInfo?.sites.nodes.filter((s: any) => {
  //         const projectStatusArr = getCurrProjectStatus(s);
  //         const pjStatus = projectStatusArr[0]?.status;

  //         return pjStatus == "startedAt" || pjStatus == "approvalLetterSentAt";
  //       });

  //       return applicationLength ? applicationLength.length : 0;
  //     case "Installation":
  //       const installationLength = sitesInfo?.sites.nodes.filter((s: any) => {
  //         const projectStatusArr = getCurrProjectStatus(s);
  //         const pjStatus = projectStatusArr[0]?.status;
  //         const range = [
  //           "acknowledgedAt",
  //           "hardwareInstalledAt",
  //           "systemTestedAt",
  //           "fitMeterInstalledAt",
  //         ];
  //         return range.includes(pjStatus);
  //       });
  //       return installationLength ? installationLength.length : 0;
  //     case "Listed":
  //       const listedLength = sitesInfo?.sites.nodes.filter((s: any) => {
  //         const projectStatusArr = getCurrProjectStatus(s);
  //         const pjStatus = projectStatusArr[0]?.status;

  //         return pjStatus == "completedAt";
  //       });
  //       return listedLength ? listedLength.length : 0;
  //     case "Contract end":
  //       const contractEndLength = sitesInfo?.sites.nodes.filter((s: any) => {
  //         const projectStatusArr = getCurrProjectStatus(s);
  //         const pjStatus = projectStatusArr[0]?.status;

  //         return pjStatus == "contractEndedAt";
  //       });
  //       return contractEndLength ? contractEndLength.length : 0;

  //     default:
  //       return 0;
  //   }
  // });

  const resetOptionFilter = (key: string) => {
    switch (key) {
      case "districts":
        setAppliedFilterOpt({ ...appliedFilterOpt, district: [] });
        break;
      case "siteTypes":
        setAppliedFilterOpt({ ...appliedFilterOpt, typeOfSite: [] });
        break;
      case "statuses":
        setAppliedFilterOpt({ ...appliedFilterOpt, projectStatus: [] });
        break;
      case "electricCompanies":
        setAppliedFilterOpt({ ...appliedFilterOpt, eleCompany: [] });
        break;
      case "all":
        setAppliedFilterOpt({
          district: [],
          typeOfSite: [],
          projectStatus: [],
          eleCompany: [],
        });
        break;
      default:
        break;
    }

    const newPayload = _.omit(queryPayload?.variables?.filter, key);
    const finalPayload = {
      variables: {
        filter: newPayload,
      },
    };

    setQueryPayload(finalPayload);
    getAllSitesInfo(finalPayload);
  };

  const resetDateRangeFilter = () => {
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
    const omitedPayload = _.omit(queryPayload?.variables?.filter, [
      "createdAtTo",
      "createdAtFrom",
    ]);
    const resetDatePayload = {
      ...queryPayload,
      variables: {
        ...queryPayload?.variables,
        filter: omitedPayload,
        limit: currDisplayTotal,
      },
    };
    setQueryPayload(resetDatePayload);
    getAllSitesInfo(resetDatePayload);
  };

  // auto click tab from dashboard
  useEffect(() => {
    if (filterType) {
      setCurrentTab(filterType);
    }
    return () => {};
  }, [filterType]);

  // store selected option as query string
  // useEffect(() => {
  //   genFilterQs();

  //   return () => {};
  // }, [sitesInfo]);

  useEffect(() => {
    if (prevSelectedOpt) setAppliedFilterOpt(prevSelectedOpt);
    if (prevPayload) {
      setQueryPayload(prevPayload);
      getAllSitesInfo(prevPayload);
    }
    if (prevSearchType) setSearchType(prevSearchType);
    if (prevKeyword) setSearch(prevKeyword);
    if (prevDateRange) setDateRange(prevDateRange);
    // if (
    //   prevSelectedOpt ||
    //   prevPayload ||
    //   prevSearchType ||
    //   prevKeyword ||
    //   prevDateRange
    // ) {
    //   // // convert as payload and call api here
    //   // const arrRange = [
    //   //   "districts",
    //   //   "siteTypes",
    //   //   "statuses",
    //   //   "electricCompanies",
    //   // ];
    //   // let newPayloadObj = {};
    //   // const paramsObj = Object.fromEntries(new URLSearchParams(prevQ));
    //   // console.log(paramsObj);
    //   // Object.entries(paramsObj).map((obj) => {
    //   //   const key = obj[0];
    //   //   const valueAsArr = obj[1].split(" ");

    //   //   newPayloadObj = {
    //   //     ...newPayloadObj,
    //   //     [key]: arrRange.includes(key) ? valueAsArr : valueAsArr[0],
    //   //   };
    //   // });

    //   setSearchType(prevSearchType);
    //   setSearch(prevKeyword);
    //   setDateRange(prevDateRange);

    //   setQueryPayload(prevPayload);
    //   getAllSitesInfo(prevPayload);
    // }

    return () => {};
  }, []);

  // console.log(`appliedFilterOpt`);
  // console.log(appliedFilterOpt);
  // console.log(`dateRange`);
  // console.log(dateRange);
  // console.log(`sitesInfo`);
  // console.log(sitesInfo?.sites);
  // console.log(`queryPayload`);
  // console.log(queryPayload);

  return (
    <>
      <Helmet>
        <title>Solar Monster - Projects</title>
      </Helmet>

      {/* header section */}
      <Flex
        flexDirection="column"
        flex={1}
        h="100vh"
        backgroundColor="#F7FAFA"
        ml="65px"
        overflowX="auto"
      >
        <Flex
          backgroundColor="WT"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          px="20px"
          minH="60px"
        >
          <Flex>
            <Text fontWeight="bold">{t("TXT_Projects")}</Text>
          </Flex>
          <Tabs variant="unstyled" mx="20px">
            <TabList>
              {statusTabs.map((x, index) => {
                let displayCount = 0;
                switch (x) {
                  case "All":
                    displayCount = allSites?.sites.totalCount ?? 0;
                    break;
                  case "Application":
                    displayCount = allApplicationSites?.sites.totalCount ?? 0;
                    break;
                  case "Installation":
                    displayCount = allInstallationSites?.sites.totalCount ?? 0;
                    break;
                  case "Listed":
                    displayCount = allListedSites?.sites.totalCount ?? 0;
                    break;
                  case "Contract End":
                    displayCount = allContractEndSites?.sites.totalCount ?? 0;
                    break;

                  default:
                    break;
                }
                return (
                  <Tab
                    padding={4}
                    onClick={() => setCurrentTab(x)}
                    borderBottomWidth={currentTab === x ? "3px" : "0"}
                    color={currentTab === x ? returnByTheme() : "GREY"}
                    borderColor={returnByTheme()}
                    key={index}
                    // justifyContent={"space-between"}
                  >
                    <Text mr={3}>
                      {t(`TXT_Project_${x.replaceAll(" ", "_")}`)}
                    </Text>
                    {/* {(loading && (
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color={isClient ? "themeOrange" : "blue.500"}
                        size="sm"
                      />
                    )) || ( */}
                    <Text
                      color={"WT"}
                      px={3}
                      marginLeft={3}
                      backgroundColor={
                        currentTab === x ? returnByTheme() : "GREY"
                      }
                      borderRadius={25}
                    >
                      {displayCount}
                      {/* {totalCountForEachTab[index]} */}
                    </Text>
                    {/* )} */}
                  </Tab>
                );
              })}
            </TabList>
          </Tabs>

          <RightCornerTool />
        </Flex>

        <Flex
          flexDirection="column"
          marginTop={"25px"}
          maxW="1920px"
          mx="auto"
          width="100%"
          px="20px"
        >
          {/* search bar row */}
          <Flex
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems={"center"}

            //pt="25px"
          >
            <InputGroup
              alignItems="center"
              flex={1}
              maxW="640px"
              mr="20px"
              position="relative"
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                position="absolute"
                left="15px"
                zIndex="5"
                color={returnByTheme()}
                onClick={() => setShowSearchTypeOpt(true)}
                cursor="pointer"
              >
                <Text>{t(searchType.name)}</Text>
                <ChevronDownIcon w="30px" h="30px" />
                <Flex
                  display={showSearchTypeOpt ? "flex" : "none"}
                  flexDirection="column"
                  backgroundColor={"WT"}
                  position="absolute"
                  top="40px"
                  left="-10px"
                  zIndex={5}
                  borderWidth={1}
                  borderColor="GREY"
                  borderTopRadius={10}
                  minW="180px"
                >
                  {searchTypeList.map((t, i) => {
                    const isSelected = searchType.name == t.name;
                    return (
                      <Flex flexDirection="column" ref={stRef} key={i}>
                        <Flex
                          p="10px"
                          alignItems="center"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSearchType(t);
                            setShowSearchTypeOpt(false);
                          }}
                          cursor="pointer"
                          borderTopRadius={10}
                          _hover={{ bg: "DISABLEDBG" }}
                        >
                          <Box mr="10px">{t.name}</Box>
                          {isSelected && (
                            <CheckIcon color={returnByTheme()} w={3} h={4} />
                          )}
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>

              <Input
                // flex={4}
                pl={"170px"}
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
                height="40px"
                fontSize="14px"
                borderRadius={"40px"}
                borderColor={returnByTheme()}
                borderWidth="1px"
                bgColor={"WT"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onKeywordSearch();
                  }
                }}
                //_focus={{ backgroundColor: "#0000" }}
                // pl="7"
                placeholder={t("TXT_Project_Search_keyword")}
              />
              <Flex
                zIndex={1}
                alignItems="center"
                justifyContent="center"
                w="34px"
                h="34px"
                borderRadius="18px"
                backgroundColor={returnByTheme()}
                position="absolute"
                right="3px"
                top="3px"
                onClick={() => onKeywordSearch()}
                _hover={{ opacity: 0.7 }}
              >
                <Icon as={FiSearch} w="22px" h="22px" color="WT" />
                {/* <SearchIcon w="25px" h="25px" color="#FFF" /> */}
              </Flex>
            </InputGroup>
            {!isClient && (
              <Flex
                flex={1}
                maxWidth="200px"
                h="40px"
                p="10px"
                onClick={() => {
                  navigate(`/create/project`);
                }}
                flexDirection={"row"}
                color="WT"
                alignItems="center"
                justifyContent="center"
                borderRadius="18px"
                backgroundColor={returnByTheme()}
                _hover={{ opacity: 0.7, cursor: "pointer" }}
              >
                <AddIcon />
                <Text marginLeft={"5px"}>{t("TXT_Project_Add_New_Pj")}</Text>
              </Flex>
            )}
          </Flex>
          {/* filter bar row */}
          <Flex
            flex={1}
            mt={"25px"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Flex alignItems="center">
              {/* <Flex mr="10px">
                <FilterIcon width="50px" height="50px" />
              </Flex> */}

              {/* filter section start */}
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                // alignItems="center"
              >
                <Flex position="relative">
                  <FilterTag onClick={() => toggleFilter("district")}>
                    <Flex flexDirection="row" alignItems="center">
                      <Text>{t("TXT_Project_District")}</Text>
                      <ChevronDownIcon w="30px" h="30px" />
                    </Flex>
                  </FilterTag>
                  {isShowFilter.isShow && isShowFilter.type == "district" && (
                    <FilterSelectOptions
                      filterListRef={districtFilterRef}
                      applyAction={(props: IAppliedFilterOption) => {
                        convertFilterParams(props);
                        setIsShowFilter({
                          isShow: false,
                          type: "district",
                        });
                      }}
                      onClose={() =>
                        setIsShowFilter({
                          isShow: false,
                          type: "district",
                        })
                      }
                      options={filterOption}
                      appliedFilterOpt={appliedFilterOpt}
                    />
                  )}
                </Flex>

                <Flex position="relative">
                  <FilterTag onClick={() => toggleFilter("typeOfSite")}>
                    <Flex flexDirection="row" alignItems="center">
                      <Text>{t("TXT_Project_Type_of_site")}</Text>
                      <ChevronDownIcon w="30px" h="30px" />
                    </Flex>
                  </FilterTag>
                  {isShowFilter.isShow && isShowFilter.type == "typeOfSite" && (
                    <FilterSelectOptions
                      filterListRef={typeOfSiteFilterRef}
                      applyAction={(props: IAppliedFilterOption) => {
                        convertFilterParams(props);
                        setIsShowFilter({
                          isShow: false,
                          type: "typeOfSite",
                        });
                      }}
                      onClose={() =>
                        setIsShowFilter({
                          isShow: false,
                          type: "typeOfSite",
                        })
                      }
                      options={filterOption}
                      appliedFilterOpt={appliedFilterOpt}
                    />
                  )}
                </Flex>

                <Flex position="relative">
                  <FilterTag onClick={() => toggleFilter("projectStatus")}>
                    <Flex flexDirection="row" alignItems="center">
                      <Text>{t("TXT_Project_Project_status")}</Text>
                      <ChevronDownIcon w="30px" h="30px" />
                    </Flex>
                  </FilterTag>
                  {isShowFilter.isShow &&
                    isShowFilter.type == "projectStatus" && (
                      <FilterSelectOptions
                        filterListRef={projectStatusFilterRef}
                        applyAction={(props: IAppliedFilterOption) => {
                          convertFilterParams(props);
                          setIsShowFilter({
                            isShow: false,
                            type: "projectStatus",
                          });
                        }}
                        onClose={() =>
                          setIsShowFilter({
                            isShow: false,
                            type: "projectStatus",
                          })
                        }
                        options={filterOption}
                        appliedFilterOpt={appliedFilterOpt}
                      />
                    )}
                </Flex>

                <Flex position="relative">
                  <FilterTag onClick={() => toggleFilter("eleCompany")}>
                    <Flex flexDirection="row" alignItems="center">
                      <Text>{t("TXT_Project_Electricity_company")}</Text>
                      <ChevronDownIcon w="30px" h="30px" />
                    </Flex>
                  </FilterTag>
                  {isShowFilter.isShow && isShowFilter.type == "eleCompany" && (
                    <FilterSelectOptions
                      filterListRef={eleCompanyFilterRef}
                      applyAction={(props: IAppliedFilterOption) => {
                        convertFilterParams(props);
                        setIsShowFilter({
                          isShow: false,
                          type: "eleCompany",
                        });
                      }}
                      onClose={() =>
                        setIsShowFilter({
                          isShow: false,
                          type: "eleCompany",
                        })
                      }
                      options={filterOption}
                      appliedFilterOpt={appliedFilterOpt}
                    />
                  )}
                </Flex>

                <Flex position="relative">
                  <FilterTag onClick={() => setIsShowDatePicker(true)}>
                    <Flex flexDirection="row" alignItems="center">
                      <Text paddingRight="5px">
                        {t("TXT_Project_Create_date")}
                      </Text>
                      <FiCalendar color={returnByTheme()} />
                    </Flex>
                  </FilterTag>
                  {isShowDatePicker && (
                    <DateRangePicker
                      dateRangePickerRef={dateRangePickerRef}
                      onClose={() => setIsShowDatePicker(false)}
                      applyDateFilter={(newRange: any) => {
                        triggerDateFilter(newRange);
                      }}
                      dateRange={dateRange}
                      // setDateRange={setDateRange}
                    />
                  )}
                </Flex>

                {/* <Button
                  onClick={() => {
                    justForTest("gg");
                  }}
                >
                  {fun}
                </Button> */}

                {/* <Flex position="relative">
                  <FilterTag onClick={() => toggleFilter("projectManager")}>
                    <Flex flexDirection="row" alignItems="center">
                      <Text>{t("TXT_Project_Project_manager")}</Text>
                      <ChevronDownIcon w="30px" h="30px" />
                    </Flex>
                  </FilterTag>
                  {isShowFilter.isShow &&
                    isShowFilter.type == "projectManager" && (
                      <FilterSelectOptions
                        filterListRef={pmFilterRef}
                        applyAction={(props: IAppliedFilterOption) => {
                          convertFilterParams(props);
                          setIsShowFilter({
                            isShow: false,
                            type: "projectManager",
                          });
                        }}
                        onClose={() =>
                          setIsShowFilter({
                            isShow: false,
                            type: "projectManager",
                          })
                        }
                        options={filterOption}
                        appliedFilterOpt={appliedFilterOpt}
                      />
                    )}
                </Flex> */}

                {/* filter section end */}
              </Flex>
            </Flex>
            <Flex>
              <Flex
                w="50px"
                h="50px"
                mr="10px"
                onClick={() => {
                  setGridMode(true);
                }}
                alignItems="center"
                justifyContent="center"
                borderRadius="25px"
                backgroundColor={gridMode ? returnByTheme() : "DISABLEDBG"}
                // _hover={{ opacity: 0.7 }}
                cursor="pointer"
              >
                <GridIcon width="25px" height="25px" />
              </Flex>
              <Flex
                w="50px"
                h="50px"
                // onClick={() => {
                //   setGridMode(false);
                // }}
                alignItems="center"
                justifyContent="center"
                borderRadius="25px"
                backgroundColor={gridMode ? "DISABLEDBG" : returnByTheme()}
                // _hover={{ opacity: 0.7 }}
                cursor="pointer"
              >
                <ListIcon />
              </Flex>
            </Flex>
          </Flex>
          {/* selected filter tags */}
          <Flex my="10px" alignItems="center" flexWrap="wrap" rowGap="10px">
            <Flex mr="10px">{t("TXT_Filter_By")}:</Flex>
            {appliedFilterOpt.district.length > 0 && (
              <SelectedTag
                tagText={appliedFilterOpt.district
                  .map((dist) => {
                    // return t(`TXT_Option_${dist}`);

                    return t(
                      `TXT_Option_${dist.toLowerCase().replaceAll("-", "_")}`
                    );
                  })
                  .join(", ")}
                onClose={() => resetOptionFilter("districts")}
              />
            )}

            {appliedFilterOpt.typeOfSite.length > 0 && (
              <SelectedTag
                tagText={appliedFilterOpt.typeOfSite
                  .map((type) => {
                    return t(`TXT_Option_${type}`);
                  })
                  .join(", ")}
                onClose={() => resetOptionFilter("siteTypes")}
              />
            )}

            {currentTab === "All" &&
              appliedFilterOpt.projectStatus.length > 0 && (
                <SelectedTag
                  tagText={appliedFilterOpt.projectStatus
                    .map((type) => {
                      return t(`TXT_PjOption_${type}`);
                    })
                    .join(", ")}
                  onClose={() => resetOptionFilter("statuses")}
                />
              )}

            {appliedFilterOpt.eleCompany.length > 0 && (
              <SelectedTag
                tagText={appliedFilterOpt.eleCompany
                  .map((type) => {
                    return t(`TXT_Option_${type}`);
                  })
                  .join(", ")}
                onClose={() => resetOptionFilter("electricCompanies")}
              />
            )}

            {showDateRangeTag && (
              <SelectedTag
                tagText={
                  dateRange?.[0]?.endDate !== null
                    ? `Create date: ${moment(dateRange?.[0]?.startDate).format(
                        "YYYY-MM-D"
                      )} - ${moment(dateRange?.[0]?.endDate).format(
                        "YYYY-MM-D"
                      )}`
                    : `Create date: ${moment(dateRange?.[0]?.startDate).format(
                        "YYYY-MM-D"
                      )}`
                }
                onClose={() => resetDateRangeFilter()}
              />
            )}

            {appliedFilterOpt.projectManager.length > 0 && (
              <SelectedTag
                tagText={appliedFilterOpt.projectManager
                  .map((type) => {
                    // return t(`TXT_Option_${type}`);
                    return type;
                  })
                  .join(", ")}
                // onClose={resetDateRangeFilter}
              />
            )}
          </Flex>
        </Flex>

        {/* main section */}

        <>
          {(sitesInfo?.sites.nodes.length == 0 && (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              borderTopRadius="20px"
              boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
              backgroundColor={"WT"}
              flexDirection={"column"}
              pt={3}
              pb={10}
            >
              <NoResultIcon stroke={returnByTheme()} />
              <Text color={returnByTheme()}>
                {t("TXT_Project_No_Project_Found")}
              </Text>
              <Text fontWeight={"bold"}>{t("TXT_Project_No_Result_Desc")}</Text>
            </Flex>
          )) ||
            (gridMode && (
              <Flex
                flexDir="column"
                ref={cardContainerRef}
                maxW="1920px"
                px="20px"
                mx="auto"
                overflow="scroll"
                width="100%"
                mb="70px"
                onScroll={() => {
                  // queryMore();
                  if (cardContainerRef.current) {
                    const { scrollTop, scrollHeight, clientHeight } =
                      cardContainerRef.current;

                    if (
                      scrollTop + clientHeight + 5 > scrollHeight &&
                      !loading
                    ) {
                      // console.log("fetch more");
                      queryMore();
                    }
                  }
                }}
              >
                <Masonry
                  // breakpointCols={window.innerWidth / 400}
                  breakpointCols={4}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {sitesInfo?.sites.nodes.map((item: any, index: number) => {
                    return (
                      <Flex ref={index == 0 ? scollToRef : null} key={index}>
                        <ProjectCard
                          item={item}
                          removeSiteAction={removeSiteAction}
                          addComment={addComment}
                          key={index}
                          totalIDs={sitesInfo?.sites.nodes.map(
                            (p: any) => p.id
                          )}
                          prevSelectedOpt={appliedFilterOpt}
                          prevPayload={queryPayload}
                          prevSearchType={searchType}
                          prevKeyword={search}
                          prevDateRange={dateRange}
                        />
                      </Flex>
                    );
                  })}
                </Masonry>
                {loading && (
                  <Flex flex={1} justifyContent="center" py="20px">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color={isClient ? "themeOrange" : "blue.500"}
                      size="sm"
                    />
                  </Flex>
                )}
                <Flex
                  position={"absolute"}
                  bottom="20px"
                  right="0"
                  width={"fit-content"}
                  alignSelf="flex-end"
                  pr="20px"
                  pl="5px"
                  bg={returnByTheme()}
                  py="5px"
                  alignItems={"center"}
                  borderTopLeftRadius="25px"
                  borderBottomLeftRadius="25px"
                  onClick={() => backToTop()}
                  zIndex="5"
                  _hover={{ cursor: "pointer" }}
                >
                  <BackTopBtn width="25px" height="25px" />
                  <Text color={"WT"} ml="10px">
                    {t("TXT_Back_to_top")}
                  </Text>
                </Flex>
              </Flex>
            )) || (
              <Flex flexDir="column" flex={14}>
                {sitesInfo?.sites.nodes.map((item: any, index: number) => {
                  return <Box key={index}>list {index}</Box>;
                })}
              </Flex>
            )}
        </>
        {/* )} */}

        <CopyRightBar />
      </Flex>
    </>
  );
};

export default Project;
