import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Image,
  Input,
  useToast,
  useOutsideClick,
  Icon,
  CloseButton,
} from "@chakra-ui/react";
import { FiSearch, FiBell, FiCheckCircle, FiBellOff } from "react-icons/fi";
import {
  convertAsAvatarTitle,
  randomBgColor,
  returnByTheme,
} from "src/utils/util";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import {
  NotificationAddedDocument,
  NotificationsDocument,
  ReadNotificationDocument,
  ReadNotificationsDocument,
} from "src/graphql/generated";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isNil } from "lodash";
import moment from "moment";
import Highlighter from "react-highlight-words";

const RightCornerTool = () => {
  const [showNoti, setShowNoti] = useState(false);
  const [notiList, setNotiList] = useState<any>([]);
  const [hadNewNoti, setHadNewNoti] = useState(false);
  const notiPopupRef = useRef(null);

  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const { data: subscriptionData, loading: receiving } = useSubscription(
    NotificationAddedDocument,
    {
      onSubscriptionData: ({ client, subscriptionData }) => {
        // subscribed.current = true;
      },
    }
  );

  const [getNotis, {}] = useLazyQuery(NotificationsDocument, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setNotiList(data.notifications.nodes);
      // check any unread thread
      const unReadNoti = data.notifications.nodes.filter((noti) =>
        isNil(noti.viewedAt)
      );
      setHadNewNoti(unReadNoti.length > 0 ? true : false);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [markOneReadNoti, {}] = useMutation(ReadNotificationDocument, {
    onCompleted: (success) => {
      getNotis();
      // toast({
      //   position: "bottom-right",
      //   title: `Marked one notification as read`,
      //   status: "success",
      // });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [markReadNoti, {}] = useMutation(ReadNotificationsDocument, {
    onCompleted: (success) => {
      toast({
        position: "bottom-right",
        title: `Marked all notification as read`,
        status: "success",
      });
      setShowNoti(false);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  useOutsideClick({
    ref: notiPopupRef,
    handler: (e: any) => {
      setShowNoti(false);
    },
  });

  useEffect(() => {
    getNotis({
      variables: {
        limit: 5,
      },
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (subscriptionData) {
      getNotis({
        variables: {
          limit: 5,
        },
      });
    }

    return () => {};
  }, [subscriptionData]);

  const convertNotiTitle = (
    i18nKey: string,
    keyValueProps: Array<{ key: string; value: string }>
  ) => {
    // console.log(i18nKey);
    // console.log(keyValueProps);
    let paramsObj = {};

    const arrOfi18nKey = i18nKey.split(",");
    // console.log(arrOfi18nKey);

    // convert to key pair object for i18n
    for (let index = 0; index < keyValueProps.length; index++) {
      const objKey = keyValueProps[index].key;
      const objValue = keyValueProps[index].value;
      paramsObj = { ...paramsObj, ...{ [objKey]: objValue } };
    }

    const arrOfFinalText = arrOfi18nKey.map((a) => {
      return t(a, paramsObj);
    });

    // const finalText = arrOfFinalText.join(" ");

    return arrOfFinalText;
  };

  const goDestination = (
    type: string,
    props: Array<{ key: string; value: string }>
  ) => {
    let paramsObj: any = {};

    //convest as {[key]:value}
    for (let index = 0; index < props.length; index++) {
      const objKey = props[index].key;
      const objValue = props[index].value;
      paramsObj = { ...paramsObj, ...{ [objKey]: objValue } };
    }

    switch (type) {
      case "account":
        navigate("/account");
        break;
      case "dashboard":
        navigate("/dashboard");
        break;
      case "project_list":
        navigate(`/project`, {
          state: {
            prevSearchType: {
              value: "siteName",
              name: "Search by name",
            },
            prevKeyword: paramsObj?.name,
            prevPayload: {
              variables: {
                filter: {
                  siteName: paramsObj?.name,
                },
              },
            },
          },
        });
        break;
      case "project_edit":
        navigate(`/edit/project/${paramsObj.code}`, {
          state: {
            currId: paramsObj?.id,
          },
        });
        break;
      case "project_detail":
        navigate(`/project/detail/${paramsObj.code}`, {
          state: {
            totalIDs: "noneed",
            currId: paramsObj?.id,
            // status: currTask.siteStatus,
          },
        });
        break;
      case "invoice_list":
      case "invoice":
        const keyWordState = {
          variables: {
            filter: {
              ketword: paramsObj?.id,
            },
            limit: 20,
          },
        };
        const stateForInvoice = paramsObj?.id
          ? {
              prevKeyword: keyWordState,
            }
          : {
              autoFilterStatus: paramsObj?.status,
            };

        navigate("/invoice", {
          state: stateForInvoice,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Flex gap="20px">
      {/* <Icon as={FiSearch} w="25px" h="25px" color={"#F0F0F0"} /> */}
      <Flex position="relative">
        <Icon
          as={FiBell}
          w="25px"
          h="25px"
          color={returnByTheme()}
          cursor="pointer"
          onClick={() => {
            setShowNoti(true);
            getNotis({
              variables: {
                limit: 5,
              },
            });
          }}
        />
        {hadNewNoti && (
          <Flex
            w="5px"
            h="5px"
            bg="RD.2"
            position="absolute"
            top="0"
            right="0"
            borderRadius="50%"
          ></Flex>
        )}

        {showNoti && (
          <Flex
            zIndex={9}
            ref={notiPopupRef}
            width="460px"
            position="absolute"
            top="30px"
            right="0"
            flexDir="column"
            bg="WT"
            borderTopRadius="12px"
          >
            <Flex
              p="5px 20px"
              bg="themeBlue"
              color="WT"
              fontWeight="semibold"
              borderTopRadius="12px"
              justifyContent="space-between"
              alignItems="center"
            >
              {t("TXT_Notification")}
              <CloseButton onClick={() => setShowNoti(false)} />
            </Flex>
            <Flex
              p="20px"
              bg="WT"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              border="1px solid rgba(0,75,146,0.3)"
            >
              {(notiList.length > 0 && (
                <>
                  <Flex
                    width="100%"
                    gap="5px"
                    alignItems="center"
                    justifyContent="center"
                    color="#004B92"
                    cursor="pointer"
                    mb="10px"
                    onClick={() => {
                      markReadNoti({
                        variables: {
                          notificationIds: notiList.map((n: any) => {
                            return n.notificationId;
                          }),
                          viewedAt: new Date(),
                        },
                      });
                    }}
                  >
                    {t("TXT_Notification_mark_all_as_read")}
                    <FiCheckCircle />
                  </Flex>
                  <Flex bg="#004B92" height="1px" width="100%"></Flex>
                  {notiList.map((n: any, i: number) => {
                    const isNew = isNil(n.viewedAt);

                    const newAuthorObj =
                      n.author.__typename == "Company"
                        ? {
                            name: n.author?.companyName,
                            email: n.author?.companyEmail,
                            url: n.author?.profileImage?.url,
                          }
                        : n.author.__typename == "User"
                        ? {
                            name: n.author?.userName,
                            email: n.author?.email,
                            url: n.author?.profileImage?.url,
                          }
                        : {
                            name: "System",
                            email: "-",
                            url: "-",
                          };

                    return (
                      <Flex key={i} gap="10px" bg="WT" width="100%" my="10px">
                        <Flex flex={1} gap="15px">
                          {(n.author.__typename == "System" && (
                            <Flex position="relative">
                              <Image
                                minWidth="45px"
                                h="45px"
                                src={require("../../src/icons/general/notiIcon.png")}
                              />
                              {isNew && (
                                <Flex
                                  w="12px"
                                  h="12px"
                                  bg="RD.2"
                                  position="absolute"
                                  top="0"
                                  right="0"
                                  borderRadius="50%"
                                  border="2px solid #ffffff"
                                ></Flex>
                              )}
                            </Flex>
                          )) || (
                            <Flex
                              position="relative"
                              borderRadius="50%"
                              minWidth="45px"
                              height="45px"
                              justifyContent="center"
                              alignItems="center"
                              border="1px solid #f0f0f0"
                              bg={
                                newAuthorObj.url
                                  ? `url(${newAuthorObj?.url})`
                                  : randomBgColor()
                              }
                              bgSize="contain"
                              bgPos="center"
                              bgRepeat="no-repeat"
                            >
                              {!newAuthorObj.url && (
                                <Text
                                  fontSize="28px"
                                  color="WT"
                                  fontWeight="bold"
                                >
                                  {convertAsAvatarTitle(
                                    newAuthorObj.name ?? newAuthorObj.email
                                  )}
                                </Text>
                              )}

                              {isNew && (
                                <Flex
                                  w="12px"
                                  h="12px"
                                  bg="RD.2"
                                  position="absolute"
                                  top="0"
                                  right="0"
                                  borderRadius="50%"
                                  border="2px solid #ffffff"
                                ></Flex>
                              )}
                            </Flex>
                          )}

                          <Flex
                            flex={1}
                            gap="5px"
                            justifyContent="space-between"
                          >
                            <Flex
                              flexDir="column"
                              borderLeft="1px solid #000000"
                              pl="15px"
                              gap="5px"
                            >
                              {convertNotiTitle(n?.topic, n?.props).map(
                                (t, i) => {
                                  return (
                                    <Highlighter
                                      searchWords={n.props.map((p: any) => {
                                        return p.value;
                                      })}
                                      highlightStyle={{
                                        fontWeight: "bold",
                                        backgroundColor: "transparent",
                                      }}
                                      autoEscape={true}
                                      // textToHighlight={convertNotiTitle(data?.topic, data?.props)}
                                      textToHighlight={t}
                                    />
                                  );
                                }
                              )}
                              {/* <Flex>{convertNotiTitle(n?.topic, n?.props)}</Flex> */}
                              <Flex color="DISABLEDTEXT">
                                {moment(n?.createdAt).toNow(true) +
                                  " " +
                                  t("TXT_ago")}
                              </Flex>
                            </Flex>
                            {!isNil(n?.landingLink) && (
                              <Flex
                                bg={isNew ? "RD.3" : "DISABLEDBG"}
                                color="WT"
                                minW="45px"
                                h="45px"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="50%"
                                cursor="pointer"
                                onClick={() => {
                                  if (isNil(n?.landingLink)) return;
                                  setShowNoti(false);
                                  goDestination(
                                    n?.landingLink.destination,
                                    n?.landingLink.props
                                  );
                                  markOneReadNoti({
                                    variables: {
                                      notificationId: n.notificationId,
                                      viewedAt: new Date(),
                                    },
                                  });
                                }}
                              >
                                Go
                              </Flex>
                            )}
                          </Flex>
                        </Flex>
                      </Flex>
                    );
                  })}
                  <Flex
                    border="1px solid #004B92"
                    borderRadius="8px"
                    color="themeBlue"
                    width="100%"
                    justifyContent="center"
                    p="5px"
                    my="10px"
                    cursor="pointer"
                    onClick={() => navigate("/notification")}
                  >
                    {t("TXT_Notification_view_all")}
                  </Flex>
                </>
              )) || (
                <Flex
                  flexDir="column"
                  gap="10px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src={require("../icons/general/noNotiIcon.png")}
                    w="40px"
                    h="40px"
                  />
                  <Flex color={"themeBlue"}>
                    {t("TXT_Notification_no_notification")}
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default RightCornerTool;
