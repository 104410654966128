import React from "react";
import { useEffect, useRef, useState } from "react";
import { Button, Flex, Text, Box, Icon, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { returnByTheme, returnByColor } from "../../../utils/util";

import { ReactComponent as StatusBarEndIcon } from "src/icons/general/statusBarEnd.svg";

import _ from "lodash";
import moment from "moment";

import { useTranslation } from "react-i18next";

import { t } from "i18next";

const ProjectProgressBarSection = ({
  siteInfo,
  currPjStatusBarObj,
  containerWidth,
}: any) => {
  const statusKeyArr = [
    "startedAt",
    "approvalLetterSentAt",
    "acknowledgedAt",
    "hardwareInstalledAt",
    "systemTestedAt",
    "fitMeterInstalledAt",
    "completedAt",
    "contractEndedAt",
  ];

  const isClient = localStorage.getItem("sf_Role") == "true";

  const { t } = useTranslation();
  const toast = useToast();

  return (
    <Flex
      py="10px"
      borderRadius="8px"
      boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
      bg={"WT"}
    >
      <Flex flexDirection="column" flex={1}>
        <Flex p="10px" color={returnByTheme()} fontWeight="bold">
          {t("TXT_Project_Progress")}
        </Flex>

        <Flex bg="DISABLEDTEXT" height="1px" mb="10px"></Flex>

        <Flex justifyContent="space-between" mx="2%">
          <Text fontWeight="bold" textTransform="uppercase">
            {t(currPjStatusBarObj?.displayName)}
          </Text>
          <Text fontWeight="bold">
            {moment(currPjStatusBarObj?.date).format("DD MMM, YYYY")}
          </Text>
        </Flex>

        <Flex height="30px" justifyContent="space-between" mx="2%">
          {statusKeyArr.map((s, i) => {
            const iscurrentStatus = currPjStatusBarObj?.index === i;
            const isLastItem = i == statusKeyArr.length - 1;

            return (
              <Flex
                alignItems={"center"}
                key={`step${i}`}
                // onClick={() => setCurrentStatus(i)}
              >
                <Flex
                  position={"relative"}
                  height="15px"
                  alignItems="center"
                  flex={1}
                >
                  {(iscurrentStatus && (
                    <>
                      {(isLastItem && (
                        <Flex
                          bg={returnByTheme()}
                          borderWidth="2px"
                          borderRadius="25px"
                          borderColor={returnByTheme()}
                        >
                          <StatusBarEndIcon fill={returnByColor()} />
                        </Flex>
                      )) || (
                        <Flex
                          width={"20px"}
                          height={"20px"}
                          backgroundColor={"WT"}
                          borderRadius={"25px"}
                          borderWidth={"6px"}
                          borderColor={returnByTheme()}
                          zIndex={1}
                        ></Flex>
                      )}
                    </>
                  )) || (
                    <Flex
                      mx="5px"
                      width={"14px"}
                      height={"14px"}
                      backgroundColor={"WT"}
                      borderRadius={"25px"}
                      zIndex={1}
                    ></Flex>
                  )}

                  {i !== statusKeyArr.length - 1 && (
                    <Flex
                      borderRadius={"50px"}
                      position={"absolute"}
                      left={isLastItem || i == 0 ? 0 : "5px"}
                      height={"15px"}
                      width={
                        i == statusKeyArr.length - 2
                          ? `${
                              (containerWidth - 60) /
                                (statusKeyArr.length - 1) +
                              16
                            }px`
                          : `${
                              (containerWidth - 60) /
                                (statusKeyArr.length - 2) +
                              4
                            }px`
                      }
                      background={
                        i === currPjStatusBarObj?.index - 1
                          ? isClient
                            ? "linear-gradient(#F79846, #EACD29)"
                            : "linear-gradient(#004b92, #69B3E3)"
                          : i < currPjStatusBarObj?.index - 1
                          ? returnByTheme()
                          : "#b4b4b4"
                      }
                    ></Flex>
                  )}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProjectProgressBarSection;
