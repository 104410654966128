import {
  Box,
  chakra,
  Flex,
  Text,
  Divider,
  GridItem,
  Grid,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useLazyQuery, useQuery } from "@apollo/client";
import CopyRightBar from "src/components/CopyRightBar";
import RightCornerTool from "src/components/RightCornerTool";
import GeneralTopHeader from "src/components/GeneralTopHeader";

const SettingPage = () => {
  const { t } = useTranslation();
  const toast = useToast();

  return (
    <>
      <Helmet>
        <title>Solar Monster - Setting</title>
      </Helmet>
      {/* header section */}
      <Flex
        flexDirection="column"
        flex={1}
        h="100vh"
        backgroundColor="#F7FAFA"
        ml="65px"
        overflowX="auto"
      >
        <GeneralTopHeader headerTitle={t("TXT_Setting")} hadRightCornerTools />
        {/* <Flex
          backgroundColor="WT"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          px="20px"
          minH="60px"
        >
          <Flex>
            <Text fontWeight="bold">{t("TXT_Setting")}</Text>
          </Flex>
          <Flex></Flex>
          <RightCornerTool />
        </Flex> */}

        <Flex
          flexDirection="column"
          marginTop={"25px"}
          maxW="1920px"
          mx="auto"
          px="20px"
          width="100%"
          mb="40px"
        >
          123
        </Flex>
        <CopyRightBar />
      </Flex>
    </>
  );
};

export default SettingPage;
