import { Box, Input, Text, Flex, useToast } from "@chakra-ui/react";
// import allDist?.listSiteRegions.regions from "../../json/allDist?.listSiteRegions.regions.json";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";
import { ReactComponent as ArrowDownBlack } from "src/icons/general/chevron-down-black.svg";
import { ReactComponent as ArrowDownGrey } from "src/icons/general/chevron-down-grey.svg";
import { CheckIcon } from "@chakra-ui/icons";
import { useOutsideClick } from "@chakra-ui/react";
import { returnByTheme } from "../../utils/util";
import { useQuery } from "@apollo/client";
import { ListSiteRegionsDocument } from "src/graphql/generated";
import { useTranslation } from "react-i18next";

interface ISelectProps {
  placeHolder?: string;
  isRequired?: boolean;
  inputLabel?: string;
  areaOnchange: Function;
  distOnchange: Function;
  buildingOnchange: Function;
  areaSelectedValue?: string;
  distSelectedValue?: string;
  buildingInputValue?: string;
  addressArr?: Array<string>;
  disableAll?: boolean;
}

const FormAddressInput = ({
  placeHolder,
  isRequired = false,
  inputLabel,
  areaOnchange,
  distOnchange,
  buildingOnchange,
  areaSelectedValue,
  distSelectedValue,
  buildingInputValue,
  addressArr,
  disableAll = false,
}: ISelectProps) => {
  const [areaToggle, setAreaToggle] = useState(false);
  const [distToggle, setDistToggle] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  const [distList, setDistList] = useState<Array<any>>();
  // const [selectedArea, setSelectedArea] = useState("");
  // const [selectedDist, setSelectedDist] = useState("");

  const aredRef = useRef(null);
  const distRef = useRef(null);

  const { data: allDist } = useQuery(ListSiteRegionsDocument, {
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Fail to get regions list`,
        status: "error",
      });
    },
  });

  useOutsideClick({
    ref: aredRef,
    handler: () => setAreaToggle(false),
  });

  useOutsideClick({
    ref: distRef,
    handler: () => setDistToggle(false),
  });

  const getDistList = () => {
    let filteredDist =
      allDist?.listSiteRegions.regions &&
      allDist?.listSiteRegions.regions.filter(
        (o, i) => o?.name == areaSelectedValue
      );
    setDistList(filteredDist?.[0]?.districts);
  };

  const handleAreaOnChange = (areaValue: string) => {
    areaOnchange(areaValue);

    setAreaToggle(false);
  };
  const handleDistOnChange = (distValue: string) => {
    distOnchange(distValue);
    setDistToggle(false);
  };

  useEffect(() => {
    getDistList();

    return () => {};
  }, [areaSelectedValue]);

  return (
    <Flex>
      <Flex
        flex={1}
        flexDirection={"row"}
        p="20px"
        justifyContent={"space-between"}
        gap="5px"
      >
        {/* label section */}
        <Flex flex={2} fontSize={15} color={"GREY"}>
          {isRequired ? (
            <Box>
              <Text display={"inline"}>{inputLabel}&nbsp;</Text>
              <Text color={"RD.1"} display={"inline"}>
                *
              </Text>
            </Box>
          ) : (
            <Flex>{inputLabel}</Flex>
          )}
        </Flex>
        {/* address input section */}
        <Flex
          flex={3}
          flexDirection={"column"}
          pointerEvents={disableAll ? "none" : "auto"}
        >
          {/* disable row */}
          <Flex
            flex={1}
            px="10px"
            py="5px"
            borderColor={"DISABLEDBG"}
            backgroundColor={"DISABLEDBG"}
            borderRadius="5px"
            borderWidth={1}
            justifyContent={"space-between"}
            color={"DISABLEDTEXT"}
            mb="10px"
          >
            {addressArr?.[0]}
            <ArrowDownGrey />
          </Flex>
          {/* area row */}
          <Flex position={"relative"} flex={3} ref={aredRef} mb="10px">
            <Flex
              flex={1}
              onClick={() => setAreaToggle(!areaToggle)}
              px="10px"
              py="5px"
              borderColor={returnByTheme()}
              borderRadius="5px"
              borderWidth={1}
              justifyContent={"space-between"}
              backgroundColor={disableAll ? "DISABLEDBG" : "WT"}
              color={disableAll ? "DISABLEDTEXT" : "BK"}
            >
              {areaSelectedValue
                ? t(`TXT_${areaSelectedValue.toLowerCase()}`)
                : t("TXT_Desc_select_area")}
              {disableAll ? <ArrowDownGrey /> : <ArrowDownBlack />}
            </Flex>
            <Flex
              position={"absolute"}
              top={"40px"}
              right={0}
              flex={1}
              width={"100%"}
              display={areaToggle ? "flex" : "none"}
              flexDirection={"column"}
              zIndex={3}
              bg="WT"
              borderBottomRadius="5px"
              borderWidth={1}
              borderColor={returnByTheme()}
            >
              {allDist?.listSiteRegions.regions &&
                allDist?.listSiteRegions.regions?.map((o, i) => {
                  let isSelected = areaSelectedValue === o?.name;
                  return (
                    <Flex
                      key={i}
                      justifyContent={"space-between"}
                      onClick={() => handleAreaOnChange(o?.name)}
                      bg="WT"
                      my="5px"
                      mx="10px"
                      _hover={{
                        cursor: "pointer",
                      }}
                    >
                      <Text
                        color={"GREY"}
                        _hover={{
                          color: returnByTheme(),
                        }}
                      >
                        {t(`TXT_${o.name.toLowerCase()}`)}
                      </Text>
                      {isSelected && <CheckIcon color={"GREY"} w={3} h={4} />}
                    </Flex>
                  );
                })}
            </Flex>
          </Flex>

          {/* dist row */}
          {areaSelectedValue && (
            <Flex position={"relative"} flex={3} ref={distRef} mb="10px">
              <Flex
                flex={1}
                onClick={() => setDistToggle(!distToggle)}
                px="10px"
                py="5px"
                borderColor={returnByTheme()}
                borderRadius="5px"
                borderWidth={1}
                justifyContent={"space-between"}
                backgroundColor={disableAll ? "DISABLEDBG" : "WT"}
                color={disableAll ? "DISABLEDTEXT" : "BK"}
              >
                {/* {distSelectedValue ? distSelectedValue : distList?.[0]?.name} */}
                {distSelectedValue
                  ? t(`TXT_Option_${distSelectedValue.toLowerCase()}`)
                  : t("TXT_Please_select_district")}
                {disableAll ? <ArrowDownGrey /> : <ArrowDownBlack />}
              </Flex>
              <Flex
                position={"absolute"}
                top={"40px"}
                right={0}
                flex={1}
                width={"100%"}
                display={distToggle ? "flex" : "none"}
                zIndex={3}
                bg="WT"
                flexDirection={"column"}
                borderBottomRadius="5px"
                borderWidth={1}
                borderColor={returnByTheme()}
              >
                {distList &&
                  distList?.length > 0 &&
                  distList?.map((d: any, i: number) => {
                    let isSelected = distSelectedValue === d?.name;
                    return (
                      <Flex
                        key={i}
                        justifyContent={"space-between"}
                        onClick={() => handleDistOnChange(d?.name)}
                        my="5px"
                        mx="10px"
                        bg="WT"
                        _hover={{
                          cursor: "pointer",
                        }}
                      >
                        <Text
                          color={"GREY"}
                          _hover={{
                            color: returnByTheme(),
                          }}
                        >
                          {t(`TXT_Option_${d.name.toLowerCase()}`)}
                        </Text>
                        {isSelected && <CheckIcon color={"GREY"} w={3} h={4} />}
                      </Flex>
                    );
                  })}
              </Flex>
            </Flex>
          )}

          {/* third row */}
          <Input
            placeholder={placeHolder}
            flex={1}
            px="10px"
            py="5px"
            borderColor={returnByTheme()}
            borderRadius="5px"
            borderWidth={1}
            justifyContent={"space-between"}
            value={buildingInputValue}
            onChange={(e) => buildingOnchange(e.target?.value)}
            backgroundColor={disableAll ? "DISABLEDBG" : "WT"}
            color={disableAll ? "DISABLEDTEXT" : "BK"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FormAddressInput;
