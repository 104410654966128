import { Box, Input, Text, Flex } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";

import TextPreviewRow from "../../../components/createProjectForm/TextPreviewRow";

import { ReactComponent as EditIcon } from "src/icons/general/editIcon.svg";
import { returnByTheme, returnByColor } from "../../../utils/util";
import { IInvestorDetailData } from "../../../type/IBaseInfotInterface";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface IInvestorDetailProps {
  investorDetailData: Array<IInvestorDetailData>;
  editInvestorDetailAction: () => void;
}

const InvestorDetailPreview = ({
  investorDetailData,
  editInvestorDetailAction,
}: IInvestorDetailProps) => {
  const { t } = useTranslation();
  // console.log("investor detail section preview");
  // console.log(investorDetailData);

  return (
    <Box mb="30px">
      <SectionTitle
        title={t("TXT_Project_BItitle_investor_detail")}
        editAction={() => editInvestorDetailAction()}
      />

      {investorDetailData &&
        investorDetailData.map((investor, index) => {
          const dateText =
            investor?.date?.[0]?.endDate !== null &&
            investor?.date?.[0]?.startDate !== investor?.date?.[0]?.endDate
              ? `${moment(investor?.date?.[0]?.startDate).format(
                  "D MMM, YYYY"
                )} - ${moment(investor?.date?.[0]?.endDate).format(
                  "D MMM, YYYY"
                )}`
              : `${moment(investor?.date?.[0]?.startDate).format(
                  "D MMM, YYYY"
                )}`;
          return (
            <Flex
              key={index}
              backgroundColor={"WT"}
              borderRadius={"5px"}
              boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
              px="20px"
              flexDirection={"column"}
              mb="10px"
            >
              {/* <TextPreviewRow
                label="Name"
                value={investor.name}
                hadBorder={false}
              /> */}
              {/* <TextPreviewRow label="Phone" value={investor.phone} /> */}
              <TextPreviewRow
                label={t("TXT_Email")}
                value={investor.email}
                hadBorder={false}
              />
              <TextPreviewRow
                label={t("TXT_Sharing_ratio")}
                value={
                  t("TXT_Project_Client_ratio") +
                  " " +
                  investor.clientRatio +
                  "% " +
                  t("TXT_Project_Investor_ratio") +
                  " " +
                  investor.investorRatio +
                  "% = 100%"
                }
              />

              <TextPreviewRow
                label={t("TXT_Project_sharing_period")}
                value={dateText}
              />
            </Flex>
          );
        })}
    </Box>
  );
};

const SectionTitle = ({
  title,
  editAction,
}: {
  title: string;
  editAction: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb="5px">
      <Flex fontSize={18} fontWeight={"bold"}>
        {title}
      </Flex>
      <Flex
        cursor={"pointer"}
        borderRadius="4px"
        borderWidth="1px"
        borderColor={returnByTheme()}
        bg="WT"
        color={returnByTheme()}
        alignItems={"center"}
        px="20px"
        py="2px"
        onClick={() => editAction()}
      >
        <EditIcon stroke={returnByColor()} />
        <Box ml="5px">{t("TXT_Edit")}</Box>
      </Flex>
    </Flex>
  );
};

export default InvestorDetailPreview;
