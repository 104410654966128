import { Button } from "@chakra-ui/react";
import { ReactNode, MouseEventHandler } from "react";
import { returnByTheme } from "../utils/util";

type ButtonProps = {
  color?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disable?: boolean;
};
const FilterTag = ({
  color = returnByTheme(),
  children,
  onClick,
  disable = false,
}: ButtonProps) => {
  return (
    <Button
      h="40px"
      bgColor={"WT"}
      borderColor={returnByTheme()}
      borderWidth={"1px"}
      color={returnByTheme()}
      borderRadius="40px"
      fontSize="20px"
      fontWeight="normal"
      onClick={onClick}
      _hover={{ backgroundColor: "#0000" }}
      _active={{ backgroundColor: "#0000" }}
      disabled={disable}
      p="15px"
      marginRight="15px"
      //marginTop='15px'
      my="5px"
    >
      {children}
    </Button>
  );
};

export default FilterTag;
