import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Icon,
  useToast,
  useOutsideClick,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BsLightning } from "react-icons/bs";
import {
  FiHome,
  FiClock,
  FiMoreHorizontal,
  FiTrash,
  FiCalendar,
  FiAlertTriangle,
} from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";
import {
  returnByTheme,
  returnByColor,
  convertAsCurrPjStatus,
  getCurrProjectStatus,
  getCurrStatusDisplayName,
  convertAsAvatarTitle,
  randomBgColor,
} from "../../utils/util";

import { SearchIcon } from "@chakra-ui/icons";
import { ReactComponent as DarkCloseBtn } from "src/icons/general/darkCloseBtn.svg";
import { ReactComponent as WhiteCloseBtn } from "src/icons/general/whiteCloseBtn.svg";
import { ReactComponent as StatusBarEndIcon } from "src/icons/general/statusBarEnd.svg";
import { ReactComponent as GenerationIcon } from "src/icons/general/generationIcon.svg";
import { ReactComponent as ConsumptionIcon } from "src/icons/general/consumptionIcon.svg";
import { ReactComponent as ReductionIcon } from "src/icons/general/reductionIcon.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import TaskSwiper from "./TaskSwiper";
import _ from "lodash";
import moment from "moment";
import PopUpModal from "src/components/PopUpModal";
import { useTranslation } from "react-i18next";
import { RemoveSiteDocument } from "../../graphql/generated";
import { useLazyQuery, useMutation } from "@apollo/client";
import { typeOfSiteColor, typeOfSiteText } from "src/type/IProjectInterface";
import { IconContext } from "react-icons";

const statusKeyArr = [
  "startedAt",
  "approvalLetterSentAt",
  "acknowledgedAt",
  "hardwareInstalledAt",
  "systemTestedAt",
  "fitMeterInstalledAt",
  "completedAt",
  "contractEndedAt",
];

const ProjectCard = ({
  item,
  removeSiteAction,
  addComment,
  totalIDs,
  prevSelectedOpt,
  prevPayload,
  prevKeyword,
  prevSearchType,
  prevDateRange,
}: {
  item: any;
  removeSiteAction: any;
  addComment: any;
  totalIDs: Array<string>;
  prevSelectedOpt: any;
  prevPayload: any;
  prevKeyword: any;
  prevSearchType: any;
  prevDateRange: any;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const isClient = localStorage.getItem("sf_Role") == "true";
  const cardRef = useRef<any>(null);
  const moreActionRef = useRef<any>(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showMoreAction, setShowMoreAction] = useState(false);
  const [showImagesGallery, setShowImagesGallery] = useState(false);

  const projectStatusArr = getCurrProjectStatus(item);
  const currPjStatus = convertAsCurrPjStatus(projectStatusArr);
  const currPjStatusBarObj = getCurrStatusDisplayName(projectStatusArr);

  useOutsideClick({
    ref: moreActionRef,
    handler: () => setShowMoreAction(false),
  });

  useEffect(() => {
    setCardWidth(cardRef?.current?.clientWidth);
    return () => {};
  }, []);

  return (
    <Flex
      flex={1}
      ref={cardRef}
      flexDirection="column"
      bg="WT"
      boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
    >
      <Flex
        bg={
          projectStatusArr[0]?.status == "contractEndedAt"
            ? returnByTheme()
            : item?.sitePower
            ? "LB.4"
            : "GN.2"
        }
        color="WT"
        p="10px"
        justifyContent="space-between"
        alignItems="center"
      >
        {item.code}
        {!isClient && (
          <Box
            cursor="pointer"
            onClick={() => setShowMoreAction(true)}
            position="relative"
          >
            <FiMoreHorizontal width="35px" height="35px" />
            {showMoreAction && (
              <Flex
                position="absolute"
                flexDirection="column"
                ref={moreActionRef}
                borderRadius="10px"
                borderColor="GREY"
                p="10px"
                bg="WT"
                zIndex={1}
                right={0}
                minWidth="max-content"
              >
                <Flex
                  color="GREY"
                  alignItems="center"
                  _hover={{ color: returnByTheme() }}
                  py="5px"
                  onClick={() =>
                    navigate(`/edit/project/${item.code}`, {
                      state: {
                        currId: item.id,
                        prevSelectedOpt: prevSelectedOpt,
                        prevPayload: prevPayload,
                        prevSearchType: prevSearchType,
                        prevKeyword: prevKeyword,
                        prevDateRange: prevDateRange,
                      },
                    })
                  }
                >
                  <Icon as={FaRegEdit} />
                  <Flex flex={1} ml="10px">
                    {t("TXT_Project_Edit_project")}
                  </Flex>
                </Flex>
                <Flex
                  color="GREY"
                  alignItems="center"
                  _hover={{ color: returnByTheme() }}
                  py="5px"
                  onClick={() => setShowConfirmDeleteModal(true)}
                >
                  <Icon as={FiTrash} />
                  <Flex flex={1} ml="10px">
                    {t("TXT_Project_Delete_project")}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Box>
        )}
      </Flex>
      {/* photo section */}
      <Flex
        onClick={() => setShowImagesGallery(true)}
        position="relative"
        height="150px"
        cursor="pointer"
        width="100%"
        bgImage={
          item?.projectImages.length > 0
            ? item?.projectImages[0]?.url
            : "url(https://media.istockphoto.com/photos/business-office-building-in-london-england-picture-id511061090?k=20&m=511061090&s=612x612&w=0&h=nPelzFaYsfdi9j8Prva-ob89zxr870ZiH-bTN6X2X4o=)"
          // "url(https://picsum.photos/200/300)"
        }
        bgSize="auto"
        bgPos="center"
        bgRepeat="no-repeat"
        p="15px"
        flexDirection="column"
        justifyContent="space-between"
      >
        <SearchIcon
          position="absolute"
          bottom="10px"
          right="10px"
          color={returnByTheme()}
          width="35px"
          height="35px"
          p="7px"
          borderRadius="17px"
          bg="WT"
        />
      </Flex>

      <Flex p="10px" flexDirection="column">
        {/* info section */}
        <Flex flexDirection="column">
          <Box
            fontWeight="medium"
            my="2px"
            textDecorationLine="underline"
            mb="5px"
            onClick={() =>
              navigate(`/project/detail/${item.code}`, {
                state: {
                  totalIDs: totalIDs,
                  currId: item.id,
                  status: currPjStatus,
                  prevSelectedOpt: prevSelectedOpt,
                  prevPayload: prevPayload,
                  prevSearchType: prevSearchType,
                  prevKeyword: prevKeyword,
                  prevDateRange: prevDateRange,
                },
              })
            }
            cursor="pointer"
          >
            {item.name}
          </Box>
          <Flex alignItems="center" mb="5px">
            <Icon as={FiClock} color="GREY" w="20px" h="20px" />
            <Text ml="10px">
              {moment(item.createdAt).format("DD MMM, YYYY")}
            </Text>
          </Flex>
          <Flex alignItems="center" mb="5px">
            <Icon as={HiOutlineLocationMarker} color="GREY" w="20px" h="20px" />
            <Text ml="10px"> {item.address.line.toUpperCase()}</Text>
          </Flex>
          <Flex mt="5px" pb="15px" borderBottom="2px solid #CFCFCF">
            {item.electricCompany?.type && (
              <Flex
                bg={item?.electricCompany?.type == "HKE" ? "RD.1" : "DB.3"}
                color="WT"
                alignItems="center"
                borderRadius="15px"
                py="5px"
                px="10px"
                mr="10px"
              >
                <BsLightning />
                <Text ml="5px"> {item.electricCompany?.type}</Text>
              </Flex>
            )}
            <Flex
              bg={
                typeOfSiteColor[
                  item.siteType.name
                    .toUpperCase()
                    .replaceAll("-", "_") as keyof typeof typeOfSiteColor
                ]
              }
              color="WT"
              alignItems="center"
              borderRadius="15px"
              py="5px"
              px="10px"
            >
              <FiHome />
              <Text ml="5px">
                {t(
                  `${
                    typeOfSiteText[
                      item.siteType.name
                        .toUpperCase()
                        .replaceAll("-", "_") as keyof typeof typeOfSiteText
                    ]
                  }`
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* project status bar section */}
        {!item?.sitePower && (
          <Flex flexDirection="column" my="15px">
            <Flex justifyContent="space-between">
              <Text fontWeight="bold" textTransform="uppercase">
                {t(currPjStatusBarObj?.displayName)}
              </Text>
              <Text fontWeight="bold">
                {moment(currPjStatusBarObj.date).format("DD MMM, YYYY")}
              </Text>
            </Flex>
            <Flex
              height="30px"
              justifyContent="space-between"
              // overflow={"hidden"}
            >
              {statusKeyArr.map((s, i) => {
                const iscurrentStatus = currPjStatusBarObj?.index === i;
                const isLastItem = i == statusKeyArr.length - 1;

                return (
                  <Flex
                    alignItems={"center"}
                    key={`step${i}`}
                    // onClick={() => setCurrentStatus(i)}
                  >
                    <Flex
                      position={"relative"}
                      height="15px"
                      alignItems="center"
                      flex={1}
                    >
                      {(iscurrentStatus && (
                        <>
                          {(isLastItem && (
                            <Flex
                              bg={returnByTheme()}
                              borderWidth="2px"
                              borderRadius="25px"
                              borderColor={returnByTheme()}
                            >
                              <StatusBarEndIcon fill={returnByColor()} />
                            </Flex>
                          )) || (
                            <Flex
                              width={"20px"}
                              height={"20px"}
                              backgroundColor={"WT"}
                              borderRadius={"25px"}
                              borderWidth={"6px"}
                              borderColor={returnByTheme()}
                              zIndex={1}
                            ></Flex>
                          )}
                        </>
                      )) || (
                        <Flex
                          mx="5px"
                          width={"14px"}
                          height={"14px"}
                          backgroundColor={"WT"}
                          borderRadius={"25px"}
                          zIndex={1}
                        ></Flex>
                      )}

                      {i !== statusKeyArr.length - 1 && (
                        <Flex
                          borderRadius={"50px"}
                          position={"absolute"}
                          left={isLastItem || i == 0 ? 0 : "5px"}
                          height={"15px"}
                          width={
                            i == statusKeyArr.length - 2
                              ? `${
                                  cardWidth / (statusKeyArr.length - 1) + 16
                                }px`
                              : `${cardWidth / (statusKeyArr.length - 2) + 4}px`
                          }
                          background={
                            i === currPjStatusBarObj?.index - 1
                              ? isClient
                                ? "linear-gradient(#F79846, #EACD29)"
                                : "linear-gradient(#004b92, #69B3E3)"
                              : i < currPjStatusBarObj?.index - 1
                              ? returnByTheme()
                              : "#b4b4b4"
                          }
                        ></Flex>
                      )}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        )}

        {/* generate power data section */}
        {item?.sitePower && (
          <Flex flexDirection={"column"} py="20px">
            <Flex alignItems="center">
              <FiCalendar />
              <Flex ml="5px">
                {/* {currPjStatus == "running" && (
                  <>
                    {moment(new Date()).format("DD MMM , YYYY")} to{" "}
                    {moment(new Date()).format("DD MMM , YYYY")}
                  </>
                )} */}

                {(projectStatusArr[0]?.status == "contractEndedAt" && (
                  <>
                    {t("TXT_Ended_from")}
                    {moment(item?.contractEndedAt).format("DD MMM , YYYY")}
                  </>
                )) || (
                  <>
                    {moment(item?.sitePower.accumulateStartDate).format(
                      "DD MMM , YYYY"
                    )}
                    &nbsp; {t("TXT_To")} &nbsp;
                    {moment(item?.sitePower.accumulateEndDate).format(
                      "DD MMM , YYYY"
                    )}
                  </>
                )}
              </Flex>
            </Flex>
            {/* <Grid mt="20px" templateColumns="repeat(3, 1fr)" gap={6}> */}
            <Flex mt="20px" justifyContent="space-between" columnGap="10px">
              <Flex
                borderRadius="5px"
                boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                // px="5px"
                p="10px"
                flexDirection="column"
                // width="120px"
                flex={1}
                justifyContent="space-between"
              >
                <GenerationIcon
                  fill={
                    projectStatusArr[0]?.status == "contractEndedAt"
                      ? "#8e8e8e"
                      : "#69B3E3"
                  }
                />

                <Box
                  textAlign="center"
                  color={
                    projectStatusArr[0]?.status == "contractEndedAt"
                      ? "#8e8e8e"
                      : "#69B3E3"
                  }
                  flexDirection="column"
                  flex={1}
                  mt="10px"
                  width={"100%"}
                >
                  {t("TXT_Project_Generation")}
                </Box>

                <Box fontSize="25px" textAlign="center" fontWeight="bold">
                  {item?.sitePower?.generatedKwh.toLocaleString()}
                </Box>
                <Flex fontSize="10px" justifyContent="space-between">
                  <Box>{t("TXT_Units")}</Box>
                  <Box>kW</Box>
                </Flex>
              </Flex>
              <Flex
                borderRadius="5px"
                boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                // px="5px"
                p="10px"
                flexDirection="column"
                // width="120px"
                flex={1}
                justifyContent="space-between"
              >
                <ConsumptionIcon
                  fill={
                    projectStatusArr[0]?.status == "contractEndedAt"
                      ? "#8e8e8e"
                      : "#e4513f"
                  }
                />

                <Box
                  textAlign="center"
                  // color="RD.3"
                  color={
                    projectStatusArr[0]?.status == "contractEndedAt"
                      ? "#8e8e8e"
                      : "#e4513f"
                  }
                  flexDirection="column"
                  flex={1}
                  mt="10px"
                  width={"100%"}
                >
                  {t("TXT_Project_Consumption")}
                </Box>

                <Box fontSize="25px" textAlign="center" fontWeight="bold">
                  {item?.sitePower?.consumedKwh.toLocaleString()}
                </Box>
                <Flex fontSize="10px" justifyContent="space-between">
                  <Box>{t("TXT_Units")}</Box>
                  <Box>kW</Box>
                </Flex>
              </Flex>
              <Flex
                borderRadius="5px"
                boxShadow="0px 2px 3px 2px rgba(0, 0, 0, 0.05)"
                // px="5px"
                p="10px"
                flexDirection="column"
                // width="120px"
                flex={1}
                justifyContent="space-between"
              >
                <ReductionIcon
                  fill={
                    projectStatusArr[0]?.status == "contractEndedAt"
                      ? "#8e8e8e"
                      : "#7DDF92"
                  }
                />
                <Flex
                  textAlign="center"
                  color={
                    projectStatusArr[0]?.status == "contractEndedAt"
                      ? "#8e8e8e"
                      : "#7DDF92"
                  }
                  flexDirection="column"
                  flex={1}
                  mt="10px"
                  width={"100%"}
                >
                  <Box>{t("TXT_Project_Carbon")}</Box>
                  <Box> {t("TXT_Project_Reduction")}</Box>
                </Flex>
                <Box fontSize="25px" textAlign="center" fontWeight="bold">
                  {Math.floor(
                    item?.sitePower?.generatedKwh * 0.997
                  ).toLocaleString()}
                </Box>
                <Flex fontSize="10px" justifyContent="space-between">
                  <Box>kg</Box>
                  <Box>kW</Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        {/* task section */}
        {/* {currPjStatus !== "end" && item.tasks.length > 0 && ( */}
        {item.tasks.length > 0 && <TaskSwiper task={item.tasks} />}

        {/* pm section */}
        <Flex>
          {item.projectManagers.map((pm: any, pmIndex: number) => {
            return (
              <Flex
                key={pmIndex}
                width="45px"
                height="45px"
                borderRadius="25px"
                justifyContent="center"
                alignItems="center"
                mr="5px"
                border="1px solid #f0f0f0"
                bg={randomBgColor()}
                color="WT"
                // bg={
                //   pm.profileImage?.url
                //     ? `url(${pm.profileImage?.url})`
                //     : "blue.400"
                // }
                // bgSize="contain"
                // bgPos="center"
                // bgRepeat="no-repeat"
              >
                {/* {!pm.profileImage?.url && (
                  <Text color="WT">
                    {convertAsAvatarTitle(pm?.name ?? pm?.email)}
                  </Text>
                )} */}
                {convertAsAvatarTitle(pm.name)}
              </Flex>
            );
          })}

          {/* <Flex
            width="45px"
            height="45px"
            borderRadius="25px"
            bg="#69B3E3"
            justifyContent="center"
            alignItems="center"
          >
            GG
          </Flex> */}
        </Flex>
      </Flex>
      <PopUpModal
        isShow={showImagesGallery}
        onClose={() => {
          setShowImagesGallery(false);
        }}
        headerColor={returnByTheme()}
        headerText="Project image"
        size="3xl"
      >
        <ProjectImagesList images={item?.projectImages} />
      </PopUpModal>
      <PopUpModal
        isShow={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        headerColor={"RD.3"}
        headerText={t("TXT_Alert")}
      >
        <Flex flexDirection="column">
          <Flex flexDirection="column" gap="10px" alignItems="center">
            <IconContext.Provider value={{ color: "#E4513F", size: "100px" }}>
              <FiAlertTriangle />
            </IconContext.Provider>
            <Flex fontWeight="bold">
              {t("TXT_Desc_delete_project")} "{item.name}" ?
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" gap="10px">
            <Button
              flex={1}
              backgroundColor="WT"
              border="1px solid #000000"
              // _hover={{ backgroundColor: {returnByTheme()} }}
              // disabled={loading}
              onClick={() => setShowConfirmDeleteModal(false)}
              marginTop="20px"
              borderRadius="40px"
            >
              {t("TXT_No_keep_it")}
            </Button>
            <Button
              flex={1}
              color="WT"
              backgroundColor={"RD.3"}
              _hover={{ backgroundColor: "RD.3" }}
              // disabled={loading}
              onClick={() => {
                removeSiteAction({
                  variables: {
                    siteId: item.id,
                  },
                });
                setShowConfirmDeleteModal(false);
              }}
              marginTop="20px"
              borderRadius="40px"
            >
              {t("TXT_Yes_delete")}
            </Button>
          </Flex>
        </Flex>
      </PopUpModal>
    </Flex>
  );
};

const ProjectImagesList = ({ images }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<any>();

  return (
    <>
      <Flex justifyContent="center" fontWeight="bold">{`${
        currentIndex ? currentIndex + 1 : 1
      } of ${images.length}`}</Flex>

      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop={images.length > 1}
        draggable={false}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        onSlideChange={(swiper) => setCurrentIndex(swiperInstance?.realIndex)}
        initialSlide={0}
        style={{ marginTop: "20px", alignItems: "center" }}
      >
        {images.map((image: any, index: number) => {
          return (
            <SwiperSlide key={index}>
              <Flex
                justifyContent="center"
                alignItems="center"
                borderRadius="15px"
                overflow="hidden"
                border="1px solid rgba(0, 0, 0, 0.05)"
                bg="#8F8F8F"
                maxH="400px"
              >
                <img
                  className="swiper-lazy preview-image swiper-zoom-target"
                  src={image.url}
                  style={{
                    objectFit: "scale-down",
                  }}
                  alt={image.name}
                />
              </Flex>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Flex mt="20px" gap="20px">
        {images.map((image: any, index: number) => {
          return (
            <img
              key={index}
              onClick={() => {
                swiperInstance.slideTo(index + 1);
              }}
              src={image.url}
              alt={image.alt}
              style={{
                width: "100px",
                height: "100px",
                objectFit: "contain",
                borderRadius: "4px",
                overflow: "hidden",
                cursor: "pointer",
                boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.05)",
                background: "#8F8F8F",
              }}
            />
          );
        })}
      </Flex>
    </>
  );
};

export default ProjectCard;
