import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Icon,
  useToast,
  Link,
  Avatar,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { returnByTheme, returnByColor } from "../../../utils/util";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import { t } from "i18next";
import { FiGlobe } from "react-icons/fi";

const ProjectInvestorSection = ({ siteInfo }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  return (
    <Flex
      py="10px"
      borderRadius="8px"
      boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
      bg={"WT"}
    >
      <Flex flexDirection="column" flex={1}>
        <Flex p="10px" color={returnByTheme()} fontWeight="bold">
          {t("TXT_Project_Investor_details")}
        </Flex>

        <Flex bg="DISABLEDTEXT" height="1px" mb="10px"></Flex>

        <Flex
          justifyContent="center"
          mx="20px"
          alignItems="center"
          flexDir="column"
          py="10px"
        >
          <Avatar w="100px" h="100px" src="avatar-1.jpg" />
          <Flex my="10px">{siteInfo?.site?.investor.name}</Flex>
          <Link
            display="flex"
            bg={returnByTheme()}
            variant="solid"
            alignItems="center"
            gap="10px"
            borderRadius="8px"
            color="WT"
            fontWeight="normal"
            px="10px"
            py="5px"
            href={`mailto:${siteInfo?.site?.investor.email}`}
            target="_blank"
          >
            <FiGlobe color="#ffffff" />
            {siteInfo?.site?.investor.email}
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProjectInvestorSection;
