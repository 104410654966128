import _ from "lodash";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  PinInput,
  PinInputField,
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BaseInput from "src/components/BaseInput";
import CheckBox from "src/components/CheckBox";
import PasswordInput from "src/components/PasswordInput";
import StyledButton from "./StyledButton";
import { returnByTheme } from "../../utils/util";

import { ReactComponent as EmailIcon } from "src/icons/auth/email.svg";
import { useMutation } from "@apollo/client";
import {
  ForgetPasswordDocument,
  ResetPasswordDocument,
  VerifyOtpDocument,
} from "src/graphql/generated";
import { useTranslation } from "react-i18next";

enum ForgotPasswordPage {
  EnterEmail,
  ValidEmail,
  ResetPassword,
}

type ModalProps = {
  setIsShowForgotModal: Function;
  isShowForgotModal: boolean;
};

const ForgotPasswordModal = ({
  isShowForgotModal,
  setIsShowForgotModal,
}: ModalProps) => {
  const [email, setEmail] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [forgotPage, setForgotPage] = useState<ForgotPasswordPage>(
    ForgotPasswordPage.ResetPassword
  );

  const isValidEmail = !_.isEmpty(email) && email.includes("@");
  const toast = useToast();
  const { t } = useTranslation();

  const [getOtp, { data: otpStatus }] = useMutation(ForgetPasswordDocument, {
    onCompleted: (success) => {
      return toast({
        position: "bottom-right",
        title: `Otp sent successfully`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Fail to get otp code`,
        status: "error",
      });
    },
  });

  const [verifyOtp, { data: verifyOtpStatus }] = useMutation(
    VerifyOtpDocument,
    {
      onCompleted: (success) => {
        setForgotPage(ForgotPasswordPage.ResetPassword);
        //   return toast({
        //     position: "bottom-right",
        //     title: `Otp sent successfully`,
        //     status: "success",
        //   });
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: `Incorret otp code or email.`,
          status: "error",
        });
      },
    }
  );

  const [resetPw, { data: resetPwStatus, loading: reseting }] = useMutation(
    ResetPasswordDocument,
    {
      onCompleted: (success) => {
        toast({
          position: "bottom-right",
          title: `Password reset successfully`,
          status: "success",
        });
        onClose();
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    }
  );

  const contiune = () => {
    if (forgotPage === ForgotPasswordPage.EnterEmail) {
      // step one get otp

      getOtp({
        variables: {
          input: {
            email: email,
          },
        },
      });
      setForgotPage(ForgotPasswordPage.ValidEmail);
    } else if (forgotPage === ForgotPasswordPage.ValidEmail) {
      // step two verify otp

      verifyOtp({
        variables: {
          input: {
            email: email,
            otp: otpCode,
          },
        },
      });
    } else if (forgotPage === ForgotPasswordPage.ResetPassword) {
      resetPw({
        variables: {
          input: {
            otp: otpCode,
            email: email,
            password: newPassword,
          },
        },
      });
    }
  };
  const onClose = () => {
    setEmail("");
    setForgotPage(ForgotPasswordPage.EnterEmail);
    setIsShowForgotModal(false);
  };

  useEffect(() => {
    setForgotPage(ForgotPasswordPage.EnterEmail);

    return () => {
      setForgotPage(ForgotPasswordPage.EnterEmail);
    };
  }, []);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isShowForgotModal}
      onClose={() => onClose()}
      isCentered
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={10}>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            experimental_spaceY="8"
          >
            {forgotPage === ForgotPasswordPage.EnterEmail && (
              <>
                <Text fontWeight={600} fontSize="65px">
                  {t("TXT_Forget_Pw")}
                </Text>
                <Text color={returnByTheme()} fontWeight={400} fontSize="25px">
                  {t("TXT_Signup_forgotPW_desc")}
                </Text>
                <Box width="75%">
                  <BaseInput
                    color={returnByTheme()}
                    backgroundColor="WT"
                    title="Email"
                    value={email}
                    borderColor={returnByTheme()}
                    left={<EmailIcon stroke="currentcolor" />}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                </Box>
                <StyledButton
                  color={returnByTheme()}
                  onClick={() => contiune()}
                  disable={!isValidEmail}
                  isLoading={reseting}
                >
                  {t("TXT_Continue")}
                </StyledButton>
              </>
            )}
            {forgotPage === ForgotPasswordPage.ValidEmail && (
              <>
                <Text fontWeight={600} fontSize="65px">
                  {t("TXT_Signup_forgotPW_desc_1")}
                </Text>
                <Text color={returnByTheme()} fontWeight={400} fontSize="25px">
                  {t("TXT_Signup_forgotPW_desc_2")}
                </Text>
                <Flex
                  width="50%"
                  alignItems="center"
                  justifyContent="center"
                  alignSelf="center"
                  experimental_spaceX="8"
                >
                  <PinInput
                    size="lg"
                    placeholder=""
                    colorScheme="blue"
                    onComplete={(e) => setOtpCode(e)}
                  >
                    <PinInputField
                      borderColor={returnByTheme()}
                      borderWidth={2}
                      paddingY={8}
                    />
                    <PinInputField
                      borderColor={returnByTheme()}
                      borderWidth={2}
                      paddingY={8}
                    />
                    <PinInputField
                      borderColor={returnByTheme()}
                      borderWidth={2}
                      paddingY={8}
                    />
                    <PinInputField
                      borderColor={returnByTheme()}
                      borderWidth={2}
                      paddingY={8}
                    />
                  </PinInput>
                </Flex>
                <StyledButton
                  color={returnByTheme()}
                  onClick={() => contiune()}
                  disable={!isValidEmail}
                >
                  {t("TXT_Continue")}
                </StyledButton>
              </>
            )}
            {forgotPage === ForgotPasswordPage.ResetPassword && (
              <>
                <Text fontWeight={600} fontSize="65px">
                  {t("TXT_Reset_pw")}
                </Text>
                <Text color={returnByTheme()} fontWeight={400} fontSize="25px">
                  {t("TXT_Signup_forgotPW_desc_3")}
                </Text>
                <Box flexDirection="column" experimental_spaceY="8" width="75%">
                  <PasswordInput
                    color={returnByTheme()}
                    title={t("TXT_New_password")}
                    backgroundColor="WT"
                    borderColor={returnByTheme()}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.currentTarget.value)}
                  />
                  <PasswordInput
                    color={returnByTheme()}
                    title={t("TXT_Confirm_password")}
                    backgroundColor="WT"
                    borderColor={returnByTheme()}
                    value={ConfirmPassword}
                    onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                  />
                </Box>
                <StyledButton
                  color={returnByTheme()}
                  onClick={() => contiune()}
                  disable={
                    newPassword !== ConfirmPassword || newPassword.length == 0
                  }
                >
                  {t("TXT_Continue")}
                </StyledButton>
              </>
            )}
          </Flex>
        </ModalBody>

        {/*           <ModalFooter alignItems="center" justifyContent="center">
      </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default ForgotPasswordModal;
