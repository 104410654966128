import { Box, chakra, Flex, Divider } from "@chakra-ui/react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Text,
  Cell,
  CartesianAxis,
} from "recharts";

const ProductionQuantityBarChart = ({ singleBarData, containerWidth }: any) => {
  const { t } = useTranslation();
  const [focusBar, setFocusBar] = useState<number | null>(null);

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    if (payload && payload.value) {
      return (
        <Text
          fontSize={"10px"}
          width={"50px"}
          x={x}
          y={y}
          textAnchor="middle"
          verticalAnchor="start"
          fill={
            singleBarData[payload.index]?.profit == 0 ? "#E8E8E8" : "#000000"
          }
        >
          {payload.value}
        </Text>
      );
    }
    return null;
  };
  const CustomYAxisTick = ({ x, y, payload }: any) => {
    if (payload && payload.value) {
      return (
        <Text x={x - 20} y={y} textAnchor="middle" verticalAnchor="middle">
          {payload.value > 1000 ? `${payload.value / 1000}k` : payload.value}
        </Text>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      if (payload[0]?.value == 0) return <></>;
      return (
        <Flex
          bg="WT"
          // width="50px"
          borderRadius="8px"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          flexDir="column"
          alignItems="center"
          p="10px"
          borderColor="GREY"
        >
          <Box fontSize="10px">{t("TXT_Total")}</Box>
          <Box fontWeight="bold">{`${payload[0].value / 1000}k`}</Box>
          <Box fontSize="8px" color="GREY">
            KWh
          </Box>
        </Flex>
      );
    }

    return null;
  };
  return (
    // <>
    //   <Flex fontWeight="bold" mb="30px">
    //     {t("TXT_Dashboard_bar_chart_title")}
    //   </Flex>
    // <ResponsiveContainer  >
    <BarChart
      data={singleBarData}
      margin={{
        top: 20,
        right: 0,
        left: 0,
        bottom: 15,
      }}
      height={containerWidth - 80}
      width={750}
      onMouseMove={(state) => {
        if (state.isTooltipActive) {
          setFocusBar(state.activeTooltipIndex!);
        } else {
          setFocusBar(null);
        }
      }}
    >
      {/* <CartesianAxis /> */}
      <CartesianGrid strokeDasharray="1" vertical={false} />
      <XAxis
        dataKey="name"
        interval={0}
        tick={<CustomXAxisTick />}
        tickLine={{ stroke: "none" }}
      />
      <YAxis tick={<CustomYAxisTick />} />
      <Tooltip content={<CustomTooltip />} cursor={false} />
      {/* <Legend /> */}
      <Bar dataKey="profit" barSize={50} fill={"#82ca9d"}>
        {singleBarData.map((entry: any, index: number) => (
          <Cell
            key={`cell-${index}`}
            fill={focusBar === index ? "#09A34E" : "#82ca9d"}
          />
        ))}
      </Bar>
    </BarChart>
    // </ResponsiveContainer>
    // </>
  );
};

export default ProductionQuantityBarChart;
