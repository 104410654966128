import axios from "axios";
import _ from "lodash";

export const isEmptyString = (value: string | number) => {
  return value?.toString().trim().length === 0 || value === "";
};

export const convertDisplayText = (value: string) => {
  const rawText = value.replace(/[^a-zA-Z0-9 ]/g, " ");

  if (value) {
    let firstCapLetter = rawText[0].toUpperCase();
    let restOfLetter = rawText.slice(1).toLowerCase();
    return firstCapLetter + restOfLetter;
  } else {
    return "-";
  }
};

export const convertFirstUpperText = (value: string, splitBy: string = "_") => {
  const titleArr = splitBy ? value.split(splitBy) : value.split("_");

  if (value) {
    return titleArr
      .map((title) => {
        return title[0].toUpperCase() + title.slice(1).toLowerCase();
      })
      .join(" ");
  } else {
    return "-";
  }
};

export const convertAsAvatarTitle = (title: string) => {
  const titleArr = title ? title.split(" ") : ["N", "A"];

  return titleArr.length > 1
    ? titleArr[0]?.charAt(0) + titleArr[1]?.charAt(0)
    : titleArr[0]?.substring(0, 2).toUpperCase();
};

export const uploadDoc = async (file: Array<File>) => {
  const endPoint =
  // process.env.REACT_APP_UPLOAD_PATH
  process.env.REACT_APP_CUSTOM_ENV === "dev"
      ? "https://api-dev.solar-monster.io/upload"
      : "https://api.solar-monster.io/upload";
  let data = new FormData();

  for (let index = 0; index < file?.length; index++) {
    data.append("files", file[index]);
  }

  try {
    const res = await axios({
      method: "post",
      url: endPoint,
      headers: {
        "Content-Type": "multipart/form-data",
        // "Access-Control-Allow-Origin": "*",
      },
      data: data,
    });
    if (res.status === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCurrProjectStatus = (pjObj: any) => {
  const statusKeyArr = [
    "startedAt",
    "approvalLetterSentAt",
    "acknowledgedAt",
    "hardwareInstalledAt",
    "systemTestedAt",
    "fitMeterInstalledAt",
    "completedAt",
    "contractEndedAt",
  ];

  const convertStatusAsObj = statusKeyArr.map((s, i) => {
    if (pjObj[s] !== null && pjObj[s] !== undefined) {
      return { status: s, date: pjObj[s], indexOfStatus: i };
    }
  });

  return _.compact(convertStatusAsObj);
};

export const getCurrStatusDisplayName = (
  statusArr: { status: string; date: any; indexOfStatus: number }[]
) => {
  const statusTextArr: { [key: string]: string } = {
    startedAt: "TXT_PjOption_Started",
    approvalLetterSentAt: "TXT_PjOption_ApprovalLetterSent",
    acknowledgedAt: "TXT_PjOption_Acknowledged",
    hardwareInstalledAt: "TXT_PjOption_HardwareInstalled",
    systemTestedAt: "TXT_PjOption_SystemTested",
    fitMeterInstalledAt: "TXT_PjOption_FitMeterInstalled",
    completedAt: "TXT_PjOption_Completed",
    contractEndedAt: "TXT_PjOption_ContractEnded",
  };
  if (statusArr.length > 0) {
    const lastIndex = statusArr.length - 1;
    const displayName = statusTextArr[statusArr[lastIndex].status];

    return {
      displayName: displayName,
      date: statusArr[lastIndex].date,
      index: statusArr[lastIndex].indexOfStatus,
    };
  } else {
    return {
      displayName: "UNKNOW",
      date: new Date(),
      index: 0,
    };
  }
};

export const convertAsCurrPjStatus = (statusArr: any) => {
  let currStatus = "before";
  statusArr.forEach((element: any) => {
    switch (element.status) {
      case "startedAt":
      case "approvalLetterSentAt":
        currStatus = "before";
        break;
      case "acknowledgedAt":
      case "hardwareInstalledAt":
      case "systemTestedAt":
      case "fitMeterInstalledAt":
        currStatus = "running";
        break;
      case "completedAt":
      case "contractEndedAt":
        currStatus = "end";
        break;
      default:
        currStatus = "before";
        break;
    }
  });

  return currStatus;
};

export const removeUselessKeyForObj = (array: any) => {
  return array.map((obj: any) => {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  });
};

export const returnByTheme = () => {
  const isClient = localStorage.getItem("sf_Role") === "true";

  return isClient ? "themeOrange" : "themeBlue";
};

export const returnByColor = () => {
  const isClient = localStorage.getItem("sf_Role") === "true";

  return isClient ? "#FCAF20" : "#004b92";
};

export const calculateIrr = (values: any, guess: any) => {
  // Credits: algorithm inspired by Apache OpenOffice

  // Calculates the resulting amount
  var irrResult = function (values: any, dates: any, rate: any) {
    var r = rate + 1;
    var result = values[0];
    for (var i = 1; i < values.length; i++) {
      result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365);
    }
    return result;
  };

  // Calculates the first derivation
  var irrResultDeriv = function (values: any, dates: any, rate: any) {
    var r = rate + 1;
    var result = 0;
    for (var i = 1; i < values.length; i++) {
      var frac = (dates[i] - dates[0]) / 365;
      result -= (frac * values[i]) / Math.pow(r, frac + 1);
    }
    return result;
  };

  // Initialize dates and check that values contains at least one positive value and one negative value
  var dates: any = [];
  var positive = false;
  var negative = false;
  for (var i = 0; i < values.length; i++) {
    dates[i] = i === 0 ? 0 : dates[i - 1] + 365;
    if (values[i] > 0) positive = true;
    if (values[i] < 0) negative = true;
  }

  // Return error if values does not contain at least one positive value and one negative value
  if (!positive || !negative) return "#NUM!";

  // Initialize guess and resultRate
  var guess = typeof guess === "undefined" ? 0.1 : guess;
  var resultRate = guess;

  // Set maximum epsilon for end of iteration
  var epsMax = 1e-10;

  // Set maximum number of iterations
  var iterMax = 50;

  // Implement Newton's method
  var newRate, epsRate, resultValue;
  var iteration = 0;
  var contLoop = true;
  do {
    resultValue = irrResult(values, dates, resultRate);
    newRate =
      resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
    epsRate = Math.abs(newRate - resultRate);
    resultRate = newRate;
    contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
  } while (contLoop && ++iteration < iterMax);

  if (contLoop) return "#NUM!";

  // Return internal rate of return
  return resultRate;
};

export const randomBgColor = () => {
  const colorArr = [
    "#F79846",
    "#F6B849",
    "#E7311B",
    "#F9C194",
    "#6B8ACA",
    "#203A97",
    "#87CCDC",
    "#64A2BB",
    "#3FACA7",
    "#B9EA9B",
  ];

  return colorArr[Math.floor(Math.random() * colorArr.length)];
};
