import { Box, Input, Text, Flex, Image } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useRef, useState, useEffect } from "react";
import { ReactComponent as CircleCloseWTBG } from "src/icons/general/circleCloseWTBG.svg";
import { ReactComponent as PDFIcon } from "src/icons/general/pdfIcon.svg";
import { ReactComponent as TrashIcon } from "src/icons/general/trashIcon.svg";
import { returnByTheme, returnByColor } from "../../utils/util";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface IFileUploadProps {
  placeHolder?: string;
  isRequired?: boolean;
  inputLabel?: string;
  onUploadFiles: (files: Array<File>) => void;
  onDeleteFile: (index: number) => void;
  filesList?: any;
  limitedType?: string;
  litmitedDesc?: string;
  litmitedDesc2?: string;
  uploadBtnIcon?: any;
  uploadBtnText?: string;
  displayType?: "preview" | "list";
  allowMulti?: boolean;
  watchOnly?: boolean;
}

const FormFilesInput = ({
  placeHolder,
  isRequired = false,
  inputLabel,
  onUploadFiles,
  onDeleteFile,
  filesList,
  limitedType,
  litmitedDesc,
  litmitedDesc2,
  uploadBtnIcon,
  uploadBtnText,
  displayType = "preview",
  allowMulti,
  watchOnly = false,
}: IFileUploadProps) => {
  const uploadRef = useRef<any>(null);
  const { t } = useTranslation();

  const sizeValidation = () => {
    // check size to do
  };

  const filesUploadOnchange = (event: any) => {
    sizeValidation();

    const arrOfFiles = Array.from(event?.target?.files).map((f: any, i) => {
      return f;
    });

    // const arrOfExistFiles = filesList?.map((f: any, i: number) => {
    //   return f.file;
    // });

    // if (filesList !== undefined) {
    //   //   const concatedArr = _.concat(filesList, arrOfFiles);
    //   //   console.log(concatedArr);

    //   onUploadFiles([...arrOfExistFiles, ...arrOfFiles]);
    // } else {
    //   onUploadFiles(arrOfFiles);
    // }
    onUploadFiles(arrOfFiles);
    uploadRef.current.value = "";
  };

  const handleDeleteFile = (index: number) => {
    onDeleteFile(index);
  };

  const handleCustomBtnClick = () => {
    uploadRef?.current?.click();
  };

  return (
    <Flex>
      <Flex
        flex={1}
        flexDirection={"row"}
        p="20px"
        justifyContent={"space-between"}
        gap="5px"
      >
        <Input
          display={"none"}
          ref={uploadRef}
          multiple={allowMulti}
          type={"file"}
          accept={limitedType}
          onChange={(e) => {
            filesUploadOnchange(e);
          }}
        />
        {/* left section */}
        <Flex
          flexDirection={"column"}
          flex={2}
          fontSize={15}
          color={"GREY"}
          flexWrap={"wrap"}
          alignItems={"baseline"}
        >
          <Box width={"100%"}>
            {isRequired ? (
              <Box>
                <Text display={"inline"}>{inputLabel}&nbsp;</Text>
                <Text color={"RD.1"} display={"inline"}>
                  *
                </Text>
              </Box>
            ) : (
              <Flex>{inputLabel}</Flex>
            )}
          </Box>

          <Box
            width={"100%"}
            fontSize={"10px"}
            mb={litmitedDesc2 ? "0px" : "17px"}
          >
            {litmitedDesc}
          </Box>
          <Box width={"100%"} fontSize={"10px"} mb="17px">
            {litmitedDesc2}
          </Box>
          {allowMulti && (
            <Flex
              borderRadius={"5px"}
              borderColor={returnByTheme()}
              borderWidth={1}
              onClick={() => handleCustomBtnClick()}
              px="20px"
              py="3px"
              color={returnByTheme()}
              alignItems={"center"}
              cursor={"pointer"}
              // _hover={{ color: "WT", background: "themeBlue" }}
            >
              + {t("TXT_Add")}
            </Flex>
          )}
        </Flex>
        {/* files input section */}
        <Flex flex={3}>
          {(filesList !== undefined && filesList?.length > 0 && (
            <Flex
              flexWrap={"wrap"}
              justifyContent={"flex-start"}
              width={"100%"}
            >
              {filesList.map((f: any, i: number) => {
                // const imageUrl = URL.createObjectURL(f?.file);

                const extensionName = f?.originalName.split(".").pop();
                if (displayType == "preview") {
                  return (
                    <Flex
                      key={i}
                      position={"relative"}
                      borderRadius={"5px"}
                      borderWidth={1}
                      width={"25%"}
                    >
                      <Image
                        src={f?.url}
                        //   width="100px"
                        height="100%"
                        borderRadius={"5px"}
                        fit={"contain"}
                        loading={"lazy"}
                      />
                      {!watchOnly && (
                        <Flex
                          position={"absolute"}
                          top={"-5px"}
                          right={"-5px"}
                          zIndex={2}
                          onClick={() => handleDeleteFile(i)}
                          cursor="pointer"
                        >
                          <CircleCloseWTBG />
                        </Flex>
                      )}
                    </Flex>
                  );
                } else {
                  return (
                    <Flex
                      key={i}
                      flexDirection={"column"}
                      width={"100%"}
                      borderBottom={i !== filesList?.length - 1 ? "1px" : "0px"}
                      borderColor={"DISABLEDBG"}
                      pb="10px"
                      pt={i !== 0 ? "10px" : "0"}
                    >
                      <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                          {(extensionName !== "pdf" && (
                            <Image
                              width={"30px"}
                              height={"30px"}
                              src={f?.url}
                              borderRadius={"5px"}
                              fit={"cover"}
                              loading={"lazy"}
                            />
                          )) || <PDFIcon width="30px" height="30px" />}
                          <Text
                            ml="5px"
                            noOfLines={1}
                            textOverflow="ellipsis"
                            maxW="250px"
                          >
                            {f?.originalName}
                          </Text>
                        </Flex>

                        {!watchOnly && (
                          <TrashIcon
                            onClick={() => handleDeleteFile(i)}
                            cursor="pointer"
                          />
                        )}
                      </Flex>
                    </Flex>
                  );
                }
              })}
            </Flex>
          )) || (
            <Flex
              flex={1}
              borderStyle={"dashed"}
              borderColor={returnByTheme()}
              borderWidth={1}
              borderRadius={"5px"}
              flexDirection={"column"}
              alignItems={"center"}
              p={"16px"}
              cursor={watchOnly ? "not-allowed" : "pointer"}
              onClick={() => {
                if (!watchOnly) {
                  handleCustomBtnClick();
                }
              }}
            >
              {uploadBtnIcon}
              <Flex color={returnByTheme()} textAlign={"center"}>
                {uploadBtnText}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FormFilesInput;
