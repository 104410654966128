import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Icon,
  useToast,
  Link,
  Avatar,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { returnByTheme, returnByColor } from "../../../utils/util";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import { t } from "i18next";
import { FiGlobe, FiMail, FiPhone } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";

const ProjectStaffSection = ({ siteInfo }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  return (
    <Flex
      py="10px"
      borderRadius="8px"
      boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
      bg={"WT"}
    >
      <Flex flexDirection="column" flex={1} overflow="hidden">
        <Flex p="10px" color={returnByTheme()} fontWeight="bold">
          {t("TXT_Project_Project_manager")}
        </Flex>

        <Flex bg="DISABLEDTEXT" height="1px" mb="10px"></Flex>

        <Box m="10px">
          <Swiper
            modules={[EffectFade, Navigation]}
            spaceBetween={0}
            navigation={true}
            effect="fade"
            // loop={true}
          >
            {siteInfo?.site?.projectManagers.map((pm: any, index: number) => {
              // imgRef.current[index] = createRef();
              return (
                <SwiperSlide key={index}>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDir="column"
                    bg="WT"
                  >
                    <Avatar w="100px" h="100px" src="avatar-1.jpg" />
                    <Flex my="10px"> {pm?.name}</Flex>
                    <Flex
                      mb="10px"
                      alignItems="center"
                      color={returnByTheme()}
                      gap="5px"
                    >
                      <FiPhone />
                      {pm?.phone?.phone}
                    </Flex>
                    <Link
                      display="flex"
                      bg={returnByTheme()}
                      variant="solid"
                      alignItems="center"
                      gap="10px"
                      borderRadius="8px"
                      color="WT"
                      fontWeight="normal"
                      px="10px"
                      py="5px"
                      href="mailto:tim@instelar.com"
                    >
                      <FiMail color="#ffffff" />
                      {pm?.email}
                    </Link>
                  </Flex>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ProjectStaffSection;
