import _ from "lodash";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Progress,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";
import { returnByTheme } from "src/utils/util";
import { useNavigate } from "react-router-dom";
import { DashboardInvoiceSumDocument } from "src/graphql/generated";
import { ReactComponent as DebitNoteIcon } from "src/icons/general/dnTotalRed.svg";
import { ReactComponent as CreditNoteIcon } from "src/icons/general/cnTotalGreen.svg";

const InvoicesRow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const { data: invoiceData } = useQuery(DashboardInvoiceSumDocument, {
    onCompleted: (data) => {},
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Fail to get invoices data`,
        status: "error",
      });
    },
  });

  return (
    <Flex justifyContent="space-between" gap="20px" overflow="auto">
      <Flex
        flex="1 0 auto"
        py="10px"
        px="20px"
        borderRadius="8px"
        boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
        bg="WT"
        gap="10px"
      >
        <Flex flex={1.5} alignItems="center" gap="20px">
          <Flex
            // width="100px"
            // height="100px"
            bg="RD.3"
            p="25px"
            borderRadius="12px"
          >
            <DebitNoteIcon />
            {/* <Image src={require("../../icons/general/dnTotalRed.png")} /> */}
          </Flex>
          <Flex flexDir="column" color="GREY" minW="130px">
            <Text noOfLines={1}>{t("TXT_Dashboard_debit_note_title")}</Text>
            <Flex alignItems="baseline" flexWrap="wrap">
              <Text color="BK" fontWeight="bold" fontSize="28px">
                {invoiceData?.dashboardInvoiceSum.debitNoteSum} &nbsp;
              </Text>
              <Text color="GREY"> HKD</Text>
            </Flex>
            <Text fontSize="8px" fontWeight="bold" color="BK">
              {t("TXT_Dashboard_year_to_date")}
            </Text>
          </Flex>
          <Flex
            color={returnByTheme()}
            bg="WT"
            py="10px"
            minW="110px"
            ml="auto"
            justifyContent="center"
            borderRadius="25px"
            boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)"
            cursor="pointer"
            onClick={() => {
              navigate(`/invoice`, {
                state: {
                  autoFilterType: "Debit",
                },
              });
            }}
          >
            {t("TXT_Dashboard_all_invoices")}
          </Flex>
        </Flex>

        <Flex width="1px" bg="DISABLEDBG" mx="10px"></Flex>

        <Flex flex={1} alignItems="center" justifyContent="space-between">
          <Flex flexDir="column" color="GREY">
            <Flex> {t("TXT_Option_Overdue")}</Flex>
            <Flex alignItems="baseline" flexDir="row">
              <Flex color="RD.2" fontWeight="bold" fontSize="28px">
                {invoiceData?.dashboardInvoiceSum.debitNoteOverdue + " "}
              </Flex>
              <Flex color="GREY"> {` HKD`}</Flex>
            </Flex>
          </Flex>

          <Image
            width="50px"
            height="50px"
            src={require("../../icons/general/magnifier.png")}
            onClick={() => {
              navigate(`/invoice`, {
                state: {
                  autoFilterStatus: "Overdue",
                  autoFilterType: "Debit",
                },
              });
            }}
            cursor="pointer"
          ></Image>
        </Flex>
      </Flex>

      <Flex
        flex="1 0 auto"
        py="10px"
        px="20px"
        borderRadius="8px"
        boxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.05)"
        bg="WT"
        gap="10px"
      >
        <Flex alignItems="center" gap="20px">
          <Flex
            // width="100px"
            // height="100px"
            bg="GN.1"
            p="25px"
            borderRadius="12px"
          >
            <CreditNoteIcon />
            {/* <Image src={require("../../icons/general/cnTotalGreen.png")} /> */}
          </Flex>
          <Flex flexDir="column" color="GREY" minW="130px">
            <Text>{t("TXT_Dashboard_credit_note_title")}</Text>
            <Flex alignItems="baseline" flexWrap="wrap">
              <Text color="BK" fontWeight="bold" fontSize="28px">
                {invoiceData?.dashboardInvoiceSum.creditNoteSum} &nbsp;
              </Text>
              <Text color="GREY"> HKD</Text>
            </Flex>
            <Text fontSize="8px" fontWeight="bold" color="BK">
              {t("TXT_Dashboard_year_to_date")}
            </Text>
          </Flex>
          <Flex
            color={returnByTheme()}
            bg="WT"
            py="10px"
            minW="110px"
            ml="auto"
            justifyContent="center"
            // px="15px"
            borderRadius="25px"
            boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)"
            cursor="pointer"
            onClick={() => {
              navigate(`/invoice`, {
                state: {
                  autoFilterType: "Credit",
                },
              });
            }}
          >
            {t("TXT_Dashboard_all_invoices")}
          </Flex>
        </Flex>

        <Flex width="1px" bg="DISABLEDBG" mx="10px"></Flex>

        <Flex flex={1} alignItems="center" justifyContent="space-between">
          <Flex flexDir="column" color="GREY">
            <Flex> {t("TXT_Option_Overdue")}</Flex>
            <Flex alignItems="baseline" flexDir="row">
              <Flex color="RD.2" fontWeight="bold" fontSize="28px">
                {invoiceData?.dashboardInvoiceSum.creditNoteOverdue + " "}
              </Flex>
              <Flex color="GREY"> {` HKD`}</Flex>
            </Flex>
          </Flex>

          <Image
            width="50px"
            height="50px"
            src={require("../../icons/general/magnifier.png")}
            onClick={() => {
              navigate(`/invoice`, {
                state: {
                  autoFilterStatus: "Overdue",
                  autoFilterType: "Credit",
                },
              });
            }}
            cursor="pointer"
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InvoicesRow;
