import {
  Box,
  Image,
  Flex,
  Text,
  Divider,
  GridItem,
  Grid,
  useToast,
  Spinner,
  CloseButton,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import CopyRightBar from "src/components/CopyRightBar";
import {
  convertAsAvatarTitle,
  randomBgColor,
  returnByTheme,
} from "../../utils/util";
import { FiCheckCircle, FiClock, FiUser } from "react-icons/fi";
import { BsChevronDoubleRight } from "react-icons/bs";
import {
  NotificationAddedDocument,
  ReadNotificationDocument,
  NotificationsDocument,
  ReadNotificationsDocument,
  RemoveNotificationDocument,
} from "src/graphql/generated";
import GeneralTopHeader from "src/components/GeneralTopHeader";
import { isNil } from "lodash";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const Notification = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [notiList, setNotiList] = useState<any>([]);
  const [isViewAll, setIsViewAll] = useState(true);
  const [loadTotal, setLoadTotal] = useState(10);

  const containerRef = useRef(null);

  const [getNotis, { data: latestNotisData, loading: isLoading }] =
    useLazyQuery(NotificationsDocument, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (isViewAll) {
          const gpByDateArr = _.groupBy(data.notifications.nodes, (result) =>
            moment(result.createdAt).format("D MMM, YYYY")
          );

          setNotiList([gpByDateArr]);
        } else {
          const gpByDateArr = _.groupBy(
            data.notifications.nodes.filter((noti: any) =>
              isNil(noti.viewedAt)
            ),
            (result) => moment(result.createdAt).format("D MMM, YYYY")
          );
          setNotiList([gpByDateArr]);
        }
      },
      onError: (error) => {
        return toast({
          position: "bottom-right",
          title: t("TXT_Error_general_err_message"),
          status: "error",
        });
      },
    });

  const queryMore = () => {
    if (latestNotisData?.notifications.pageInfo.hasNextPage) {
      setLoadTotal(loadTotal + 5);
      getNotis({
        variables: {
          limit: loadTotal + 5,
        },
      });
    }
  };

  const [markReadNoti, {}] = useMutation(ReadNotificationsDocument, {
    onCompleted: (success) => {
      getNotis();
      toast({
        position: "bottom-right",
        title: `Marked all notification as read`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [markOneReadNoti, {}] = useMutation(ReadNotificationDocument, {
    onCompleted: (success) => {
      getNotis();
      // toast({
      //   position: "bottom-right",
      //   title: `Marked one notification as read`,
      //   status: "success",
      // });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const [removeNoti, {}] = useMutation(RemoveNotificationDocument, {
    onCompleted: (success) => {
      getNotis();
      toast({
        position: "bottom-right",
        title: `One notification had been removed`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  useEffect(() => {
    getNotis({
      variables: {
        limit: loadTotal,
      },
    });
    return () => {};
  }, []);

  return (
    <>
      <Helmet>
        <title>Solar Monster - Notification</title>
      </Helmet>
      {/* header section */}
      <Flex
        flexDirection="column"
        flex={1}
        h="100vh"
        backgroundColor="#F7FAFA"
        overflowX="auto"
        ml="65px"
      >
        <GeneralTopHeader
          headerTitle={t("TXT_Notification")}
          hadRightCornerTools
        />

        <Flex
          flexDirection="column"
          marginTop={"25px"}
          maxW="1920px"
          mx="auto"
          px="20px"
          width="100%"
          mb="40px"
          maxH="85vh"
          overflow="auto"
          ref={containerRef}
          onScroll={() => {
            // queryMore();
            if (containerRef.current) {
              const { scrollTop, scrollHeight, clientHeight } =
                containerRef.current;

              if (scrollTop + clientHeight + 5 > scrollHeight && !isLoading) {
                queryMore();
              }
            }
          }}
        >
          <Flex justifyContent="space-between" mb="20px" alignItems="center">
            <Flex
              gap="5px"
              borderRadius="25px"
              border="1px solid #004b92"
              p="3px"
            >
              <Flex
                px="10px"
                py="5px"
                bg={isViewAll ? "themeBlue" : "WT"}
                color={isViewAll ? "WT" : "GREY"}
                cursor="pointer"
                borderRadius="25px"
                border={isViewAll ? "1px solid #004b92" : "none"}
                onClick={() => {
                  setIsViewAll(true);
                  getNotis({
                    variables: {
                      limit: loadTotal,
                    },
                  });
                }}
              >
                {t("TXT_Notification_view_all")}
              </Flex>

              <Flex
                px="10px"
                py="5px"
                bg={isViewAll ? "WT" : "themeBlue"}
                color={isViewAll ? "GREY" : "WT"}
                cursor="pointer"
                borderRadius="25px"
                border={isViewAll ? "none" : "1px solid #004b92"}
                onClick={() => {
                  setIsViewAll(false);
                  getNotis({
                    variables: {
                      limit: loadTotal,
                    },
                  });
                }}
              >
                {t("TXT_Notification_unread")}
              </Flex>
            </Flex>
            <Flex
              gap="10px"
              alignItems="center"
              color="themeBlue"
              cursor="pointer"
              // color={
              //   !isViewAll && notiList.length > 0 ? "themeBlue" : "DISABLEDTEXT"
              // }
              // cursor={
              //   !isViewAll && notiList.length > 0 ? "pointer" : "not-allowed"
              // }
              onClick={() => {
                if (!isViewAll && notiList.length == 0) return;
                markReadNoti({
                  variables: {
                    notificationIds: notiList.map((n: any) => {
                      return n.notificationId;
                    }),
                    viewedAt: new Date(),
                  },
                });
              }}
            >
              {t("TXT_Notification_mark_all_as_read")}
              <FiCheckCircle />
            </Flex>
          </Flex>

          <Flex flexDir="column">
            {notiList.map((noti: any, i: number) => {
              if (Object.keys(noti).length > 0) {
                return (
                  <Flex flexDir="column" key={i}>
                    {Object.entries(noti).map((notiObj, objIndex) => {
                      const tag = notiObj[0];
                      const data: any = notiObj[1] ?? [];

                      return (
                        <div key={objIndex}>
                          <Text
                            p="5px"
                            bg="DISABLEDTEXT"
                            borderRadius="4px"
                            width="fit-content"
                            mb="15px"
                          >
                            {tag}
                          </Text>
                          {data.map((n: any, ni: number) => {
                            return (
                              <div key={ni}>
                                <NotificationDetailRow
                                  data={n}
                                  removeNoti={removeNoti}
                                  markOneReadNoti={markOneReadNoti}
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </Flex>
                );
              } else {
                return (
                  <Flex
                    key={i}
                    flexDir="column"
                    gap="10px"
                    justifyContent="center"
                    alignItems="center"
                    p="50px 20px"
                    bg="WT"
                    borderRadius="12px"
                  >
                    <Image
                      src={require("../../icons/general/noNotiIcon.png")}
                      w="80px"
                      h="80px"
                    />
                    <Flex color={"themeBlue"} fontWeight="bold">
                      {t("TXT_Notification_no_notification")}
                    </Flex>
                  </Flex>
                );
              }
            })}
          </Flex>
        </Flex>

        {/* <Flex onClick={() => }>temp to Fetch more</Flex> */}

        <CopyRightBar />
      </Flex>
    </>
  );
};

const NotificationDetailRow = ({ data, removeNoti, markOneReadNoti }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = isNil(data.viewedAt);

  const newAuthorObj =
    data.author.__typename == "Company"
      ? {
          name: data.author?.companyName,
          email: data.author?.companyEmail,
          url: data.author?.profileImage?.url,
        }
      : data.author.__typename == "User"
      ? {
          name: data.author?.userName,
          email: data.author?.email,
          url: data.author?.profileImage?.url,
        }
      : {
          name: "System",
          email: "-",
          url: "-",
        };

  const convertNotiTitle = (
    i18nKey: string,
    keyValueProps: Array<{ key: string; value: string }>
  ) => {
    // console.log(i18nKey);
    // console.log(keyValueProps);
    let paramsObj = {};

    const arrOfi18nKey = i18nKey.split(",");
    // console.log(arrOfi18nKey);

    // convert to key pair object for i18n
    for (let index = 0; index < keyValueProps.length; index++) {
      const objKey = keyValueProps[index].key;
      const objValue = keyValueProps[index].value;
      paramsObj = { ...paramsObj, ...{ [objKey]: objValue } };
    }

    const arrOfFinalText = arrOfi18nKey.map((a) => {
      return t(a, paramsObj);
    });

    // const finalText = arrOfFinalText.join(" ");

    return arrOfFinalText;
  };

  const goDestination = (
    type: string,
    props: Array<{ key: string; value: string }>
  ) => {
    let paramsObj: any = {};

    //convest as {[key]:value}
    for (let index = 0; index < props.length; index++) {
      const objKey = props[index].key;
      const objValue = props[index].value;
      paramsObj = { ...paramsObj, ...{ [objKey]: objValue } };
    }
    console.log(paramsObj);

    switch (type) {
      case "account":
        navigate("/account");
        break;
      case "dashboard":
        navigate("/dashboard");
        break;
      case "project_list":
        navigate(`/project`, {
          state: {
            prevSearchType: {
              value: "siteName",
              name: "Search by name",
            },
            prevKeyword: paramsObj?.name,
            prevPayload: {
              variables: {
                filter: {
                  siteName: paramsObj?.name,
                },
              },
            },
          },
        });
        break;
      case "project_edit":
        navigate(`/edit/project/${paramsObj.code}`, {
          state: {
            currId: paramsObj?.id,
          },
        });
        break;
      case "project_detail":
        navigate(`/project/detail/${paramsObj.code}`, {
          state: {
            totalIDs: "noneed",
            currId: paramsObj?.id,
            // status: currTask.siteStatus,
          },
        });
        break;
      case "invoice_list":
      case "invoice":
        const keyWordState = {
          variables: {
            filter: {
              ketword: paramsObj?.id,
            },
            limit: 20,
          },
        };
        const stateForInvoice = paramsObj?.id
          ? {
              prevKeyword: keyWordState,
            }
          : {
              autoFilterStatus: paramsObj?.status,
            };

        navigate("/invoice", {
          state: stateForInvoice,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Flex
      flexDir="column"
      p="20px"
      borderRadius="12px"
      boxShadow="0px 0px 1px 2px rgba(215, 215, 215, 0.05)"
      borderWidth="1px"
      gap="10px"
      bg={isNew ? "WT" : "DISABLEDBG"}
      mb="20px"
    >
      <Flex gap="15px" alignItems="flex-start">
        {(data.author.__typename == "System" && (
          <Flex position="relative">
            <Image src={require("../../icons/general/notiIcon.png")} />
            {isNew && (
              <Flex
                w="20px"
                h="20px"
                bg="RD.2"
                position="absolute"
                top="0"
                right="0"
                borderRadius="50%"
                border="4px solid #ffffff"
              ></Flex>
            )}
          </Flex>
        )) || (
          <Flex
            position="relative"
            borderRadius="50%"
            width="65px"
            height="65px"
            justifyContent="center"
            alignItems="center"
            border="1px solid #f0f0f0"
            bg={
              newAuthorObj.url ? `url(${newAuthorObj?.url})` : randomBgColor()
            }
            bgSize="contain"
            bgPos="center"
            bgRepeat="no-repeat"
          >
            {!newAuthorObj.url && (
              <Text fontSize="28px" color="WT" fontWeight="bold">
                {convertAsAvatarTitle(newAuthorObj.name ?? newAuthorObj.email)}
              </Text>
            )}
            {isNew && (
              <Flex
                w="20px"
                h="20px"
                bg="RD.2"
                position="absolute"
                top="0"
                right="0"
                borderRadius="50%"
                border="4px solid #ffffff"
              ></Flex>
            )}
          </Flex>
        )}

        <Flex flexDir="column" flex={1} gap="5px">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex color="WT" bg="#004b92" borderRadius="8px" p="5px 10px">
              {isNil(data.notificationMetadatas[0]) ||
              data.notificationMetadatas[0]?.value == "user"
                ? t("TXT_System")
                : t(`TXT_${data.notificationMetadatas[0]?.value}`)}
            </Flex>
            <CloseButton
              onClick={() => {
                removeNoti({
                  variables: {
                    notificationId: data.notificationId,
                  },
                });
              }}
            />
          </Flex>
          {
            // convertNotiTitle(
            //   "TXT_Notification_Project_Create,TXT_Notification_Project_Image_Upload,TXT_Notification_Project_Document_Upload",
            //   [
            //     { key: "project_code", value: "site1" },
            //     { key: "project_name", value: "Tin shui wai" },
            //     { key: "image_count", value: "1" },
            //     { key: "document_count", value: "3" },
            //   ]
            // )
            convertNotiTitle(data?.topic, data?.props).map((t, i) => {
              return (
                <Highlighter
                  searchWords={data.props.map((p: any) => {
                    return p.value;
                  })}
                  highlightStyle={{
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                  }}
                  autoEscape={true}
                  // textToHighlight={convertNotiTitle(data?.topic, data?.props)}
                  textToHighlight={t}
                />
              );
            })
          }
        </Flex>
      </Flex>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
        ml="80px"
      >
        <Flex alignItems="center" color="DISABLEDTEXT" gap="10px">
          <Flex alignItems="center" gap="5px">
            <FiUser />
            {t("TXT_By")} {newAuthorObj.name}
          </Flex>
          <Flex alignItems="center" gap="5px">
            <FiClock />{" "}
            {moment(data?.createdAt).toNow(true) + " " + t("TXT_ago")}
          </Flex>
        </Flex>
        {!isNil(data?.landingLink) && (
          <Flex
            color="#459EE3"
            alignItems="center"
            gap="5px"
            cursor="pointer"
            onClick={() => {
              if (isNil(data?.landingLink)) return;
              goDestination(
                data?.landingLink.destination,
                data?.landingLink.props
              );
              markOneReadNoti({
                variables: {
                  notificationId: data.notificationId,
                  viewedAt: new Date(),
                },
              });
            }}
          >
            {t("TXT_Details")} <BsChevronDoubleRight />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Notification;
