import { Box, Button, Text, Flex } from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormNumberTextInput from "src/components/createProjectForm/FormNumberTextInput";
import { returnByTheme } from "../../../utils/util";
import FormTextInput from "src/components/createProjectForm/FormTextInput";
import { IEleDetailData } from "../../../type/IBillInfoInterface";
import CurrencyInput from "react-currency-input-field";

interface IEleDetailProps {
  eleDetailData: IEleDetailData;
  updateEleDetailState: (value: any) => void;
  isPreviewMode?: boolean;
  onClose?: () => void;
  isValidBillAc?: boolean;
}

const EleDetailSection = ({
  eleDetailData,
  updateEleDetailState,
  isPreviewMode = false,
  onClose = () => {},
  isValidBillAc = true,
}: IEleDetailProps) => {
  const { t } = useTranslation();
  // console.log("ele bill detail section state");
  // console.log(eleDetailData);

  const [previewState, setPreviewState] = useState<IEleDetailData>({
    fitCode: "",
    meterID: "",
    fitMeterID: "",
    fitRate: 0,
    energyOutputByEC: 0,
    energyOutputByContractor: 0,
  });
  const displayData = isPreviewMode ? previewState : eleDetailData;

  useEffect(() => {
    if (isPreviewMode) {
      setPreviewState(eleDetailData);
    }
    return () => {};
  }, []);

  const getNewState = (key: string, value: any) => {
    if (isPreviewMode) {
      setPreviewState({
        ...displayData,
        [key]: value,
      });
    } else {
      updateEleDetailState({
        ...displayData,
        [key]: value,
      });
    }
  };

  return (
    <>
      <Flex
        backgroundColor={"WT"}
        borderRadius={"5px"}
        // boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
        width="100%"
        flexDirection={"column"}
        mt="20px"
        pb="10px"
      >
        {!isPreviewMode && (
          <SectionTitle title={t("TXT_Project_Electricity_details")} />
        )}

        <FormTextInput
          placeHolder={t("TXT_Project_FiT_application_code")}
          inputLabel={t("TXT_Project_FiT_application_code")}
          onChange={(value: string) => {
            getNewState("fitCode", value);
          }}
          inpputtedValue={displayData?.fitCode}
        />

        <FormNumberTextInput
          placeHolder={t("TXT_Project_Meter_id")}
          inputLabel={t("TXT_Project_Meter_id")}
          onChange={(value: string) => {
            getNewState("meterID", value);
          }}
          inpputtedValue={displayData?.meterID}
          isDisable={!isValidBillAc}
        />

        <FormNumberTextInput
          placeHolder={t("TXT_Project_Meter_id_fit")}
          inputLabel={t("TXT_Project_Meter_id_fit")}
          onChange={(value: string) => {
            getNewState("fitMeterID", value);
          }}
          inpputtedValue={displayData?.fitMeterID}
          isDisable={!isValidBillAc}
        />

        <Flex
          flex={1}
          flexDirection={"row"}
          py="10px"
          px="20px"
          justifyContent={"space-between"}
          gap="5px"
        >
          {/* label section */}
          <Flex flex={2} fontSize={15} color={"GREY"}>
            <Flex>{t("TXT_Project_Fit_rate")}</Flex>
          </Flex>
          <Flex
            flex={3}
            py="5px"
            borderColor={returnByTheme()}
            borderRadius="5px"
            borderWidth={1}
            justifyContent="space-between"
          >
            <Flex color={"GREY"} px="10px">
              HK$
            </Flex>
            <CurrencyInput
              // className="calc-num-input"
              // defaultValue={1000}
              maxLength={6}
              style={{ outline: "none", width: "100%" }}
              placeholder="0.0"
              value={displayData?.fitRate}
              decimalsLimit={2}
              onValueChange={(value, name) => getNewState("fitRate", value)}
            />
            <Flex color={"GREY"} px="10px">
              $kWh
            </Flex>
          </Flex>
        </Flex>

        {/* <FormNumberTextInput
          placeHolder={"0.0"}
          inputLabel={t("TXT_Project_Fit_rate")}
          onChange={(value: string) => {
            getNewState("fitRate", value);
          }}
          inpputtedValue={displayData?.fitRate.toString()}
          leftIcon={<Flex color={"GREY"}>HK$</Flex>}
          // leftIcon={"HK"}
          rightIcon={<Flex color={"GREY"}>($kWh)</Flex>}
          // rightIcon={"$kWh"}
        /> */}

        <FormNumberTextInput
          placeHolder={t("TXT_Project_Approved_generation_capacity")}
          inputLabel={t("TXT_Project_Energy_output1")}
          addComma={true}
          onChange={(value: string) => {
            getNewState("energyOutputByEC", value);
          }}
          hints={"By CLP/HKE"}
          inpputtedValue={displayData?.energyOutputByEC.toString()}
          rightIcon={<Flex color={"GREY"}>(kW)</Flex>}
          // rightIcon={"kW"}
        />

        <FormNumberTextInput
          placeHolder={t("TXT_Project_Installed_capacity")}
          inputLabel={""}
          addComma={true}
          onChange={(value: string) => {
            getNewState("energyOutputByContractor", value);
          }}
          hints={"by contractor"}
          inpputtedValue={displayData?.energyOutputByContractor.toString()}
          rightIcon={<Flex color={"GREY"}>(kW)</Flex>}
          // rightIcon={"kW"}
        />
      </Flex>
      {isPreviewMode && (
        <Flex flexDirection="row" width="100%" px="20px">
          <Button
            backgroundColor="WT"
            _hover={{ backgroundColor: "WT" }}
            onClick={() => onClose()}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            borderColor="BK"
            borderWidth="1px"
          >
            <Text color="BK">{t("TXT_Cancel")}</Text>
          </Button>
          <Button
            backgroundColor={returnByTheme()}
            _hover={{ backgroundColor: returnByTheme() }}
            onClick={() => {
              updateEleDetailState(previewState);
              onClose();
            }}
            marginTop="20px"
            borderRadius="10px"
            flex={1}
            marginLeft="20px"
          >
            <Text color="WT">{t("TXT_Save_change")}</Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      p="20px"
      // mb="20px"
      fontSize={18}
      fontWeight={"bold"}
      borderBottomColor={"DISABLEDBG"}
      borderBottomWidth={1}
    >
      {title}
    </Flex>
  );
};

export default EleDetailSection;
